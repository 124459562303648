import React from 'react'
import { Nav } from 'react-bootstrap'

const CustomTab = ({ item, eventKey }) => {
    return (
        <Nav.Link className="navi-link" eventKey={eventKey}>
            <span className="navi-text text-dark-50 font-size-h5 font-weight-bold">
                {item}
            </span>
        </Nav.Link>
    )
}

export default CustomTab
