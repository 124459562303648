import React from 'react'
import * as actions from '../../../_redux/folders/foldersActions';
import { useIntl } from 'react-intl';
import { RENAME_STRING, searches_url, CREATE_STRING } from '../../../../../../_metronic/_helpers';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setArhivaQueryValue, setArhivaURLparams, openAsideModal } from '../../../_redux/arhiva/ArhivaActions';

export const SaveExistingButton = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    return (
        <>
            <Button
                className={
                    "btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"}
                onClick={() => {
                    dispatch(actions.openFolderDialog(RENAME_STRING, searches_url))
                }}
            >
                {intl.formatMessage({id: 'MENU.OPTIONS.SAVE'})}
            </Button>
        </>
    )
}

export const NewSearchButton = () => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <>
            <Button
                className={
                    "btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"}
                onClick={() => {
                    history.push('/archive');
                    dispatch(setArhivaQueryValue(null));
                }}
            >
                {intl.formatMessage({ id: "COMMON.NEW_SEARCH" })}
            </Button>
        </>
    )
}

export const EditSearchButton = () => {
    const history = useHistory();
    const activeFolder = useSelector(
        (state) => (state.folders.activeFolder)
    );
    const dispatch = useDispatch();
    const { url_pretrage } = activeFolder;
    const intl = useIntl()
    return (
        <>
            <Button
                className={
                    "btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"}
                onClick={() => {
                    dispatch(setArhivaURLparams(url_pretrage));
                    history.push('/archive' + url_pretrage);
                }}
            >
                {intl.formatMessage({id: 'MENU.OPTIONS.EDIT'})}
            </Button>
        </>
    )
}

export const SaveSearchButton = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    return (
        <>
            <Button
                className={
                    "btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"}
                onClick={() => {
                    dispatch(actions.openFolderDialog(CREATE_STRING, searches_url))
                }}
            >
                {intl.formatMessage({ id: "COMMON.SAVE_SEARCH" })}
            </Button>
        </>
    )
}

export const FilterButton = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    return (
        <>
            <Button className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"
                onClick={() => dispatch(openAsideModal())}>
                {intl.formatMessage({ id: "MENU.OPTIONS.FILTER" })}
            </Button>
        </>
    )
}