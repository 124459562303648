import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";

import ProfileService from "../../Profile/service";
import {
  GET_MEDIA_LIST_URL,
  GET_POST_TYPES_URL,
  a_media_edit_url,
} from "../../../../_metronic/_helpers/Constants";
import { Table } from "../shared";
import { useSelector } from "react-redux";
import { sortCaret } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import { useHistory } from "react-router-dom";
import DeleteModal from "../shared/DeleteModal";
import MultipleDeleteModal from "../shared/MultipleDeleteModal";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { getMedia } from "../../../_redux/media/actions";
import { SearchTable } from "../shared/Table/SearchTable";

const MediaPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [getSearch, setSearch] = useState("");

  const service = new ProfileService();
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);

  const [filterBy, setFilterBy] = useState({
    type: "All",
    created_at: "All",
  });

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });

  const [sizePerPage, setSizePerPage] = useState(20);

  const history = useHistory();

  const [currentMedia, setCurrentMedia] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isMultipleDeleteModalVisible,
    setMultipleIsDeleteModalVisible,
  ] = useState(false);
  const [currentMediaIds, setCurrentMediaIds] = useState(null);

  const [startDate, setStartDate] = useState();

  const data = useSelector((state) => state?.media?.Index ?? []);
  const showLoader = useSelector((state) => state?.media?.showLoader ?? []);

  const fetchMediaList = async (page) => {
    let payload = {
      page: page - 1,
      size: sizePerPage,
      title: getSearch,
      post_type: filterBy.type === "All" ? null : filterBy.type,
      created_at:
        filterBy.created_at === "All" || filterBy.created_at === "Invalid date"
          ? null
          : filterBy.created_at,
      order_by: orderBy.order_by === "" ? null : orderBy.order_by,
      order_direction:
        orderBy.order_direction === "" ? null : orderBy.order_direction,
    };
    dispatch(getMedia(payload));

    // const response = await service.post(GET_MEDIA_LIST_URL, payload);
    // return response.data;
  };

  const { isLoading, isFetching } = useQuery(
    ["media", page, filterBy, orderBy, sizePerPage, getSearch],
    () => fetchMediaList(page),
    { keepPreviousData: true }
  );
  const postTypes = useQuery(
    ["postTypes"],
    async () => (await service.post(GET_POST_TYPES_URL)).data,
    { keepPreviousData: true }
  );

  const onSort = (field, order) => {
    const sortField = (field) => {
      switch (field) {
        case "media.name":
          return "media";
        case "post_type.name":
          return "type";
        default:
          return field;
      }
    };
    setOrderBy({
      order_by: sortField(field),
      order_direction: order,
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      sort: true,
      sortCaret: sortCaret,
      // formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_media_edit_url}/${row.id}`);
        },
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "post_type.name",
      text: intl.formatMessage({ id: "TH_TYPE" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_media_edit_url}/${row.id}`);
        },
        openDeleteModal: (row) => {
          setCurrentMedia(row);
          setIsDeleteModalVisible(true);
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  // if (isLoading) return null;
  const paginationOptions = {
    totalSize: data?.length ?? 0,
    sizePerPage,
    page,
  };

  const deleteSelectedItems = (selectedIds) => {
    setMultipleIsDeleteModalVisible(true);
    setCurrentMediaIds(selectedIds);
  };

  const searchByCreatedDate = (date) => {
    setStartDate(date);
    setFilterBy({
      ...filterBy,
      created_at: moment(date).format("YYYY-MM-DD"),
    });
  };

  return (
    <Card>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        currentItem={currentMedia}
        setCurrentItem={setCurrentMedia}
        itemType="media"
      />
      <MultipleDeleteModal
        isVisible={isMultipleDeleteModalVisible}
        setIsVisible={setMultipleIsDeleteModalVisible}
        currentItems={currentMediaIds}
        setCurrentItems={setCurrentMediaIds}
        setSelectedRows={setAllIds}
        itemType="media"
      />
      <CardHeader title={intl.formatMessage({ id: "MENU.MEDIA" })} sticky>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary ml-5"
            onClick={() => {
              history.push(a_media_edit_url);
            }}
          >
            {intl.formatMessage({ id: "MENU.NEW" })}{" "}
            {intl.formatMessage({ id: "MENU.MEDIA" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-lg-3">
            {!postTypes.isLoading && (
              <select
                className="form-control"
                name="type"
                placeholder="Filter by Types"
                onChange={(e) => {
                  setFilterBy({
                    ...filterBy,
                    type: e.target.value,
                  });
                  setPage(1);
                }}
                value={filterBy.type}
              >
                <option value={"All"}>All</option>
                {postTypes?.data?.data?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            )}
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              {intl.formatMessage({ id: "TH_TYPE" })}
            </small>
          </div>
          <div className="col-lg-3">
            <DatePicker
              className="form-control"
              selected={startDate}
              onChange={(date) => searchByCreatedDate(date)}
              dateFormat="dd.MM.yyyy"
              isClearable
              placeholderText="All"
            />
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Created Date
            </small>
          </div>
          <div className="col-lg-3"></div>
          <SearchTable setSearch={setSearch} />
        </div>
        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
          </div>
        )}

        <Table
          showLoader={showLoader}
          data={data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={setPage}
          isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
      </CardBody>
    </Card>
  );
};

export default MediaPage;
