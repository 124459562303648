import { put, takeLatest, call } from "redux-saga/effects";
import { setPostsData } from "./action";
import { getPostsCall } from "./Service";

function* attemptGetPostData(action) {
  const { payload } = action;
  try {
    const response = yield call(getPostsCall, payload);
    console.log("postType", response.data);
    yield put(setPostsData(response.data));
  } catch (error) {}
}

export function* saga() {
  yield takeLatest("GET_POST_DATA", attemptGetPostData);
}
