import { put, takeLatest, call } from "redux-saga/effects";
import {
  editPostSizeFromRedux,
  getAllChannelsData,
  getAllPostSizeData,
  setAllPostSizeData,
  showLoader,
} from "./actions";
import {
  createPostSizeCall,
  deleteAllPostsCall,
  getAllPostSizeDataCall,
  postDeleteCall,
  updatePostCall,
} from "./Service";

function* attemptGetPostSizeSaga(action) {
  try {
    yield put(showLoader(true));
    const response = yield call(getAllPostSizeDataCall);
    yield put(setAllPostSizeData(response.data));
    yield put(showLoader(false));
  } catch (error) {}
}

function* attemptCreatePostSize(action) {
  const { payload, toasts, intl, closeModal, setErrMessage } = action;
  try {
    const response = yield call(createPostSizeCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(action.toasts.hideToast());
    yield put(getAllPostSizeData());
    closeModal("exampleModal");
  } catch (error) {
    setErrMessage(error?.response?.data?.errors);
  }
}

function* attemptUpdate(action) {
  const { setErrMessage, closeModal, payload } = action;
  try {
    const response = yield call(updatePostCall, action);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
    yield put(editPostSizeFromRedux(payload));
    // yield put(getAllPostSizeData());
    closeModal("exampleModalEdit");
  } catch (error) {
    setErrMessage(error?.response?.data?.errors);
  }
}
function* attemptDeleteChannel(action) {
  try {
    const response = yield call(postDeleteCall, action.payload);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
    // yield put(getAllPostSizeData());
    // yield put(action.closeDeleteModal());
  } catch (error) {}
}

function* attemptDeleteAll(action) {
  try {
    const response = yield call(deleteAllPostsCall, action);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
    // yield put(getAllPostSizeData());
    // yield put(action.setAllIds([]));
  } catch (error) {}
}

export function* saga() {
  yield takeLatest("CREATE_POST_SIZE", attemptCreatePostSize);
  yield takeLatest("DELETE_POST_SIZE", attemptDeleteChannel);
  yield takeLatest("GET_ALL_POST_SIZE_DATA", attemptGetPostSizeSaga);
  yield takeLatest("EDIT_POST_SIZE", attemptUpdate);
  yield takeLatest("COMMON.Delete", attemptDeleteAll);
}
