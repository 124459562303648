/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { hasId, licni, searches_url, clipping_media_url, archive_url } from "../../../_helpers";
import { useSelector } from "react-redux";
import { FilterButton, NewSearchButton, SaveExistingButton, SaveSearchButton, EditSearchButton } from "../../../../app/modules/ECommerce/pages/arhiva/ArhivaButtons/Buttons";
import { CreateFolderButton, RemoveFolderButton, RenameFolderButton, PurgeFolderButton } from "../../../../app/modules/ECommerce/pages/folders/folder-buttons/Buttons";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const [showCreateButton, setShowCreateButton] = useState(false);
  const [showOtherButtons, setShowOtherButtons] = useState(true);
  const { currentState } = useSelector(
    (state) => ({ currentState: state.folders })
  )
  const { arhivaState } = useSelector(
    (state) => ({ arhivaState: state.arhiva })
  )
  const [type, setType] = useState('')
  useEffect(() => {
    if (location.pathname.includes(licni)) {
      setType(licni)
    }
    else if (location.pathname.includes(searches_url) || location.pathname.includes(archive_url)) {
      setType(searches_url);
    }
    if (location.pathname.includes(`/folders/${licni}`) || location.pathname.includes(`/folders/${searches_url}`)) {
      setShowCreateButton(true);
      if (hasId(location)) {
        setShowOtherButtons(true);
      }
      else {
        setShowOtherButtons(false);
      }
    }
    else if (location.pathname.includes(`/folders/${clipping_media_url}`)) {
      setShowCreateButton(false);
      if (hasId(location)) {
        setShowOtherButtons(true);
      }
      else {
        setShowOtherButtons(false);
      }
    }
    else {
      setShowCreateButton(false);
      setShowOtherButtons(false)
    }
  }, [location])

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      )
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const footer = getBreadcrumbsAndTitle("kt_footer", location.pathname);
    aside.breadcrumbs.length > 0 && subheader.setBreadcrumbs(aside.breadcrumbs);
    header.breadcrumbs.length > 0 && subheader.setBreadcrumbs(header.breadcrumbs);
    footer.breadcrumbs.length > 0 && subheader.setBreadcrumbs(footer.breadcrumbs);
    // eslint-disable-next-line
  }, [location.pathname, currentState]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
            </button>
          )}

          <BreadCrumbs items={subheader.breadcrumbs} />

          <div className="d-flex align-items-baseline mx-4">
            <h5 className="text-dark font-weight-bold my-2">
              {/*<small></small>*/}
            </h5>
          </div>
        </div>


        {/* Toolbar */}
        <div className="d-flex align-items-center">
          <span style={{ marginLeft: 5 }}>
            {/* buttons */}
            {showCreateButton && <CreateFolderButton type={type}/>}
            {showOtherButtons && (
              <>
                <RenameFolderButton />
                <RemoveFolderButton />
                {location.pathname.includes(searches_url) ? <EditSearchButton /> : <PurgeFolderButton />}
              </>
            )}
            {window.location.search && arhivaState.query &&
              <>
                <FilterButton />
                <NewSearchButton />

                {currentState?.activeFolder?.title && <SaveExistingButton />}
                {currentState?.activeFolder?.title === undefined && <SaveSearchButton />}
              </>
            }
          </span>
        </div>
      </div>
    </div >
  );
}
