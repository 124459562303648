import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getAllStreamDataCall = async (action) => {
  const {payload, size, page} = action
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  try {
    const response = await fetch(`https://streamapi.arhiva.me/api/get-all-streams?token=${payload}&page=${page}&limit=${size}`, requestOptions);
    const result = await response.text();
    let k = JSON.parse(result)
    return k;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getAllServersDataCall = async (token) => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  try {
    const response = await fetch(`https://streamapi.arhiva.me/api/get-all-servers?token=${token}&page=1&limit=1000`, requestOptions);
    const result = await response.text();
    let k = JSON.parse(result)
    return k;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const createStreamCall = async (data) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4) {
        resolve(this.responseText);
      }
    });

    xhr.open("POST", "https://streamapi.arhiva.me/api/add-stream");

    xhr.send(data);
  });
};

export const streamDeleteCall = (id) => {
  return axios.delete(`${ARHIVA_REST_URL}api/sizes/delete/${id}`);
};

export const updateStreamCall = (action) => {
  let data = action.payload;
  // let id = data.get("custom_cmd")
  
  // data.delete("custom_cmd")

 

return new Promise((resolve, reject) => {
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;

  xhr.addEventListener("readystatechange", function() {
    if (this.readyState === 4) {
      resolve(this.responseText);
    }
  });

  xhr.open("POST", `https://streamapi.arhiva.me/api/edit-stream/${action?.id}`);

  xhr.send(data);
});
};


export const startStreamCall = (action) => {
  return new Promise((resolve, reject) => {
    let row = action.payload;
    // WARNING: For GET requests, body is set to null by browsers.
    var data = new FormData();

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4) {
        resolve(this.responseText); // Resolve the promise with the response
      }
    });

    xhr.addEventListener("error", function() {
      reject(new Error("Request failed")); // Reject the promise with an error
    });

    xhr.open("GET", `https://streamapi.arhiva.me/api/start-stream/${row.id}?token=${row.token}`);

    xhr.send(data);
  });
};


export const stopStreamCall = (action) => {
  return new Promise((resolve, reject) => {
    let row = action.payload;
    // WARNING: For GET requests, body is set to null by browsers.
    var data = new FormData();

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4) {
        resolve(this.responseText); // Resolve the promise with the response
      }
    });

    xhr.addEventListener("error", function() {
      reject(new Error("Request failed")); // Reject the promise with an error
    });

    xhr.open("GET", `https://streamapi.arhiva.me/api/stop-stream/${row.id}?token=${row.token}`);

    xhr.send(data);
  });
};

export const deleteStreamCall = (action) => {
  return new Promise((resolve, reject) => {
    let row = action.payload;
    // WARNING: For GET requests, body is set to null by browsers.
    var data = new FormData();

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4) {
        resolve(this.responseText); // Resolve the promise with the response
      }
    });

    xhr.addEventListener("error", function() {
      reject(new Error("Request failed")); // Reject the promise with an error
    });

    xhr.open("GET", `https://streamapi.arhiva.me/api/delete-stream/${row.id}?token=${row.token}`);

    xhr.send(data);
  });
};


export const deleteAllStreamsCall = (action) => {
  let data = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/sizes/bulk-delete`, data);
};
