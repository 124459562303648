import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const ArhivaUIContext = createContext();

export function useArhivaUIContext() {
  return useContext(ArhivaUIContext);
}

export const FoldersUIConsumer = ArhivaUIContext.Consumer;

export function ArhivaUIProvider({ foldersUIEvents, children }) {
  // const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [favoriteIds, setFavoriteIds] = useState([]);
  // const setQueryParams = useCallback(nextQueryParams => {
  //     setQueryParamsBase(prevQueryParams => {
  //         if (isFunction(nextQueryParams)) {
  //             nextQueryParams = nextQueryParams(prevQueryParams);
  //         }

  //         if (isEqual(prevQueryParams, nextQueryParams)) {
  //             return prevQueryParams;
  //         }

  //         return nextQueryParams;
  //     });
  // }, []);

  const value = {
    // queryParams,
    // setQueryParamsBase,
    ids,
    setIds,
    favoriteIds,
    setFavoriteIds,
    // setQueryParams,
  };

  return (
    <ArhivaUIContext.Provider value={value}>
      {children}
    </ArhivaUIContext.Provider>
  );
}
