import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { ARHIVA_REST_URL } from "../../../../environments/environment.prod";
import * as toasts from "../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

export const ManageChannels = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getAllChannels();
    getUserChannels();
  }, []);

  const getAllChannels = () => {
    axios
      .post(`${ARHIVA_REST_URL}api/channels`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((e) => {});
  };

  const getUserChannels = () => {
    setLoader(true);
    axios
      .post(`${ARHIVA_REST_URL}api/users/get-user-channels`)
      .then((response) => {
        setSelectedChannel(
          response.data.data.map((d) => {
            return {
              value: d.id,
              label: d.name,
              priority: d?.pivot?.priority,
              approved: d?.pivot?.approved,
            };
          })
        );
      })
      .catch((e) => {})
      .finally(() => {
        setLoader(false);
      });
  };

  const handleSelectChannels = (e) => {
    setSelectedChannel(e);
  };

  const handleSetPriority = (e) => {
    let changeSelectedChannel = [...selectedChannel];
    changeSelectedChannel.map((up) => {
      up.priority = e.target.value;
      return up;
    });
    setSelectedChannel(changeSelectedChannel);
  };

  const handleSingleSelect = (e, userId) => {
    let changeChannelPriority = [...selectedChannel];
    changeChannelPriority.find((obj) => obj.value == userId).priority = e.value;
    setSelectedChannel(changeChannelPriority);
  };

  const handleApprovel = (e, userId) => {
    let changeChannelApprovel = [...selectedChannel];
    changeChannelApprovel.find((obj) => obj.value == userId).approved = e.value;
    setSelectedChannel(changeChannelApprovel);
  };

  const handleManageChannelCall = () => {
    let data = {};
    data.channels = selectedChannel?.map((selectedChannel) => {
      return {
        id: selectedChannel.value,
        priority: selectedChannel.priority ?? "",
        approved: selectedChannel.approved ?? 0,
      };
    });
    axios
      .post(`${ARHIVA_REST_URL}api/users/assign-user-channels`, data)
      .then((response) => {
        dispatch(
          toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
        );
        dispatch(toasts.hideToast());
      })
      .catch((e) => {
        setErrMessage(e.response.data.errors);
      });
  };

  return (
    <>
      <div className="flex-row-fluid ml-lg-8 ">
        <form className="card card-custom">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                {intl.formatMessage({ id: "MENU.CHANNEL.DESCRIPTION" })}
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                If you want you can manage channels
              </span>
            </div>

            <div className="card-toolbar">
              <button
                onClick={() => handleManageChannelCall()}
                type="button"
                className="btn btn-success mr-2"
              >
                {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
              </button>
              <a className="btn btn-secondary" href="/user/profile">
                {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
              </a>
            </div>
          </div>
          <div className="form">
            <div className="card-body">
              {loader == true ? (
                <>
                  <div className="w-100 d-flex justify-content-center">
                    <div className="spinner"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                      {intl.formatMessage({ id: "MENU.CHANNEL" })}
                    </label>
                    <div className="col-lg-9 col-xl-6 pr-0">
                      <Select
                        value={selectedChannel ?? []}
                        onChange={(e) => handleSelectChannels(e)}
                        isMulti
                        options={
                          data?.map((d) => {
                            return {
                              value: d.id,
                              label: d.name,
                              priority: "all",
                            };
                          }) ?? []
                        }
                      />
                      <div className="text-danger">
                        {errMessage?.channels ?? ""}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-3 row">
                    <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                      {intl.formatMessage({ id: "Priorities" })}
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div className="radio-inline">
                        <label className="radio">
                          <input
                            onClick={(e) => handleSetPriority(e)}
                            type="radio"
                            name="radios2"
                            defaultValue="not_sending"
                          />
                          <span />
                          {intl.formatMessage({ id: "User_Not_Sending" })}
                        </label>
                        <label className="radio">
                          <input
                            onClick={(e) => handleSetPriority(e)}
                            type="radio"
                            name="radios2"
                            defaultValue="all"
                          />
                          <span />
                          {intl.formatMessage({ id: "User_All" })}
                        </label>
                        <label className="radio">
                          <input
                            onClick={(e) => handleSetPriority(e)}
                            type="radio"
                            name="radios2"
                            defaultValue="important"
                          />
                          <span />
                          {intl.formatMessage({ id: "User_Important" })}
                        </label>
                      </div>
                    </div>
                  </div>
                  {selectedChannel?.map((channel) => {
                    return (
                      <>
                        <div className="d-flex align-items-center mb-2">
                          <span className="bullet bullet-bar bg-success align-self-stretch mr-2" />
                          <div className="col-xl-3 col-lg-3 col-form-label text-alert">
                            <a
                              href="#"
                              className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                            >
                              {channel?.label}
                            </a>
                            {/* <span className="text-muted font-weight-bold">def</span> */}
                          </div>
                          <div className="col-lg-3 pl-0">
                            <div
                              className="dropdown dropdown-inline w-100"
                              data-toggle="tooltip"
                              title
                              data-placement="left"
                              data-original-title="Quick actions"
                            >
                              <Select
                                onChange={(e) =>
                                  handleSingleSelect(e, channel.value)
                                }
                                value={{
                                  value: channel.priority,
                                  label: channel.priority,
                                }}
                                options={[
                                  {
                                    value: "not_sending",
                                    label: "not sending",
                                  },
                                  { value: "all", label: "all" },
                                  { value: "important", label: "important" },
                                ]}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 pr-0">
                            <div
                              className="dropdown dropdown-inline w-100"
                              data-toggle="tooltip"
                              title
                              data-placement="left"
                              data-original-title="Quick actions"
                            >
                              {channel.approved == 1 ? (
                                <>
                                  <span class="label label-lg label-light-primary label-inline">
                                    Approved
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span class="label label-lg label-light-primary label-inline">
                                    Disapproved
                                  </span>
                                </>
                              )}
                              {/* <Select
                                value={{
                                  value: channel.approved,
                                  label: channel.approved == 1?"Approved":"Disapproved",
                                }}
                                onChange={(e)=>handleApprovel(e, channel.value)}
                                options={[
                                  { value: 0, label: "Disapproved" },
                                  { value: 1, label: "Approved" },
                                ]}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
