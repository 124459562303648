import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/folders/foldersActions";
import { useHistory } from "react-router-dom";

const RemoveFolderDialog = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const folders = useSelector((state) => state.folders);
  const history = useHistory();
  const { activeFolder, activeId } = folders;
  const closeFormDialog = () => {
    dispatch(actions.closeFolderDialog());
  };
  const submitRemoveFolder = () => {
    dispatch(actions.deleteFolder(activeFolder.id, activeFolder.type));
    closeFormDialog();
    // history.push(`/folders/${activeFolder.type}`);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={true}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">
          {intl.formatMessage({ id: "DIALOG.REMOVE.TITLE" })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage(
              { id: "DIALOG.WARNING.REMOVE.MESSAGE" },
              {
                activeFoldertitle: (
                  <strong key="dialogkeyremove">{activeFolder.title}</strong>
                ),
              }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closeFormDialog();
            }}
            color="primary"
          >
            {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
          </Button>
          <Button
            onClick={() => {
              submitRemoveFolder();
            }}
            color="primary"
            autoFocus
          >
            {intl.formatMessage({ id: "DIALOG.WARNING.CONFIRM" })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveFolderDialog;
