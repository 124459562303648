import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { Table } from "../modules/Admin/shared";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as toasts from "../../app/modules/ECommerce/_redux/toasts/toastsActions";

import * as moment from "moment";
import { sortCaret } from "../../_metronic/_helpers";
import {
  createPostSize,
  deleteAll,
  deletePost,
  deletePostSizeFromRedux,
  delteMultiPostSizesFromRedux,
  editPost,
  editPostSizeFromRedux,
  getAllPostSizeData,
} from "../_redux/postSizes/actions";

export const PostSize = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // ---------------- state vars -----------------------
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [clickedRow, setClickedRow] = useState("");

  const [name, setName] = useState("");
  const [ordered_by, setOrdered_by] = useState("");
  const [errMessage, setErrMessage] = useState("");

  const [allIds, setAllIds] = useState([]);

  const data = useSelector((state) => state?.postSize?.Index ?? []);
  const showLoader = useSelector((state) => state?.category?.showLoader ?? []);

  // -------------- state vars end ---------------------

  const paginationOptions = {
    totalSize: data?.totalCount,
    sizePerPage,
    page,
  };

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });
  const onSort = (field, order) => {
    setOrderBy({
      order_by: field,
      order_direction: order,
    });
  };
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      headerStyle: { verticalAlign: "initial" },
    },

    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: (cell, row) => {
        return (
          <>
            <a
              href
              title="Edit customer"
              data-toggle="modal"
              data-target="#exampleModalEdit"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                setErrMessage("");
                setClickedRow(row.id);
                setName(row.name);
                setOrdered_by(row.order);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <title>
                    {intl.formatMessage({ id: "MENU.OPTIONS.EDIT" })}
                  </title>
                  <desc>Created with Sketch.</desc>
                  <defs />
                  <g
                    id="Stockholm-icons-/-Communication-/-Write"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x={0} y={0} width={24} height={24} />
                    <path
                      d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                      id="Path-11"
                      fill="#000000"
                      fillRule="nonzero"
                      transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                    />
                    <path
                      d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                      id="Path-57"
                      fill="#000000"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                  </g>
                </svg>
              </span>
            </a>

            {/* ---------------------------- Delete Button --------------------------- */}

            <span
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target={`#exampleModalDelete`}
              onClick={() => {
                setClickedRow(row.id);
              }}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              title={intl.formatMessage({
                id: "MENU.OPTIONS.DELETE",
              })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <path
                    d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                    fill="red"
                    fillRule="nonzero"
                  />
                  <path
                    d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                    fill="red"
                    opacity="0.3"
                  />
                </g>
              </svg>
              {/*end::Svg Icon*/}
            </span>
          </>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  useEffect(() => {
    getAllPostSize();
  }, [sizePerPage, page]);

  const getAllPostSize = () => {
    dispatch(getAllPostSizeData());
  };

  const handleCreatePostSize = () => {
    let data = {};
    data.name = name;
    data.order = ordered_by;
    data.is_deleted = 0;
    dispatch(createPostSize(data, toasts, intl, closeModal, setErrMessage));
  };
  const emptyFormValues = () => {
    setErrMessage("");
    setName("");
  };

  const closeModal = (id) => {
    window.$(`#${id}`).modal("hide");
  };

  const hanedleDelete = (id) => {
    console.log("here in del", id);
    dispatch(deletePostSizeFromRedux(id, closeModal));
    dispatch(deletePost(id, intl, toasts));
  };

  const handleUpdatePost = (id) => {
    let data = {};
    data.name = name;
    data.order = ordered_by;

    data.id = id;
    // dispatch(editPostSizeFromRedux(id, data, closeModal));
    dispatch(editPost(data, intl, toasts, closeModal, setErrMessage));
  };

  const deleteSelectedItems = (idsToDelete) => {
    let data = {};
    data.ids = idsToDelete;
    console.log("ids to be deleted", idsToDelete);
    dispatch(delteMultiPostSizesFromRedux(idsToDelete, closeModal, setAllIds));
    dispatch(deleteAll(data, toasts, intl));
  };

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "post_size" })}>
        <CardHeaderToolbar>
          <button
            className="btn btn-primary font-weight-bold"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={() => {
              emptyFormValues();
            }}
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              data-toggle="modal"
              data-target={`#exampleModalMultiDelete`}
              // onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>{" "}
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
          </div>
        )}
        <Table
          showLoader={showLoader}
          data={data.data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setSizePerPage={setSizePerPage}
          setPage={setPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
        {/* Modal create post start*/}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {intl.formatMessage({ id: "MENU.CREATE_POST_SIZE" })}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
              </div>
              <div className="modal-body">
                {" "}
                <div className="form-group">
                  <label>{intl.formatMessage({ id: "Name" })}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "Name" })}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrMessage("");
                    }}
                  />
                  <div className="text-danger">{errMessage?.name ?? ""}</div>
                </div>
                <div className="form-group">
                  <label>Order By</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Order by"}
                    value={ordered_by}
                    onChange={(e) => {
                      setOrdered_by(e.target.value);
                      setErrMessage("");
                    }}
                  />
                  <div className="text-danger">{errMessage?.order ?? ""}</div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  {intl.formatMessage({
                    id: "CONTACT.GENERAL.CANCEL.BUTTON",
                  })}
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold"
                  onClick={handleCreatePostSize}
                >
                  {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal create post end*/}

        {/* ---------------------- Delete post Modal -------------------- */}
        <div
          className="modal fade"
          id={`exampleModalDelete`}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Post Size
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
              </div>
              <div className="modal-body">
                {intl.formatMessage({
                  id: "POST_SIZE.DELETE_POST_SIMPLE.DESCRIPTION",
                })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  {intl.formatMessage({ id: "CONTACT.GENERAL.CANCEL.BUTTON" })}
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold"
                  onClick={() => {
                    hanedleDelete(clickedRow);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------- Update post Modal -------------------- */}
        <div
          className="modal fade"
          id={`exampleModalEdit`}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {intl.formatMessage({
                    id: "MENU.EDIT_POST_SIZE",
                  })}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
              </div>
              <div className="modal-body">
                {" "}
                <div className="form-group">
                  <label>{intl.formatMessage({ id: "Name" })}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "Name" })}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrMessage("");
                    }}
                  />
                  <div className="text-danger">{errMessage?.name ?? ""}</div>
                </div>
                <div className="form-group">
                  <label>Order By</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Order by"}
                    value={ordered_by}
                    onChange={(e) => {
                      setOrdered_by(e.target.value);
                      setErrMessage("");
                    }}
                  />
                  <div className="text-danger">{errMessage?.order ?? ""}</div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  {intl.formatMessage({
                    id: "CONTACT.GENERAL.CANCEL.BUTTON",
                  })}
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold"
                  onClick={() => {
                    handleUpdatePost(clickedRow);
                  }}
                >
                  {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------------------- Multi Delete Modal ------------------------------------ */}
        <div
          className="modal fade"
          id={`exampleModalMultiDelete`}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {/* {intl.formatMessage({
                    id: "CHANNELS.DELETE_CHANNEL_SIMPLE.TITLE",
                  })} */}
                  Delete Post-Sizes
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
              </div>
              <div className="modal-body">
                {/* {intl.formatMessage({
                    id: "CHANNELS.DELETE_CHANNEL_SIMPLE.DESCRIPTION",
                  })} */}
                Are you sure to permanently delete these post sizes?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold"
                  onClick={() => deleteSelectedItems(allIds)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
