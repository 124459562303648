/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { useIntl, FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as gconst from "../../../../../src/_metronic/_helpers/Constants";
import { sendMessage } from "../_redux/contactCrud";
import {
  showToast,
  hideToast,
} from "../../ECommerce/_redux/toasts/toastsActions";
import { useDispatch, useSelector } from "react-redux";

function ContactPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const personal_information = user ? user.personal_information : null;
  const initialValues = {
    fullname:
      personal_information &&
      personal_information?.first_name &&
      personal_information.last_name
        ? `${personal_information?.first_name} ${personal_information.last_name}`
        : "",
    phone:
      personal_information && personal_information.mobile
        ? `+${personal_information.mobile}`
        : "",
    email:
      personal_information && personal_information.email
        ? `${personal_information.email}`
        : "",
    message: "",
  };

  const ContactSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(
        gconst.min_input,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.min_input,
          }}
        />
      )
      .max(
        gconst.max_input,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.max_input,
          }}
        />
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .matches(
        /^[a-zA-Z\p{L}]+ [a-zA-Z\p{L}]+$/gu,
        intl.formatMessage({ id: "CONTACT.VALIDATION.NAME" })
      ),

    email: Yup.string()
      .email(<FormattedMessage id="AUTH.VALIDATION.EMAIL" />)
      .min(
        gconst.min_input,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.min_input,
          }}
        />
      )
      .max(
        gconst.max_input,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.max_input,
          }}
        />
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ContactSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      sendMessage(values.fullname, values.email, values.phone, values.message)
        .then((response) => {
          dispatch(showToast(response.data.status, response.data.title));
          dispatch(hideToast());
          disableLoading();
          setStatus(intl.formatMessage({ id: response.data.status }));
        })
        .catch((error) => {
          disableLoading();
          dispatch(
            showToast(error.response.data.status, error.response.data.title)
          );
          dispatch(hideToast());
          setStatus(intl.formatMessage({ id: error.response.data.status }));
        })
        .finally(() => {
          setIsRequested(true);
        });
    },
  });

  return (
    <>
      <div className="text-center pt-15">
        <h1 className="h2 font-weight-bolder text-dark mb-6">
          {intl.formatMessage({ id: "PAGE.CONTACT.TITLE" })}
        </h1>
        <div className="h4 text-dark-50 font-weight-normal">
          {intl.formatMessage({ id: "PAGE.CONTACT.SUBTITLE" })}
        </div>
        <div className="row">
          <div className="offset-md-3 col-md-6">
            <span className="svg-icon svg-icon-full">
              <SVG src={toAbsoluteUrl("/media/bg/contact-bg.svg")}></SVG>
            </span>
          </div>
        </div>
      </div>

      <div className="container card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h5 className="card-label">
              {intl.formatMessage({ id: "PAGE.CONTACT.FORM_TITLE" })}
            </h5>
          </div>
        </div>
        {!isRequested ? (
          <form
            className="kt-form"
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-xl-3"></div>
                <div className="col-xl-6">
                  <div className="form-group">
                    <input
                      className="form-control form-control-solid h-auto py-5 px-6"
                      name="fullname"
                      placeholder={intl.formatMessage({
                        id: "CONTACT.INPUT.NAME",
                      })}
                      {...formik.getFieldProps("fullname")}
                    />
                    {formik.touched.fullname && formik.errors.fullname ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.fullname}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control form-control-solid h-auto py-5 px-6"
                      name="phone"
                      placeholder={intl.formatMessage({
                        id: "CONTACT.INPUT.PHONE",
                      })}
                      {...formik.getFieldProps("phone")}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control form-control-solid h-auto py-5 px-6"
                      name="email"
                      type="email"
                      autoComplete="chrome-off"
                      placeholder={intl.formatMessage({
                        id: "CONTACT.INPUT.EMAIL",
                      })}
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control form-control-solid h-auto py-5 px-6"
                      name="message"
                      rows={4}
                      placeholder={intl.formatMessage({
                        id: "CONTACT.INPUT.MESSAGE",
                      })}
                      {...formik.getFieldProps("message")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-3"></div>
                <div className="col-xl-6" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="btn btn-primary font-weight-bold mr-2"
                  >
                    {intl.formatMessage({ id: "CONTACT.GENERAL.SEND.BUTTON" })}
                    {loading && <span className="spinner spinner-white"></span>}
                  </button>
                  <button
                    type="reset"
                    className="btn btn-clean font-weight-bold"
                  >
                    {intl.formatMessage({
                      id: "CONTACT.GENERAL.CANCEL.BUTTON",
                    })}
                  </button>
                </div>
                <div className="col-xl-3"></div>
              </div>
            </div>
          </form>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
      </div>
    </>
  );
}

export default ContactPage;
