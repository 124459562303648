import { put, takeLatest, call } from "redux-saga/effects";
import {
  deleteSource,
  editSourceAction,
  getSourcesData,
  setSourcesData,
  showLoader,
} from "./actions";
import {
  getSourcesDataCall,
  saveSourcesDataCall,
  editSourceCall,
  getSourceByIdCall,
  deleteSourceCall,
} from "./Service";
import { a_source_url } from "../../../_metronic/_helpers";

function* attemptGetSourcesData(actions) {
  try {
    yield put(showLoader(true));
    const response = yield call(getSourcesDataCall, actions.payload);
    yield put(setSourcesData(response?.data));
    yield put(showLoader(false));
  } catch (errors) {}
}

function* attemptCreateSourcesData(actions) {
  try {
    const response = yield call(saveSourcesDataCall, actions.payload);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(actions.history.push(a_source_url));
    return response;
  } catch (errors) {}
}

function* attemptEditSource(actions) {
  try {
    const response = yield call(editSourceCall, actions);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(actions.history.push(a_source_url));
    return response;
  } catch (errors) {
    yield put(
      actions.toasts.showToast(
        500,
        actions.intl.formatMessage({ id: errors.response.data.message })
      )
    );
    yield put(actions.toasts.hideToast());
  }
}

function* attemptGetSourceById(actions) {
  const { setCurrentSource } = actions;
  try {
    const response = yield call(getSourceByIdCall, actions.payload);
    yield put(setCurrentSource(response.data.data[0]));
  } catch (errors) {}
}

function* attemptDeleteSource(action) {
  try {
    const response = yield call(deleteSourceCall, action);
    // yield put(getSourcesData());
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
  } catch (error) {
    yield put(
      action.toasts.showToast(
        500,
        action.intl.formatMessage({ id: error.response.data.message })
      )
    );
    yield put(action.toasts.hideToast());
  }
}

export default function* saga() {
  yield takeLatest("GET_SOURCES_DATA", attemptGetSourcesData);
  yield takeLatest("SAVE_SOURCE", attemptCreateSourcesData);
  yield takeLatest("EDIT_SOURCE", attemptEditSource);
  yield takeLatest("GET_SOURCE_BY_ID", attemptGetSourceById);
  yield takeLatest("DELETE_SOURCE", attemptDeleteSource);
}
