import { put, takeLatest, delay, call } from "redux-saga/effects";
import { getSubjectsData, setSubjectsData, setSubjectsErrors } from "./actions";
import {
  createSubjectCall,
  deleteSubjectCall,
  editSubjectCall,
  getSubjectsCall,
} from "./Service";

function* attemptGetSubjectsData(action) {
  const { payload } = action;
  try {
    const response = yield call(getSubjectsCall, payload);
    console.log("rsponse", response.data);
    yield put(setSubjectsData(response.data));
  } catch (e) {}
}

function* attemptCreateSubject(action) {
  const { payload, toasts, intl, setLoader } = action;
  try {
    const response = yield call(createSubjectCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (e) {
    yield put(setSubjectsErrors(e.response.data));
    yield delay(3000);
    yield put(setSubjectsErrors([]));
  } finally {
    setLoader(false);
  }
}

function* attemptEditSubjects(action) {
  const { payload, toasts, intl, modalClose, getData } = action;
  try {
    const response = yield call(editSubjectCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response?.data?.title }))
    );
    yield put(toasts.hideToast());
    if (modalClose) {
      modalClose("#exampleModalAssign");
      yield put(getSubjectsData(getData));
    }
  } catch (e) {
    console.log("here in e");
    yield put(setSubjectsErrors(e?.response?.data));
    yield delay(3000);
    yield put(setSubjectsErrors([]));
  } finally {
    modalClose(false);
  }
}

function* attemptDeleteSubject(action) {
  const { payload, toasts, intl } = action;
  try {
    const response = yield call(deleteSubjectCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (e) {}
}

export function* saga() {
  yield takeLatest("GET_SUBJECTS_DATA", attemptGetSubjectsData);
  yield takeLatest("CREATE_SUBJECT", attemptCreateSubject);
  yield takeLatest("EDIT_SUBJECT", attemptEditSubjects);
  yield takeLatest("DELETE_SUBJECT", attemptDeleteSubject);
}
