import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { useFormik } from "formik";
import axios from "axios";
import { ARHIVA_REST_URL } from "../../../../environments/environment";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as gconst from "../../../../../src/_metronic/_helpers/Constants";
import * as auth from "../_redux/authRedux";
import {
  register,
  validateEmail,
  validatePhoneNumber,
  validateUsername,
} from "../_redux/authCrud";
import * as actions from "../../ECommerce/_redux/toasts/toastsActions";
const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  username: "",
  phoneNumber: "",
  institution_name: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { intl } = props;
  const [userQuery, setUserQuery] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [email, setEmail] = useState(undefined);
  const [validatedUsername, setValidatedUsername] = useState(false);
  const [username, setUsername] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [validatedPhoneNumber, setValidatedPhoneNumber] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [id, setId] = useState(null);

  useEffect(() => {
    setId(window.$(`#kt_select2_1`));
  }, []);

  useEffect(() => {
    if (id != null) {
      id.on("select2:select", function(e) {
        console.log("e:", e);
        if (typeof e.params.data.id === "string") {
          window
            .$(".select2-selection__choice")
            .append(`<span>${e.params.data.id}</span>`);
          setCompanyName(e.params.data.id);
        } else {
          setCompanyName(e.params.data.name);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (companyName != "") {
      console.log("companyName", companyName);
      formik.values.institution_name = companyName;
    }
  }, [companyName]);

  useEffect(() => {
    if (id !== null) {
      id.select2({
        width: "resolve",
        placeholder: "Please select a company",
        allowClear: true,
        tags: true,
        maximumSelectionLength: 1,
        templateResult: (row) => {
          return row.name;
        },
        templateSelection: (row) => {
          return row.name;
        },
        minimumInputLength: 1,
        ajax: {
          delay: 250,
          url: `${ARHIVA_REST_URL}api/insitutions/search`,
          method: "post",
          data: function(params) {
            var query = {
              title: params.term,
            };

            // Query parameters will be ?search=[term]&type=public
            return query;
          },
          processResults: function(data, params) {
            console.log("data", data.data.data);
            params.page = params.page || 1;

            return {
              results: data.data.data,
            };
          },
        },
      });
      window.$(".select2-search__field").addClass("form-control");
    }
  }, [id]);

  const RegistrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(
        gconst.min_input,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.min_input,
          }}
        />
      )
      .max(
        gconst.max_input,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.max_input,
          }}
        />
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // .matches(
    //   /^[a-zA-Z\p{L}]+ [a-zA-Z\p{L}]+$/gu,
    //   intl.formatMessage({ id: "CONTACT.VALIDATION.NAME" })
    // ),
    lastname: Yup.string()
      .min(
        gconst.min_input,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.min_input,
          }}
        />
      )
      .max(
        gconst.max_input,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.max_input,
          }}
        />
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phoneNumber: Yup.string()
      .min(
        gconst.min_input,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.min_input,
          }}
        />
      )
      .max(
        gconst.max_input,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.max_input,
          }}
        />
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // .matches(
    //   /^[a-zA-Z\p{L}]+ [a-zA-Z\p{L}]+$/gu,
    //   intl.formatMessage({ id: "CONTACT.VALIDATION.NAME" })
    // ),
    email: Yup.string()
      .email(<FormattedMessage id="AUTH.VALIDATION.EMAIL" />)
      .min(
        gconst.min_input,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.min_input,
          }}
        />
      )
      .max(
        gconst.max_input,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.max_input,
          }}
        />
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    username: Yup.string()
      .min(
        gconst.usrn_min_lenght,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.usrn_min_lenght,
          }}
        />
      )
      .max(
        gconst.usrn_max_lenght,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.usrn_max_lenght,
          }}
        />
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .matches(
        /^[a-zA-Z_-]+$/g,
        intl.formatMessage({ id: "CONTACT.VALIDATION.USERNAME" })
      ),

    pwdspecchar: Yup.boolean(),
    pwdcpaschar: Yup.boolean(),
    password: Yup.string()
      .min(
        gconst.pwd_min_lenght,
        <FormattedMessage
          id="AUTH.VALIDATION.PWD_MIN_LENGHT"
          values={{
            pwd_min_lenght: gconst.pwd_min_lenght,
          }}
        />
      )
      .max(
        gconst.pwd_max_lenght,
        <FormattedMessage
          id="AUTH.VALIDATION.PWD_MAX_LENGHT"
          values={{
            pwd_max_lenght: gconst.pwd_max_lenght,
          }}
        />
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .when("pwdspecchar", {
        is: (val) => gconst.pwd_char === true,
        then: Yup.string().matches(
          /[!@#$%^&*(),.?":{}|<>]/g,
          intl.formatMessage({ id: "AUTH.VALIDATION.PWD_SPEC_CHAR" })
        ),
      })
      .when("pwdcpaschar", {
        is: (val) => gconst.pwd_char === true,
        then: Yup.string().matches(
          /[A-Z]/g,
          intl.formatMessage({ id: "AUTH.VALIDATION.PWD_CAPS_LETTER" })
        ),
      }),

    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )

      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          <FormattedMessage id="AUTH.VALIDATION.PASSWORD_MATCH" />
        ),
      }),

    acceptTerms: Yup.bool().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.AGREEMENT_REQUIRED" })
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      register(
        values.email,
        values.firstname,
        values.lastname,
        values.username,
        values.phoneNumber,
        values.institution_name,
        values.password
      )
        .then((res) => {
          dispatch(actions.showToast(200, res.data.title));
          dispatch(actions.hideToast());
          disableLoading();
          history.push("/auth/login");
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(actions.showToast(400, error.response.data.title));
          dispatch(actions.hideToast());
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  useEffect(() => {
    if (validatedEmail) {
      setEmail(formik.values.email);
      validateEmail(formik.values.email)
        .then(() => {
          setEmailError(false);
        })
        .catch((error) => {
          setEmailError(true);
          dispatch(
            actions.showToast(
              error.response.data.status,
              error.response.data.title
            )
          );
          dispatch(actions.hideToast());
        });
    }
  }, [dispatch, formik.values.email, validatedEmail]);

  useEffect(() => {
    if (formik.values.email !== email) {
      setValidatedEmail(false);
    }
  }, [email, formik.values.email]);

  useEffect(() => {
    if (validatedUsername) {
      setUsername(formik.values.username);
      validateUsername(formik.values.username)
        .then(() => {
          setUsernameError(false);
        })
        .catch((error) => {
          setUsernameError(true);
          dispatch(
            actions.showToast(
              error.response.data.status,
              error.response.data.title
            )
          );
          dispatch(actions.hideToast());
        });
    }
  }, [dispatch, formik.values.username, validatedUsername]);

  useEffect(() => {
    if (formik.values.username !== username) {
      setValidatedUsername(false);
    }
  }, [formik.values.username, username]);

  useEffect(() => {
    if (validatedPhoneNumber) {
      setPhoneNumber(formik.values.phoneNumber);
      validatePhoneNumber(formik.values.phoneNumber)
        .then(() => {
          setPhoneNumberError(false);
        })
        .catch((error) => {
          setPhoneNumberError(true);
          dispatch(
            actions.showToast(
              error.response.data.status,
              error.response.data.title
            )
          );
          dispatch(actions.hideToast());
        });
    }
  }, [dispatch, formik.values.phoneNumber, validatedPhoneNumber]);

  useEffect(() => {
    if (formik.values.phoneNumber !== phoneNumber) {
      setValidatedPhoneNumber(false);
    }
  }, [formik.values.phoneNumber, phoneNumber]);

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.REGISTER.SUB_TITLE" />
        </p>
      </div>

      <form
        onFocus={() => {
          if (!formik.errors.email && formik.values.email) {
            setValidatedEmail(true);
          }
          if (!formik.errors.username && formik.values.username) {
            setValidatedUsername(true);
          }
          if (!formik.errors.phoneNumber && formik.values.phoneNumber) {
            setValidatedPhoneNumber(true);
          }
        }}
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Firstname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.FIRST_NAME",
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "firstname"
            )}`}
            name="firstname"
            {...formik.getFieldProps("firstname")}
          />
          {formik.touched.firstname && formik.errors.firstname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Firstname */}

        {/* begin: Lastname */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.LAST_NAME",
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "lastname"
            )}`}
            name="lastname"
            {...formik.getFieldProps("lastname")}
          />
          {formik.touched.lastname && formik.errors.lastname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Lastname */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.EMAIL",
            })}
            type="email"
            autoComplete="chrome-off"
            className={`form-control form-control-solid h-auto py-5 px-6 ${emailError &&
              "is-invalid"} ${getInputClasses("email")}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Username */}
        <div className="form-group fv-plugins-icon-container">
          <input
            autoComplete="off"
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.USERNAME",
            })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${usernameError &&
              "is-invalid"} ${getInputClasses("username")}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        {/* end: Username */}

        {/* begin: Phone Number */}
        <div className="form-group fv-plugins-icon-container">
          <input
            autoComplete="off"
            placeholder={"Phone Number"}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${phoneNumberError &&
              "is-invalid"} ${getInputClasses("phoneNumber")}`}
            name="Phone Number"
            {...formik.getFieldProps("phoneNumber")}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phoneNumber}</div>
            </div>
          ) : null}
        </div>
        {/* end: Phone Number */}
        <div className="form-group row">
          <div className="col-lg-12 col-md-9 col-sm-12">
            <select
              className="form-control select2"
              id="kt_select2_1"
              multiple
              name="param"
            >
              <option label="Label"></option>
            </select>
          </div>
        </div>

        {/* begin: Company*/}

        {/* <div class="form-group row">
          <div class=" col-lg-12 col-md-9 col-sm-12">
            <select
              class="js-example-placeholder-single js-states form-control"
              id="kt_select2_1"
              name="param"
            >
              <option></option>
            </select>
          </div>
        </div> */}
        {/* end: Company */}
        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.PASSWORD",
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.CONFIRM_PASSWORD",
            })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              {...formik.getFieldProps("acceptTerms")}
            />
            {intl.formatMessage({
              id: "AUTH.VALIDATION.AGREEMENT_ACCEPT",
            })}{" "}
            <Link to="/legal" target="_blank" rel="noopener noreferrer">
              {intl.formatMessage({
                id: "AUTH.VALIDATION.AGREEMENT",
              })}
            </Link>
            .
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.values.acceptTerms ||
              emailError ||
              usernameError
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>
              {" "}
              <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
            </span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
