import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";
import { DebounceInput } from "react-debounce-input";
import ProfileService from "../../Profile/service";
import {
  GET_ALL_USERS,
  a_users_edit_url,
  a_users_url,
  a_users_url_view,
} from "../../../../_metronic/_helpers/Constants";
import { Table } from "../shared";
import { sortCaret } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import { useHistory } from "react-router-dom";
import DeleteModal from "../shared/DeleteModal";
import MultipleDeleteModal from "../shared/MultipleDeleteModal";
import PublishModal from "../shared/PublishModal";
import { ARHIVA_REST_URL } from "../../../../environments/environment.prod";
import {
  AssignChannelsModal,
  channelsModal,
} from "../shared/AssignChannelsModal/Index";
import * as toasts from "../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { EditUserModal } from "../shared/EditUserModal/Index";
import { CreateUserModal } from "../shared/CreateUserModal/Index";
import { SearchTable } from "../shared/Table/SearchTable";
import {
  assignChannelToUser,
  changeStatus,
  createUser,
  editUser,
  getAllUsers,
  setUserStatusOnSite,
} from "../../../_redux/users/actions";
import { useSelector } from "react-redux";
import { getAllChannelsData } from "../../../_redux/channels/actions";
import { isEmpty } from "lodash";

const UsersPage = () => {
  const intl = useIntl();

  const [getSearch, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [callLoader, setCallLoader] = useState(false);
  const [approvelStatus, setApprovelStatus] = useState("");
  const [isVerified, setIsVerified] = useState(null);
  const [filterBy, setFilterBy] = useState({
    status: null,
    belonging: "All",
    is_verified: "all",
  });

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });

  const [channels, setChannels] = useState([]);
  const channelsData = useSelector((state) => state?.channels?.Index ?? []);

  const userRolesData = useSelector(
    (state) => state?.users?.UserRoles?.data ?? []
  );

  useEffect(() => {
    if (!isEmpty(channelsData)) {
      setChannels(channelsData?.data);
    }
  }, [channelsData]);

  let payload = {
    page: page - 1,
    size: sizePerPage,
    status: filterBy.status,
    title: getSearch,
  };
  useEffect(() => {
    dispatch(getAllChannelsData(payload));
  }, []);

  const dispatch = useDispatch();
  const usersData = useSelector((state) => state?.users?.Index ?? []);
  const showLoader = useSelector((state) => state?.users?.showLoader ?? []);

  const handleAssignUser = (id) => {
    let data = {};
    // data.approved = approvelStatus == "approved" ? true : false;
    data.channels = selectedChannels?.map((selectedChannel) => {
      return {
        id: selectedChannel.value,
        priority: selectedChannel.priority ?? "",
        approved: selectedChannel.approved == 1 ? true : false,
      };
    });
    dispatch(
      assignChannelToUser(
        data,
        toasts,
        modalClose,
        intl,
        setErrMessage,
        activeUser?.id,
        payload,
        getUserData
      )
    );
  };

  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errMessage, setErrMessage] = useState("");
  const [bool, setBool] = useState(true);

  const modalClose = (id) => {
    window.$(id).modal("hide");
  };

  const handleCreateUser = () => {
    setCallLoader(true);
    let data = {};
    data.selectedRole = selectedRole?.map((sr) => sr.value);
    data.username = userName;
    data.name = firstName + " " + lastName;
    data.first_name = firstName;
    data.last_name = lastName;
    data.email = email;
    data.password = password;
    dispatch(
      createUser(
        data,
        setBool,
        bool,
        modalClose,
        intl,
        toasts,
        setErrMessage,
        setCallLoader
      )
    );
  };

  const [selectedEditUser, setSelectedEditUser] = useState(null);
  const [newbool, setNewBool] = useState(null);

  const handleEditUser = () => {
    setCallLoader(true);
    let data = {};
    data.selectedRole = selectedRole?.map((sr) => sr.value);
    data.username = userName;
    data.name = firstName + " " + lastName;
    data.first_name = firstName;
    data.last_name = lastName;
    data.email = email;
    data.password = password;
    data.id = selectedEditUser?.id;

    dispatch(
      editUser(
        data,
        setBool,
        bool,
        modalClose,
        intl,
        toasts,
        setErrMessage,
        setCallLoader
      )
    );
  };
  useEffect(() => {
    setUserName(selectedEditUser?.username);
    setFirstName(selectedEditUser?.first_name);
    setLastName(selectedEditUser?.last_name);
    setEmail(selectedEditUser?.email);
  }, [selectedEditUser, newbool]);

  const service = new ProfileService();

  const getUserData = {
    page: page - 1,
    size: sizePerPage,
    title: getSearch,
    status: filterBy.status === "All" ? null : filterBy.status,
    belonging: filterBy.belonging === "All" ? null : filterBy.belonging,
    order_by: orderBy.order_by === "" ? null : orderBy.order_by,
    order_direction:
      orderBy.order_direction === "" ? null : orderBy.order_direction,
    username: filterBy.username || "",
    is_verified:
      filterBy.is_verified == "all"
        ? null
        : filterBy.is_verified == "false"
        ? 0
        : 1,
    role: filterBy.role === "all" ? null : filterBy.role,
  };

  Object.keys(getUserData).forEach((key) => {
    if (getUserData[key] === null || getUserData[key] === "") {
      delete getUserData[key];
    }
  });

  let { isLoading, isFetching, data } = useQuery(
    [
      "users",
      page,
      filterBy,
      orderBy,
      sizePerPage,
      // channels,
      bool,
      getSearch,
      isVerified,
    ],
    () => dispatch(getAllUsers(getUserData)),
    {
      keepPreviousData: true,
    }
  );
  const [priority, setPriority] = useState("");
  const history = useHistory();

  const [currentUser, setCurrentUser] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isMultipleDeleteModalVisible,
    setMultipleIsDeleteModalVisible,
  ] = useState(false);
  const [isPublishModalVisible, setPublishModalVisible] = useState(false);
  const [publishType, setPublishType] = useState(true);
  const [currentUsers, setCurrentUsers] = useState(null);

  const [activeUser, setActiveUser] = useState(null);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [loader, setLoader] = useState(false);

  const onSort = (field, order) => {
    setOrderBy({
      order_by: field,
      order_direction: order,
    });
  };
  const changeUserStatus = (row) => {
    let data = {
      status: [null, "", 0].includes(row?.is_verified) ? 1 : 0,
      user_id: row?.id,
    };

    dispatch(setUserStatusOnSite(row));
    dispatch(changeStatus(data, intl, toasts, getUserData));
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "username",
      text: intl.formatMessage({ id: "TH_USERNAME" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_users_edit_url}/${row.id}`);
        },
      },
      // formatter: (username) => {
      //   return username[0].toLowerCase() + username.slice(1);
      // },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "first_name",
      text: intl.formatMessage({ id: "TH_FIRST_NAME" }),
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "last_name",
      text: intl.formatMessage({ id: "TH_LAST_NAME" }),
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "mobile",
      text: intl.formatMessage({ id: "TH_MOBILE" }),
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "is_verified",
      text: "VERIFIED/UNVERIFIED",
      sort: true,
      sortCaret: sortCaret,
      formatter: (is_verified) => {
        return [null, "", 0].includes(is_verified) ? (
          <span className="label label-lg label-light-danger label-inline">
            Unverified
          </span>
        ) : (
          <span className="label label-lg label-light-success label-inline">
            Verified
          </span>
        );
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "TH_STATUS" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (status) => {
        return status === 0 || status === "" ? (
          <span className="label label-lg label-light-danger label-inline">
            Disabled
          </span>
        ) : (
          <span className="label label-lg label-light-success label-inline">
            Enabled
          </span>
        );
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },

    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy");
      },
      // formatter: columnFormatters.ColorColumnFormatter,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy");
      },
      // formatter: columnFormatters.ColorColumnFormatter,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        isUser: (row) => {
          let isUservar = true;
        },
        changeUserStatus: (row) => {
          changeUserStatus(row);
        },

        viewUserPage: (row) => {
          history.push(`${a_users_url_view}/${row.id}`);
        },
        openEditModal: (row) => {
          history.push(`${a_users_edit_url}/${row.id}/account`);
          // setNewBool(!newbool);
          // setErrMessage("");
          // setSelectedEditUser(
          //   usersData.data.find((user) => user?.id === row?.id)
          // );
        },
        openAssignModal: (row) => {
          setErrMessage("");
          setSelectedChannels(
            row?.channels?.map((channel) => {
              return {
                value: channel.id,
                label: channel.name,
                priority: channel.pivot.priority,
                approved: channel.pivot.approved,
              };
            })
          );
          setActiveUser(row);
        },
        openDeleteModal: (row) => {
          setCurrentUser(row);
          setIsDeleteModalVisible(true);
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  // if (isLoading) return null;
  const paginationOptions = {
    totalSize: usersData?.totalCount,
    sizePerPage,
    page,
  };

  const deleteSelectedItems = (selectedIds) => {
    setMultipleIsDeleteModalVisible(true);
    setCurrentUsers(selectedIds);
  };

  const publishSelectedItems = (selectedIds, type) => {
    setPublishModalVisible(true);
    setCurrentUsers(selectedIds);
    setPublishType(type);
  };
  const emptyFormValues = () => {
    setSelectedRole([]);
    setErrMessage("");
    setUserName("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
  };

  return (
    <Card>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        currentItem={currentUser}
        setCurrentItem={setCurrentUser}
        itemType="users"
      />
      <MultipleDeleteModal
        isVisible={isMultipleDeleteModalVisible}
        setIsVisible={setMultipleIsDeleteModalVisible}
        currentItems={currentUsers}
        setCurrentItems={setCurrentUsers}
        itemType="users"
        setAllIds={setAllIds}
      />
      <PublishModal
        isVisible={isPublishModalVisible}
        setIsVisible={setPublishModalVisible}
        currentItems={currentUsers}
        setCurrentItems={setCurrentUsers}
        publishType={publishType}
        itemType="users"
      />
      <AssignChannelsModal
        setApprovelStatus={setApprovelStatus}
        setPriority={setPriority}
        activeUser={activeUser}
        channels={channels}
        setSelectedChannels={setSelectedChannels}
        selectedChannels={selectedChannels}
        handleAssignUser={handleAssignUser}
        errMessage={errMessage}
        setErrMessage={setErrMessage}
      />
      <CreateUserModal
        callLoader={callLoader}
        handleCreateUser={handleCreateUser}
        setSelectedRole={setSelectedRole}
        selectedRole={selectedRole}
        setUserName={setUserName}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        setPassword={setPassword}
        errMessage={errMessage ?? ""}
        setErrMessage={setErrMessage}
        userName={userName}
        firstName={firstName}
        lastName={lastName}
        email={email}
        password={password}
      />

      <CardHeader title={intl.formatMessage({ id: "MENU.USERS" })} sticky>
        <CardHeaderToolbar>
          <button
            type="button"
            data-toggle="modal"
            data-target="#exampleModalCreate"
            className="btn btn-primary ml-5"
            onClick={() => {
              emptyFormValues();
              history.push(`${a_users_url}`);
            }}
          >
            {intl.formatMessage({ id: "MENU.NEW" })}{" "}
            {intl.formatMessage({ id: "MENU.USER" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-lg-3">
            <DebounceInput
              minLength={2}
              debounceTimeout={1000}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              placeholder="Filter by Username"
              className="form-control"
            />
          </div>
          <div className="col-lg-2">
            <select
              className="form-control"
              name="type"
              placeholder="Filter by Status"
              onChange={(e) => {
                setFilterBy({
                  ...filterBy,
                  status: e.target.value == "0" ? 0 : e.target.value,
                });
                setPage(1);
              }}
              value={filterBy.status}
            >
              <option value={"All"}>All</option>
              <option value={1}>Enabled</option>
              <option value={0}>Disabled</option>
            </select>
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Status
            </small>
          </div>
          <div className="col-lg-2">
            <select
              className="form-control"
              name="type"
              placeholder="Filter by Affiliation "
              onChange={(e) => {
                setFilterBy({
                  ...filterBy,
                  belonging: e.target.value,
                });
                setPage(1);
              }}
              value={filterBy.belonging}
            >
              <option value={"All"}>All</option>
              <option value={"Arhimed"}>Arhimed</option>
              <option value={"PC"}>PC</option>
              <option value={"Represent"}>Represent</option>
            </select>
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Affiliation
            </small>
          </div>
          <div className="col-lg-2">
            <select
              className="form-control"
              name="type"
              placeholder="Filter by Verified/Unverified"
              onChange={(e) => {
                setFilterBy({
                  ...filterBy,
                  is_verified: e.target.value,
                });
                setPage(1);
              }}
              value={isVerified}
            >
              <option value={"all"}>All</option>
              <option value={true}>Verified</option>
              <option value={false}>Unverified</option>
            </select>
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              Verified/Unverified
            </small>
          </div>
          <div className="col-lg-3">
            <select
              className="form-control"
              name="type"
              placeholder="Filter by Roles"
              onChange={(e) => {
                setFilterBy({
                  ...filterBy,
                  role: e.target.value,
                });
                setPage(1);
              }}
            >
              <option value={"all"}>All</option>
              {userRolesData?.map((usr) => {
                return <option value={usr.name}>{usr.name}</option>;
              })}
            </select>
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Roles
            </small>
          </div>
        </div>
        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm"
              onClick={() => publishSelectedItems(allIds, true)}
            >
              <i className="fa fa-stream"></i> Publish
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm mr-5"
              onClick={() => publishSelectedItems(allIds, false)}
            >
              <i className="fa fa-sync-alt"></i> Unpublish
            </button>
          </div>
        )}
        <Table
          // showLoader={showLoader}
          data={usersData?.data ? usersData?.data : []}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={setPage}
          isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
      </CardBody>
    </Card>
  );
};

export default UsersPage;
