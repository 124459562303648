import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  Input,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { ARHIVA_REST_URL } from "../../../../environments/environment";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import Select from "react-select";

import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";

import { useIntl } from "react-intl";
import {
  createSubject,
  getSubjectsData,
  updateSubjects,
} from "../../../_redux/Subjects/actions";
import { a_subject_url } from "../../../../_metronic/_helpers";

export default function Edit() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [loader, setLoader] = useState(false);

  const [subjectName, setSubjectName] = useState("");
  const [instActid, setInstActId] = useState([]);
  const [assignedInstitutes, setAssignedInstitutes] = useState([]);

  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPage] = useState(1);

  // const [id, setId] = useState(null);

  const subjectErrs = useSelector((state) => state?.subjects?.Errors ?? []);
  const subjectData = useSelector((state) => state?.subjects?.Index ?? []);

  // -----------------------------------------------------------------------------------------------
  const [inputValue, setInputValue] = useState([]);
  const [valData, setValData] = useState([]);
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");

  // useEffect(() => {
  //   setInputValue({ label: "yellow", value: 2 });
  // }, [params]);

  // const renderData = async () => {
  //   const API = await axios.get(
  //     "https://jsonplaceholder.typicode.com/comments"
  //   );
  //   const serverResponse = API.data;
  //   const dropDownValue = serverResponse?.map((response) => ({
  //     value: response.id,
  //     label: response.email,
  //   }));
  //   setDropDownOpt(dropDownValue);
  // };
  const onChange = (event) => {
    setId(event.value);
    setEmail(event.label);
  };

  const loadOptions = async (inputValue, callback) => {
    let d = {};
    d.page = page - 1;
    d.size = sizePerPage;
    d.title = inputValue;
    const response = await axios.post(
      `${ARHIVA_REST_URL}api/insitutions/search`,
      d
    ); //use that input value in the endpoint
    let data = await response.data.data.data;
    callback(
      data?.map((d) => {
        return { value: d.id, label: d.name };
      })
    );
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue({ inputValue });
    return inputValue;
  };

  // -----------------------------------------------------------------------------------------------

  useEffect(() => {
    if (params) {
      let currentSubject = subjectData?.data?.filter(
        (sub) => sub.id == params.id
      );
      if (currentSubject) {
        setSubjectName(currentSubject[0]?.name);
        setAssignedInstitutes(currentSubject[0]?.institutes);
      }
    }
  }, [subjectData]);

  useEffect(() => {
    if (!subjectData?.data?.length) getAllSubjects();
  }, [page, sizePerPage]);

  const getAllSubjects = () => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;
    dispatch(getSubjectsData(data));
  };

  useEffect(() => {
    if (assignedInstitutes)
      setValData(
        assignedInstitutes?.map((v) => {
          return { value: v.id, label: v.name };
        })
      );
  }, [assignedInstitutes]);

  const handleChange = (e) => {
    setValData(e);
  };

  const backToSubjects = () => {
    history.push(a_subject_url);
  };

  const handleCreateSubject = () => {
    if (params.id != null) {
      setLoader(true);
      console.log("edit");
      let data = {};
      data.id = params.id;
      data.institute_ids = valData?.map((k) => k.value) ?? [];
      data.name = subjectName;
      dispatch(updateSubjects(data, toasts, intl, setLoader));
    } else {
      setLoader(true);
      let data = {};
      data.institute_ids = valData?.map((k) => k.value) ?? null;
      data.name = subjectName;
      dispatch(createSubject(data, toasts, intl, setLoader));
    }
  };

  // useEffect(() => {
  //   if (id != null) {
  //     id.select2({
  //       width: "resolve",
  //       placeholder: "Select Institute Name",
  //       allowClear: true,
  //       tags: true,
  //       multiple: true,
  //       // maximumSelectionLength: 1,
  //       templateResult: (row) => {
  //         return row.name;
  //       },
  //       templateSelection: (row) => {
  //         return row.name;
  //       },
  //       minimumInputLength: 1,
  //       ajax: {
  //         delay: 250,
  //         url: `${ARHIVA_REST_URL}api/insitutions/search`,
  //         method: "post",
  //         data: function(params) {
  //           var query = {
  //             title: params.term,
  //           };

  //           // Query parameters will be ?search=[term]&type=public
  //           return query;
  //         },
  //         processResults: function(data, params) {
  //           params.page = params.page || 1;

  //           return {
  //             results: data.data.data,
  //           };
  //         },
  //       },
  //       // data: [{ id: 1, text: "Hello!", selected: true }],
  //     });
  //     window.$(".select2-search__field").addClass("form-control");
  //   }
  // }, [id]);

  // useEffect(() => {
  //   if (id != null) {
  //     let inst = assignedInstitutes?.map((p) => {
  //       return new Option(p.name, p.id, true, true);
  //     });
  //     id.append(inst).trigger("change");
  //   }
  //   let x = assignedInstitutes?.forEach((p, i) => {
  //     setTimeout(function() {
  //       window.$(".select2-selection__choice")[i].append(p.name);
  //       // window.$(".select2-selection__choice")[i].attr("id", "value");
  //       window
  //         .$(window.$(".select2-selection__choice")[i])
  //         .attr("id", `SomeID-${i}`);
  //       window.$(`#SomeID-${i}`).click(function() {
  //         alert(`Handler for ${i} `);
  //       });
  //     }, 1000);
  //   });
  // }, [id, assignedInstitutes]);

  // useEffect(() => {
  //   setId(window.$(`#kt_select2_1`));
  // }, []);

  // useEffect(() => {
  //   if (id != null) {
  //     // window.$(`#SomeID-0`).on("select2:unselecting", function(e) {
  //     //   console.log(e);
  //     //   // setInstActId(null);
  //     // });
  //     window.$(`#SomeID-0`).click(function() {
  //       alert("Handler for .click() called.");
  //     });
  //   }
  // }, [id]);

  // useEffect(() => {
  //   if (id != null) {
  //     id.on("select2:select", function(e) {
  //       //   setInstitutionOptions(e.params.data);
  //       setInstActId((instActid) => [...instActid, e.params.data.id]);
  //     });
  //   }
  // }, [id]);

  return (
    <>
      <Card>
        <CardHeader title={params?.id ? "Subject Edit" : "Subject Create"}>
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={() => backToSubjects()}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              {/* {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })} */}
              Back
            </button>
            <button
              disabled={loader}
              type="submit"
              className={
                loader
                  ? "btn btn-primary ml-2 spinner spinner-right"
                  : "btn btn-primary ml-2 "
              }
              onClick={() => handleCreateSubject()}
            >
              {/* {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })} */}
              Save Changes
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="mt-5">
            <form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-12">
                  <label>Subject Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Activity Name"
                    onChange={(e) => setSubjectName(e.target.value)}
                    value={subjectName}
                  />

                  {subjectErrs?.errors?.name ? (
                    <div className="text-danger">
                      {subjectErrs.errors.name[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* <div className="form-group row">
                <div className="col-lg-12">
                  <label>Select Institute</label>
                  <Select
                    options={InstitutionsData?.map((ins) => {
                      return { value: ins.id, label: ins.name };
                    })}
                    onChange={(e) => {
                      handleInstituteSelect(e);
                    }}
                    value={institutionOptions}
                    searchable={(e) => {
                      handleSearch(e);
                    }}
                  />
                  {ErrorMessages?.errors?.institute_id ? (
                    <div className="text-danger">
                      {ErrorMessages.errors.institute_id[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div> */}
              <div className="form-group row">
                <div className="col-lg-12 col-md-9 col-sm-12">
                  <label>Select Institute</label>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    loadOptions={loadOptions}
                    defaultOptions
                    onInputChange={(e) => handleInputChange(e)}
                    onChange={(e) => handleChange(e)}
                    value={valData}
                  />
                  {/* <select
                    className="form-control select2"
                    id="kt_select2_1"
                    multiple
                    name="param[]"
                  >
                    <option label="Label"></option>
                    {params.id ? (
                      <option label="Label" selected value></option>
                    ) : (
                      ""
                    )}
                  </select> */}
                  {/* {ErrorMessages?.errors?.institute_id ? (
                    <div className="text-danger">
                      {ErrorMessages.errors.institute_id[0]}
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
              {/* <div className="form-group row">
                <div className="col-lg-12">
                  <div>
                    <label className="col-form-label ">Status</label>
                    <div className="">
                      <input
                        // checked={crStatus == 1 ? true : false}
                        data-switch="true"
                        type="checkbox"
                        data-on-text="Yes"
                        data-handle-width="50"
                        data-off-text="No"
                        data-on-color="danger"
                        id="is_public"
                        name="Status"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              <button type="button" style={{ display: "none" }} />
            </form>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
