import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";

import ProfileService from "../../Profile/service";
import {
  GET_ALL_SOURCES_URL,
  a_source_edit_url,
} from "../../../../_metronic/_helpers/Constants";
import { Table } from "../shared";
import { sortCaret } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import { useHistory } from "react-router-dom";
import DeleteModal from "../shared/DeleteModal";
import MultipleDeleteModal from "../shared/MultipleDeleteModal";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { useIntl } from "react-intl";
import { SearchTable } from "../shared/Table/SearchTable";
import CustomDatePicker from "../shared/DatePicker/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { getSourcesData } from "../../../_redux/sources/actions";

const SourcesPage = () => {
  const intl = useIntl();
  const data = useSelector((state) => state?.sources?.Index ?? []);
  const showLoader = useSelector((state) => state?.sources?.showLoader ?? []);
  const [getSearch, setSearch] = useState("");
  const [dateValue, setDateValue] = useState(new Date());

  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);

  const [filterBy, setFilterBy] = useState({
    created_at: null,
  });

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });

  const [sizePerPage, setSizePerPage] = useState(20);

  const history = useHistory();
  const dispatch = useDispatch();
  const [currentSource, setCurrentSource] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isMultipleDeleteModalVisible,
    setMultipleIsDeleteModalVisible,
  ] = useState(false);
  const [currentSources, setCurrentSources] = useState(null);

  const fetchSources = async (page) => {
    dispatch(
      getSourcesData({
        page: page - 1,
        size: sizePerPage,
        title: getSearch,
        created_at:
          !filterBy.created_at || filterBy.created_at === "Invalid date"
            ? null
            : filterBy.created_at,
        order_by: !orderBy.order_by ? null : orderBy.order_by,
        order_direction: !orderBy.order_direction
          ? null
          : orderBy.order_direction,
      })
    );
    // const response = await service.post(GET_ALL_SOURCES_URL);
    // return response.data;
  };

  const { isLoading, isFetching } = useQuery(
    [page, filterBy, orderBy, sizePerPage, getSearch],
    () => fetchSources(page),
    { keepPreviousData: true }
  );

  const onSort = (order_by, order_direction) =>
    setOrderBy({ order_by, order_direction });

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,

      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      sort: true,
      sortCaret: sortCaret,
      // formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_source_edit_url}/${row.id}`);
        },
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_source_edit_url}/${row.id}`);
        },
        openDeleteModal: (row) => {
          setCurrentSource(row);
          setIsDeleteModalVisible(true);
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  // if (isLoading) return null;
  const paginationOptions = {
    totalSize: data?.totalCount ?? 0,
    sizePerPage,
    page,
  };

  const deleteSelectedItems = (selectedIds) => {
    setMultipleIsDeleteModalVisible(true);
    setCurrentSources(selectedIds);
  };

  return (
    <Card>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        currentItem={currentSource}
        setCurrentItem={setCurrentSource}
        itemType="source"
      />
      <MultipleDeleteModal
        isVisible={isMultipleDeleteModalVisible}
        setIsVisible={setMultipleIsDeleteModalVisible}
        currentItems={currentSources}
        setCurrentItems={setCurrentSources}
        setSelectedRows={setAllIds}
        itemType="source"
      />
      <CardHeader title={intl.formatMessage({ id: "MENU.SOURCE" })} sticky>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary ml-5"
            onClick={() => {
              history.push(a_source_edit_url);
            }}
          >
            {intl.formatMessage({ id: "MENU.NEW" })}{" "}
            {intl.formatMessage({ id: "MENU.SOURCE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row justify-content-between">
          <div className="col-lg-3">
            <CustomDatePicker id={"sourceDate"} setDateValue={setDateValue} />
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Created Date
            </small>
          </div>
          <SearchTable setSearch={setSearch} />
        </div>
        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
          </div>
        )}
        <Table
          // showLoader={showLoader}
          data={data.data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={setPage}
          isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
      </CardBody>
    </Card>
  );
};

export default SourcesPage;
