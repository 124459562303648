import { put, takeLatest, call } from "redux-saga/effects";
import { a_media_url } from "../../../_metronic/_helpers";
import { getMedia, setAllMediaData, showLoader } from "./actions";
import {
  createMediaCall,
  deleteMediaCall,
  editMediaCall,
  getAllMediaCall,
  multiDeleteCall,
} from "./Service";

function* attemptGetMedia(action) {
  try {
    // yield put(showLoader(true));
    const response = yield call(getAllMediaCall, action.payload);
    yield put(setAllMediaData(response.data.data));
    // yield put(showLoader(false));
  } catch (error) {}
}

function* attemptCreateMedia(action) {
  try {
    const response = yield call(createMediaCall, action);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
    yield put(action.history.push(a_media_url));
    yield put(getMedia());
  } catch (error) {}
}

function* attemptEditMedia(action) {
  try {
    const response = yield call(editMediaCall, action);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
    yield put(action.history.push(a_media_url));
    yield put(getMedia());
  } catch (error) {}
}

function* attemptDeleteMedia(action) {
  try {
    const response = yield call(deleteMediaCall, action);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
    yield put(getMedia());
  } catch (error) {}
}

function* attemptMultiDeleteMedia(action) {
  try {
    const response = yield call(multiDeleteCall, action);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
    yield put(getMedia());
  } catch (error) {}
}

export function* saga() {
  yield takeLatest("GET_MEDIA_DATA", attemptGetMedia);
  yield takeLatest("CREATE_MEDIA", attemptCreateMedia);
  yield takeLatest("EDIT_MEDIA", attemptEditMedia);
  yield takeLatest("DELETE_MEDIA", attemptDeleteMedia);
  yield takeLatest("DELETE_MULTI_MEDIA", attemptMultiDeleteMedia);
}
