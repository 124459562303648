import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const createChannelCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/channels/create`, data);
};

export const getAllChannelsDataCall = (data) => {
  if (data.status === "All") {
    delete data.status;
  }
  return axios.post(`${ARHIVA_REST_URL}api/channels`, data);
};

export const channelDeleteCall = (id) => {
  return axios.post(`${ARHIVA_REST_URL}api/channels/delete/${id}`);
};

export const selectedChannelDelete = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/channels/delete`, data);
};

export const updateChannelCall = (action) => {
  let id = action.id;
  let data = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/channels/update/${id}`, data);
};

export const assignUserCall = (action) => {
  let id = action.id;
  let data = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/channels/${id}/assign-users`, data);
};

export const enableChannelStatusCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/channels/change-status`, data);
};

export const disableChannelStatusCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/channels/change-status`, data);
};
