export const getSocialCategoryData = (payload) => {
  return {
    type: "GET_SOCIAL_CATEGORY_DATA",
    payload: payload,
  };
};

export const setSocialCategoryData = (payload) => {
  return {
    type: "SET_SOCIAL_CATEGORY_DATA",
    payload: payload,
  };
};

export const changeSocialMediaStatus = (payload, toasts, intl) => {
  return {
    type: "CHANGE_SOCIAL_MEDIA_STATUS",
    payload: payload,
    toasts: toasts,
    intl: intl,
  };
};

export const setSocialStatus = (payload) => {
  return {
    type: "SET_SOCIAL_STATUS",
    payload: payload,
  };
};

export const setMultiSocialStatus = (payload, status) => {
  return {
    type: "SET_MULTI_STATUS",
    payload: payload,
    status: status,
  };
};
