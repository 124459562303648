import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, useMediaQuery, useTheme, DialogActions, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { closeFolderDialog } from '../../../_redux/folders/foldersActions';
import { useIntl } from 'react-intl';
import { Document, Page } from 'react-pdf/dist/entry.webpack';

const PdfDialog = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const intl = useIntl();
    const file = {
        url: 'url'
    }
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            aria-labelledby="form-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="form-dialog-title">
                Title
            </DialogTitle>
            <DialogContent>
                {/* <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                // onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document> */}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(closeFolderDialog());
                    }}
                    color="primary">
                    {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PdfDialog
