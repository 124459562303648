import React from "react";
import AsideMenuOptions from "./AsideMenuOptions";

const AsideMenuItem = ({ folderId, data, type }) => {
  // console.log("inside", data, type);
  return (
    <>
      <span className="menu-text">{data?.title}</span>
      <AsideMenuOptions data={data ?? []} id={folderId} type={type} />
    </>
  );
};

export default AsideMenuItem;
