export const getAllPostSizeData = () => {
  return {
    type: "GET_ALL_POST_SIZE_DATA",
  };
};

export const setAllPostSizeData = (payload) => {
  return {
    type: "SET_ALL_POST_SIZE_DATA",
    payload: payload,
  };
};

export const createPostSize = (
  payload,
  toasts,
  intl,
  closeModal,
  setErrMessage
) => {
  return {
    type: "CREATE_POST_SIZE",
    payload: payload,
    toasts: toasts,
    intl: intl,
    closeModal: closeModal,
    setErrMessage: setErrMessage,
  };
};

// -------------------------------------- DELETE --------------------------------------------------

export const deletePost = (payload, intl, toasts) => {
  return {
    type: "DELETE_POST_SIZE",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const deletePostSizeFromRedux = (id, closeModal) => {
  return {
    type: "DELETE_POST_SIZE_FROM_REDUX",
    id: id,
    closeModal: closeModal,
  };
};

// ------------------------------------- Edit ---------------------------------

export const editPost = (payload, intl, toasts, closeModal, setErrMessage) => {
  return {
    type: "EDIT_POST_SIZE",
    payload: payload,
    intl: intl,
    toasts: toasts,
    closeModal: closeModal,
    setErrMessage: setErrMessage,
  };
};

export const editPostSizeFromRedux = (payload) => {
  return {
    type: "EDIT_POST_SIZE_FROM_REDUX",
    payload: payload,
  };
};
// --------------------------------------- Delete All ---------------------------

export const deleteAll = (payload, toasts, intl) => {
  return {
    type: "COMMON.Delete",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const delteMultiPostSizesFromRedux = (ids, closeModal, setAllIds) => {
  return {
    type: "DELETE_MULTI_POST_SIZE_FROM_REDUX",
    ids: ids,
    closeModal: closeModal,
    setAllIds: setAllIds,
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});
