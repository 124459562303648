import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";

import ProfileService from "../../Profile/service";
import {
  GET_POST_TYPES_URL,
  a_post_type_edit_url,
} from "../../../../_metronic/_helpers/Constants";
import { Table } from "../shared";
import { useDispatch } from "react-redux";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { sortCaret } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import { useHistory } from "react-router-dom";
import DeleteModal from "../shared/DeleteModal";
import MultipleDeleteModal from "../shared/MultipleDeleteModal";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { useIntl } from "react-intl";
import CustomDatePicker from "../shared/DatePicker/CustomDatePicker";
import {
  getPostTypeData,
  sendNewOrderData,
  setPostTypeData,
} from "../../../_redux/PostType/actions";
import { useSelector } from "react-redux";

const PostTypesPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const service = new ProfileService();
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);
  const [ptData, setPtData] = useState([]);

  const [filterBy, setFilterBy] = useState({
    created_at: null,
  });

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });

  const postTypeData = useSelector((state) => state?.postType?.Index ?? []);

  const [sizePerPage, setSizePerPage] = useState(20);

  const history = useHistory();

  const [currentPostType, setCurrentPostType] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isMultipleDeleteModalVisible,
    setMultipleIsDeleteModalVisible,
  ] = useState(false);
  const [currentPostTypes, setCurrentPostTypes] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [dateValue, setDateValue] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const [list, setList] = useState(null);

  const [noOfRows, setNoOfRows] = useState(5);
  const [totPage, setTotPage] = useState(null);

  const [skipNum, setSkipNum] = useState(0);

  // useEffect(() => {
  //   let sortArr = postTypeData?.data?.sort((a, b) =>
  //     a?.order_by > b?.order_by ? 1 : -1
  //   );
  //   setPtData(sortArr);
  // }, [postTypeData?.data]);

  // const fetchPostTypes = async (page) => {
  //   setShowLoader(true);
  //   const response = await service.post(GET_POST_TYPES_URL, {
  //     page: page - 1,
  //     size: sizePerPage,
  //     created_at:
  //       !filterBy.created_at || filterBy.created_at === "Invalid date"
  //         ? null
  //         : filterBy.created_at,
  //     order_by: !orderBy.order_by ? null : orderBy.order_by,
  //     order_direction: !orderBy.order_direction
  //       ? null
  //       : orderBy.order_direction,
  //   });
  //   setShowLoader(false);
  //   return response.data;
  // };

  // const { isLoading, isFetching, data } = useQuery(
  //   ["post-type", page, filterBy, orderBy, sizePerPage],
  //   () => fetchPostTypes(page),
  //   { keepPreviousData: true }
  // );

  // useEffect(() => {
  //   if (postTypeData.data) {
  //     setPtData(postTypeData.data);
  //   }
  // }, [postTypeData?.data]);

  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (postTypeData?.data?.length && counter == 0) {
      setCounter(1);
      sortArrayByOrderBy();
    }
  }, [postTypeData?.data]);

  const sortArrayByOrderBy = () => {
    let srtArr = postTypeData?.data?.sort((a, b) =>
      a.order_by > b.order_by ? 1 : -1
    );
    setPtData(srtArr);
  };

  useEffect(() => {
    window.$("tbody").sortable({
      change: function(event, ui) {},
      update: function(event, ui) {},
      stop: function(event, ui) {
        countRows();
      },
    });
  }, [ptData]);

  const countRows = () => {
    updateNames();
  };

  useEffect(() => {
    var list = window.$("tbody");
    setList(list);
    initDemo();

    // window.$("#change-ord").click(function() {
    // list.sortable({
    //   stop: function(event, ui) {
    //     updateNames(window.$(this));
    //   },
    //   handle: ".handle",
    // });
    // });

    function initDemo() {
      window.$(".ord-by").val(function() {
        return this.name;
      });
    }

    function showNameAsValue(el) {
      el.value = el.name;
    }
  }, []);

  const checkIdsSelected = (e, sr) => {
    const newIds = [...allIds];
    newIds.push(sr.id);
    if (e.target.checked) {
      const newIds = [...allIds];
      newIds.push(sr.id);
      setAllIds(newIds);
    } else {
      const newIds = [...allIds];
      newIds.pop(sr.id);
      setAllIds(newIds);
    }
  };

  const getPostType = () => {
    let payload = {
      page: page - 1,
      size: noOfRows,
      created_at:
        !filterBy.created_at || filterBy.created_at === "Invalid date"
          ? null
          : filterBy.created_at,
      order_by: "order_by",
      // !orderBy.order_by ? null : orderBy.order_by,
      order_direction: "asc",
      // !orderBy.order_direction
      // ? null
      // : orderBy.order_direction,
    };

    dispatch(getPostTypeData(payload));
  };
  const updateNames = () => {
    let tmp = [...ptData];
    let arr = [];
    list.find("tr").each(function(idx) {
      var inp = window.$(this).find("input.test");
      arr.push(inp[0].value);
    });
    tmp = tmp.map((t) => {
      let bo = (page - 1) * noOfRows;
      let index = arr.indexOf("" + t.id);
      t.order_by = index + 1 + bo;
      return t;
    });
    let dataT = {
      data: tmp,
      totalCount: postTypeData.totalCount,
    };

    let b = dataT?.data?.map((d) => {
      return {
        id: d.id,
        order_by: d.order_by,
      };
    });

    dispatch(setPostTypeData(dataT));
    dispatch(sendNewOrderData(b));
  };

  useEffect(() => {
    getPostType();
  }, [page, filterBy, orderBy, noOfRows]);

  const onSort = (order_by, order_direction) =>
    setOrderBy({ order_by, order_direction });

  const columns = [
    {
      dataField: "id",
      text: "ID",
      // sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      // sort: true,
      sortCaret: sortCaret,
      // formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_post_type_edit_url}/${row.id}`);
        },
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "is_public",
      text: intl.formatMessage({ id: "TH_IS_PUBLIC" }),
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      formatter: (val) => {
        return val ? "Yes" : "No";
      },
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_post_type_edit_url}/${row.id}`);
        },
        openDeleteModal: (row) => {
          setCurrentPostType(row);
          setIsDeleteModalVisible(true);
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  // if (isLoading) return null;
  const paginationOptions = {
    totalSize: postTypeData?.totalCount ?? 0,
    sizePerPage,
    page,
  };

  const deleteSelectedItems = (selectedIds) => {
    setMultipleIsDeleteModalVisible(true);
    setCurrentPostTypes(selectedIds);
  };

  const searchByCreatedDate = (date) => {
    setStartDate(date);
    setFilterBy({
      ...filterBy,
      created_at: moment(date).format("YYYY-MM-DD"),
    });
  };

  // const getNumberOfPages = () => {
  //   let npg = postTypeData?.data / noOfRows;

  //   console.log(npg);
  // };

  const [bool, setBool] = useState(false);

  useEffect(() => {
    let npg = postTypeData?.totalCount / noOfRows;
    let relNo = Math.ceil(npg);
    setTotPage(relNo);
  }, [postTypeData?.data]);

  useEffect(() => {
    window.$("#pagination-demo").twbsPagination("destroy");
  }, [bool]);

  useEffect(() => {
    if (totPage) {
      console.log("totPage", totPage);
      // window.$("#pagination-demo").twbsPagination("destroy");
      window.$("#pagination-demo").twbsPagination({
        totalPages: totPage ?? 1,
        visiblePages: 6,
        next: ">",
        prev: "<",
        first: "<<",
        last: ">>",
        onPageClick: function(event, page) {
          setCounter(0);
          setPage(page);
          //fetch content and render here
          // window.$("#page-content").text("Page " + page) + " content here";
        },
      });
    }
  }, [ptData]);

  useEffect(() => {
    window
      .$(".page-link")
      .addClass("btn btn-icon btn-sm border-0 btn-light  mr-2 my-1");
  });

  return (
    <Card>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        currentItem={currentPostType}
        setCurrentItem={setCurrentPostType}
        itemType="post-type"
      />
      <MultipleDeleteModal
        isVisible={isMultipleDeleteModalVisible}
        setIsVisible={setMultipleIsDeleteModalVisible}
        currentItems={currentPostTypes}
        setCurrentItems={setCurrentPostTypes}
        setSelectedRows={setAllIds}
        itemType="post-type"
      />
      <CardHeader title={intl.formatMessage({ id: "MENU.POST-TYPE" })} sticky>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary ml-5"
            onClick={() => {
              history.push(a_post_type_edit_url);
            }}
          >
            {intl.formatMessage({ id: "MENU.NEW" })}{" "}
            {intl.formatMessage({ id: "MENU.POST-TYPE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-lg-2">
            {/* <DatePicker
              className="form-control"
              selected={startDate}
              onChange={(date) => searchByCreatedDate(date)}
              dateFormat="dd.MM.yyyy"
              isClearable
              placeholderText="All"
            /> */}
            <CustomDatePicker id={"post-type"} setDateValue={setDateValue} />

            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Created Date
            </small>
          </div>
        </div>
        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
          </div>
        )}
        <div className="react-bootstrap-table table-responsive">
          <table
            className="table table table-head-custom table-vertical-center overflow-hidden"
            id="table1"
            name="table1"
          >
            <thead>
              <th>
                {" "}
                <input type="checkbox" style={{ display: "none" }} />
                <label className="checkbox checkbox-single">
                  <input type="checkbox" />
                  <span />
                </label>
              </th>
              <th>ORDER BY</th>
              <th>NAME</th>
              <th>CREATED</th>
              <th>UPDATED</th>
              <th>IS PUBLIC</th>
              <th className="text-right pr-0">ACTIONS</th>
            </thead>
            <tbody>
              {ptData?.map((sr) => {
                return (
                  <tr id="fla" style={{ cursor: "grab" }}>
                    <td>
                      {" "}
                      {/* <input
                        onChange={(e) => checkIdsSelected(e, sr)}
                        type="checkbox"
                        style={{ display: "hidden" }}
                      /> */}
                      <label className="checkbox checkbox-single">
                        <input
                          onChange={(e) => checkIdsSelected(e, sr)}
                          type="checkbox"
                        />
                        <span />
                      </label>
                    </td>
                    <td className="ord-by" name={sr.order_by}>
                      <input type="hidden" class="test" value={sr.id} />
                      {sr.order_by}
                    </td>
                    <td>{sr.name}</td>
                    <td>
                      {moment(sr.created_at).format("DD.MM.yyyy HH:mm:ss")}
                    </td>
                    <td>
                      {moment(sr.updated_at).format("DD.MM.yyyy HH:mm:ss")}
                    </td>
                    <td>{sr.is_public ? "Yes" : "No"}</td>
                    <td className="text-right pr-0">
                      <a
                        title="Edit customer"
                        data-toggle="modal"
                        data-target="#exampleModalEdit"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => {
                          history.push(`${a_post_type_edit_url}/${sr.id}`);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <title>
                              Stockholm-icons / Communication / Write
                            </title>
                            <desc>Created with Sketch.</desc>
                            <defs />
                            <g
                              id="Stockholm-icons-/-Communication-/-Write"
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect
                                id="bound"
                                x={0}
                                y={0}
                                width={24}
                                height={24}
                              />
                              <path
                                d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                id="Path-11"
                                fill="#000000"
                                fillRule="nonzero"
                                transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                              />
                              <path
                                d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                id="Path-57"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                        </span>
                      </a>
                      <a
                        title="Delete customer"
                        className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                        onClick={() => {
                          setCurrentPostType(sr);
                          setIsDeleteModalVisible(true);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <title>Stockholm-icons / General / Trash</title>
                            <desc>Created with Sketch.</desc>
                            <defs />
                            <g
                              id="Stockholm-icons-/-General-/-Trash"
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect
                                id="bound"
                                x={0}
                                y={0}
                                width={24}
                                height={24}
                              />
                              <path
                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                id="round"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                              <path
                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                id="Shape"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                        </span>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center flex-wrap false show">
            <div className="d-flex flex-wrap py-2 mr-3 ">
              <ul
                id="pagination-demo"
                class="pagination-sm d-flex flex-wrap py-2 mr-3"
              ></ul>
            </div>
            <div className="d-flex align-items-center py-3">
              <select
                id="nor"
                onChange={(e) => {
                  setNoOfRows(e.target.value);
                  setBool(!bool);
                }}
                className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light false"
                style={{ width: "75px" }}
              >
                <option className="btn" value={5}>
                  5
                </option>
                <option className="btn" value={10}>
                  10
                </option>
                <option className="btn" value={20}>
                  20
                </option>
                <option className="btn" value={30}>
                  30
                </option>
                <option className="btn" value={50}>
                  50
                </option>
              </select>
              {/* <span className="react-bootstrap-table-pagination-total">
                Showing rows from 1 to 20
              </span> */}
            </div>
          </div>
        </div>
        {/* <Table
          showLoader={showLoader}
          data={postTypeData?.data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={setPage}
          // isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        /> */}
      </CardBody>
    </Card>
  );
};

export default PostTypesPage;
