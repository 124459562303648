import { createSlice } from "@reduxjs/toolkit";
const _ = require("lodash");

export const initialFoldersState = {
  listLoading: false,
  actionsLoading: false,
  data: {
    licni: null,
    pretraga: null,
    kliping: null,
  },
  lastError: null,
  folderDialog: false,
  dialogType: undefined,
  folderType: undefined,
  foldersLoaded: {
    licni: false,
    pretraga: false,
    kliping: false,
  },
  activeFolder: {},
  activePost: {},
  stats: undefined,
  posts: undefined,
  activeId: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const foldersSlice = createSlice({
  name: "folders",
  initialState: initialFoldersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getFolderById
    folderFetched: (state, action) => {
      state.actionsLoading = false;
      state.folderForEdit = action.payload.folderForEdit;
      state.error = null;
    },
    // findFolders
    personalFoldersFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data.licni = data;
      state.foldersLoaded.licni = true;
    },
    searchesFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data.pretraga = data;
      state.foldersLoaded.pretraga = true;
    },
    clippingFoldersFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.data.kliping = data;
      state.foldersLoaded.kliping = true;
    },
    // createFolder
    folderCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // deleteFolder
    folderDeleted: (state, action) => {
      const { type, id } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      // state.data[type] = [state.data[type], id];
    },
    // renameFolder
    folderRenamed: (state, action) => {
      const { type, new_name, id } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.data[type][id].title = new_name;
    },
    folderPurged: (state, action) => {
      state.error = null;
      state.listLoading = false;
    },
    postsFetched: (state, action) => {
      const { data, stats } = action.payload;
      state.posts = data;
      state.stats = stats;
      state.listLoading = false;
    },
    // createFolderDialog
    folderDialogOpened: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.folderDialog = true;
      state.dialogType = action.payload.dialogType;
      state.folderType = action.payload.type;
    },
    folderDialogClosed: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.folderDialog = false;
      state.dialogType = undefined;
      state.folderType = undefined;
    },
    activeFolderSet: (state, action) => {
      const { folder, id } = action.payload;
      state.activeFolder = folder;
      state.activeId = id;
    },
    activePostSet: (state, action) => {
      state.activePost = action.payload;
    },
  },
});
