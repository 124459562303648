/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import FavoriteButton from "../folder-buttons/FavoriteButton";

export const FavoritesColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    { props }
) => {
    return (
        <FavoriteButton rowIndex={rowIndex} props={props}/>
    )
}

