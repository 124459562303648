import React, { useState, useRef, useMemo, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  Pagination,
  CardHeader,
  CardHeaderToolbar,
  CardBody,
} from "../../../../../_metronic/_partials/controls";
// import * as columnFormatters from "./column-formatters";
import { ReactiveList } from "@appbaseio/reactivesearch";
import {
  sortCaret,
  headerSortingClasses,
  defaultSearchQuery,
  transformData,
  getSelectRow,
  searches_url,
  POST_INFORMATION_STRING,
} from "../../../../../_metronic/_helpers";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import * as uiHelpers from "./UIhelpers/ArhivaUIHelpers";
import * as actions from "../../_redux/arhiva/ArhivaActions";
import * as folderActions from "../../_redux/folders/foldersActions";
import { useArhivaUIContext } from "./ArhivaUIContext";
import * as columnFormatters from "../folders/folders-formatters/index";
import ArhivaSidebar from "./ArhivaSidebar/ArhivaSidebar";
import HeaderButtonsContainer from "../folders/folder-buttons/HeaderButtons/HeaderButtonsContainer";

export default function ArhivaTable() {
  const { currentState } = useSelector(
    (state) => ({ currentState: state.arhiva }),
    shallowEqual
  );
  const arhivaUIContext = useArhivaUIContext();
  const arhivaUIProps = useMemo(() => {
    return {
      ids: arhivaUIContext.ids,
      setIds: arhivaUIContext.setIds,
      favoriteIds: arhivaUIContext.favoriteIds,
      setFavoriteIds: arhivaUIContext.setFavoriteIds,
    };
  });
  const { posts, stats } = currentState;
  const { folders } = useSelector(
    (state) => ({ folders: state.folders }),
    shallowEqual
  );
  const tableRef = useRef();
  const [size, setSize] = useState(20);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState("displaydate");
  const dispatch = useDispatch();

  useEffect(() => {
    // clear selections list
    arhivaUIProps.setIds([]);
    arhivaUIProps.setFavoriteIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   Table columns
  const columns = [
    {
      dataField: "favorite",
      text: "",
      formatter: columnFormatters.FavoritesColumnFormatter,
      formatExtraData: {
        props: {
          favoriteIds: arhivaUIProps.favoriteIds,
          setFavoriteIds: arhivaUIProps.setFavoriteIds,
          posts: posts,
        },
      },
    },
    {
      dataField: "type",
      text: "Tip medija",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "displaydate",
      text: "Vreme objave",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "title",
      text: "Naslov",
      sort: true,
      events: {
        // open post information on title click
        onClick: (e, column, columnIndex, row, rowIndex) => {
          dispatch(folderActions.openFolderDialog(POST_INFORMATION_STRING));
          dispatch(folderActions.setActivePost(row));
        },
      },
      formatter: columnFormatters.TitleColumnFormatter,
      // // sortCaret: sortCaret,
      // headerSortingClasses,
    },

    {
      dataField: "rijeci_str",
      text: "Kljucne reci",
      sort: false,
      // // sortCaret: sortCaret,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      classes: "text-right pr-3",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // fetch posts from data returned in search results and set them as active state
  const setPosts = async (response, id) => {
    const data = await transformData(response, id);
    dispatch(actions.setFolderPosts(data, response.resultStats));
  };
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: stats?.numberOfResults ? stats.numberOfResults : 1000,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: size,
    page: stats && stats.currentPage + 1,
  };

  return (
    <>
      <ReactiveList
        dataField=" "
        size={size}
        componentId="arhivalist"
        react={{
          and: ["s", "dateRangeArhiva", "toggle", "categories", "sorting"],
        }}
        ref={tableRef}
        // sortOptions={[{ sortBy: sortOrder, dataField: sortField }]}
        // innerClass={{ sortOptions: 'sort-selector' }}
        onQueryChange={(prev, next) => {
          dispatch(actions.setArhivaQuery(next));
        }}
        defaultQuery={() => defaultSearchQuery(folders.activeFolder)}
        key="arhivatablekey"
        className="search-list my-8"
        onData={(data) => {
          setPosts(data);
        }}
        infiniteScroll={false}
        render={({ loading, error, data }) => {
          return (
            <>
              <div
                className="card card-custom card-stretch"
                id="kt_page_stretched_card"
              >
                <CardHeader sticky={true}>
                  <CardHeaderToolbar>
                    <HeaderButtonsContainer
                      type={searches_url}
                      props={arhivaUIProps}
                    />
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <ArhivaSidebar />
                  {/* <SelectedFilters onClear={(component, value) => {
								    if (value === undefined) {
									// clear all filter removes query and redirects
									dispatch(setArhivaQueryValue(null))
									history.push('/archive')
							    	}
							    }} */}

                  <div className="card-scroll">
                    <PaginationProvider
                      pagination={paginationFactory(paginationOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <Pagination
                            paginationProps={paginationProps}
                            isLoading={loading}
                          >
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              bordered={false}
                              classes="table table-head-custom table-vertical-center"
                              bootstrap4
                              remote
                              keyField="id"
                              key="arhiva-table"
                              data={posts ? posts : []}
                              columns={columns}
                              {...paginationTableProps}
                              onTableChange={(
                                type,
                                {
                                  page,
                                  sizePerPage,
                                  sortField,
                                  sortOrder,
                                  data,
                                }
                              ) => {
                                if (type === "pagination") {
                                  if (page != undefined) {
                                    tableRef.current.setPage(page - 1);
                                    return (
                                      type,
                                      {
                                        page,
                                        sizePerPage,
                                        sortField,
                                        sortOrder,
                                        data,
                                      }
                                    );
                                  }
                                  setSize(sizePerPage);
                                  return (
                                    type,
                                    {
                                      page,
                                      sizePerPage,
                                      sortField,
                                      sortOrder,
                                      data,
                                    }
                                  );
                                }
                                if (type === "sort") {
                                  setSortOrder(sortOrder);
                                  setSortField(sortField);
                                }
                              }}
                              selectRow={getSelectRow({
                                posts,
                                ids: arhivaUIProps.ids,
                                setIds: arhivaUIProps.setIds,
                              })}
                            ></BootstrapTable>
                          </Pagination>
                        );
                      }}
                    </PaginationProvider>
                  </div>
                </CardBody>
              </div>
            </>
          );
        }}
      />
    </>
  );
}
