import { put, takeLatest, call } from "redux-saga/effects";
import { getAllCrawlersData, setAllCrawlersData, showLoader } from "./actions";
import { deleteCrawlerCall, getAllCrawlersDataCall } from "./Service";

function* attemptGetAllCrawlers(action) {
  const { payload } = action;
  try {
    yield put(showLoader(true));
    const response = yield call(getAllCrawlersDataCall, payload);
    yield put(setAllCrawlersData(response.data));
    yield put(showLoader(false));
  } catch (error) {}
}

function* attemptDeleteCrawler(actions) {
  try {
    const response = yield call(deleteCrawlerCall, actions.id);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(getAllCrawlersData());
  } catch (errors) {}
}

// function* attemptGetCrawlerRuleType() {
//   try {
//     const response = yeild call()
//   } catch {

//   }
// }

export function* saga() {
  yield takeLatest("GET_ALL_CRAWLERS", attemptGetAllCrawlers);
  yield takeLatest("DELETE_CRAWLER", attemptDeleteCrawler);
  // yield takeLatest("GET_CRAWLER_RULE_TYPE", attemptGetCrawlerRuleType);
}
