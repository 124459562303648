export const getAllStreamData = (payload, size, page) => {
  return {
    type: "GET_ALL_STREAM_DATA",
    payload,
    size,
    page
  };
};

export const getAllServersData = (payload, size, page) => {
  return {
    type: "GET_ALL_SERVERS_DATA",
    payload,
    size,
    page
  };
};


export const setAllStreamData = (payload) => {
  return {
    type: "SET_ALL_STREAM_DATA",
    payload: payload,
  };
};

export const setAllServersData = (payload) => {
  return {
    type: "SET_ALL_SERVERS_DATA",
    payload: payload,
  };
};

export const createStream = (
  payload,
  toasts,
  intl,
  closeModal,
  setErrMessage,
  page,
  size
) => {

  return {
    type: "CREATE_STREAM",
    payload: payload,
    toasts: toasts,
    intl: intl,
    closeModal: closeModal,
    setErrMessage: setErrMessage,
    page,
    size
  };
};

// -------------------------------------- DELETE --------------------------------------------------

export const deleteStream = (payload, intl, toasts,closeModal) => {
  return {
    type: "DELETE_STREAM",
    payload: payload,
    intl: intl,
    toasts: toasts,
    closeModal
  };
};

export const deleteStreamFromRedux = (id, closeModal) => {
  return {
    type: "DELETE_STREAM_FROM_REDUX",
    id: id,
    closeModal: closeModal,
  };
};

// ------------------------------------- Edit ---------------------------------

export const editStream = (payload, intl, toasts, closeModal, setErrMessage, page, size, id) => {
  return {
    type: "EDIT_STREAM",
    payload: payload,
    intl: intl,
    toasts: toasts,
    closeModal: closeModal,
    setErrMessage: setErrMessage,
    page,
    size,
    id
  };
};

export const startStream =(payload, intl, toasts)=>{
  return {
    type:"START_STREAM",
    payload:payload,
    intl: intl,
    toasts:toasts
  }
}
export const stopStream =(payload, intl, toasts)=>{
  return {
    type:"STOP_STREAM",
    payload:payload,
    intl: intl,
    toasts:toasts
  }
}



export const editStreamFromRedux = (payload) => {
  return {
    type: "EDIT_STREAM_FROM_REDUX",
    payload: payload,
  };
};
// --------------------------------------- Delete All ---------------------------

export const deleteAll = (payload, toasts, intl) => {
  return {
    type: "COMMON.Delete",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const delteMultiStreamsFromRedux = (ids, closeModal, setAllIds) => {
  return {
    type: "DELETE_MULTI_STREAM_FROM_REDUX",
    ids: ids,
    closeModal: closeModal,
    setAllIds: setAllIds,
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});
