import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { foldersSlice } from "../app/modules/ECommerce/_redux/folders/foldersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { toastsSlice } from "../app/modules/ECommerce/_redux/toasts/toastsSlice";
import { arhivaSlice } from "../app/modules/ECommerce/_redux/arhiva/ArhivaSlice";
import * as channels from "../app/_redux/channels/reducer";
import * as institutions from "../app/_redux/Institutions/reducer";
import * as instituteActivity from "../app/_redux/InstituteActivities/reducer";
import * as subjects from "../app/_redux/Subjects/reducer";
import * as policies from "../app/_redux/policy/reducer";
import * as postSize from "../app/_redux/postSizes/postSizeReducer";
import * as stream from "../app/_redux/stream/streamReducer";
import * as postType from "../app/_redux/PostType/reducer";
import * as users from "../app/_redux/users/reducer";
import * as media from "../app/_redux/media/mediaReducer";
import * as posts from "../app/_redux/Post/reducer";
import * as sections from "../app/_redux/sections/reducer";
import * as social from "../app/_redux/Social/reducer";
import * as crawlers from "../app/_redux/Crawler/reducer";
import * as category from "../app/_redux/Category/reducer";
import * as roles from "../app/_redux/roles/reducer";
import * as folders from "../app/_redux/FolderTabs/reducer";
import * as channelsSaga from "../app/_redux/channels/saga";
import * as usersSaga from "../app/_redux/users/saga";
import * as institutionsSaga from "../app/_redux/Institutions/saga";
import * as instituteActivitySaga from "../app/_redux/InstituteActivities/saga";
import * as subjectsSaga from "../app/_redux/Subjects/saga";
import * as postSizeSaga from "../app/_redux/postSizes/saga";
import * as streamSaga from "../app/_redux/stream/saga";
import * as postTypeSaga from "../app/_redux/PostType/saga";
import * as mediaSaga from "../app/_redux/media/saga";
import * as postsSaga from "../app/_redux/Post/saga";
import * as socialSaga from "../app/_redux/Social/saga";
import * as policySaga from "../app/_redux/policy/saga";
import * as sectionsSaga from "../app/_redux/sections/saga";
import * as crawlersSaga from "../app/_redux/Crawler/saga";
import * as categorySaga from "../app/_redux/Category/saga";
import * as rolesSaga from "../app/_redux/roles/saga";
import * as folderSaga from "../app/_redux/FolderTabs/saga";
import * as search from "../app/modules/ECommerce/pages/arhiva/ArhivaSearch/_redux/SearchRedux";
import SourceReducer from "../app/_redux/sources/reducer";
import SourceSaga from "../app/_redux/sources/saga";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  arhiva: arhivaSlice.reducer,
  folders: foldersSlice.reducer,
  products: productsSlice.reducer,
  toasts: toastsSlice.reducer,
  search: search.reducer,
  channels: channels.reducer,
  postSize: postSize.reducer,
  stream: stream.reducer,
  postType: postType.reducer,
  media: media.reducer,
  posts: posts.reducer,
  institutions: institutions.reducer,
  instituteActivity: instituteActivity.reducer,
  subjects: subjects.reducer,
  policies: policies.reducer,
  sections: sections.reducer,
  social: social.reducer,
  users: users.reducer,
  crawlers: crawlers.reducer,
  category: category.reducer,
  sources: SourceReducer,
  roles: roles.reducer,
  foldersData: folders.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    search.saga(),
    channelsSaga.saga(),
    postSizeSaga.saga(),
    streamSaga.saga(),
    postTypeSaga.saga(),
    mediaSaga.saga(),
    postsSaga.saga(),
    socialSaga.saga(),
    institutionsSaga.saga(),
    instituteActivitySaga.saga(),
    subjectsSaga.saga(),
    policySaga.saga(),
    sectionsSaga.saga(),
    usersSaga.saga(),
    crawlersSaga.saga(),
    categorySaga.saga(),
    SourceSaga(),
    rolesSaga.saga(),
    folderSaga.saga(),
  ]);
}
