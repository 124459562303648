import React from 'react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';

const FavoriteButton = ({ props, rowIndex }) => {
    const { favoriteIds, setFavoriteIds, posts } = props;

    return (
        <span className={`svg-icon svg-icon-lg svg-icon-${favoriteIds.some((el) => el === posts[rowIndex].id)
            && 'primary'} ml-1 `} onClick={() => {
                if (favoriteIds.some((id) => id === posts[rowIndex].id)) {
                    setFavoriteIds(favoriteIds.filter((id) => id !== posts[rowIndex].id));
                } else {
                    const newIds = [...favoriteIds];
                    newIds.push(posts[rowIndex].id);
                    setFavoriteIds(newIds);
                }
            }}>
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
        </span>
    )
}

export default FavoriteButton
