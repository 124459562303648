import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { Modal, Button } from "react-bootstrap";

import { START_CRAWLER_MANUALLY_URL } from "../../../../../_metronic/_helpers/Constants";
import ProfileService from "../../../Profile/service";
import { useDispatch } from "react-redux";
import * as toasts from "../../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from 'react-intl';

const StartManuallyModal = ({
  isVisible,
  setIsVisible,
  currentItem,
  setCurrentItem,
}) => {
  const service = new ProfileService();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const intl = useIntl();

  const saveItem= async () => {
      return await service.post(START_CRAWLER_MANUALLY_URL, { id: currentItem.id });
  };

  const mutation = useMutation(saveItem, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("crawlers");
      dispatch(
        toasts.showToast(
          data.data.status,
          intl.formatMessage(
            { id: data.data.title },
          )
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(
        toasts.showToast(
          error.response.data.status,
          intl.formatMessage(
            {
              id: error.response.data.details
                ? error.response.data.details
                : error.response.data.errors[0],
            }
          )
        )
      );
      dispatch(toasts.hideToast());
    },
  });
  if (!isVisible) return null;

  const itemOption = "crawler";  

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        setCurrentItem(null);
        setIsVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Start Manually {currentItem.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure to start manually this {itemOption}?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setCurrentItem(null);
            setIsVisible(false);
          }}
        >
          {intl.formatMessage({ id: "COMMON.NO" })}
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            try {
              mutation.mutate();
              setCurrentItem(null);
              setIsVisible(false);
            } catch (error) {}
          }}
        >
          {intl.formatMessage({ id: "COMMON.YES" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StartManuallyModal;
