import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  admin_url,
  a_crawler_url,
  a_manage_url,
  a_policy_url,
  a_users_url,
  a_crawler_edit_url,
  a_manage_edit_url,
  a_policy_edit_url,
  a_users_edit_url,
  a_category_url,
  a_category_edit_url,
  a_media_url,
  a_media_edit_url,
  a_section_url,
  a_section_edit_url,
  a_post_type_url,
  a_post_type_edit_url,
  a_source_url,
  a_source_edit_url,
  error_url,
  a_channels_url,
  a_users_url_view,
  a_institute_url,
  a_institute_activity_url,
  a_subject_url,
  a_velicine_url,
  a_institute_activity_edit_url,
  a_subjects_edit_url,
  a_stream_url,
  a_customer_folder_management,
} from "../../../_metronic/_helpers";
import ErrorsPage from "../errors/ErrorsPage";

import AdminDashboardPage from "./Dashboard";

import PolicyPage from "./Policy";
import PolicyEditPage from "./Policy/EditPage";

import UsersPage from "./Users";
import UsersEditPage from "./Users/EditPage";

import { InstitutePage } from "./Institute/Index";

import CrawlerPage from "./Crawler";
import CrawlerEditPage from "./Crawler/EditPage";

import ManagePage from "./Manage";
import ManageEditPage from "./Manage/EditPage";

import CategoriesPage from "./Categories";
import CategoryEditPage from "./Categories/EditPage";

import MediaPage from "./Media";
import MediaEditPage from "./Media/EditPage";

import SectionsPage from "./Sections";
import SectionEditPage from "./Sections/EditPage";

import PostTypesPage from "./Post Types";
import PostTypeEditPage from "./Post Types/EditPage";

import SourcesPage from "./Sources";
import SourceEditPage from "./Sources/EditPage";
import { Channels } from "../../pages/Channels";
import { View } from "./Users/View";
import { PostSize } from "../../pages/PostSize";
import { Index } from "./InstituteActivity/Index";
import Edit from "./InstituteActivity/Edit";
import Subjects from "./Subject/Index";
import SubjectEdit from "./Subject/Edit";
import { StreamManagment } from "../../pages/StreamManagment";
import FolderPurging from "./Folder/FolderPurging";

const AdminPage = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const {
    user: { account_information },
  } = useSelector((state) => state.auth);

  const isAdmin = account_information.roles.includes("administrator");
  const isManager = account_information.roles.includes("manager");
  const showAdminPage = isAdmin || isManager;

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Switch>
          {showAdminPage && (
            <Route exact path={admin_url} component={AdminDashboardPage} />
          )}
          {showAdminPage && (
            <Route
              path={`${a_policy_edit_url}/:id?`}
              component={PolicyEditPage}
            />
          )}
          {showAdminPage && (
            <Route path={a_policy_url} component={PolicyPage} />
          )}

          {showAdminPage && (
              <Route path={a_customer_folder_management} component={FolderPurging} />
          )}
          {isAdmin && (
            // <Route
            //   path={`${a_users_edit_url}/:id?`}
            //   component={UsersEditPage}
            // />
            <Route
              path={`${a_users_edit_url}/:id?`}
              // path={`${a_users_edit_url}/edit`}
              component={UsersEditPage}
            />
          )}
          {isAdmin && (
            <Route path={a_institute_url} component={InstitutePage} />
          )}
          {isAdmin && (
            <Route exact path={a_institute_activity_url} component={Index} />
          )}
          {isAdmin && <Route exact path={a_subject_url} component={Subjects} />}
          {isAdmin && <Route path={a_users_url} component={UsersPage} />}
          {isAdmin && (
            <Route
              path={`${a_crawler_edit_url}/:id?`}
              component={CrawlerEditPage}
            />
          )}
          {isAdmin && (
            <Route path={`${a_users_url_view}/:id?`} component={View} />
          )}
          {isAdmin && <Route path={a_crawler_url} component={CrawlerPage} />}
          {isAdmin && (
            <Route
              path={`${a_category_edit_url}/:id?`}
              component={CategoryEditPage}
            />
          )}
          {isAdmin && (
            <Route path={a_category_url} component={CategoriesPage} />
          )}
          {isAdmin && (
            <Route
              path={`${a_media_edit_url}/:id?`}
              component={MediaEditPage}
            />
          )}
          {isAdmin && <Route path={a_media_url} component={MediaPage} />}
          {isAdmin && (
            <Route
              path={`${a_section_edit_url}/:id?`}
              component={SectionEditPage}
            />
          )}
          {isAdmin && <Route path={a_section_url} component={SectionsPage} />}
          {isAdmin && (
            <Route
              path={`${a_post_type_edit_url}/:id?`}
              component={PostTypeEditPage}
            />
          )}
          {isAdmin && (
            <Route path={a_post_type_url} component={PostTypesPage} />
          )}
          {isAdmin && (
            <Route
              path={`${a_source_edit_url}/:id?`}
              component={SourceEditPage}
            />
          )}
          {isAdmin && <Route path={a_source_url} component={SourcesPage} />}
          {isAdmin && <Route path={a_channels_url} component={Channels} />}
          {/* Add here some text */}
          {isAdmin && <Route path={a_velicine_url} component={PostSize} />}
          {isAdmin && <Route path={a_stream_url} component={StreamManagment} />}

          {isAdmin && (
            <Route
              exact
              path={`${a_institute_activity_edit_url}/:id?`}
              component={Edit}
            />
          )}
          {isAdmin && (
            <Route
              exact
              path={`${a_subjects_edit_url}/:id?`}
              component={SubjectEdit}
            />
          )}

          {showAdminPage && (
            <Route
              path={`${a_manage_edit_url}/:id?`}
              component={ManageEditPage}
            />
          )}
          {showAdminPage && (
            <Route path={a_manage_url} component={ManagePage} />
          )}
          <Redirect to={`..${error_url}`} />
        </Switch>
      </QueryClientProvider>
    </>
  );
};

export default AdminPage;
