export const getAllChannelsData = (payload) => {
  return {
    type: "GET_ALL_CHANNELS_DATA",
    payload: payload,
  };
};

export const setAllChannelsData = (payload) => {
  return {
    type: "SET_ALL_CHANNELS_DATA",
    payload: payload,
  };
};

export const createChannel = (
  payload,
  toasts,
  intl,
  closeModal,
  setErrMessage,
  getAllChannelsPayload
) => {
  return {
    type: "CREATE_CHANNEL",
    payload: payload,
    toasts: toasts,
    intl: intl,
    closeModal: closeModal,
    setErrMessage: setErrMessage,
    getAllChannelsPayload: getAllChannelsPayload,
  };
};
export const createChannelResponseSuccess = (payload) => ({
  type: "CREATE_CHANNEL_RESPONSE_SUCCESS",
  payload: payload,
});

// -------------------------------------- DELETE --------------------------------------------------

export const deleteChannels = (
  payload,
  intl,
  toasts,
  closeModal,
  getAllChannelsPayload
) => {
  return {
    type: "DELETE_CHANNEL",
    payload: payload,
    intl: intl,
    toasts: toasts,
    closeModal: closeModal,
    getAllChannelsPayload: getAllChannelsPayload,
  };
};

export const deleteChannelFromRedux = (id, closeModal) => {
  return {
    type: "DELETE_CHANNEL_FROM_REDUX",
    id: id,
    closeModal: closeModal,
  };
};

// --------------------------------------- Delete all --------------------------------------
export const deleteAllChannels = (payload, toasts, intl) => {
  return {
    type: "DELETE_SELECTED_CHANNEL",
    payload: payload,
    toasts: toasts,
    intl: intl,
  };
};

export const multiDeleteChannelsFromRedux = (ids, setAllIds, closeModal) => {
  return {
    type: "DELETE_MULTI_CHANNELS_FROM_REDUX",
    ids: ids,
    setAllIds: setAllIds,
    closeModal: closeModal,
  };
};

// ------------------------------------- Edit ---------------------------------

export const editChannel = (
  payload,
  id,
  intl,
  toasts,
  closeModal,
  setErrMessage,
  getAllChannelsPayload
) => {
  return {
    type: "EDIT_CHANNEL",
    payload: payload,
    id: id,
    intl: intl,
    toasts: toasts,
    closeModal: closeModal,
    setErrMessage: setErrMessage,
    getAllChannelsPayload: getAllChannelsPayload,
  };
};

export const editChannelFromRedux = (payload, id) => {
  return {
    type: "EDIT_CHANNEL_FROM_REDUX",
    payload: payload,
    id: id,
  };
};

export const assignUser = (
  payload,
  id,
  intl,
  toasts,
  closeModal,
  setErrMessage,
  getAllChannelsPayload
) => {
  return {
    type: "ASSIGN_USER",
    payload: payload,
    id: id,
    intl: intl,
    toasts: toasts,
    closeModal: closeModal,
    setErrMessage: setErrMessage,
    getAllChannelsPayload: getAllChannelsPayload,
  };
};

export const enableChannelStatus = (payload, intl, toasts) => {
  return {
    type: "ENABLE_CHANNEL_STATUS",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const disableChannelStatus = (
  payload,
  intl,
  toasts,
  getAllChannelsPayload
) => {
  return {
    type: "DISABLE_CHANNEL_STATUS",
    payload: payload,
    intl: intl,
    toasts: toasts,
    getAllChannelsPayload: getAllChannelsPayload,
  };
};
export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});
