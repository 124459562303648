import { combineReducers } from "redux";

export const setAllSectionsData = (payload) => {
  return {
    type: "SET_SECTIONS_DATA",
    payload: payload,
  };
};

const Index = (state = [], action) => {
  const { payload, toasts, intl, id, ids } = action;
  switch (action.type) {
    case "SET_SECTIONS_DATA":
      return payload;
    case "DELETE_SECTION_FROM_REDUX":
      const deleteData = state.filter((x) => (x.id === id ? false : x));

      return deleteData;
    case "DELTE_MULTI_SECTION_FROM_REDUX":
      const deleteMultiData = state.filter((x) =>
        ids.includes(x.id) ? false : x
      );

      //   setAllIds([]);
      return deleteMultiData;
    default:
      return state;
  }
};
const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};
export const reducer = combineReducers({
  Index,
  showLoader,
});
