import React, { useEffect, useState } from "react";
import { ARHIVA_REST_URL } from "../../../../../environments/environment.prod";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import {
  assignUserMedia,
  getAllMediaData,
} from "../../../../_redux/users/actions";
import { useSelector } from "react-redux";

export const AssignMedia = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const mediaData = useSelector((state) => state?.media?.Index.data ?? []);
  const selectedMedia = useSelector(
    (state) => state?.media?.Index?.current_assign_media ?? []
  );

  const [callLoader, setCallLoader] = useState(false);
  const [data, setData] = useState([]);
  const [checkedMedia, setCheckedMedia] = useState([]);
  const [preSelectedMedia, setPreSelectedMedia] = useState([]);
  const [inputText, setInputText] = useState("");

  const inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const showSearchedData = (input) => {
    const filteredData = data?.data?.filter((el) => {
      //if no input the return the original
      if (input === "") {
        return el;
      }
      //return the item which contains the user input
      else {
        return el.name.toLowerCase().includes(input);
      }
    });
    return (
      <>
        {filteredData?.map((item) => (
          <>
            <div className="col-lg-6 mb-3">
              <label className="checkbox">
                <input
                  checked={checkedMedia.includes(item.id)}
                  onClick={() => {
                    checkMediaFiles();
                  }}
                  type="checkbox"
                  id={item.id}
                  value={item.id}
                  name="test[]"
                />
                <span />
                {item?.name}
              </label>
            </div>
          </>
        ))}
      </>
    );
  };

  useEffect(() => {
    setData(mediaData);
  }, [mediaData]);

  useEffect(() => {
    setCheckedMedia(selectedMedia?.map((s) => s.id));
  }, [selectedMedia]);

  useEffect(() => {
    getAllMedia();
  }, []);

  const getAllMedia = () => {
    let data = {};
    data.user_id = props?.routId;
    dispatch(getAllMediaData(data));
  };

  const checkMediaFiles = () => {
    var selectedIDs = window
      .$("input[name^=test]:checked")
      .map(function() {
        return parseInt(window.$(this).val());
      })
      .get();
    setCheckedMedia(selectedIDs);
  };

  const handleAssignMedia = () => {
    setCallLoader(true);
    let data = {};
    data.media_ids = checkedMedia;
    let id = props?.routId;
    dispatch(assignUserMedia(id, data, intl, toasts, setCallLoader));
  };

  return (
    <>
      {" "}
      <div className="flex-row-fluid ml-lg-8">
        {/*begin::Card*/}
        <div className="card card-custom">
          {/*begin::Header*/}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                {intl.formatMessage({
                  id: "assign_media",
                })}
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                {intl.formatMessage({ id: "assign_media" })} to{" "}
                {intl.formatMessage({ id: "MENU.USER" })}
              </span>
            </div>
            <div className="card-toolbar">
              <button
                onClick={() => handleAssignMedia()}
                type="button"
                disabled={callLoader}
                className="btn btn-success mr-2 d-flex"
              >
                {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                {callLoader == true ? (
                  <>
                    <div
                      className="spinner ml-2"
                      style={{ width: "20px" }}
                    ></div>
                  </>
                ) : (
                  ""
                )}
              </button>
              <NavLink
                to={`/admin/user/view/${props.routId}`}
                className="navi-link py-4"
                activeClassName="active"
                exact
              >
                <button type="button" className="btn btn-secondary">
                  {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
                </button>
              </NavLink>
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Form*/}
          <form className="form">
            <div className="card-body">
              {props.loader == true ? (
                <>
                  <div className="d-flex justify-content-center">
                    <div className="spinner"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-9 col-xl-6 ">
                      <h5 className="font-weight-bold mb-6">
                        {intl.formatMessage({ id: "MENU.MEDIA" })}
                      </h5>
                    </div>
                    <div className="form-group">
                      <div className="input-icon">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onChange={inputHandler}
                        />
                        <span>
                          <i className="flaticon2-search-1 icon-md" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="form-group row"
                    style={{ maxHeight: "500px", overflow: "scroll" }}
                  >
                    {showSearchedData(inputText)}
                    {/* {data?.map((d) => {
                      return (
                        <>
                          <div className="col-lg-6 mb-3">
                            <label className="checkbox">
                              <input
                                checked={checkedMedia.includes(d.id)}
                                onClick={() => {
                                  checkMediaFiles();
                                }}
                                type="checkbox"
                                id={d.id}
                                value={d.id}
                                name="test[]"
                              />
                              <span />
                              {d?.name}
                            </label>
                          </div>
                        </>
                      );
                    })} */}
                  </div>
                </>
              )}
            </div>
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Card*/}
      </div>
    </>
  );
};
