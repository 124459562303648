import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, toasts, intl } = action;
  switch (action.type) {
    case "SET_ALL_CRAWLERS_DATA":
      return payload;
    default:
      return state;
  }
};
const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};
export const reducer = combineReducers({
  Index,
  showLoader,
});
