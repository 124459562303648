import React from "react";
import * as actions from "../../../_redux/folders/foldersActions";
import { useIntl } from "react-intl";
import {
  CREATE_STRING,
  searches_url,
  RENAME_STRING,
  PURGE_STRING,
  REMOVE_STRING,
} from "../../../../../../_metronic/_helpers";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const CreateFolderButton = ({ type, position }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <Button
        className={
          position === "aside"
            ? "btn btn-light font-weight-normal w-75 p-2 mx-auto mb-5 text-capitalize"
            : "btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"
        }
        onClick={() =>
          type === searches_url && position === "aside"
            ? history.push(`/folders/${searches_url}`)
            : dispatch(actions.openFolderDialog(CREATE_STRING, type))
        }
      >
        {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}
      </Button>
    </>
  );
};

export const PurgeFolderButton = () => {
  const { currentState } = useSelector((state) => ({
    currentState: state.folders,
  }));
  const { activeFolder } = currentState;

  const intl = useIntl();
  const dispatch = useDispatch();
  return (
    <>
      {activeFolder.purge == 1 && activeFolder.post_number > 0 ? (
        <Button
          className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"
          onClick={() =>
            dispatch(actions.openFolderDialog(PURGE_STRING, activeFolder.type))
          }
        >
          {intl.formatMessage({ id: "MENU.OPTIONS.CLEAR" })}
        </Button>
      ) : (
        <Button
          className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"
          disabled={true}
        >
          {intl.formatMessage({ id: "MENU.OPTIONS.CLEAR" })}
        </Button>
      )}
    </>
  );
};

export const RemoveFolderButton = () => {
  const { currentState } = useSelector((state) => ({
    currentState: state.folders,
  }));
  const { activeFolder } = currentState;
  const intl = useIntl();
  const dispatch = useDispatch();
  return (
    <>
      {activeFolder.delete == 1 ? (
        <Button
          className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"
          onClick={() =>
            dispatch(actions.openFolderDialog(REMOVE_STRING, activeFolder.type))
          }
        >
          {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}
        </Button>
      ) : (
        <Button
          className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"
          disabled={true}
        >
          {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}
        </Button>
      )}
    </>
  );
};

export const RenameFolderButton = () => {
  const { currentState } = useSelector((state) => ({
    currentState: state.folders,
  }));
  const { activeFolder } = currentState;

  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <>
      {activeFolder.rename == 1 ? (
        <Button
          className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"
          onClick={() =>
            dispatch(actions.openFolderDialog(RENAME_STRING, activeFolder.type))
          }
        >
          {intl.formatMessage({ id: "MENU.OPTIONS.RENAME" })}
        </Button>
      ) : (
        <Button
          className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mx-2"
          disabled={true}
        >
          {intl.formatMessage({ id: "MENU.OPTIONS.RENAME" })}
        </Button>
      )}
    </>
  );
};
