export const getAllCrawlersData = (payload) => {
  return {
    type: "GET_ALL_CRAWLERS",
    payload: payload,
  };
};

export const setAllCrawlersData = (payload) => {
  return {
    type: "SET_ALL_CRAWLERS_DATA",
    payload: payload,
  };
};

export const deleteCrawler = (id, intl, toasts) => {
  return {
    type: "DELETE_CRAWLER",
    id: id,
    intl: intl,
    toasts: toasts,
  };
};

export const getCrawler = (id, setCreatedBy) => {
  return {
    type: "GET_CRAWLER",
    id: id,
    setCreatedBy: setCreatedBy,
  };
};

export const getCrawlerRuleType = () => {
  return {
    type: "GET_CRAWLER_RULE_TYPE",
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});
