import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getUserAccountSettings,
  saveEditAccountSettings,
  updateAccountSettings,
  updatedEditUserProfile,
} from "../../../../_redux/users/actions";
import { useSelector } from "react-redux";
import CustomDatePicker from "../../shared/DatePicker/CustomDatePicker";
import moment from "moment";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";

import FormData from "form-data";
import { ARHIVA_REST_URL } from "../../../../../environments/environment.dev";

export default function Account(props) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  // const formData = new FormData();

  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [remove, setRemove] = useState(false);

  // const userAccountInfo = useSelector(
  //   (state) => state?.users?.UserInfo?.account_information ?? {}
  // );
  const [is_active, setIs_active] = useState(false);

  useEffect(() => {
    // console.log(props?.renderCall);
    setIs_active(props?.renderCall);
  }, [props?.renderCall]);

  const userAccountInfo = useSelector(
    (state) => state?.users?.userAccountSettings ?? {}
  );

  const errorMessage = useSelector(
    (state) => state?.users?.showErrorMessage ?? {}
  );

  const [dob, setDob] = useState(userAccountInfo?.date_of_birth);
  const [password, setPassword] = useState("");

  useEffect(() => {
    userAccountInfo.avatar ? setRemove(true) : setRemove(false);
  }, [userAccountInfo?.avatar, selectedImage]);

  useEffect(() => {
    let user_id = props?.params;
    dispatch(getUserAccountSettings(user_id));
  }, [props?.params]);

  useEffect(() => {
    dispatch(updateAccountSettings("date_of_birth", dob));
  }, [dob]);

  const handleAccountSubmit = () => {
    let dataImg = new FormData();
    setLoader(true);
    let data = {};
    data = userAccountInfo;
    data.password = password;
    let birthDate = moment.utc(data.date_of_birth).format("YYYY-MM-DD");
    data.date_of_birth = birthDate;

    // delete Object.assign(data, { ["user_id"]: data["id"] })["id"];
    Object.assign(data, { ["user_id"]: data["id"] });
    Object.keys(data).forEach((key) => {
      if (data[key] === "Invalid date") {
        delete data[key];
      }
    });

    const formData = new FormData();
    // data.append("image", selectedImage);
    if (selectedImage) {
      data.image = selectedImage;
    }

    if (remove == false) {
      data.is_deleted = true;
    }

    for (var key in data) {
      formData.append(key, data[key]);
    }

    // let fd = new FormData();
    // for (let i = 0; i < formData.length; i++) {
    //   formData.append("user_id" + (i + 1), formData[i], "id" + (i + 1));
    // }

    dispatch(
      saveEditAccountSettings(formData, props?.params, toasts, intl, setLoader)
    );
  };

  return (
    <>
      {!loader ? (
        <form className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-3 ">
              <div
                className="image-input image-input-outline"
                id="kt_profile_avatar"
              >
                <div
                  style={{ height: "120px", width: "120px" }}
                  className="image-input-wrapper"
                >
                  {/* {selectedImage && ( */}
                  <div>
                    <img
                      id="file"
                      className="image-input-wrapper"
                      // alt="Loading..."
                      width={"250px"}
                      height={"250px"}
                      // src={URL.createObjectURL(selectedImage)}
                      src={
                        remove == false
                          ? ""
                          : selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : ARHIVA_REST_URL +
                            userAccountInfo?.avatar?.replace(/^\/|\/$/g, "")
                      }
                    />
                    <br />
                  </div>
                  {/* )} */}
                </div>

                <label
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  data-original-title="Change avatar"
                >
                  <i className="fa fa-pen icon-sm text-muted" />
                  <input
                    type="file"
                    name="profile_avatar"
                    accept=".png, .jpg, .jpeg"
                    onChange={(event) => {
                      setSelectedImage(event.target.files[0]);
                      setRemove(event.target.files[0] ? true : false);
                    }}
                  />
                  <input type="hidden" name="profile_avatar_remove" />
                </label>
                <span
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="cancel"
                  data-toggle="tooltip"
                  data-original-title="Cancel avatar"
                >
                  <i className="ki ki-bold-close icon-xs text-muted" />
                </span>
                <span
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="remove"
                  data-toggle="tooltip"
                  data-original-title="Remove avatar"
                  onClick={() => setRemove(false)}
                >
                  <i className="ki ki-bold-close icon-xs text-muted" />
                </span>
              </div>
              <span className="form-text text-muted">
                Allowed file types: png, jpg, jpeg.
              </span>
            </div>
            <div className="col-1 d-flex flex-column  justify-content-end pl-0">
              <div className="">
                <div className="checkbox-inline">
                  <label className="checkbox checkbox-primary">
                    <input
                      type="checkbox"
                      checked={userAccountInfo?.status}
                      name="Checkboxes12"
                      onChange={(e) =>
                        dispatch(
                          updateAccountSettings(
                            "status",
                            e.target.checked == true ? 1 : 0
                          )
                        )
                      }
                    />
                    <span />
                    Status
                  </label>
                </div>
                <span className="form-text text-muted">Account Status</span>
              </div>
            </div>

            <div className="col-lg-4 d-flex flex-column  justify-content-end">
              <label>Id</label>
              <input
                type="text"
                className="form-control"
                disabled
                // name="model"
                placeholder="Id"
                value={userAccountInfo?.id}
              />
            </div>
            <div className="col-lg-4 d-flex flex-column  justify-content-end">
              <label>Stari Id</label>
              <input
                type="text"
                className="form-control"
                disabled
                // name="model"
                placeholder="Stari Id"
                value={userAccountInfo?.stari_id ?? ""}
              />
            </div>
          </div>

          <div className="form-group row">
            {/* <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "Name" })}</label>
              <input
                type="text"
                className="form-control"
                // name="model"
                // onChange={(e) => setName(e.target.value)}
                onChange={(e) =>
                  dispatch(updateAccountSettings("name", e.target.value))
                }
                placeholder="name"
                value={userAccountInfo?.name}
              />
              {errorMessage?.name ? (
                <div className="text-danger">{errorMessage?.name}</div>
              ) : (
                ""
              )}
            </div> */}

            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "First_Name" })}</label>
              <input
                type="text"
                className="form-control"
                // name="model"
                onChange={(e) =>
                  dispatch(updateAccountSettings("first_name", e.target.value))
                }
                placeholder="First name"
                value={userAccountInfo?.first_name}
              />
            </div>
            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "Last_Name" })}</label>
              <input
                type="text"
                className="form-control"
                // name="model"
                onChange={(e) =>
                  dispatch(updateAccountSettings("last_name", e.target.value))
                }
                placeholder="Last name"
                value={userAccountInfo?.last_name}
              />
            </div>
            <div className="col-lg-4">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                // name="model"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                value={password}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "AUTH.INPUT.USERNAME" })}</label>
              <input
                type="text"
                className="form-control"
                // name="model"
                disabled
                onChange={(e) =>
                  dispatch(updateAccountSettings("username", e.target.value))
                }
                placeholder="Username"
                value={userAccountInfo?.username}
              />
            </div>
            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}</label>
              <input
                type="email"
                className="form-control"
                // name="model"
                onChange={(e) =>
                  dispatch(updateAccountSettings("email", e.target.value))
                }
                placeholder="Email"
                value={userAccountInfo?.email}
              />
              {errorMessage?.email ? (
                <div className="text-danger">{errorMessage?.email}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "TH_MOBILE" })}</label>
              <input
                type="text"
                className="form-control"
                // name="model"
                onChange={(e) =>
                  dispatch(updateAccountSettings("mobile", e.target.value))
                }
                placeholder="Mobile"
                value={userAccountInfo?.mobile}
              />
              {errorMessage?.mobile ? (
                <div className="text-danger">{errorMessage?.mobile}</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "Company_Name" })}</label>
              <input
                type="text"
                className="form-control"
                // name="model"
                onChange={(e) =>
                  dispatch(updateAccountSettings("company", e.target.value))
                }
                placeholder="Company name"
                value={userAccountInfo?.company}
              />
            </div>
            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "Website" })}</label>
              <input
                type="text"
                className="form-control"
                // name="model"
                onChange={(e) =>
                  dispatch(updateAccountSettings("website", e.target.value))
                }
                placeholder="www.example.com"
                value={userAccountInfo?.website ?? ""}
              />
            </div>
            <div className="col-lg-4">
              <label>Email Notification Time</label>
              <input
                className="form-control"
                type="time"
                onChange={(e) =>
                  dispatch(
                    updateAccountSettings(
                      "email_notification_time",
                      e.target.value
                    )
                  )
                }
                id="example-time-input"
                value={userAccountInfo?.email_notification_time ?? ""}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-4">
              <label>Gender</label>
              <input
                type="text"
                className="form-control"
                // name="model"
                onChange={(e) =>
                  dispatch(updateAccountSettings("gender", e.target.value))
                }
                placeholder="Male/Female"
                value={userAccountInfo?.gender ?? ""}
              />
              {errorMessage?.gender ? (
                <div className="text-danger">{errorMessage?.gender}</div>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "date_of_birth" })}</label>
              {/* <CustomDateTimePicker
              // dateValue={dob ?? ""}
              id={"dob_account"}
              setDateValue={setDob}
            /> */}
              <CustomDatePicker
                id="dob_account"
                setDateValue={setDob}
                dateValue={userAccountInfo?.date_of_birth ?? ""}
              />
              {/* <CustomDateTimePicker
              setDateTimeValue={dob}
              id={"dob_account_kt"}
              dateValue={dob}
            /> */}
              {/* <select
              className="form-control form-control "
              name="manufacture"
            >
              <option value="Pontiac">12/18/20</option>
            </select> */}
            </div>
            <div className="col-lg-4">
              <label>Email Verified At</label>
              <input
                type="text"
                className="form-control"
                // name="model"
                // onChange={(e) => setName(e.target.value)}
                disabled
                placeholder="verified/unverified"
                value={userAccountInfo?.email_verified_at ?? ""}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-4">
              <label>Created At</label>
              <input
                type="text"
                className="form-control"
                disabled
                // name="model"
                // onChange={(e) => setCreated_at(e.target.value)}
                placeholder="name"
                value={moment
                  .utc(userAccountInfo?.created_at)
                  .format("MM/DD/YYYY  hh:mm:ss A")}
              />
            </div>
            <div className="col-lg-4">
              <label>Updated At</label>
              <input
                type="text"
                className="form-control"
                disabled
                // name="model"
                // onChange={(e) => setUpdated_at(e.target.value)}
                placeholder="name"
                value={moment
                  .utc(userAccountInfo?.updated_at)
                  .format("MM/DD/YYYY  hh:mm:ss A")}
              />
            </div>
            <div className="col-lg-4">
              <label>Is Verified</label>
              <input
                type="text"
                className="form-control"
                disabled
                // onChange={(e) =>
                //   dispatch("account_information", "is_verified", e.target.value)
                // }
                placeholder="verified/unverified"
                value={userAccountInfo?.is_verified ? "Verified" : "Unverified"}
              />
            </div>
          </div>

          {/* <div className="form-group row">
            <div className="col-lg-4">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                // name="model"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                value={password}
              />
            </div>
          </div> */}
          <div className="form-group row p-2 d-flex flex-row-reverse">
            <button
              onClick={() => {
                handleAccountSubmit();
              }}
              className="btn btn-primary ml-2 "
              type="button"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => history.push(`${"/admin/users"}`)}
            >
              <i className="fa fa-arrow-left" />
              Back
            </button>{" "}
          </div>
          {/* <button type="submit" style={{ display: "none" }} /> */}
        </form>
      ) : (
        <>
          <div
            className="wao"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <div className="spinner spinner-lg"></div>
          </div>
        </>
      )}
    </>
  );
}
