// export const ARHIVA_REST_URL = "https://rest.arhiva.me/";
export const ARHIVA_REST_URL = "https://dev.rest.arhiva.me/";

// export const ARHIVA_REST_URL = "http://127.0.0.1:8080/";
// export const ARHIVA_REST_URL = "http://192.168.18.13/";
// export const ARHIVA_REST_URL =
//   "http://e5c2-2400-adc5-1d4-9e00-496a-f2af-2491-4a7a.ngrok.io/";
// export const ARHIVA_REST_URL =
//   "http://4cf7-2400-adc5-1d4-9e00-183-344e-2713-15dd.ngrok.io/";
// export const ARHIVA_REST_URL = "http://192.168.18.13:8000/";
// export const ARHIVA_REST_URL = "https://butt.loca.lt/";
// export const ARHIVA_REST_URL = "https://arhiva.loca.lt/";
// export const ARHIVA_REST_URL =
//   "http://7c94-2400-adc5-1d4-9e00-b8cb-1ff2-38dd-3c3e.ngrok.io/";
