import React, { useEffect } from "react";
import { PaginationLinks } from "./PaginationLinks";
import { PaginationToolbar } from "./PaginationToolbar";
import { getPagesCount } from "../../../_helpers";
export function Pagination(props) {
  const { children, isLoading, paginationProps, show } = props;
  const { sizePerPage, totalSize } = paginationProps;
  const pagesCount = getPagesCount(totalSize, sizePerPage);

  useEffect(() => {
    window.$(".react-bootstrap-table-pagination-total").innerText = "ok";
  }, []);

  return (
    <>
      {children}
      <div
        className={`d-flex justify-content-between align-items-center flex-wrap ${pagesCount <
          2 && "flex-row-reverse"} ${
          show === true ? "d-lg-none d-md-none d-sm-none d-xl-none" : "show"
        }`}
      >
        <PaginationLinks paginationProps={paginationProps} />
        <PaginationToolbar
          isLoading={isLoading}
          paginationProps={paginationProps}
        />
      </div>
    </>
  );
}
