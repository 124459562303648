import React, { useState, useEffect } from "react";
import axios from "axios";
import { ARHIVA_REST_URL } from "../../../../../../environments/environment";
import { Table } from "../../../shared";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import * as toasts from "../../../../ECommerce/_redux/toasts/toastsActions";
import { CardHeaderToolbar } from "../../../../../../_metronic/_partials/controls";
import {
  createNewFolder,
  deleteFolder,
  getFoldersData,
} from "../../../../../_redux/FolderTabs/actions";
import { useDispatch } from "react-redux";
import { FolderCreateModal } from "./FolderCreateModal";
import DeleteFolderModal from "./DeleteFolderModal";

export default function Personal(props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [checkIftrue, setCheckIfTrue] = useState(false);
  const [data, setData] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);

  // -------------------- Folders --------------------
  const [folderName, setFolderName] = useState("");
  const [folderDes, setFolderDes] = useState("");
  const [folderId, setFolderId] = useState(null);

  const showLoader = useSelector((state) => state?.users?.showLoader ?? []);

  const foldersData = useSelector((state) => state?.foldersData?.Index ?? []);

  const folderErrors = useSelector((state) => state?.foldersData?.Errors);

  const modalClose = (id) => {
    window.$(id).modal("hide");
  };

  const handleDeleteFolder = () => {
    if (folderId != null) {
      let data = {};
      data.folder_id = folderId;
      dispatch(deleteFolder(data, toasts, intl, props?.params.id, modalClose));
    }
  };

  const paginationOptions = {
    totalSize: foldersData?.all_folders?.totalCount,
    sizePerPage,
    page: page,
  };

  useEffect(() => {
    setCheckIfTrue(props?.renderFolderCall);
  }, [props?.renderFolderCall]);

  useEffect(() => {
    if (checkIftrue == true) {
      getAllFolders();
    }
  }, [checkIftrue, page, sizePerPage]);

  const getAllFolders = async () => {
    let data = {};
    data.folder_type = "licni";
    data.user_id = props?.params.id;
    data.size = sizePerPage;
    data.page = page - 1;
    dispatch(getFoldersData(data));
  };

  const handleCreateFolder = () => {
    let data = {};
    data.folder_type = "licni";
    data.user_id = props?.params?.id;
    if (folderId != null) {
      data.new_name = folderName;
    } else {
      data.folder_name = folderName;
    }
    data.description = folderDes;
    if (folderId != null) {
      data.folder_id = folderId;
    }
    dispatch(createNewFolder(data, toasts, intl, modalClose));
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "title",
      text: "Title",
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: (cell, row) => {
        return (
          <div>
            <a
              href
              title="Edit customer"
              data-toggle="modal"
              data-target="#folderModalCreate"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                setFolderName(row.title);
                setFolderDes(row.content);
                setFolderId(row.id);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>
            <span
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target={`#folderModalDelete`}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              title={intl.formatMessage({
                id: "MENU.OPTIONS.DELETE",
              })}
              onClick={() => {
                setFolderId(row?.id);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
              {/*end::Svg Icon*/}
            </span>
          </div>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  return (
    <>
      <CardHeaderToolbar style={{ display: "flex", justifyContent: "end" }}>
        <button
          className="btn btn-primary font-weight-bold"
          data-toggle="modal"
          data-target="#folderModalCreate"
          onClick={() => {
            setFolderName("");
            setFolderDes("");
            setFolderId(null);
          }}
        >
          Create Folder
        </button>
      </CardHeaderToolbar>
      <Table
        showLoader={showLoader}
        data={foldersData?.all_folders?.data ?? []}
        columns={columns}
        pagiOptions={paginationOptions}
        setPage={setPage}
        // isFetching={isFetching}
        setSizePerPage={setSizePerPage}
        selectRowOptions={{
          allIds,
          setAllIds,
        }}
        intl={intl}
      />
      <FolderCreateModal
        folderId={folderId}
        folderErrors={folderErrors}
        folderName={folderName}
        setFolderName={setFolderName}
        folderDes={folderDes}
        setFolderDes={setFolderDes}
        handleCreateFolder={handleCreateFolder}
      />
      <DeleteFolderModal handleDeleteFolder={handleDeleteFolder} />
    </>
  );
}
