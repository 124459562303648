import {
  GET_ALL_CRAWLERS,
  DELETE_CRAWLER_URL,
} from "../../../_metronic/_helpers/Constants";
import { ARHIVA_REST_URL } from "../../../environments/environment";
import axios from "axios";

export const getAllCrawlersDataCall = (data) => {
  return axios.post(`${GET_ALL_CRAWLERS}`, data);
};

export const deleteCrawlerCall = (id) => {
  return axios.delete(DELETE_CRAWLER_URL + id);
};
