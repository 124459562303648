import React from "react";
import { Accordion, Card } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
const CustomTabContent = ({ eventKey, item, activeKey }) => {
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
        <a href className="card-title text-dark">
          <span className="svg-icon svg-icon-primary">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Navigation/Angle-double-right.svg"
              )}
              style={{
                transform:
                  activeKey === eventKey ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </span>
          <div className="card-label text-dark pl-4">{item.title}</div>
        </a>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className="text-dark-50 font-size-lg pl-12">
          {item.content}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default CustomTabContent;
