import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ProfileService from "../../Profile/service";
import { GET_ALL_USERS } from "../../../../_metronic/_helpers/Constants";
import { Redirect, Switch, Route, NavLink } from "react-router-dom";
import Account from "./EditTabs/Account";
import System from "./EditTabs/System";
import { ARHIVA_REST_URL } from "../../../../environments/environment";
import { useDispatch } from "react-redux";
import moment from "moment";
import * as toasts from "../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";

import {
  getUserPersonalInfo,
  upadetUserSettings,
} from "../../../_redux/users/actions";
import { useSelector } from "react-redux";
import Media from "./EditTabs/Media";
import MediaCategories from "./EditTabs/MediaCategories";
import Social from "./EditTabs/Social";
import Folders from "./EditTabs/Folders";
import { RTC } from "./EditTabs/RTC";

const UserEdit = () => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [callLoader, setCallLoader] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [activeTab, setActiveTab] = useState("");

  const history = useHistory();
  const service = new ProfileService();

  const userAccountInfo = useSelector(
    (state) => state?.users?.UserInfo.account_information ?? []
  );

  const systemSettingsInfo = useSelector(
    (state) => state?.users?.UserInfo.system_settings ?? []
  );

  const params = useParams();

  useEffect(() => {
    window.$('a[data-toggle="tab"]').on("show.bs.tab", function(e) {
      // console.log("sd", e.target.text);
      setActiveTab(e.target.text);
    });
  }, []);

  // const getUserData = () => {
  //   setLoader(true);
  //   let id = params?.id;
  //   dispatch(getUserPersonalInfo(id, setLoader));
  // };

  // useEffect(() => {
  //   getUserData();
  // }, []);

  const handleSubmit = () => {
    setCallLoader(true);
    let data = {};
    data = { ...userAccountInfo, ...systemSettingsInfo };
    let activeDateFrom = moment
      .utc(data.active_from)
      .format("YYYY-MM-DD  HH:mm:ss ");
    data.active_from = activeDateFrom;

    let activeDateTo = moment
      .utc(data.active_to)
      .format("YYYY-MM-DD  HH:mm:ss ");
    data.active_to = activeDateTo;

    let infoAccessFromDate = moment
      .utc(data.information_access_from)
      .format("YYYY-MM-DD ");
    data.information_access_from = infoAccessFromDate;

    let infoAccessToDate = moment
      .utc(data.information_access_to)
      .format("YYYY-MM-DD");
    data.information_access_to = infoAccessToDate;

    let birthDate = moment.utc(data.date_of_birth).format("YYYY-MM-DD");
    data.date_of_birth = birthDate;

    delete data.roles;
    delete Object.assign(data, { ["user_id"]: data["id"] })["id"];

    Object.keys(data).forEach((key) => {
      if (
        // data[key] === null ||
        // data[key] === "" ||
        data[key] === "Invalid date"
      ) {
        delete data[key];
      }
    });

    dispatch(upadetUserSettings(data, setCallLoader, params.id, toasts, intl));
  };

  return (
    <>
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Edit User </h3>
              </div>
              <div className="card-toolbar"></div>
            </div>
            <div className="card-body">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#account">
                    Account
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#system">
                    System
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#media">
                    Media
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#mediaCategory">
                    Media Category
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#social">
                    Social
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#folders">
                    Folders
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#rtc">
                    RTC
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div id="account" className="container tab-pane active">
                  <br />
                  <Account
                    renderCall={activeTab == "Account" ? true : false}
                    params={params}
                    loader={loader}
                  />
                </div>
                <div id="system" className="container tab-pane fade">
                  <br />
                  <System
                    renderCall={activeTab == "System" ? true : false}
                    params={params}
                    loader={loader}
                  />
                </div>
                <div id="media" className="container tab-pane fade">
                  <br />
                  <Media
                    renderCall={activeTab == "Media" ? true : false}
                    params={params}
                  />
                </div>
                <div id="mediaCategory" className="container tab-pane fade">
                  <br />
                  <MediaCategories
                    renderCall={activeTab == "Media Category" ? true : false}
                    params={params}
                  />
                </div>
                <div id="social" className="container tab-pane fade">
                  <br />
                  <Social
                    renderCall={activeTab == "Social" ? true : false}
                    params={params}
                  />
                </div>
                <div id="folders" className="container tab-pane fade">
                  <br />
                  <Folders
                    renderCall={activeTab == "Folders" ? true : false}
                    params={params}
                  />
                </div>
                <div id="rtc" className="container tab-pane fade">
                  <br />
                  <RTC
                    renderCall={activeTab == "RTC" ? true : false}
                    params={params}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEdit;
