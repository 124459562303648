import React, { useEffect, useState } from "react";
import { ARHIVA_REST_URL } from "../../../../../environments/environment";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { changeUserPassword } from "../../../../_redux/users/actions";

export const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [callLoader, setCallLoader] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConformPassword] = useState("");
  const [errMessage, setErrMessage] = useState(null);

  const handleEditUser = () => {
    if (newPassword == confirmPassword) {
      setCallLoader(true);
      let data = {};
      data.user_id = props?.routId;
      data.old_password = currentPassword;
      data.new_password = newPassword;

      dispatch(changeUserPassword(data,intl, toasts, setCallLoader))

      
    } else {
      setErrMessage({ password: "Password not matched" });
    }
  };

  return (
    <>
      <div className="card card-custom">
        {/*begin::Header*/}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              {intl.formatMessage({
                id: "MENU.PROFILE.CHANGE_PASSWORD",
              })}
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Change your account password
            </span>
          </div>
          <div className="card-toolbar">
            <button
              onClick={() => handleEditUser()}
              type="button"
              disabled={callLoader}
              className="btn btn-success mr-2 d-flex"
            >
              {intl.formatMessage({
                id: "MENU.OPTIONS.SAVE",
              })}
              {callLoader == true ? (
                <>
                  <div className="spinner ml-2" style={{ width: "20px" }}></div>
                </>
              ) : (
                ""
              )}
            </button>
            <NavLink
              to={`/admin/user/view/${props.routId}`}
              className="navi-link py-4"
              activeClassName="active"
              exact
            >
              <button type="button" className="btn btn-secondary">
                {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
              </button>
            </NavLink>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Form*/}
        <form className="form">
          <div className="card-body">
            {/*begin::Alert*/}
            {/*end::Alert*/}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                {intl.formatMessage({
                  id: "AUTH.INPUT.CURRENT_PASSWORD",
                })}
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  type="password"
                  className="form-control form-control-lg form-control-solid mb-2"
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.CURRENT_PASSWORD",
                  })}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                {intl.formatMessage({
                  id: "AUTH.INPUT.NEW_PASSWORD",
                })}
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  onChange={(e) => setNewPassword(e.target.value)}
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.NEW_PASSWORD",
                  })}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                {intl.formatMessage({
                  id: "AUTH.INPUT.CONFIRM_PASSWORD",
                })}
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  onChange={(e) => setConformPassword(e.target.value)}
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.CONFIRM_PASSWORD",
                  })}
                />
                <div className="text-danger">{errMessage?.password}</div>
              </div>
            </div>
          </div>
        </form>
        {/*end::Form*/}
      </div>
    </>
  );
};
