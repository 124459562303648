import { put, takeLatest, call } from "redux-saga/effects";
import { getAllChannelsData } from "../channels/actions";
import { setAllMediaData, setUnallowedMediaData } from "../media/actions";
import {
  getAllMediaData,
  getAllUsers,
  getUnallowedMediaData,
  getUserAccountSettings,
  getUserPersonalInfo,
  getUserSystemSettings,
  handleErrors,
  setProfileInfo,
  setUserAccountSettings,
  setUserRoles,
  setUsers,
  setUserSystemSettings,
  showLoader,
} from "./actions";
import { getSocialCategoryData } from "./../Social/actions";
import {
  assignChannelToUserCall,
  assignUserMediaCall,
  assignUserUnallowedMediaCall,
  attemptSaveEditSystemSettingsCall,
  changeMediaStatusCall,
  changeMultiMediaStatusCall,
  changeMultiUnallowedMediaStatusCall,
  changeUnallowedMediaStatusCall,
  changeUserPasswordCall,
  changeUserStatusCall,
  createUserCall,
  deleteuserCall,
  editAccountInfoCall,
  editEmailSettingsCall,
  editPersonalInfoCall,
  getAllMediaDataCall,
  getAllUsersCall,
  getProfileInfoCall,
  getUnallowedMediaDataCall,
  getUserAccountSettingsCall,
  getUserCall,
  getUsersRoleCall,
  getUserSystemSettingsCall,
  saveEditAccountSettingsCall,
  updateUserCall,
  updateUserSettingsCall,
} from "./Service";

function* attemptgetAllUsers(actions) {
  try {
    yield put(showLoader(true));
    const response = yield call(getAllUsersCall, actions);
    yield put(setUsers(response.data));
    yield put(showLoader(false));
  } catch (errors) {}
}

function* attemptChangeUserStatus(actions) {
  const { payload, intl, toasts, getUserData } = actions;
  try {
    const response = yield call(changeUserStatusCall, payload);
    // yield put(getAllUsers(getUserData));
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (errors) {}
}

function* attemptCreateUser(action) {
  const {
    payload,
    setBool,
    bool,
    modalClose,
    intl,
    toasts,
    setErrMessage,
    setCallLoader,
  } = action;
  try {
    const response = yield call(createUserCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllUsers());
    yield put(modalClose("#exampleModalCreate"));
    yield put(setBool(!bool));
  } catch (error) {
    setErrMessage(error?.response?.data?.errors);
  } finally {
    setCallLoader(false);
  }
}

function* attemptEditUser(action) {
  const {
    payload,
    setBool,
    bool,
    modalClose,
    intl,
    toasts,
    setErrMessage,
    setCallLoader,
  } = action;
  try {
    const response = yield call(updateUserCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllUsers());
    yield put(modalClose("#exampleModalEdit"));
    yield put(setBool(!bool));
  } catch (error) {
    setErrMessage(error?.response?.data?.errors);
  } finally {
    setCallLoader(false);
  }
}

function* attemptAssignChannelToUser(action) {
  const {
    payload,
    toasts,
    modalClose,
    intl,
    setErrMessage,
    activeUserId,
    channelsPayload,
    getUserData,
  } = action;
  try {
    const response = yield call(assignChannelToUserCall, payload, activeUserId);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllChannelsData(channelsPayload));
    yield put(getAllUsers(getUserData));
    yield put(modalClose("#exampleModal"));
  } catch (error) {
    setErrMessage(error?.response?.data?.errors);
  }
}

function* attemptDeleteUser(action) {
  const { id, intl, toasts } = action;
  try {
    const response = yield call(deleteuserCall, id);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllUsers());
  } catch (errors) {}
}

function* attemptGetUser(action) {
  const { id, setCreatedBy } = action;
  try {
    const response = yield call(getUserCall, id);
    yield put(setCreatedBy(response?.data?.data[0] ?? null));
  } catch (errors) {}
}

function* attemptGetUserRoles() {
  try {
    const response = yield call(getUsersRoleCall);
    yield put(setUserRoles(response.data));
  } catch (errors) {}
}

function* attemptGetUserProfileInfo(action) {
  try {
    const response = yield call(getProfileInfoCall, action.id);
    yield put(setProfileInfo(response.data));
    yield put(action.setLoader(false));
  } catch (errors) {}
}

function* attempEditPersonalInfo(action) {
  const { id, payload, setCallLoader, intl, toasts } = action;
  try {
    const response = yield call(editPersonalInfoCall, id, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(setCallLoader(false));
  } catch (errors) {}
}

function* attemptEditAccountInfo(action) {
  const { payload, intl, toasts } = action;
  try {
    const response = yield call(editAccountInfoCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (errors) {}
}

function* attemptEditEmailSettings(action) {
  const { payload, intl, toasts } = action;
  try {
    const response = yield call(editEmailSettingsCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (errors) {}
}

function* attemptAssignUserMedia(action) {
  const { payload, id, intl, toasts, setCallLoader } = action;
  let data = {};
  data.user_id = id;
  try {
    const response = yield call(assignUserMediaCall, id, payload);
    yield put(getUnallowedMediaData(data));
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    setCallLoader(false);
  } catch (errors) {}
}

function* attemptAssignUserUnallowedMedia(action) {
  const { payload, id, intl, toasts, setCallLoader } = action;
  try {
    const response = yield call(assignUserUnallowedMediaCall, id, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    setCallLoader(false);
  } catch (errors) {}
}

function* attemptGetAllMediaData(action) {
  const { payload } = action;
  try {
    // yield put(showLoader(true));
    const response = yield call(getAllMediaDataCall, payload);
    yield put(setAllMediaData(response.data));
  } catch (errors) {
  } finally {
    // yield put(showLoader(false));
  }
}

function* attemptChangeMediaStatus(action) {
  const { payload, toasts, intl, selectedPostType } = action;
  let data = {};
  data.user_id = payload.user_id;
  data.post_type_id = selectedPostType ?? "";
  data.title = payload.title;

  try {
    const response = yield call(changeMediaStatusCall, payload);

    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    // yield put(getAllMediaData(data));
  } catch (errors) {}
}

function* attemptChangeUnallowedMediaStatus(action) {
  const { payload, toasts, intl, selectedPostType, selectedMediaType } = action;
  let data = {};
  data.user_id = payload.user_id;
  data.post_type_id = selectedPostType ?? "";
  data.media_id = selectedMediaType ?? "";
  data.page = payload.page;
  data.size = payload.size;
  data.title = payload.title;

  let ge = {};
  ge.user_id = payload.user_id;
  ge.size = payload.size;
  ge.page = payload.page;
  ge.media_id = payload.media_id;

  try {
    const response = yield call(changeUnallowedMediaStatusCall, payload);

    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    // yield put(getSocialCategoryData(ge));
    // yield put(getUnallowedMediaData(data));
  } catch (errors) {}
}

function* attemptChangeMultiMediaStatus(action) {
  const { payload, toasts, intl } = action;
  let data = {};
  data.user_id = payload.user_id;
  data.title = payload.title;

  try {
    const response = yield call(changeMultiMediaStatusCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    // yield put(getAllMediaData(data));
  } catch (errors) {}
}
function* attemptChangeMultiUnallowedMediaStatus(action) {
  const { payload, toasts, intl } = action;
  let data = {};
  data.user_id = payload.user_id;
  data.size = payload.size;
  data.page = payload.page;
  data.title = payload.title;

  let ge = {};
  ge.user_id = payload.user_id;
  ge.size = payload.size;
  ge.page = payload.page;
  ge.media_id = payload.media_id;

  try {
    const response = yield call(changeMultiUnallowedMediaStatusCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    // yield put(getSocialCategoryData(ge));
    // yield put(getUnallowedMediaData(data));
  } catch (errors) {}
}

function* attemptGetUnallowedMediaData(action) {
  const { payload } = action;
  try {
    // yield put(showLoader(true));
    const response = yield call(getUnallowedMediaDataCall, payload);

    yield put(setUnallowedMediaData(response.data));
  } catch (errors) {
  } finally {
    // yield put(showLoader(false));
  }
}

function* attemptChangeUserPassword(action) {
  const { payload, intl, toasts, setCallLoader } = action;
  try {
    const response = yield call(changeUserPasswordCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(setCallLoader(false));
  } catch (errors) {}
}

function* attemptUpdateUserSettings(action) {
  const { payload, setCallLoader, id, toasts, intl } = action;
  try {
    const response = yield call(updateUserSettingsCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getUserPersonalInfo(id));
    yield put(setCallLoader(false));
  } catch (errors) {
    let errorMessage = errors?.response?.data?.errors;
    yield put(handleErrors(errorMessage));
    setCallLoader(false);
  }
}

function* attemptGetUserAccountSettings(action) {
  const { user_id } = action;
  try {
    const response = yield call(getUserAccountSettingsCall, user_id);
    yield put(setUserAccountSettings(response.data));
  } catch (errors) {}
}

function* attemptSaveEditAccountSettings(action) {
  const { payload, id, toasts, intl, setLoader } = action;

  try {
    const response = yield call(saveEditAccountSettingsCall, payload);
    yield put(getUserAccountSettings(id.id));
    setLoader(false);
    yield put(handleErrors({}));

    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (errors) {
    let errorMessage;
    errorMessage = errors?.response?.data?.errors;
    yield put(handleErrors(errorMessage));
    setLoader(false);
  }
}

function* attempGetUserSystemSettings(action) {
  const { user_id } = action;
  try {
    const response = yield call(getUserSystemSettingsCall, user_id);
    yield put(setUserSystemSettings(response.data));
  } catch (errors) {}
}

function* attemptSaveEditSystemSettings(action) {
  const { payload, toasts, intl, setLoader } = action;
  try {
    const response = yield call(attemptSaveEditSystemSettingsCall, payload);
    yield put(getUserSystemSettings(payload.user_id));
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(setLoader(true));
  } catch (errors) {}
}

export function* saga() {
  yield takeLatest("CHANGE_USER_STATUS", attemptChangeUserStatus);
  yield takeLatest("GET_ALL_USERS", attemptgetAllUsers);
  yield takeLatest("CREATE_USER", attemptCreateUser);
  yield takeLatest("EDIT_USER", attemptEditUser);
  yield takeLatest("ASSIGN_CHANNEL_TO_USER", attemptAssignChannelToUser);
  yield takeLatest("DELETE_USER", attemptDeleteUser);
  yield takeLatest("GET_USER", attemptGetUser);
  yield takeLatest("GET_USER_ROLES", attemptGetUserRoles);
  yield takeLatest("GET_USER_PROFILE_INFO", attemptGetUserProfileInfo);
  yield takeLatest("EDIT_PERSONAL_PROFILE_INFO", attempEditPersonalInfo);
  yield takeLatest("EDIT_ACCOUNT_INFO", attemptEditAccountInfo);
  yield takeLatest("EDIT_EMAIL_SETTINGS", attemptEditEmailSettings);
  yield takeLatest("ASSIGN_USER_MEDIA", attemptAssignUserMedia);

  yield takeLatest(
    "ASSIGN_USER_UNALLOWED_MEDIA",
    attemptAssignUserUnallowedMedia
  );

  yield takeLatest("GET_ALL_MEDIA_DATA", attemptGetAllMediaData);
  yield takeLatest("CHANGE_MEDIA_STATUS", attemptChangeMediaStatus);
  yield takeLatest(
    "CHANGE_Unallowed_MEDIA_STATUS",
    attemptChangeUnallowedMediaStatus
  );
  yield takeLatest("MULTI_MEDIA_STATUS_CHANGE", attemptChangeMultiMediaStatus);
  yield takeLatest(
    "MULTI_UNALLOWED_MEDIA_STATUS_CHANGE",
    attemptChangeMultiUnallowedMediaStatus
  );
  yield takeLatest("GET_UNALLOWED_MEDIA_DATA", attemptGetUnallowedMediaData);
  yield takeLatest("CHANGE_USER_PASSWORD", attemptChangeUserPassword);
  yield takeLatest("UPDATE_USER_SETTINGS", attemptUpdateUserSettings);
  yield takeLatest("GET_USER_ACCOUNT_SETTINGS", attemptGetUserAccountSettings);
  yield takeLatest(
    "SAVE_EDIT_ACCOUNT_SETTINGS",
    attemptSaveEditAccountSettings
  );
  yield takeLatest("GET_USER_SYSTEM_SETTINGS", attempGetUserSystemSettings);
  yield takeLatest("SAVE_EDIT_SYSTEM_SETTINGS", attemptSaveEditSystemSettings);
}
