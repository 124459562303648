import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MediaFactory from "../../_metronic/_helpers/MediaFactory";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";

export default function NotificationDetail() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [play, setPlay] = useState(false);
  const [playUrl, setPlayUrl] = useState("");
  const [isPdf, setIsPdf] = useState(false);

  const { t } = useTranslation();

  function timeConverter(UNIX_timestamp, t) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ];
    // months = months.map((m) => m);
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
      date + ". " + month + " " + year + ". " + hour + ":" + min + ":" + sec;
    return time;
  }
  useEffect(() => {
    if (id) {
      showNotificationDetail(id);
    }
  }, [id]);

  const showNotificationDetail = (id) => {
    let data = {};
    data.id = id;
    axios
      .post(`https://rest.arhiva.me/api/detail-web-notifications/show`, data)
      .then((res) => {
        let d = res.data.data;

        let structuredData = {
          page_title: "Alert klip",
          media_name: d?.media?.name,
          media_category_name: d?.data_segment?.dma_data?.category?.Naziv,
          title: d?.notification_data
            ? d?.notification_data?.title
            : d?.data_segment?.Naslov,
          date:
            moment(d?.data_segment?.TS)
              .locale("me")
              .format("dddd")
              ?.toUpperCase() +
            " ," +
            timeConverter(d?.data_segment?.TS, t),
          description: d?.data_segment?.Transkript,
          // mediaType:d?.data_segment?.dma_data?.media?.Tip == 1 ? "loading" : null,
          mediaType: MediaFactory(d, d?.data_segment),
          subjects: d?.data_segment?.dma_data_subjects,
          themes: d?.data_segment?.themes ?? [],
          channel: d.channel,
          id: d?.old_segment_id,
          is_opened: true,
          is_bookmarked: d?.post_stare?.length ?? 0,
          notification_id: d?.id,
          Pocetak: d?.data_segment?.Pocetak,
          post_type: d?.data_segment?.dma_data?.media?.Tip,
        };
        setData(structuredData);
      });
  };

  const handleClick = () => {
    const videoUrl = `https://files.arhiva.me/${data?.mediaType?.data?.videoDetails}`;
    setPlayUrl(videoUrl);
    const player = (
      <ReactPlayer url={videoUrl} playing controls width="100%" height="100%" />
    );
    setPlay(true);
  };

  const showPdf = () => {
    setIsPdf(true);
  };

  const handleDownload = () => {
    const url = `https://files.arhiva.me/${data?.mediaType?.data}`;
    const fileName = url.substring(url.lastIndexOf("/") + 1);

    fetch(url, { method: "GET", headers: {} })
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary link element to trigger the download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const loadMedia = () => {
    if (data?.mediaType?.type == 3) {
      return (
        <>
          <a
            onClick={handleDownload}
            className="btn btn-bg-white btn-icon-danger btn-hover-primary btn-icon mr-3 my-2 my-lg-0"
          >
            <i className="flaticon2-image-file   icon-md" />
          </a>
        </>
      );
    } else if (data?.mediaType?.type == 1) {
      return (
        <>
          <a
            className="btn btn-bg-white btn-icon-danger btn-hover-primary btn-icon mr-3 my-2 my-lg-0"
            onClick={handleClick}
          >
            <i className=" fas fa-video text-danger" />
          </a>
        </>
      );
    } else if (data?.mediaType?.type == 2) {
      return (
        <>
          <a
            className="btn btn-bg-white btn-icon-danger btn-hover-primary btn-icon mr-3 my-2 my-lg-0"
            onClick={showPdf}
          >
            <i className=" far fa-file-pdf " />
          </a>
        </>
      );
    }
  };

  const renderObjectInstitutes = () => {
    return (
      <>
        {data?.subjects?.filter((dds) => {
          return dds.Tip == "O" && dds.institute !== null;
        }).length ? (
          <div
            className="accordion accordion-toggle-arrow"
            id="accordionExample1"
          >
            <div className="card">
              <div className="card-header">
                <div
                  className="card-title"
                  data-toggle="collapse"
                  data-target="#collapseOne1"
                >
                  Institucije u svojstvu objekta
                </div>
              </div>
              <div
                id="collapseOne1"
                className="collapse show"
                data-parent="#accordionExample1"
              >
                <div className="card-body">
                  {data?.subjects
                    ?.filter((dds) => {
                      return dds.Tip == "O" && dds.institute !== null;
                    })
                    ?.map((instSub) => {
                      return (
                        <h6 className="text-muted" key={instSub?.ID}>
                          {instSub?.institute?.Naziv}
                        </h6>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderSubjectInstitutes = () => {
    if (data?.subjects?.find((s) => s.Tip == "S" && s.institute != null)) {
      return (
        <>
          <div
            className="accordion accordion-toggle-arrow"
            id="accordionExample1"
          >
            <div className="card">
              <div className="card-header">
                <div
                  className="card-title"
                  data-toggle="collapse"
                  data-target="#collapseOne2"
                >
                  Institucije u svojstvu subjekta
                </div>
              </div>
              <div
                id="collapseOne2"
                className="collapse show"
                data-parent="#accordionExample1"
              >
                <div className="card-body">
                  {data?.subjects
                    ?.filter((dds) => {
                      return dds.Tip == "S" && dds.institute != null;
                    })
                    ?.map((instSub) => (
                      <h6 className="text-muted" key={instSub?.ID}>
                        {instSub?.institute?.Naziv}
                      </h6>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const renderSubjectPersons = () => {
    return (
      <>
        {data?.subjects?.filter((dds) => {
          return dds.Tip == "S" && dds.subject != null;
        }).length ? (
          <div
            className="accordion accordion-toggle-arrow"
            id="accordionExample1"
          >
            <div className="card">
              <div className="card-header">
                <div
                  className="card-title"
                  data-toggle="collapse"
                  data-target="#collapseOne3"
                >
                  Osobe u svojstvu subjekta
                </div>
              </div>
              <div
                id="collapseOne3"
                className="collapse show"
                data-parent="#accordionExample1"
              >
                <div className="card-body">
                  {data?.subjects
                    ?.filter((dds) => {
                      return dds.Tip == "S" && dds.subject != null;
                    })
                    ?.map((instSub) => (
                      <h6 className="text-muted" key={instSub?.ID}>
                        {instSub?.subject?.Naziv}
                      </h6>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const renderObjectPersons = () => {
    return (
      <>
        {data?.subjects?.filter((dds) => {
          return dds.Tip == "O" && dds.subject != null;
        }).length ? (
          <div
            className="accordion accordion-toggle-arrow"
            id="accordionExample1"
          >
            <div className="card">
              <div className="card-header">
                <div
                  className="card-title"
                  data-toggle="collapse"
                  data-target="#collapseOne4"
                >
                  Osobe u svojstvu objekta
                </div>
              </div>
              <div
                id="collapseOne4"
                className="collapse show"
                data-parent="#accordionExample1"
              >
                <div className="card-body">
                  {data?.subjects
                    ?.filter((dds) => {
                      return dds.Tip == "O" && dds.subject != null;
                    })
                    ?.map((instSub) => (
                      <h6 className="text-muted" key={instSub?.ID}>
                        {instSub?.subject?.Naziv}
                      </h6>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const handleScroll = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      {play ? (
        <>
          <div>
            <a
              className="btn btn-bg-white btn-icon-danger btn-hover-primary btn-icon mr-3 my-2 my-lg-0"
              style={{ position: "absolute", right: "10px", top: "10px" }}
              onClick={() => setPlay(false)}
            >
              <i className="flaticon-circle " />
            </a>

            <ReactPlayer
              url={playUrl}
              playing
              controls
              width="100%"
              height="100%"
            />
          </div>
        </>
      ) : (
        <>
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <img src="http://localhost:3000/media/logos/favicon.ico" />
              </div>
              <div className="card-title">
                <h3 className="card-label">Arhimed Clipping</h3>
              </div>
              <div className="card-toolbar">
                <i className="flaticon2-settings text-primary" />
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center mb-10">
                {/*begin::Symbol*/}
                <div className="symbol symbol-40 symbol-light-danger mr-5">
                  <span className="symbol-label">
                    <i className="flaticon2-world text-primary" />
                  </span>
                </div>
                {/*end::Symbol*/}
                {/*begin::Text*/}
                <div className="d-flex flex-column font-weight-bold">
                  <a
                    href="#"
                    className="text-dark text-hover-primary  font-size-lg"
                  >
                    {data?.media_name}
                  </a>
                  <span className="text-muted">
                    {data?.media_category_name}
                  </span>
                </div>
                {/*end::Text*/}
              </div>

              <h2>{data?.title}</h2>
              <span className="text-muted">{data?.date}</span>
              {data?.mediaType?.data?.videoDetails ? (
                <div
                  style={{
                    width: "100%",
                    height: "500px",
                    marginBottom: "10px",
                  }}
                >
                  <ReactPlayer
                    url={`https://files.arhiva.me/${data?.mediaType?.data?.videoDetails}`}
                    playing
                    controls
                    width="100%"
                    height="100%"
                  />
                </div>
              ) : null}

              <div
                className="py-2 bg-primary subheader-solid "
                id="kt_subheader"
              >
                <div className=" container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                  {/*begin::Info*/}
                  <div className="d-flex align-items-center flex-wrap mr-2">
                    <i
                      className="flaticon2-information icon-lg "
                      style={{ color: "white" }}
                    />

                    {/*end::Action*/}
                  </div>
                  {/*end::Info*/}
                  {/*begin::Toolbar*/}
                  <div className="d-flex align-items-center flex-wrap">
                    {/*begin::Actions*/}
                    <a
                      onClick={handleScroll}
                      className="btn btn-bg-white btn-icon-info btn-hover-primary btn-icon mr-3 my-2 my-lg-0"
                    >
                      <i className="flaticon-users-1  icon-md" />
                    </a>
                    {/* https://files.arhiva.me/${data?.mediaType?.data?.videoDetails} --- Video  */}
                    {/* https://files.arhiva.me/${data?.mediaType?.data} --- Image  */}
                    {loadMedia()}

                    <a className="btn btn-bg-white btn-icon-info btn-hover-primary btn-icon mr-3 my-2 my-lg-0">
                      <i className="flaticon-download-1 icon-md" />
                    </a>
                    <a
                      href={data?.post_type == "3" ? data?.Pocetak : "#"}
                      className="btn btn-bg-white btn-icon-success btn-hover-primary btn-icon mr-3 my-2 my-lg-0"
                    >
                      <i className="flaticon2-graphic-design icon-md" />
                    </a>
                  </div>
                  {/*end::Toolbar*/}
                </div>
              </div>
              {isPdf ? (
                <div className="p-4 bg-light  alert-shadow fade show mt-2 d-flex">
                  {data?.mediaType?.data?.map((file) => {
                    return (
                      <>
                        <a
                          href={`https://files.arhiva.me/${file}`}
                          className="col-1 d-flex flex-column align-items-center m-2 ml-4"
                        >
                          <i className=" fas fa-file-pdf icon-3x " />
                          <div
                            className="text-wrap text-center mt-1"
                            style={{ width: "100px", fontSize: "10px" }}
                          >
                            {file}
                          </div>
                        </a>
                      </>
                    );
                  })}
                </div>
              ) : null}
              <div className="mt-3 p-4 bg-light  alert-shadow fade show ">
                <p>{data?.description}</p>
              </div>
            </div>
            <div className="card-footer ">
              <h5 className="text-muted">Tema:</h5>
              <span className="text-muted">{data?.channel?.name}</span>

              <div>{renderObjectInstitutes()}</div>
              <div>{renderSubjectInstitutes()}</div>
              <div>{renderSubjectPersons()}</div>
              <div>{renderObjectPersons()}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
