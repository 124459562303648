import React, { useState } from "react";
import { useFormik } from "formik";
import { connect, useDispatch } from "react-redux";
import { Link} from "react-router-dom";
import * as Yup from "yup";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import * as gconst from "../../../../../src/_metronic/_helpers/Constants";
import * as auth from "../_redux/authRedux";
import { setNewPassword } from "../_redux/authCrud";
import { Redirect } from "react-router-dom";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";

const initialValues = {
  password: "",
  changePassword: "",
};

function ResetPassword(props) {

    const dispatch = useDispatch();
    const [url, setUrl] = useState(window.location.search);
    const rKey = url.substring(url.indexOf("=") + 1, url.lastIndexOf("&"));
    const login = url.substring(url.lastIndexOf("=") + 1)

	const { intl } = props;
	const [isRequested, setIsRequested] = useState(false);
	const ResetPasswordSchema = Yup.object().shape({
        pwdspecchar: Yup.boolean(),
        pwdcpaschar: Yup.boolean(),
        password: Yup.string()
                .min(gconst.pwd_min_lenght, 
                    intl.formatMessage({ id: "AUTH.VALIDATION.PWD_MIN_LENGHT" },  
                        { pwd_min_lenght: gconst.pwd_min_lenght })
                    )
                .max(gconst.pwd_max_lenght, 
                    intl.formatMessage({ id: "AUTH.VALIDATION.PWD_MAX_LENGHT" },  
                        { pwd_max_lenght: gconst.pwd_max_lenght })
                    )
                .required( intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
        .when ('pwdspecchar', { is: (val) => gconst.pwd_char === true, 
            then: Yup.string().matches(/[!@#$%^&*(),.?":{}|<>]/g, intl.formatMessage({ id: "AUTH.VALIDATION.PWD_SPEC_CHAR" }) )
        })
        .when ('pwdcpaschar', { is: (val) => gconst.pwd_char === true, 
            then: Yup.string().matches(/[A-Z]/g, intl.formatMessage({ id: "AUTH.VALIDATION.PWD_CAPS_LETTER" }) )
        }),
        
        changePassword: Yup.string()
                .required( intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD", })
                )
                
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")], intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_MATCH" })
                ),
            }),
	});

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
        setNewPassword(values.changePassword, rKey, login)
                  .then((response) => {
                    setIsRequested(true);
					setSubmitting(true);
                    setStatus(
                        <FormattedHTMLMessage id= "AUTH.RESET.SUCCESS_SET" />
                    )
                    dispatch(
                        toasts.showToast(
                          response.data.status,
                          intl.formatMessage(
                            { id: <FormattedHTMLMessage id= "AUTH.RESET.SUCCESS_SET" />},
                          )
                        )
                      );
                      dispatch(toasts.hideToast());
                  })
        .catch((error) => {
            setIsRequested(false);
            setSubmitting(false);
            setStatus(
               <FormattedHTMLMessage id= "AUTH.RESET.INVALID_KEY" />
            )
            dispatch(
                toasts.showToast(
                  error.response.data.status,
                  intl.formatMessage(
                    {
                      id: <FormattedHTMLMessage id= "AUTH.RESET.INVALID_KEY" />,
                    },
                  )
                )
              );
              dispatch(toasts.hideToast());
              resetForm();
        });
    },
  });

	return (
    <>
		{/*begin::Content header*/}
		<div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
            <span className="font-weight-bold text-dark-50">{intl.formatMessage({ id: "AUTH.GENERAL.NO_ACCOUNT" })}</span>
            <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">{intl.formatMessage({ id: "AUTH.GENERAL.SIGNUP_BUTTON" })}</Link>
        </div>
        {/*end::Content header*/}
		
		<div className="login-form login-forgot" style={{ display: "block" }}>
			<div className="text-center mb-10 mb-lg-20">
				<h3 className="font-size-h1">
                {intl.formatMessage({ id: "AUTH.RESET.TITLE" })}
				</h3>
				<div className="text-muted font-weight-bold">
                    {intl.formatMessage({ id: "AUTH.RESET.SUBTITLE" })}
				</div>
			</div>
			<form
				onSubmit={formik.handleSubmit}
				className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
			>
            {formik.status ? (
                 isRequested ? (
                    <Redirect to="/auth" />
                  ) : ( 
                      null
                  )
            ) : (
                null
			)}
            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
            <input
                placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
                </div>
            ) : null}
            </div>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <div className="form-group fv-plugins-icon-container">
            <input
                placeholder={intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "changePassword"
                )}`}
                name="changePassword"
                {...formik.getFieldProps("changePassword")}
            />
            {formik.touched.changePassword && formik.errors.changePassword ? (
                <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                    {formik.errors.changePassword}
                </div>
                </div>
            ) : null}
            </div>
            {/* end: Confirm Password */}
            <div className="form-group d-flex flex-wrap flex-center">
				<button
					id="kt_login_forgot_submit"
					type="submit"
					className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					disabled={formik.isSubmitting}
				>
					{intl.formatMessage({ id: "AUTH.GENERAL.SUBMIT_BUTTON" })}
				</button>
				<Link to="/auth">
				<button
					type="button"
					id="kt_login_forgot_cancel"
					className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
				>
					{intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
				</button>
				</Link>
            </div>
			</form>
        </div>
    </>
	);
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
