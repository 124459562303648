import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";

import ProfileService from "../../Profile/service";
import {
  GET_ALL_CRAWLERS,
  GET_ALL_CRAWLER_RULE_TYPES,
  GET_ALL_CRAWLER_NETWORK_TYPES,
  a_crawler_edit_url,
  GET_RUNNING_CRAWLERS,
} from "../../../../_metronic/_helpers/Constants";
import { useSelector } from "react-redux";
import { Table } from "../shared";
import { sortCaret } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteModal from "../shared/DeleteModal";
import MultipleDeleteModal from "../shared/MultipleDeleteModal";
import PublishModal from "../shared/PublishModal";
import StartManuallyModal from "../shared/StartManuallyModal";
import { useIntl } from "react-intl";
import { SearchTable } from "../shared/Table/SearchTable";
import { getAllCrawlersData } from "../../../_redux/Crawler/actions";

const CrawlerPage = () => {
  const intl = useIntl();

  const [getSearch, setSearch] = useState("");
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(20);

  const [filterBy, setFilterBy] = useState({
    status: "All",
    rule_type: "All",
    network_type: "All",
  });

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });
  const data = useSelector((state) => state?.crawlers?.Index?.data ?? []);
  const showLoader = useSelector((state) => state?.crawlers?.showLoader ?? []);

  const service = new ProfileService();

  const getAllData = () => {
    let payload = {
      page: page - 1,
      size: sizePerPage,
      title: getSearch,
      status: filterBy.status === "All" ? null : filterBy.status,
      rule_type: filterBy.rule_type === "All" ? null : filterBy.rule_type,
      network_type:
        filterBy.network_type === "All" ? null : filterBy.network_type,
      order_by: orderBy.order_by === "" ? null : orderBy.order_by,
      order_direction:
        orderBy.order_direction === "" ? null : orderBy.order_direction,
    };
    dispatch(getAllCrawlersData(payload));
  };

  const { isLoading, isFetching } = useQuery(
    ["crawlers", page, filterBy, orderBy, sizePerPage, getSearch],
    () => getAllData(),
    {
      keepPreviousData: true,
    }
  );

  // helpers for component rerendering
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  // used for checking running crawlers
  useEffect(() => {
    // get running crawlers and update UI based on the fetched data
    const checkRunningCrawlers = async () => {
      const crawlers = data.data;
      if (!crawlers || !crawlers.length) {
        return;
      }
      const runningCrawlerIds = (await service.get(GET_RUNNING_CRAWLERS)).data;
      crawlers.forEach(
        (crawler) =>
          (crawler.running = runningCrawlerIds.includes(crawler.id) ? 1 : 0)
      );
      forceUpdate();
    };

    // delay in seconds
    const delay = 30;
    const interval = setInterval(() => checkRunningCrawlers(), delay * 1000);

    return () => clearInterval(interval);
  }, [data, forceUpdate, service]);

  useEffect(() => {
    let payload = {
      page: page - 1,
      size: sizePerPage,
      title: getSearch,
      status: filterBy.status === "All" ? null : filterBy.status,
      rule_type: filterBy.rule_type === "All" ? null : filterBy.rule_type,
      network_type:
        filterBy.network_type === "All" ? null : filterBy.network_type,
      order_by: orderBy.order_by === "" ? null : orderBy.order_by,
      order_direction:
        orderBy.order_direction === "" ? null : orderBy.order_direction,
    };
    dispatch(getAllCrawlersData(payload));
  }, []);

  const fetchCrawlerRuleTypes = async () => {
    const response = await service.post(GET_ALL_CRAWLER_RULE_TYPES);
    return response.data;
  };

  const fetchCrawlerNetworkTypes = async () => {
    const response = await service.post(GET_ALL_CRAWLER_NETWORK_TYPES);
    return response.data;
  };

  const ruleTypes = useQuery(["ruleTypes"], () => fetchCrawlerRuleTypes(), {
    keepPreviousData: true,
  });

  const networkTypes = useQuery(
    ["networkTypes"],
    () => fetchCrawlerNetworkTypes(),
    {
      keepPreviousData: true,
    }
  );

  const history = useHistory();

  const [currentCrawler, setCurrentCrawler] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isStartManuallyModalVisible,
    setIsStartManuallyModalVisible,
  ] = useState(false);
  const [
    isMultipleDeleteModalVisible,
    setMultipleIsDeleteModalVisible,
  ] = useState(false);
  const [isPublishModalVisible, setPublishModalVisible] = useState(false);
  const [publishType, setPublishType] = useState(true);
  const [currentCrawlers, setCurrentCrawlers] = useState(null);

  const onSort = (field, order) => {
    const sortField = (field) => {
      switch (field) {
        case "network_type.name":
          return "network_type";
        case "rule_type.name":
          return "rule_type";
        default:
          return field;
      }
    };
    setOrderBy({
      order_by: sortField,
      order_direction: order,
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "title",
      text: intl.formatMessage({ id: "TH_TITLE" }),
      sort: true,
      sortCaret: sortCaret,
      // formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_crawler_edit_url}/${row.id}`);
        },
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "rule_type.name",
      text: intl.formatMessage({ id: "TH_RULE_TYPE" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "network_type.name",
      text: intl.formatMessage({ id: "TH_NETWORK_TYPE" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "cron_timeout",
      text: intl.formatMessage({ id: "TH_CRONE_TIMEOUT" }),
      formatter: (cronTimeout) => {
        return `${cronTimeout} min`;
      },
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "TH_STATUS" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (status) => {
        return status === 0 || status === "" ? (
          <span className="label label-lg label-light-danger label-inline">
            {intl.formatMessage({ id: "COMMON.Disabled" })}
          </span>
        ) : (
          <span className="label label-lg label-light-success label-inline">
            {intl.formatMessage({ id: "COMMON.Enabled" })}
          </span>
        );
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "running",
      text: intl.formatMessage({ id: "TH_RUNNING" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (running) => {
        return running === 0 || running === "" ? (
          <span className="label label-lg label-light-danger label-inline">
            OFF
          </span>
        ) : (
          <span className="label label-lg label-light-success label-inline">
            ON
          </span>
        );
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "executed_at",
      text: intl.formatMessage({ id: "TH_EXECUTED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return val ? moment(val).format("DD.MM.yyyy HH:mm") : "Not Started Yet";
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_crawler_edit_url}/${row.id}`);
        },
        openDeleteModal: (row) => {
          setCurrentCrawler(row);
          setIsDeleteModalVisible(true);
        },
        isCrawler: true,
        openStartManuallyModal: (row) => {
          setCurrentCrawler(row);
          setIsStartManuallyModalVisible(true);
        },
      },
      classes: "text-center pr-0",
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  // if (isLoading) return null;
  const paginationOptions = {
    totalSize: data?.totalCount ?? 0,
    sizePerPage,
    page,
  };

  const publishSelectedItems = (selectedIds, type) => {
    setPublishModalVisible(true);
    setCurrentCrawlers(selectedIds);
    setPublishType(type);
  };

  const deleteSelectedItems = (selectedIds) => {
    setMultipleIsDeleteModalVisible(true);
    setCurrentCrawlers(selectedIds);
  };

  return (
    <Card>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        currentItem={currentCrawler}
        setCurrentItem={setCurrentCrawler}
        itemType="crawlers"
      />
      <StartManuallyModal
        isVisible={isStartManuallyModalVisible}
        setIsVisible={setIsStartManuallyModalVisible}
        currentItem={currentCrawler}
        setCurrentItem={setCurrentCrawler}
      />
      <PublishModal
        isVisible={isPublishModalVisible}
        setIsVisible={setPublishModalVisible}
        currentItems={currentCrawlers}
        setCurrentItems={setCurrentCrawlers}
        publishType={publishType}
        itemType="crawlers"
      />
      <MultipleDeleteModal
        isVisible={isMultipleDeleteModalVisible}
        setIsVisible={setMultipleIsDeleteModalVisible}
        currentItems={currentCrawlers}
        setCurrentItems={setCurrentCrawlers}
        itemType="crawlers"
      />
      <CardHeader title={intl.formatMessage({ id: "MENU.CRAWLERS" })} sticky>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary ml-5"
            onClick={() => {
              history.push(`${a_crawler_edit_url}`);
            }}
          >
            {intl.formatMessage({ id: "MENU.NEW" })}{" "}
            {intl.formatMessage({ id: "MENU.CRAWLER" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-lg-3">
            {!ruleTypes.isLoading && (
              <select
                className="form-control"
                name="rule-type"
                placeholder="Filter by Rule Type"
                onChange={(e) => {
                  setFilterBy({
                    ...filterBy,
                    rule_type: e.target.value,
                  });
                  setPage(1);
                }}
                value={filterBy.rule_type}
              >
                <option value={"All"}>
                  {intl.formatMessage({ id: "COMMON.ALL" })}
                </option>
                {ruleTypes.data.data.map((ruleType) => (
                  <option key={ruleType.id} value={ruleType.id}>
                    {ruleType.name}
                  </option>
                ))}
              </select>
            )}
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              {intl.formatMessage({ id: "TH.FILTER_BY.RULE_TYPE" })}
            </small>
          </div>
          <div className="col-lg-3">
            {!networkTypes.isLoading && (
              <select
                className="form-control"
                name="network-type"
                placeholder="Filter by Network Type"
                onChange={(e) => {
                  setFilterBy({
                    ...filterBy,
                    network_type: e.target.value,
                  });
                  setPage(1);
                }}
                value={filterBy.network_type}
              >
                <option value={"All"}>
                  {" "}
                  {intl.formatMessage({ id: "COMMON.ALL" })}
                </option>
                {networkTypes.data.data.map((netType) => (
                  <option key={netType.id} value={netType.id}>
                    {netType.name}
                  </option>
                ))}
              </select>
            )}
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              {intl.formatMessage({ id: "TH.FILTER_BY.NETWORK_TYPE" })}
            </small>
          </div>
          <div className="col-lg-3">
            <select
              className="form-control"
              name="type"
              placeholder="Filter by Status"
              onChange={(e) => {
                setFilterBy({
                  ...filterBy,
                  status: e.target.value,
                });
                setPage(1);
              }}
              value={filterBy.status}
            >
              <option value={"All"}>
                {intl.formatMessage({ id: "COMMON.ALL" })}
              </option>
              <option value={1}>
                {" "}
                {intl.formatMessage({ id: "COMMON.Enabled" })}
              </option>
              <option value={0}>
                {" "}
                {intl.formatMessage({ id: "COMMON.Disabled" })}{" "}
              </option>
            </select>
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              {intl.formatMessage({ id: "TH.FILTER_BY.STATUS" })}
            </small>
          </div>
          <SearchTable setSearch={setSearch} />
        </div>

        {allIds.length > 1 && (
          <div className="form-group">
            <p>
              {intl.formatMessage({ id: "COMMON.SELECTED_COUNT" })}
              {allIds.length}
            </p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>{" "}
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm"
              onClick={() => publishSelectedItems(allIds, true)}
            >
              <i className="fa fa-eye"></i>
              {intl.formatMessage({ id: "COMMON.Publish" })}
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm mr-5"
              onClick={() => publishSelectedItems(allIds, false)}
            >
              <i className="fa fa-eye-slash"></i>
              {intl.formatMessage({ id: "COMMON.Unpublish" })}
            </button>
          </div>
        )}

        <Table
          showLoader={showLoader}
          data={data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={setPage}
          isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
      </CardBody>
    </Card>
  );
};

export default CrawlerPage;
