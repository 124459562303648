import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, DialogContentText, useTheme, useMediaQuery } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../../_redux/folders/foldersActions";

const PurgeFolderDialog = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const folders = useSelector(
        (state) => (state.folders)
    );
    const { activeFolder, activeId } = folders;
    const submitPurgeFolder = () => {
        dispatch(actions.purgeFolder(activeId, activeFolder.type));
    }
    const closeFormDialog = () => {
        dispatch(actions.closeFolderDialog());
    }
    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={true}
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="form-dialog-title">
                    {intl.formatMessage({ id: "DIALOG.PURGE.TITLE" })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {intl.formatMessage({ id: "DIALOG.WARNING.PURGE.MESSAGE" },
                            {
                                activeFoldertitle: <strong key="dialogkeypurge">{activeFolder.title}</strong>
                            }
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            closeFormDialog()
                        }}
                        color="primary">
                        {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
                    </Button>
                    <Button
                        onClick={() => {
                            submitPurgeFolder();
                            closeFormDialog()
                        }}
                        color="primary"
                        autoFocus
                    >
                        {intl.formatMessage({ id: "DIALOG.WARNING.CONFIRM" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PurgeFolderDialog
