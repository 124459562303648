export const getAllInstituteActivities = (payload) => {
  return {
    type: "GET_ALL_INSTITUTE_ACTIVITIES",
    payload: payload,
  };
};

export const setAllInstituteActivities = (payload) => {
  return {
    type: "SET_ALL_INSTITUTIONS_ACTIVITITES_DATA",
    payload: payload,
  };
};

export const createInstituteActivity = (payload, toasts, intl, setLoader) => {
  return {
    type: "CREATE_INSTITUTE_ACTIVITY",
    payload: payload,
    toasts: toasts,
    intl: intl,
    setLoader: setLoader,
  };
};

export const setErrors = (payload) => {
  return {
    type: "SET_ERRORS_INSTITUTE_ACTIVITY",
    payload: payload,
  };
};

export const editInstituteActivity = (payload, toasts, intl, setLoader) => {
  return {
    type: "EDIT_INSTITUTE_ACTIVITY",
    payload: payload,
    toasts: toasts,
    intl: intl,
    setLoader: setLoader,
  };
};

export const setInstituteActivityStatus = (id, status) => {
  return {
    type: "SET_INSTITUTE_ACTIVITY_STATUS",
    id: id,
    status: status,
  };
};

export const deleteInstituteActivity = (payload, toasts, intl, data) => {
  return {
    type: "DELETE_INSTITUTE_ACTIVITY",
    payload: payload,
    toasts: toasts,
    intl: intl,
    data: data,
  };
};

export const deleteActivityFromRedux = (payload, modalClose) => {
  return {
    type: "DELETE_ACTIVITY_FROM_REDUX",
    payload: payload,
    modalClose: modalClose,
  };
};

export const setMultiStatus = (ids, status) => {
  return {
    type: "SET_MULTI_ACTIVITIES",
    ids: ids,
    status: status,
  };
};

export const changeMultiActivity = (ids, status, toasts, intl) => {
  return {
    type: "CHANGE_MULTI_ACTIVITIES",
    ids: ids,
    status: status,
    toasts: toasts,
    intl: intl,
  };
};

export const removeMultiActivity = (ids, toasts, intl, data) => {
  return {
    type: "REMOVE_MULTI_ACTIVETY",
    ids: ids,
    toasts: toasts,
    intl: intl,
    data: data,
  };
};

export const deleteMultiActivityFromRedux = (ids, modalClose, setAllIds) => {
  return {
    type: "DELETE_MULTI_ACTIVITY_FROM_REDUX",
    ids: ids,
    modalClose: modalClose,
    setAllIds: setAllIds,
  };
};
