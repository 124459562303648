export const createMedia = (payload, intl, toasts, history) => {
  return {
    type: "CREATE_MEDIA",
    payload: payload,
    intl: intl,
    toasts: toasts,
    history: history,
  };
};

export const editMedia = (payload, intl, toasts, history) => {
  return {
    type: "EDIT_MEDIA",
    payload: payload,
    intl: intl,
    toasts: toasts,
    history: history,
  };
};

export const setAllMediaData = (payload) => {
  return {
    type: "SET_ALL_MEDIA_DATA",
    payload: payload,
  };
};

export const setUnallowedMediaData = (payload) => {
  return {
    type: "SET_UNALLOWED_MEDIA_DATA",
    payload: payload,
  };
};

export const getMedia = (payload) => {
  return {
    type: "GET_MEDIA_DATA",
    payload: payload,
  };
};

export const deleteMedia = (id, intl, toasts) => {
  return {
    type: "DELETE_MEDIA",
    id: id,
    intl: intl,
    toasts: toasts,
  };
};

export const deleteMediaFromRedux = (id) => {
  return {
    type: "DELETE_MEDIA_FROM_REDUX",
    id: id,
  };
};

export const multiDeleteMedia = (payload, intl, toasts) => {
  return {
    type: "DELETE_MULTI_MEDIA",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const multiDeleteMediaFromRedux = (ids) => {
  return {
    type: "DELETE_MULTI_MEDIA_FROM_REDUX",
    ids: ids,
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});

export const setAssignUnallowedMedia = (payload) => {
  return {
    type: "SET_ASSIGN_UNALLOWED_MEDIA",
    payload: payload,
  };
};

export const setMultiAssignUnallowedMedia = (payload, status) => {
  return {
    type: "SET_MULTI_ASSIGN_UNALLOWED_MEDIA",
    payload: payload,
    status: status,
  };
};

export const setMediaCheckedStatus = (payload) => {
  return {
    type: "SET_MEDIA_CHECKED_STATUS",
    payload: payload,
  };
};

export const setMultiMediaCheckStatus = (payload, status) => {
  return {
    type: "SET_MULTI_MEDIA_CHECK_STATUS",
    payload: payload,
    status: status,
  };
};
