import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, toasts, intl, id, ids } = action;
  switch (action.type) {
    case "SET_USERS":
      return payload;
    case "SET_USER_STATUS_ON_SITE":
      const updatedData = state.data.map((x) =>
        x.id === payload.id
          ? { ...x, is_verified: payload.is_verified == 1 ? 0 : 1 }
          : x
      );

      return {
        ...state,
        data: updatedData,
      };
    // return updatedData;
    case "DELETE_USER_FROM_REDUX":
      const deleteData = state.data.filter((x) => (x.id === id ? false : x));
      return {
        ...state,
        data: deleteData,
      };
    case "MULTI_DELETE_USER_FROM_REDUX":
      const deleteMultiData = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );
      // console.log("state:", state, action);
      return {
        ...state,
        data: deleteMultiData,
      };
    default:
      return state;
  }
};

const UserRoles = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_USER_ROLES":
      return payload;
    default:
      return state;
  }
};

const userAccountSettings = (state = [], action) => {
  const { payload, fieldName } = action;
  switch (action.type) {
    case "SET_USER_ACCOUNT_SETTINGS":
      return payload;
    case "UPDATE_ACCOUNT_SETTINGS":
      let newChange = (state[fieldName] = payload);
      return { ...state };
    default:
      return state;
  }
};

const userSystemSettings = (state = [], action) => {
  const { payload, fieldName } = action;
  switch (action.type) {
    case "SET_USER_SYSTEM_SETTINGS":
      return payload;
    case "UPDATE_SYSTEM_SETTINGS":
      let newCahnge = (state[fieldName] = payload);
      return { ...state };
    default:
      return state;
  }
};

const UserInfo = (state = {}, action) => {
  const { payload, tabName, fieldName } = action;

  switch (action.type) {
    case "SET_USER_PROFILE_INFO":
      return payload;
    case "UPDATE_EDIT_USER_PROFILE":
      let newUpdate = {
        // ...state,
        // [tabName]: {
        //   ...state[tabName],
        //   [fieldName]: payload,
        // },
      };
      return { ...state, ...newUpdate };
    default:
      return state;
  }
};

const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};

const showErrorMessage = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case "EDIT_USER_ERROR":
      return payload ?? {};
    default:
      return state;
  }
};

export const reducer = combineReducers({
  Index,
  UserRoles,
  UserInfo,
  showLoader,
  showErrorMessage,
  userAccountSettings,
  userSystemSettings,
});
