import React, { useState, useEffect } from "react";
import {
  Input,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { ARHIVA_REST_URL } from "../../../../environments/environment";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getAllInstitutionsData } from "./../../../_redux/Institutions/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  createInstituteActivity,
  editInstituteActivity,
} from "../../../_redux/InstituteActivities/actions";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";

import { useIntl } from "react-intl";
import { a_institute_activity_url } from "../../../../_metronic/_helpers";
import { getAllInstituteActivities } from "./../../../_redux/InstituteActivities/actions";

const Edit = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [loader, setLoader] = useState(false);

  const [statusId, setStatusId] = useState(null);
  const [instActivityName, setInstActivityName] = useState("");

  const [status, setStatus] = useState(0);

  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [crStatus, setCrStatus] = useState(0);

  const [inputValue, setInputValue] = useState([]);
  const [valData, setValData] = useState([]);

  const [assignedInstitute, setAssignedInstitute] = useState({});

  const InstitutionsActivitiesData = useSelector(
    (state) => state?.instituteActivity?.Index ?? []
  );

  const ErrorMessages = useSelector(
    (state) => state?.instituteActivity?.Errors ?? []
  );

  useEffect(() => {
    console.log("params in edit", location);
  }, [location]);

  useEffect(() => {
    setStatusId(window.$("#is_public"));
  }, [crStatus]);

  useEffect(() => {
    if (crStatus) statusId.bootstrapSwitch("destroy");
  }, [crStatus]);

  useEffect(() => {
    if (statusId != null) {
      if (InstitutionsData) {
        statusId.bootstrapSwitch();
        window
          .$("#is_public")
          .on("switchChange.bootstrapSwitch", function(event, state) {
            if (state) {
              setStatus(1);
            } else {
              setStatus(0);
            }
          });
      }
    }
  }, [statusId, InstitutionsData, crStatus]);

  // ----------------------------------------------------------------------------------

  const loadOptions = async (inputValue, callback) => {
    let d = {};
    d.page = page - 1;
    d.size = sizePerPage;
    d.title = inputValue;
    const response = await axios.post(
      `${ARHIVA_REST_URL}api/insitutions/search`,
      d
    ); //use that input value in the endpoint
    let data = await response.data.data.data;
    console.log("in", data)
    callback(
      data?.map((d) => {
        return { value: d.id, label: d.name };
      })
    );
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue({ inputValue });
    return inputValue;
  };

  const handleChange = (e) => {
    setValData(e);
  };

  useEffect(() => {
    if (params.id != null) {
      let currentActivity = InstitutionsActivitiesData?.data?.filter(
        (ins) => ins.id == params.id
      );
      if (currentActivity) {
        setInstActivityName(currentActivity[0]?.name);
        setAssignedInstitute(currentActivity[0]?.institute);
        setCrStatus(currentActivity[0]?.status);
      }
    }
  }, [params.id, InstitutionsActivitiesData]);

  useEffect(() => {
    if (assignedInstitute) {
      let obj = {};
      obj.value = assignedInstitute.id;
      obj.label = assignedInstitute.name;

      setValData(obj);
    }
  }, [assignedInstitute]);

  // ------------------------------------------------------------------------------------------

  const InstitutionsData = useSelector(
    (state) => state?.institutions?.Index ?? []
  );

  const getInstituteActivities = () => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;
    dispatch(getAllInstituteActivities(data));
  };

  useEffect(() => {
    getInstituteActivities();
  }, [params]);

  useEffect(() => {
    getAllInstitute();
  }, []);

  const getAllInstitute = () => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;
    dispatch(getAllInstitutionsData(data));
  };

  const handleCreateActivity = () => {
    if (params.id != null) {
      setLoader(true);
      let data = {};
      data.id = params.id;
      data.institute_id = valData?.value ?? null;
      data.name = instActivityName;
      data.status = status;

      dispatch(editInstituteActivity(data, toasts, intl, setLoader));
      // dispatch(createInstituteActivity(data, toasts, intl));
    } else {
      setLoader(true);
      let data = {};
      data.institute_id = valData?.value;
      data.name = instActivityName;
      data.status = status;

      dispatch(createInstituteActivity(data, toasts, intl, setLoader));
    }
  };

  const backToInstituteActivity = () => {
    history.push({
      pathname: a_institute_activity_url,
      state: { page: location?.state?.page },
    });
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            params?.id
              ? "Institution Activity Edit"
              : "Institution Activity Create"
          }
        >
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={() => backToInstituteActivity()}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              {/* {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })} */}
              Back
            </button>
            <button
              disabled={loader}
              type="submit"
              className={
                loader
                  ? "btn btn-primary ml-2 spinner spinner-right"
                  : "btn btn-primary ml-2 "
              }
              onClick={() => handleCreateActivity()}
            >
              {/* {loader ? <div class="spinner spinner-right"></div> : ""} */}
              {/* {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })} */}
              Save Changes
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="mt-5">
            <form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-12">
                  <label>Institution Activity Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Activity Name"
                    onChange={(e) => setInstActivityName(e.target.value)}
                    value={instActivityName}
                  />
                  {ErrorMessages?.errors?.name ? (
                    <div className="text-danger">
                      {ErrorMessages.errors.name[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div className="form-group row">
                <div className="col-lg-12">
                  <label>Select Institute</label>
                  <Select
                    options={InstitutionsData?.map((ins) => {
                      return { value: ins.id, label: ins.name };
                    })}
                    onChange={(e) => {
                      handleInstituteSelect(e);
                    }}
                    value={institutionOptions}
                    searchable={(e) => {
                      handleSearch(e);
                    }}
                  />
                  {ErrorMessages?.errors?.institute_id ? (
                    <div className="text-danger">
                      {ErrorMessages.errors.institute_id[0]}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div> */}
              <div className="form-group row">
                <div className="col-lg-12 col-md-9 col-sm-12">
                  <label>Select Institute</label>
                  <AsyncSelect
                    cacheOptions
                    // isMulti
                    loadOptions={loadOptions}
                    defaultOptions={InstitutionsData?.data?.map((d)=>({value: d?.id, label: d?.name}))}
                    onInputChange={(e) => handleInputChange(e)}
                    onChange={(e) => handleChange(e)}
                    value={valData}
                  />
                  {/* <select
                    className="form-control select2"
                    id="kt_select2_1"
                    multiple
                    name="param"
                  >
                    <option label="Label"></option>
                    {params.id ? (
                      <option label="Label" selected value></option>
                    ) : (
                      ""
                    )}
                  </select>
                  {ErrorMessages?.errors?.institute_id ? (
                    <div className="text-danger">
                      {ErrorMessages.errors.institute_id[0]}
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-12">
                  <div>
                    <label className="col-form-label ">Status</label>
                    <div className="">
                      <input
                        checked={crStatus == 1 ? true : false}
                        data-switch="true"
                        type="checkbox"
                        data-on-text="Yes"
                        data-handle-width="50"
                        data-off-text="No"
                        data-on-color="primary"
                        id="is_public"
                        name="Status"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button type="button" style={{ display: "none" }} />
            </form>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Edit;
