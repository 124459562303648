import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import "../../../../../../_metronic/_assets/css/highlightText.css";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import Highlighter from "react-highlight-words";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "./lotties/animation.json";
import { useSelector } from "react-redux";
import axios from "axios";
import { ARHIVA_REST_URL } from "../../../../../../environments/environment";
import FileSaver from "file-saver";
import { useDispatch } from "react-redux";
import * as actions from "../../../_redux/folders/foldersActions";
import {
  CREATE_STRING,
  searches_url,
  RENAME_STRING,
  PURGE_STRING,
  REMOVE_STRING,
} from "../../../../../../_metronic/_helpers";
export const SearchIndex = (props) => {
  const isLoading = useSelector((state) => state?.search?.isLoading);
  const data = useSelector((state) => state?.search?.data);
  const searchData = useSelector((state) => state?.search?.searchedWords ?? []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const intl = useIntl();

  const [words, setWords] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (searchData.length) {
      setWords(searchData?.join(searchData)?.split(" ") ?? []);
    }
  }, [searchData]);

  const _renderPostMedia = (post) => {
    return (
      <>
        <div className="row mb-2">
          {post.post_files.map((postFile) => {
            return _renderSinglePostMedia(postFile);
          })}
        </div>
        {}
      </>
    );
  };
  const [dataIdMacth, setDataIdMacth] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [selectedIdsArray, setSelectedIdsArray] = useState([]);
  const [postFileDownloadId, setPostFileDownloadId] = useState([]);

  const checkPostFiles = () => {
    var selectedIDs = window
      .$("input[name^=test]:checked")
      .map(function() {
        return window.$(this).val();
      })
      .get();
    selectedIDs = selectedIDs?.map((s) => parseInt(s));
    let selectedData = data?.filter(
      (d) => selectedIDs?.includes(d?.id) && d?.post_files?.length
    );
    setPostFileDownloadId(selectedData.map((d) => d.id));
    setDisableButton(selectedData.length > 0 ? true : false);
  };

  useEffect(() => {}, [disableButton]);
  const _renderSinglePostMedia = (postFile) => {
    if (postFile.file_type == "image") {
      return (
        <img
          src={postFile.file_path}
          className="col-6 player-12951994 w-100"
          alt=""
        />
      );
    } else if (postFile.file_type == "video") {
      return (
        <video
          className="player-12951994 w-100"
          src={postFile.file_path}
          type="video/mp4"
          controls
        >
          Your browser does not support HTML5 video.
        </video>
      );
    }
  };
  const _renderTableBody = () => {
    return data?.length
      ? data.map((d, i) => {
          return (
            <>
              <tr className="datatable-row">
                <td>
                  <label className="checkbox checkbox-single mr-4">
                    <input
                      type="checkbox"
                      id={d.type.id}
                      value={d.id}
                      name="test[]"
                      onClick={() => {
                        checkPostFiles();
                      }}
                    />
                    <span />
                  </label>
                  <a
                    className="datatable-toggle-detail"
                    data-toggle="collapse"
                    data-target={`#collapse${i}`}
                  >
                    <i class="fa fa-caret-down"></i>
                  </a>
                </td>
                <td>{d.id}</td>
                <td>{d.title}</td>
                <td>{d?.media?.name}</td>
                <td>{d.section.name}</td>
                <td>{d.type.name}</td>
                <td>{moment(d.created_at).format("YYYY-MM-DD")}</td>
                <td>{moment(d.updated_at).format("YYYY-MM-DD")}</td>
              </tr>
              <tr
                id={`collapse${i}`}
                className="collapse"
                data-parent="#accordionExample1"
              >
                <td class="card-body" colSpan={8}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12 mb-5">
                        <h3>
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={words}
                            autoEscape={true}
                            textToHighlight={d.title}
                          />
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8 p-5">
                        <p>
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={words}
                            autoEscape={true}
                            textToHighlight={d.content}
                          />
                        </p>
                      </div>
                      <div className="col-lg-4 p-5">
                        <div className="card card-custom gutter-b bg-light-dark">
                          <div className="card-body">
                            {_renderPostMedia(d)}
                            <a
                              href={d.social.url}
                              className="btn btn-dark w-100"
                            >
                              <span className="svg-icon svg-icon-primary svg-icon-2x">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x={0} y={0} width={24} height={24} />
                                    <path
                                      d="M11.7573593,15.2426407 L8.75735931,15.2426407 C8.20507456,15.2426407 7.75735931,15.6903559 7.75735931,16.2426407 C7.75735931,16.7949254 8.20507456,17.2426407 8.75735931,17.2426407 L11.7573593,17.2426407 L11.7573593,18.2426407 C11.7573593,19.3472102 10.8619288,20.2426407 9.75735931,20.2426407 L5.75735931,20.2426407 C4.65278981,20.2426407 3.75735931,19.3472102 3.75735931,18.2426407 L3.75735931,14.2426407 C3.75735931,13.1380712 4.65278981,12.2426407 5.75735931,12.2426407 L9.75735931,12.2426407 C10.8619288,12.2426407 11.7573593,13.1380712 11.7573593,14.2426407 L11.7573593,15.2426407 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(7.757359, 16.242641) rotate(-45.000000) translate(-7.757359, -16.242641) "
                                    />
                                    <path
                                      d="M12.2426407,8.75735931 L15.2426407,8.75735931 C15.7949254,8.75735931 16.2426407,8.30964406 16.2426407,7.75735931 C16.2426407,7.20507456 15.7949254,6.75735931 15.2426407,6.75735931 L12.2426407,6.75735931 L12.2426407,5.75735931 C12.2426407,4.65278981 13.1380712,3.75735931 14.2426407,3.75735931 L18.2426407,3.75735931 C19.3472102,3.75735931 20.2426407,4.65278981 20.2426407,5.75735931 L20.2426407,9.75735931 C20.2426407,10.8619288 19.3472102,11.7573593 18.2426407,11.7573593 L14.2426407,11.7573593 C13.1380712,11.7573593 12.2426407,10.8619288 12.2426407,9.75735931 L12.2426407,8.75735931 Z"
                                      fill="#000000"
                                      transform="translate(16.242641, 7.757359) rotate(-45.000000) translate(-16.242641, -7.757359) "
                                    />
                                    <path
                                      d="M5.89339828,3.42893219 C6.44568303,3.42893219 6.89339828,3.87664744 6.89339828,4.42893219 L6.89339828,6.42893219 C6.89339828,6.98121694 6.44568303,7.42893219 5.89339828,7.42893219 C5.34111353,7.42893219 4.89339828,6.98121694 4.89339828,6.42893219 L4.89339828,4.42893219 C4.89339828,3.87664744 5.34111353,3.42893219 5.89339828,3.42893219 Z M11.4289322,5.13603897 C11.8194565,5.52656326 11.8194565,6.15972824 11.4289322,6.55025253 L10.0147186,7.96446609 C9.62419433,8.35499039 8.99102936,8.35499039 8.60050506,7.96446609 C8.20998077,7.5739418 8.20998077,6.94077682 8.60050506,6.55025253 L10.0147186,5.13603897 C10.4052429,4.74551468 11.0384079,4.74551468 11.4289322,5.13603897 Z M0.600505063,5.13603897 C0.991029355,4.74551468 1.62419433,4.74551468 2.01471863,5.13603897 L3.42893219,6.55025253 C3.81945648,6.94077682 3.81945648,7.5739418 3.42893219,7.96446609 C3.0384079,8.35499039 2.40524292,8.35499039 2.01471863,7.96446609 L0.600505063,6.55025253 C0.209980772,6.15972824 0.209980772,5.52656326 0.600505063,5.13603897 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(6.014719, 5.843146) rotate(-45.000000) translate(-6.014719, -5.843146) "
                                    />
                                    <path
                                      d="M17.9142136,15.4497475 C18.4664983,15.4497475 18.9142136,15.8974627 18.9142136,16.4497475 L18.9142136,18.4497475 C18.9142136,19.0020322 18.4664983,19.4497475 17.9142136,19.4497475 C17.3619288,19.4497475 16.9142136,19.0020322 16.9142136,18.4497475 L16.9142136,16.4497475 C16.9142136,15.8974627 17.3619288,15.4497475 17.9142136,15.4497475 Z M23.4497475,17.1568542 C23.8402718,17.5473785 23.8402718,18.1805435 23.4497475,18.5710678 L22.0355339,19.9852814 C21.6450096,20.3758057 21.0118446,20.3758057 20.6213203,19.9852814 C20.2307961,19.5947571 20.2307961,18.9615921 20.6213203,18.5710678 L22.0355339,17.1568542 C22.4260582,16.76633 23.0592232,16.76633 23.4497475,17.1568542 Z M12.6213203,17.1568542 C13.0118446,16.76633 13.6450096,16.76633 14.0355339,17.1568542 L15.4497475,18.5710678 C15.8402718,18.9615921 15.8402718,19.5947571 15.4497475,19.9852814 C15.0592232,20.3758057 14.4260582,20.3758057 14.0355339,19.9852814 L12.6213203,18.5710678 C12.2307961,18.1805435 12.2307961,17.5473785 12.6213203,17.1568542 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                      transform="translate(18.035534, 17.863961) scale(1, -1) rotate(45.000000) translate(-18.035534, -17.863961) "
                                    />
                                  </g>
                                </svg>
                              </span>
                              {intl.formatMessage({
                                id: "link_to_original_article",
                              })}
                            </a>
                            <h5 className="font-weight-bold text-dark mb-2 mt-2">
                              Autor
                            </h5>
                            <h6 className="text-muted">Presenter / Urednik</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ display: "none" }}></td>
                <td style={{ display: "none" }}></td>
                <td style={{ display: "none" }}></td>
                <td style={{ display: "none" }}></td>
                <td style={{ display: "none" }}></td>
                <td style={{ display: "none" }}></td>
                <td style={{ display: "none" }}></td>
              </tr>
            </>
          );
        })
      : null;
  };
  const [filterBy, setFilterBy] = useState({
    post_type: "All",
    media: "All",
    section: "All",
    created_at: "All",
  });

  useEffect(() => {
    if (data) {
      // window
      //   .$("#kt_datatable1")
      //   .dataTable()
      //   .fnDestroy();
      // window.$("#kt_datatable1").DataTable();
    }
  }, [data, isLoading]);
  useEffect(() => {
    let inputElm = document.getElementById("tagify");
    let tagify = new window.Tagify(inputElm, {});

    tagify.addTags(searchData);
  }, []);

  // const searchByCreatedDate = (date) => {
  //   setStartDate(date);
  //   setFilterBy({
  //     ...filterBy,
  //     created_at: moment(date).format("YYYY-MM-DD"),
  //   });
  // };

  const [selectedPosts, setSelectedPosts] = useState([]);
  const [documentTitle, setDocumentTitle] = useState("Internet objave");
  const [subTitle, setSubTitle] = useState("Na dan: 02.02.2022");
  const [folderName, setFolderName] = useState("");
  const [folderDescription, setFolderDescription] = useState("");

  useEffect(() => {
    window.$("#selectAll").click(function() {
      window
        .$("input[name=subCheckbox]")
        .prop("checked", window.$(this).prop("checked"));
    });

    window.$("input[type=checkbox]").click(function() {
      if (!window.$(this).prop("checked")) {
        window.$("#selectAll").prop("checked", false);
      }
    });
  }, []);

  const _handleDownloadPdf = () => {
    var selectedIDs = window
      .$("input[name^=test]:checked")
      .map(function() {
        return window.$(this).val();
      })
      .get();

    let data = {};
    data.selected_ids = postFileDownloadId;
    axios
      .post(`${ARHIVA_REST_URL}api/search/download-posts-pdf`, data, {
        responseType: "arraybuffer", // important
      })
      .then((response) => {
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", "file.pdf"); //or any other extension
        // document.body.appendChild(link);
        // link.click();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {});
  };

  const _handleSavePdf = () => {
    var selectedIDs = window
      .$("input[name^=test]:checked")
      .map(function() {
        return window.$(this).val();
      })
      .get();
    setSelectedPosts(selectedIDs);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var selectedCheckboxesId = window
      .$("input[name^=Checkboxes1]:checked")
      .map(function() {
        return window.$(this).val();
      })
      .get();

    var sortingIds = window.$("input[name^=radios1]:checked").val();

    let data = {};
    data.selected_ids = selectedPosts;
    data.document_title = documentTitle;
    data.sub_title = subTitle;
    data.document_form = selectedCheckboxesId;
    data.sort = sortingIds;
    axios
      .post(`${ARHIVA_REST_URL}api/search/save-posts-pdf`, data, {
        responseType: "blob", // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {});
  };
  const dispatch = useDispatch();
  const [createFolderData, setCreateFolderData] = useState([]);
  const handleCreateFolder = () => {
    // let data = {};
    // data.folder_name = folderName;
    // data.description = folderDescription;
    // data.folder_type = "licni";
    // axios
    //   .post(`${ARHIVA_REST_URL}api/folder/create`, data)
    //   .then((response) => {
    //     setCreateFolderData(response.data);
    //     dispatch(actions.fetchPersonalFolders());
    //   })
    //   .catch((e) => {});
    dispatch(actions.openFolderDialog(CREATE_STRING, "licni"));
  };
  const [fetchedFolder, setFetchedFolders] = useState([]);
  const folders = useSelector((state) => state.folders.data.licni);

  useEffect(() => {
    setFetchedFolders(folders);
  }, [folders]);

  // useEffect(() => {
  //   _fetchFolderData();
  // }, [createFolderData]);

  // const _fetchFolderData = () => {
  //   let data = {};
  //   data.folder_type = "licni";
  //   axios
  //     .post(`${ARHIVA_REST_URL}api/folder/get`, data)
  //     .then((response) => {
  //       setFetchedFolders(response.data);
  //     })
  //     .catch((e) => {});
  // };

  const [floderId, setFolderId] = useState("");
  const handleRemove = (id) => {
    // let data = {};
    // data.folder_id = id;
    // axios
    //   .post(`${ARHIVA_REST_URL}api/folder/delete`, data)
    //   .then((response) => {
    //     _fetchFolderData();
    //     dispatch(actions.fetchPersonalFolders());
    //   })
    //   .catch((e) => {});
    dispatch(actions.deleteFolder(id, "licni"));
    // dispatch(actions.fetchPersonalFolders());
  };

  const handleOpenFolder = (id) => {
    let data = {};
    data.folder_id = id;
    axios
      .post(`${ARHIVA_REST_URL}api/folder/posts/get`, data)
      .then((response) => {})
      .catch((e) => {});
  };

  const handleAddPosts = () => {
    var selectedIDs = window
      .$("input[name^=test]:checked")
      .map(function() {
        return window.$(this).val();
      })
      .get();
    var fid = window
      .$("input[name^=CheckboxesFolder]:checked")
      .map(function() {
        return window.$(this).val();
      })
      .get();
    let data = {};
    data.folder_id = fid;
    data.post_ids = selectedIDs;
    axios
      .post(`${ARHIVA_REST_URL}api/folder/add-post`, data)
      .then((response) => {
        window.toastr.success("Posts Added Successfully");
      })
      .catch((e) => {
        window.toastr.error("Something went wrong");
      });
  };
  useEffect(() => {
    document
      .getElementById("CheckboxesAllSelect")
      .addEventListener("click", (event) => {
        if (event.target.checked) {
          var items = document.getElementsByName("test[]");
          for (var i = 0; i < items.length; i++) {
            if (items[i].type == "checkbox") items[i].checked = true;
          }
        } else {
          var items = document.getElementsByName("test[]");
          for (var i = 0; i < items.length; i++) {
            if (items[i].type == "checkbox") items[i].checked = false;
          }
        }
      });
  }, []);

  const handleSelectPostByType = (e, selectId) => {
    var items = document.getElementsByName("test[]");
    if (e.target.checked == true) {
      for (var i = 0; i < items.length; i++) {
        if (items[i].id == selectId) items[i].checked = true;
      }
      checkPostFiles();
    } else {
      for (var i = 0; i < items.length; i++) {
        if (items[i].id == selectId) items[i].checked = false;
      }
      checkPostFiles();
    }
  };

  const renderPostType = () => {
    let d =
      data?.map((item) => {
        return {
          id: item.type.id,
          name: item.type.name,
        };
      }) ?? [];

    let ids = d.map((data) => data.id);
    const uniqueIds = [...new Set(ids)];
    let unique = uniqueIds.map((id) => {
      return d.find((data) => data.id == id);
    });

    return unique.map((item) => {
      return (
        <>
          <a class="dropdown-item">
            <label className="checkbox">
              <input
                type="checkbox"
                value={item.id}
                onClick={(e) => handleSelectPostByType(e, item.id)}
                name="CheckboxFolder"
              />
              <span />

              {item.name}
            </label>
          </a>
        </>
      );
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "media.name",
      text: "Media",
      headerStyle: { verticalAlign: "initial" },
      sort: true,
    },
    {
      dataField: "section.name",
      text: "Section",
      headerStyle: { verticalAlign: "initial" },
      sort: true,
    },
    {
      dataField: "type.name",
      text: "Type Name",
      headerStyle: { verticalAlign: "initial" },
      sort: true,
    },

    {
      dataField: "created_at",
      text: "Created",
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      sort: true,

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: "Updated",
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },

      sort: true,

      headerStyle: { verticalAlign: "initial" },
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <div>
            <div class="btn-group pl-0 pt-5 pb-5">
              <button type="button" class="btn btn-outline-secondary">
                <input
                  type="checkbox"
                  name="CheckboxesAllSelect"
                  id="CheckboxesAllSelect"
                />
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split mr-5"
                id="dropdownMenuReference"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-reference="parent"
              >
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuReference"
              >
                {renderPostType()}
              </div>
            </div>
            <div class="btn-group pl-0 pt-5 pb-5">
              <button type="button" class="btn btn-outline-secondary p-1">
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Files/Folder.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split mr-5"
                id="dropdownMenuReferenceTwo"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-reference="parent"
              >
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div
                id="folder_id"
                className="dropdown-menu"
                x-placement="top-start"
                onClick={(e) =>
                  e.target.tagName === "A"
                    ? setFolderId(e.target.getAttribute("value"))
                    : ""
                }
              >
                {fetchedFolder?.map((folder) => {
                  return (
                    <a
                      className="dropdown-item"
                      value={folder.id}
                      style={{ justifyContent: "space-between" }}
                    >
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          name="CheckboxesFolder"
                          value={folder.id}
                        />
                        <span />
                        {folder.title}
                      </label>

                      <button
                        style={{ border: "none", background: "none" }}
                        onClick={() => handleRemove(folder.id)}
                      >
                        {" "}
                        <span className="svg-icon svg-icon-primary svg-icon-2x">
                          {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Home/Trash.svg*/}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                              <path
                                d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </button>
                      {/* <button
                        onClick={() => {
                          handleOpenFolder(folder.id);
                        }}
                      >
                        Open
                      </button> */}
                    </a>
                  );
                })}

                <div className="dropdown-divider" />
                <span
                  className="dropdown-item"
                  style={{ justifyContent: "space-between" }}
                >
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Files/Folder-plus.svg*/}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      type="button"
                      // data-toggle="modal"
                      // data-target="#exampleModalFolder"
                      onClick={handleCreateFolder}
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11,13 L11,11 C11,10.4477153 11.4477153,10 12,10 C12.5522847,10 13,10.4477153 13,11 L13,13 L15,13 C15.5522847,13 16,13.4477153 16,14 C16,14.5522847 15.5522847,15 15,15 L13,15 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,15 L9,15 C8.44771525,15 8,14.5522847 8,14 C8,13.4477153 8.44771525,13 9,13 L11,13 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>
                  <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => {
                      handleAddPosts();
                    }}
                  >
                    Save
                  </button>
                </span>
              </div>
            </div>

            <span
              className="svg-icon svg-icon-primary svg-icon-2x mr-5"
              data-toggle="modal"
              data-target="#exampleModalLong"
              onClick={() => {
                _handleSavePdf();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <path
                    d="M16,17 L16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 L8,17 L5,17 C3.8954305,17 3,16.1045695 3,15 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,15 C21,16.1045695 20.1045695,17 19,17 L16,17 Z M17.5,11 C18.3284271,11 19,10.3284271 19,9.5 C19,8.67157288 18.3284271,8 17.5,8 C16.6715729,8 16,8.67157288 16,9.5 C16,10.3284271 16.6715729,11 17.5,11 Z M10,14 L10,20 L14,20 L14,14 L10,14 Z"
                    fill="#000000"
                  />
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    x={8}
                    y={2}
                    width={8}
                    height={2}
                    rx={1}
                  />
                </g>
              </svg>
              {/*end::Svg Icon*/}
            </span>

            <button
              disabled={disableButton ? false : true}
              style={{ background: "white", border: "none" }}
              className={
                disableButton
                  ? "svg-icon svg-icon-primary svg-icon-2x"
                  : "svg-icon svg-icon-muted svg-icon-2x"
              }
              title="Download original posts"
              // disabled="true"

              onClick={() => {
                _handleDownloadPdf();
              }}
            >
              {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Files/Cloud-download.svg*/}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path
                    d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z"
                    fill="#000000"
                    opacity="0.3"
                  />
                  <path
                    d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    transform="translate(11.959697, 18.661508) rotate(-270.000000) translate(-11.959697, -18.661508) "
                  />
                </g>
              </svg>
              {/*end::Svg Icon*/}
            </button>
          </div>

          <h3 className="card-title">
            {intl.formatMessage({ id: "searched" })} :{" "}
            <input id="tagify" className="tagify__tag " readOnly={true} />
          </h3>
          <CardHeaderToolbar>
            <button
              class="btn btn-secondary"
              type="submit"
              onClick={() => {
                props.setBool(false);
              }}
            >
              Search Again
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <React.Fragment>
            <div className="row">
              <div className="col-lg-12">
                <div className="dataTables_wrapper dt-bootstrap4">
                  <div className="table-responsive">
                    {isLoading ? (
                      <>
                        <div className="row w-100 justify-content-center">
                          <div className="text-center">
                            <Lottie
                              options={defaultOptions}
                              height={400}
                              width={400}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <table
                          id="kt_datatable"
                          className="table table-separate table-head-custom table-checkable dataTable no-footer dtr-inline"
                        >
                          <thead>
                            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                              <th></th>
                              <th>Id</th>
                              <th>Title</th>
                              <th>Media</th>
                              <th>Section</th>
                              <th>Type Name</th>
                              <th>Created At</th>
                              <th>Updated At</th>
                            </tr>
                          </thead>
                          <tbody
                            className="datatable-body accordion accordion-toggle-arrow"
                            id="accordionExample1"
                          >
                            {_renderTableBody()}
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </CardBody>
      </Card>
      <div
        className="modal fade"
        id="exampleModalFolder"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                New folder
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Folder Name"
                  id="example-text-input"
                  onChange={(e) => setFolderName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Description"
                  id="example-text-input"
                  onChange={(e) => setFolderDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={handleCreateFolder}
                type="button"
                className="btn btn-primary font-weight-bold"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalLong"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {intl.formatMessage({ id: "searchIndex.Prepare_for_print" })}
                {/* Pripremi za štampu */}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <form className="form" onSubmit={(e) => handleSubmit(e)}>
              <div className="modal-body">
                <p>
                  {" "}
                  {intl.formatMessage({
                    id: "searchIndex.Number_of_selected_posts",
                  })}
                  : {selectedPosts?.length}
                </p>
                <div className="form-group">
                  <label>
                    <strong>
                      {intl.formatMessage({ id: "searchIndex.Document_title" })}
                    </strong>
                  </label>
                  <input
                    value={documentTitle}
                    onChange={(e) => setDocumentTitle(e.target.value)}
                    type="text"
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="form-group">
                  <label>
                    <strong>
                      {" "}
                      {intl.formatMessage({
                        id: "searchIndex.Subtitle_of_the_document",
                      })}
                    </strong>
                  </label>
                  <input
                    onChange={(e) => setSubTitle(e.target.value)}
                    value={subTitle}
                    type="text"
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="form-group">
                  <label>
                    <strong>
                      {intl.formatMessage({ id: "searchIndex.Document_form" })}
                    </strong>
                  </label>
                  <div className="checkbox-list">
                    <label className="checkbox">
                      <input
                        value={"content"}
                        type="checkbox"
                        name="Checkboxes1"
                      />
                      <span />
                      {intl.formatMessage({ id: "searchIndex.Content" })}
                    </label>

                    <label className="checkbox ">
                      <input type="checkbox" name="CheckAll" id="selectAll" />
                      <span />
                      {intl.formatMessage({
                        id: "searchIndex.Transcripts_of_publications",
                      })}
                    </label>
                    <label className="checkbox ml-4">
                      <input type="checkbox" name="subCheckbox" />
                      <span />
                      {intl.formatMessage({ id: "searchIndex.Press" })}
                    </label>
                    <label className="checkbox ml-4">
                      <input type="checkbox" name="subCheckbox" />
                      <span />
                      Tv
                    </label>
                    <label className="checkbox ml-4">
                      <input type="checkbox" name="subCheckbox" />
                      <span />
                      Web
                    </label>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        defaultChecked="checked"
                        name="Checkboxes1"
                        value={"scan_newspaper"}
                      />
                      <span />
                      {intl.formatMessage({
                        id: "searchIndex.Scanning_the_pages_of_a_newspaper",
                      })}
                    </label>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        defaultChecked="checked"
                        name="Checkboxes1"
                        value={"Portal_screenshot"}
                      />
                      <span />
                      {intl.formatMessage({
                        id: "searchIndex.Portal_screenshot",
                      })}
                    </label>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        defaultChecked="checked"
                        name="Checkboxes1"
                        value={"video_attachment_Screenshot"}
                      />
                      <span />
                      {intl.formatMessage({
                        id: "searchIndex.Screenshot_of_video_attachment",
                      })}
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    <strong>
                      {intl.formatMessage({
                        id: "searchIndex.How_to_sort_posts",
                      })}
                    </strong>
                  </label>
                  <div className="radio-list">
                    <label className="radio">
                      <input type="radio" value={"asc"} name="radios1" />
                      <span />
                      {intl.formatMessage({
                        id: "searchIndex.From_the_oldest_to_the_newest",
                      })}
                    </label>

                    <label className="radio">
                      <input
                        type="radio"
                        value={"desc"}
                        defaultChecked="checked"
                        name="radios1"
                      />
                      <span />
                      {intl.formatMessage({
                        id: "searchIndex.From_newest_to_oldest",
                      })}
                    </label>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  {intl.formatMessage({ id: "searchIndex.give_it_up" })}
                </button>
                <button
                  type="submit"
                  className="btn btn-primary font-weight-bold"
                >
                  {intl.formatMessage({ id: "searchIndex.Print" })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
