import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, toasts, intl } = action;
  switch (action.type) {
    case "SET_SOCIAL_CATEGORY_DATA":
      return payload;
    case "SET_SOCIAL_STATUS":
      let check = state.selected_unallowed_media_categories.find(
        (saa) => saa.id == payload.id
      );
      return {
        ...state,
        selected_unallowed_media_categories: check
          ? state.selected_unallowed_media_categories.filter(
              (s) => s.id != payload.id
            )
          : [...state.selected_unallowed_media_categories, payload],
      };
    case "SET_MULTI_STATUS":
      console.log("pay:", payload);
      console.log("sta:", action.status);

      let tmp = [];
      if (action.status) {
        tmp = [...state.selected_unallowed_media_categories, ...payload];
        tmp = [...new Set(tmp)];
      } else {
        tmp = state.selected_unallowed_media_categories.filter((aa) => {
          if (payload.map((p) => p.id).includes(aa.id)) {
            return false;
          }
          return true;
        });
      }
      return {
        ...state,
        selected_unallowed_media_categories: tmp,
      };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  Index,
});
