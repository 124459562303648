import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getAllMediaCall = (action) => {
  let data = action;
  return axios.post(`${ARHIVA_REST_URL}api/media/list`, data);
};

export const createMediaCall = (action) => {
  let data = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/media/add`, data);
};

export const editMediaCall = (action) => {
  let data = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/media/update`, data);
};

export const deleteMediaCall = (action) => {
  let id = action.id;
  return axios.delete(`${ARHIVA_REST_URL}api/media/delete/${id}`);
};

export const multiDeleteCall = (action) => {
  let data = {};
  data.ids = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/media/bulk-delete`, data);
};
