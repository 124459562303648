import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Table } from "../../shared";
import { useDispatch } from "react-redux";
import Lottie from "react-lottie";
import sadbox from "../../../Other/pages/lottieFile/sadbox.json";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import {
  changeSocialMediaStatus,
  getSocialCategoryData,
  setMultiSocialStatus,
  setSocialStatus,
} from "../../../../_redux/Social/actions";
import {
  changeUnallowedMediaStatus,
  multiUnallowedMediaStatusChange,
} from "./../../../../_redux/users/actions";
import { SearchTable } from "../../shared/Table/SearchTable";

export default function Social(props) {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const showLoader = useSelector((state) => state?.users?.showLoader ?? []);

  const socialC = useSelector(
    (state) => state?.social?.Index?.allowed_media_category ?? []
  );

  const socialCategoryData = useSelector(
    (state) => state?.social?.Index?.allowed_media_category?.data ?? []
  );

  const currentSocialCategoryData = useSelector(
    (state) => state?.social?.Index?.selected_unallowed_media_categories ?? []
  );

  const allowedMedia = useSelector(
    (state) => state?.social?.Index?.allowed_social_media
  );

  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  const [allIds, setAllIds] = useState([]);

  const [filterMediaId, setFilterMediaId] = useState("");

  const [getSearch, setSearch] = useState("");

  const [is_active, setIs_active] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: sadbox,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    let tempAlreadyAssigned = [...currentSocialCategoryData];
    let tmpAllMedias = [...socialCategoryData];
    if (currentSocialCategoryData.length) {
      let tmpArray = tmpAllMedias?.map((d) => {
        let tmp = { ...d };
        if (tempAlreadyAssigned?.map((aaf) => aaf?.id)?.includes(d.id)) {
          tmp.status = true;
        }
        return tmp;
      });
      setTableData(tmpArray);
    } else {
      setTableData(socialCategoryData);
    }
  }, [currentSocialCategoryData.length]);

  useEffect(() => {
    setTableData(socialCategoryData);
  }, [socialC?.data]);

  useEffect(() => {
    setIs_active(props?.renderCall);
  }, [props?.renderCall]);

  const paginationOptions = {
    totalSize: socialC?.totalCount,
    sizePerPage,
    page,
  };

  useEffect(() => {
    if (is_active == true) {
      getSocialCategory();
    }
  }, [page, sizePerPage, getSearch, is_active]);

  const getSocialCategory = () => {
    setAllIds([]);
    let data = {};
    data.user_id = props?.params?.id;
    data.media_id = filterMediaId ?? "";
    if (getSearch != "") {
      data.title = getSearch;
      data.page = 0;
      data.size = sizePerPage;
    } else {
      data.size = sizePerPage;
      data.page = page - 1;
    }

    dispatch(getSocialCategoryData(data));
  };

  const handleMeidaChange = (e) => {
    setAllIds([]);
    setFilterMediaId(e.target.value);
    let data = {};
    data.user_id = props?.params?.id;
    data.media_id = e.target.value ?? "";
    if (getSearch != "") {
      data.title = getSearch;
      data.page = 0;
      data.size = sizePerPage;
    } else {
      data.size = sizePerPage;
      data.page = page - 1;
    }

    dispatch(getSocialCategoryData(data));
  };

  const handleUnallowedMediaChecked = (e, row) => {
    let data = {};
    data.user_id = props.params.id;
    data.media_category_id = row.id;
    data.status = e.target.checked == true ? 1 : 0;
    data.media_id = filterMediaId ?? "";
    if (getSearch != "") {
      data.title = getSearch;
      data.page = 0;
      data.size = sizePerPage;
    } else {
      data.size = sizePerPage;
      data.page = page - 1;
    }
    dispatch(setSocialStatus(row));
    dispatch(changeSocialMediaStatus(data, toasts, intl));
  };

  const checkIfAssignMediaIsTrue = (row) => {
    if (currentSocialCategoryData?.map((p) => p.id).includes(row.id)) {
      return true;
    } else {
      return false;
    }
  };

  const ChangeMultiStatus = (mediaIds, status) => {
    let data = {};
    data.user_id = props.params.id;
    data.media_category_ids = mediaIds;
    data.status = status == true ? 1 : 0;
    data.media_id = filterMediaId ?? "";
    if (getSearch != "") {
      data.title = getSearch;
      data.page = 0;
      data.size = sizePerPage;
    } else {
      data.size = sizePerPage;
      data.page = page - 1;
    }

    let fObj = mediaIds.map((id) => {
      return socialCategoryData.find((x) => x.id == id);
    });
    console.log("mded", fObj);

    dispatch(setMultiSocialStatus(fObj, status));

    dispatch(changeSocialMediaStatus(data, toasts, intl));
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "post_type.name",
      text: "Post Type",
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "media.name",
      text: "Media",
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),

      formatter: (cell, row) => {
        return (
          <div>
            <span className="switch switch-sm switch-icon switch-primary">
              <label>
                <input
                  onChange={(e) => handleUnallowedMediaChecked(e, row)}
                  type="checkbox"
                  checked={checkIfAssignMediaIsTrue(row) == true ? true : false}
                  name="select"
                />
                <span />
              </label>
            </span>
          </div>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  return (
    <>
      <div className="form-group row">
        <div className="col-lg-3">
          <select
            className="form-control"
            name="type"
            placeholder="Filter by Status"
            onChange={(e) => handleMeidaChange(e)}
            // onChange={(e) => {
            //   setFilterBy({
            //     ...filterBy,
            //     status: e.target.value == "0" ? 0 : e.target.value,
            //   });
            //   setPage(1);
            // }}
            // value={filterBy.status}
          >
            <option value={""}>All</option>
            {allowedMedia?.map((am) => {
              return <option value={am?.id}>{am?.name}</option>;
            })}
          </select>
          <small className="form-text text-muted">
            <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Allowed Media
          </small>
        </div>
        <SearchTable setSearch={setSearch} />
      </div>
      {allIds.length > 1 && (
        <div className="form-group">
          <p>Selected records count:{allIds.length}</p>
          &nbsp;
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder font-size-sm"
            onClick={() => ChangeMultiStatus(allIds, true)}
          >
            Enable
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder font-size-sm mr-5"
            onClick={() => ChangeMultiStatus(allIds, false)}
          >
            Disable
          </button>
        </div>
      )}
      {/* allowedMedia */}
      {allowedMedia?.length ? (
        <Table
          // showLoader={showLoader}
          data={tableData ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={setPage}
          // isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
      ) : (
        <div>
          <Lottie options={defaultOptions} height={200} width={400} />
          <p style={{ display: "flex", justifyContent: "center" }}>
            Please Assign Some Media
          </p>
        </div>
      )}
    </>
  );
}
