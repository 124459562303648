import React from 'react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const ElectronicActions = () => {
    return (
        <>
            <OverlayTrigger
                overlay={<Tooltip>Pregled video zapisa</Tooltip>}
            >
                <span className="svg-icon svg-icon-lg svg-icon-primary ml-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Video-camera.svg")}/>
                </span>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={<Tooltip>Preuzmi video</Tooltip>}
            >
                <span className="svg-icon svg-icon-lg svg-icon-primary ml-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Cloud-upload.svg")} />
                </span>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={<Tooltip>Pripremi za stampu</Tooltip>}
            >
                <span className="svg-icon svg-icon-lg svg-icon-primary ml-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Printer.svg")} />
                </span>
            </OverlayTrigger>


        </>
    )
}

export default ElectronicActions
