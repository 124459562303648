/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import ElectronicActions from "./ActionsColumComponents/ElectronicActions";
import PDFActions from "./ActionsColumComponents/PDFActions";
import InternetActions from "./ActionsColumComponents/InternetActions";
import * as rowTypeConstant from "../../../../../../_metronic/_helpers";
export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
) => (
        <>
            {row.type === rowTypeConstant.INTERNET_STRING && <InternetActions row={row}/>}
            {row.type === rowTypeConstant.PDF_STRING && <PDFActions row={row}/>}
            {row.type === rowTypeConstant.ELECTRONIC_STRING && <ElectronicActions row={row}/>}
        </>
    );
