import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { PERSONAL_INFORMATION_URL } from "./../../../../_metronic/_helpers/Constants";
import * as auth from "../../Auth/_redux/authRedux";
import { useIntl } from "react-intl";
import ProfileService from "../service";

function PersonaInformation(props) {
  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const dispatch = useDispatch();
  const {
    personal_information,
    account_information,
    email_settings,
  } = useSelector((state) => state.auth.user, shallowEqual);

  const intl = useIntl();

  useEffect(() => {
    if (personal_information.pic) {
      setPic(personal_information.pic);
    }
  }, [personal_information]);
  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);
    setTimeout(async () => {
      setloading(false);
      setSubmitting(false);
      try {
        const profileService = new ProfileService();
        await profileService.post(PERSONAL_INFORMATION_URL, values);
        const user = {
          account_information,
          email_settings,
          personal_information: values,
        };
        dispatch(auth.actions.fulfillUser(user));
        dispatch(auth.actions.requestUser());
        setloading(false);
      } catch (error) {
        setloading(false);
        setSubmitting(false);
        setStatus(error);
      }
    }, 1000);
  };
  // UI Helpers
  const initialValues = {
    avatar: personal_information.avatar,
    first_name: personal_information?.first_name,
    last_name: personal_information.last_name,
    company: personal_information.company,
    mobile: personal_information.mobile,
    email: personal_information.email,
    website: personal_information.website,
  };
  const Schema = Yup.object().shape({
    avatar: Yup.string().nullable(),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    company: Yup.string(),
    mobile: Yup.string().required("Phone is required"),
    email: Yup.string()
      .email("Wrong email format")
      .required("Email is required"),
    website: Yup.string(),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const getUserPic = () => {
    if (!pic) {
      return "none";
    }

    return `url(${pic})`;
  };
  const removePic = () => {
    setPic("");
  };
  return (
    <form
      className="card card-custom card-stretch"
      onSubmit={formik.handleSubmit}
    >
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            {intl.formatMessage({ id: "MENU.PROFILE.PERSONAL_INFORMATIONS" })}
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            {intl.formatMessage({
              id: "MENU.PROFILE.PERSONAL_INFORMATIONS_DESC",
            })}
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
            {formik.isSubmitting}
          </button>
          <Link to="/user/profile" className="btn btn-secondary">
            {intl.formatMessage({ id: "CONTACT.GENERAL.CANCEL.BUTTON" })}
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">
                {intl.formatMessage({ id: "Customer_Info" })}
              </h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
            <div className="col-lg-9 col-xl-6">
              <div
                className="image-input image-input-outline"
                id="kt_profile_avatar"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/users/blank.png"
                  )}`,
                }}
              >
                <div
                  className="image-input-wrapper"
                  style={{ backgroundImage: `${getUserPic()}` }}
                />
                <label
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Change avatar"
                >
                  <i className="fa fa-pen icon-sm text-muted"></i>
                  <input
                    type="file"
                    // name="pic"
                    accept=".png, .jpg, .jpeg"
                    // {...formik.getFieldProps("pic")}
                  />
                  <input type="hidden" name="profile_avatar_remove" />
                </label>
                <span
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="cancel"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Cancel avatar"
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </span>
                <span
                  onClick={removePic}
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="remove"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Remove avatar"
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </span>
              </div>
              <span className="form-text text-muted">
                Allowed file types: png, jpg, jpeg.
              </span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              {intl.formatMessage({ id: "First_Name" })}
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder={intl.formatMessage({ id: "First_Name" })}
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "first_name"
                )}`}
                name="first_name"
                {...formik.getFieldProps("first_name")}
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div className="invalid-feedback">
                  {formik.errors.first_name}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              {intl.formatMessage({ id: "Last_Name" })}
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder={intl.formatMessage({ id: "Last_Name" })}
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "last_name"
                )}`}
                name="last_name"
                {...formik.getFieldProps("last_name")}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div className="invalid-feedback">
                  {formik.errors.last_name}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              {intl.formatMessage({ id: "Company_Name" })}
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder={intl.formatMessage({ id: "Company_Name" })}
                className={`form-control form-control-lg form-control-solid`}
                name="company"
                {...formik.getFieldProps("company")}
              />
              <span className="form-text text-muted">
                If you want your invoices addressed to a company. Leave blank to
                use your full name.
              </span>
            </div>
          </div>
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mt-10 mb-6">
                {intl.formatMessage({ id: "Contact_Info" })}
              </h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              {intl.formatMessage({ id: "INPUT.MOBILE" })}
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-phone"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="+1(123)112-11-11"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "phone"
                  )}`}
                  name="mobile"
                  {...formik.getFieldProps("mobile")}
                />
              </div>
              {formik.touched.mobile && formik.errors.mobile ? (
                <div className="invalid-feedback display-block">
                  {formik.errors.mobile}
                </div>
              ) : null}
              <span className="form-text text-muted">
                We'll never share your phone with anyone else.
              </span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              {intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="email"
                  placeholder="Email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback display-block">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              {intl.formatMessage({ id: "Company_Site" })}
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  placeholder="https://keenthemes.com"
                  className={`form-control form-control-lg form-control-solid`}
                  name="website"
                  {...formik.getFieldProps("website")}
                />
              </div>
              {formik.touched.website && formik.errors.website ? (
                <div className="invalid-feedback display-block">
                  {formik.errors.website}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Form */}
    </form>
  );
}

export default connect(null, auth.actions)(PersonaInformation);
