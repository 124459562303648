/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";

import {
  Input,
  Select,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  GET_ALL_POSTS_URL,
  EDIT_POST_URL,
  CREATE_POST_URL,
  a_manage_url,
} from "../../../../_metronic/_helpers/Constants";
import ProfileService from "../../Profile/service";

const EditSchema = Yup.object().shape({
  title: Yup.string().required(),
  name: Yup.string().required(),
  content: Yup.string().required(),
});

const ManageEdit = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [currentPost, setCurrentPost] = useState(null);
  const history = useHistory();
  const service = new ProfileService();

  const params = useParams();
  useEffect(() => {
    const fetchPost = async () => {
      if (!params.id) return;
      try {
        const response = await service.post(GET_ALL_POSTS_URL, {
          id: params.id,
          page: 0,
          size: 20,
        });
        setCurrentPost(response.data.data[0]);
      } catch (error) {}
    };

    fetchPost();
  }, [params]);

  const savePost = async (values) => {
    if (params.id) {
      return await service.post(EDIT_POST_URL, {
        id: parseInt(params.id, 10),
        ...values,
      });
    }
    return await service.post(CREATE_POST_URL, values);
  };

  const mutation = useMutation(savePost, {
    onSuccess: (data) => {
      dispatch(
        toasts.showToast(
          data.data.status,
          intl.formatMessage({ id: data.data.title })
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(
        toasts.showToast(
          error.response.data.status,
          intl.formatMessage({
            id: error.response.data.details
              ? error.response.data.details
              : error.response.data.errors[0],
          })
        )
      );
      dispatch(toasts.hideToast());
    },
  });

  const backToPostList = () => {
    history.push(`${a_manage_url}`);
  };
  const btnRef = useRef();

  const savePostClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const initialValues = {
    title: currentPost ? currentPost.title : "",
    content: currentPost ? currentPost.content : "",
    name: currentPost ? currentPost.name : "",
  };

  const formTitle = params.id
    ? intl.formatMessage({ id: "MENU.OPTIONS.EDIT" }) + " " + "Post"
    : intl.formatMessage({ id: "Add_new" }) +
      " " +
      intl.formatMessage({ id: "Post" });
  return (
    <Card>
      <CardHeader title={formTitle}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToPostList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={savePostClick}
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <Formik
            initialValues={initialValues}
            validationSchema={EditSchema}
            enableReinitialize
            onSubmit={async (values) => {
              try {
                await mutation.mutateAsync(values);
                history.push(`${a_manage_url}`);
              } catch (error) {
                console.error(error);
              }
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, values, setFieldValue, errors, touched }) => {
              return (
                <>
                  <Form className="form form-label-right">
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Field
                          type="text"
                          name="title"
                          component={Input}
                          placeholder="Title"
                          label="Title"
                          withFeedbackLabel={false}
                        />
                        {errors?.title && (
                          <span className="error">{errors?.title}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Field
                          type="text"
                          name="name"
                          component={Input}
                          placeholder="Name"
                          label="Name"
                          withFeedbackLabel={false}
                        />
                        {errors?.name && (
                          <span className="error">{errors?.name}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Enter Content</label>
                      <Field
                        name="content"
                        as="textarea"
                        className="form-control"
                      />
                      {errors?.content && (
                        <span className="error">{errors?.content}</span>
                      )}
                    </div>
                    <button
                      type="submit"
                      style={{ display: "none" }}
                      ref={btnRef}
                      onSubmit={() => handleSubmit()}
                    ></button>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </CardBody>
    </Card>
  );
};

export default ManageEdit;
