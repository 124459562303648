import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getSectionsDataCall = (actions) => {
  let data = actions.payload;
  return axios.post(`${ARHIVA_REST_URL}api/section/list`, data);
};

export const editSectionCall = (actions) => {
  let data = actions.payload;
  return axios.post(`${ARHIVA_REST_URL}api/section/update`, data);
};

export const createSectionCall = (actions) => {
  let data = actions.payload;
  return axios.post(`${ARHIVA_REST_URL}api/section/add`, data);
};

export const deleteSectionCall = (id) => {
  return axios.delete(`${ARHIVA_REST_URL}api/section/delete/${id}`);
};
