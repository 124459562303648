import { put, takeLatest, call } from "redux-saga/effects";
import { getPostTypeData, setPostTypeData } from "./actions";
import {
  createPostTypeCall,
  deletePostTypeCall,
  editPostTypeCall,
  getPostTypeCall,
  multiPostTypeDeleteCall,
  sendNewOrderCall,
} from "./Service";

function* attemptGetPostType(action) {
  const { payload } = action;

  try {
    const response = yield call(getPostTypeCall, payload);
    yield put(setPostTypeData(response.data));
  } catch (errors) {}
}

function* attemptDeletePostType(action) {
  const { id } = action;
  try {
    const response = yield call(deletePostTypeCall, id);
    yield put(getPostTypeData());
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
  } catch (errors) {}
}

function* attemptEditPostType(action) {
  const { payload, intl, toasts } = action;
  try {
    const response = yield call(editPostTypeCall, payload);
    console.log("post type edit response:", response.data);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (errors) {
    console.log(errors?.response);
  }
}

function* attemptCreatePostType(action) {
  const { payload, intl, toasts } = action;
  try {
    const response = yield call(createPostTypeCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (errors) {
    console.log(errors?.response);
  }
}

function* attemptSendNewOrderData(action) {
  const { payload } = action;
  let data = {};
  data.data = payload;
  try {
    const response = yield call(sendNewOrderCall, data);
  } catch (e) {}
}

function* attemptMultiPostTypeDelete(action) {
  try {
    const response = yield call(multiPostTypeDeleteCall, action);
    yield put(getPostTypeData());
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
  } catch (e) {}
}

export function* saga() {
  yield takeLatest("GET_POST_TYPE_DATA", attemptGetPostType);
  yield takeLatest("DELETE_POST_TYPE", attemptDeletePostType);
  yield takeLatest("EDIT_POST_TYPE", attemptEditPostType);
  yield takeLatest("CREATE_POST_TYPE", attemptCreatePostType);
  yield takeLatest("SEND_NEW_ORDER_DATA", attemptSendNewOrderData);
  yield takeLatest("MULTI_POST_TYPE_DELETE", attemptMultiPostTypeDelete);
}
