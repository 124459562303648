import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getPostTypeCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/post-type/list`, data);
};

export const deletePostTypeCall = (id) => {
  return axios.delete(`${ARHIVA_REST_URL}api/post-type/delete/${id}`);
};

export const editPostTypeCall = (payload) => {
  return axios.post(`${ARHIVA_REST_URL}api/post-type/update`, payload);
};

export const createPostTypeCall = (payload) => {
  return axios.post(`${ARHIVA_REST_URL}api/post-type/create`, payload);
};

export const sendNewOrderCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/post-type/order`, data);
};

export const multiPostTypeDeleteCall = (action) => {
  let data = {};
  data.ids = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/post-type/bulk-delete`, data);
};
