import React, { useEffect } from 'react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, URLexpression } from '../../../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const InternetActions = ({ row }) => {
    const extractURL = JSON.stringify(row.extras).match(URLexpression);
    const url = 'https://' + extractURL.toString();

    return (
        <>
            <OverlayTrigger
                overlay={<Tooltip>Pregled originalne objave</Tooltip>}
            >
                <span className="svg-icon svg-icon-lg svg-icon-primary ml-1" style={{ cursor: 'pointer' }}>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
                </span>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={<Tooltip>Link do originalne objave</Tooltip>}
            >
                <a href={url} target='_blank' className="svg-icon svg-icon-lg svg-icon-primary ml-1" style={{ cursor: 'pointer' }}>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clip.svg")} />
                </a>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={<Tooltip>Pripremi za stampu</Tooltip>}
            >
                <span className="svg-icon svg-icon-lg svg-icon-primary ml-1" style={{ cursor: 'pointer' }}>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Printer.svg")} />
                </span>
            </OverlayTrigger>


        </>
    )
}

export default InternetActions
