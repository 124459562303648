import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import {  NavLink, useLocation } from "react-router-dom";
import { 
  checkIsActive,
  contactpage_url,
  legal_url,
  privacy_url,
} from "../../../_helpers";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-text active "
      : "";
  };
  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">2004 - {today.toString()}</span> {" "}
          <FormattedHTMLMessage id="GENERAL.COPYRIGHT" />
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <NavLink to={privacy_url} className='nav-link pr-3 pl-0'>
            <span className={getMenuItemActive(`${privacy_url}`)}>
              <FormattedMessage id="MENU.PRIVACY" />
            </span>
          </NavLink>
          <NavLink to={legal_url} className="nav-link px-3">
            <span className={getMenuItemActive(`${legal_url}`)}>
              <FormattedMessage id="MENU.LEGAL" />
            </span>
          </NavLink>
          <NavLink to={contactpage_url} className="nav-link pl-3 pr-0">
            <span className={getMenuItemActive(`${contactpage_url}`)}>
              <FormattedMessage id="MENU.CONTACT" />
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
