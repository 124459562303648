import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  REMOVE_STRING,
  RENAME_STRING,
  PURGE_STRING,
  searches_url,
  convertTime,
} from "../../../../../../_metronic/_helpers";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import * as actions from "../../../_redux/folders/foldersActions";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useLang } from "../../../../../../_metronic/i18n";

const FolderCard = ({ folder, id }) => {
  const lang = useLang();
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const [time, setTime] = useState(null);

  useEffect(() => {
    setTime(convertTime(folder.updated_at, lang));
  }, [folder, lang]);

  return (
    folder.active == 1 && (
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-shrink-0 mr-7">
              <div className="symbol symbol-50 symbol-lg-120">
                <span className="font-size-h3 symbol-label font-weight-boldest">
                  {folder.title
                    .split(/\s/)
                    .reduce(
                      (response, word) => (response += word.slice(0, 1)),
                      ""
                    )}
                </span>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                <div className="mr-3">
                  <Link
                    to={history.location.pathname + "/" + id}
                    className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >
                    {folder.title}
                    <i className="flaticon2-correct text-success icon-md ml-2"></i>
                  </Link>
                  <div className="d-flex flex-wrap my-2">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="folder-created">
                          {intl.formatMessage({ id: "TOOLTIP.CREATED" })}
                        </Tooltip>
                      }
                    >
                      <span className="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                        <span className="icon-gray-500 mr-3">
                          <i className="flaticon-add icon-md text-muted font-weight-bold"></i>
                        </span>
                        {convertTime(folder.created_at, lang)}
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="folder-modified">
                          {intl.formatMessage({ id: "TOOLTIP.MODIFIED" })}
                        </Tooltip>
                      }
                    >
                      <span className="text-muted font-weight-bold">
                        <span className="icon-gray-500 mr-3">
                          <i className="flaticon-edit icon-md text-muted font-weight-bold"></i>
                        </span>
                        {time}
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="my-lg-0 my-1">
                  {folder.rename == 1 ? (
                    <Button
                      className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
                      onClick={() => {
                        // combo dispatch neccessary for dialog data
                        dispatch(actions.setActiveFolder(folder, id));
                        dispatch(
                          actions.openFolderDialog(RENAME_STRING, folder.type)
                        );
                      }}
                    >
                      {intl.formatMessage({ id: "MENU.OPTIONS.RENAME" })}
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
                      disabled={true}
                    >
                      {intl.formatMessage({ id: "MENU.OPTIONS.RENAME" })}
                    </Button>
                  )}

                  {folder.delete == 1 ? (
                    <Button
                      className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
                      onClick={() => {
                        dispatch(actions.setActiveFolder(folder, id));
                        dispatch(
                          actions.openFolderDialog(REMOVE_STRING, folder.type)
                        );
                      }}
                    >
                      {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
                      disabled={true}
                    >
                      {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}
                    </Button>
                  )}

                  {/* checks if folder is purgeable and not on searches page */}
                  {folder.purge == 1 && folder.post_number > 0 ? (
                    <Button
                      className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
                      onClick={() => {
                        dispatch(actions.setActiveFolder(folder, id));
                        dispatch(
                          actions.openFolderDialog(PURGE_STRING, folder.type)
                        );
                      }}
                    >
                      {intl.formatMessage({ id: "MENU.OPTIONS.CLEAR" })}
                    </Button>
                  ) : (
                    folder.type !== searches_url && (
                      <Button
                        className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
                        disabled={true}
                      >
                        {intl.formatMessage({ id: "MENU.OPTIONS.CLEAR" })}
                      </Button>
                    )
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center flex-wrap justify-content-between">
                <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">
                  {folder.content}
                </div>
                <div className="d-flex mt-4 mt-sm-0">
                  <span className="font-weight-bold mr-4">Progress</span>
                  <div className="progress progress-xs mt-2 mb-2 flex-shrink-0 w-150px w-xl-250px">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "63%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span className="font-weight-bolder text-dark ml-4">78%</span>
                </div>
              </div>
            </div>
          </div>
          <div className="separator separator-solid my-7"></div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-piggy-bank icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">
                  Earnings
                </span>
                <span className="font-weight-bolder font-size-h5">
                  <span className="text-dark-50 font-weight-bold">$</span>
                  249,500
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-confetti icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">
                  Expenses
                </span>
                <span className="font-weight-bolder font-size-h5">
                  <span className="text-dark-50 font-weight-bold">$</span>
                  164,700
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-pie-chart icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">Net</span>
                <span className="font-weight-bolder font-size-h5">
                  <span className="text-dark-50 font-weight-bold">$</span>
                  782,300
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-file-2 icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column flex-lg-fill">
                <span className="text-dark-75 font-weight-bolder font-size-sm">
                  73 Tasks
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-chat-1 icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column">
                <span className="text-dark-75 font-weight-bolder font-size-sm">
                  648 Comments
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default FolderCard;
