import React, { useState } from "react";
import { useIntl } from "react-intl";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import * as actions from "../../../../../app/modules/ECommerce/_redux/folders/foldersActions";
import {
  RENAME_STRING,
  REMOVE_STRING,
  PURGE_STRING,
  searches_url,
} from "../../../../_helpers";
import { useDispatch } from "react-redux";

const AsideMenuOptions = ({ data, id, type }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const options = [
    intl.formatMessage({ id: "MENU.OPTIONS.RENAME" }),
    intl.formatMessage({ id: "MENU.OPTIONS.DELETE" }),
    intl.formatMessage({ id: "MENU.OPTIONS.CLEAR" }),
  ];
  // selects clicked item and opens up the component
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  }

  // closes the component
  function handleClose(e) {
    setAnchorEl(null);
    e.preventDefault();
  }

  // handles options
  function handleOption(e, option) {
    e.preventDefault();

    // pass dispatches together always to provide data to dialog box
    if (option === options[0]) {
      dispatch(actions.setActiveFolder(data, id));
      // type is passed from parent to know which type of folder is being changed
      dispatch(actions.openFolderDialog(RENAME_STRING, type));
    }

    if (option === options[1]) {
      dispatch(actions.setActiveFolder(data, id));
      dispatch(actions.openFolderDialog(REMOVE_STRING, type));
    }

    if (option === options[2]) {
      dispatch(actions.setActiveFolder(data, id));
      dispatch(actions.openFolderDialog(PURGE_STRING, type));
    }

    setAnchorEl(null);
  }
  return (
    <>
      {/* <IconButton
        style={{ zIndex: 2 }}
        aria-label="More"
        aria-controls="long-menu"
        aria-haspopup={true}
        onClick={handleClick}
        edge="end"
        color="primary"
      >
        <MoreVertIcon />
      </IconButton> */}
      <i className="fas fa-ellipsis-v text-primary d-flex align-items-center" onClick={handleClick} style={{fontSize:"1rem"}}/>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {Number(data.rename) === 1 ? (
          <MenuItem onClick={(e) => handleOption(e, options[0])}>
            {options[0]}
          </MenuItem>
        ) : (
          <MenuItem disabled={true}>{options[0]}</MenuItem>
        )}
        {Number(data.delete) === 1 ? (
          <MenuItem onClick={(e) => handleOption(e, options[1])}>
            {options[1]}
          </MenuItem>
        ) : (
          <MenuItem disabled={true}>{options[1]}</MenuItem>
        )}
        {data.type !== searches_url &&
          data.post_number > 0 &&
          (Number(data.purge) === 1 ? (
            <MenuItem onClick={(e) => handleOption(e, options[2])}>
              {options[2]}
            </MenuItem>
          ) : (
            <MenuItem disabled={true}>{options[2]}</MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default AsideMenuOptions;
