import React from "react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useIntl } from "react-intl";

export const AssignChannelsModal = (props) => {
  const intl = useIntl();

  const handleChange = (e) => {
    props.setSelectedChannels(e);
  };

  const handleAllSelect = (e) => {
    let changeAllChannelPriority = [...props.selectedChannels];
    changeAllChannelPriority.map((up) => {
      up.priority = e.target.value;
      return up;
    });
    props.setSelectedChannels(changeAllChannelPriority);
  };
  const handleSingleSelect = (e, userId) => {
    let changeChannelPriority = [...props.selectedChannels];
    changeChannelPriority.find((obj) => obj.value == userId).priority = e.value;
    props.setSelectedChannels(changeChannelPriority);
  };
  const handleApprovelStatus = (e, userId) => {
    let changeChannelApprovel = [...props.selectedChannels];
    changeChannelApprovel.find((obj) => obj.value == userId).approved = e.value;
    props.setSelectedChannels(changeChannelApprovel);
  };

  return (
    <div
      className="modal fade show"
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props?.activeUser?.name}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" className="ki ki-close" />
            </button>
          </div>
          <div className="modal-body">
            <Select
              onChange={(e) => handleChange(e)}
              isMulti
              value={props?.selectedChannels}
              //   value={props?.activeUser?.channels?.map((channel) => {
              //     return { value: channel.id, label: channel.name };
              //   })}
              options={props?.channels?.map((channel) => {
                return {
                  value: channel.id,
                  label: channel.name,
                  priority: "all",
                };
              })}
            />
            <div className="text-danger">
              {props?.errMessage?.channels ?? ""}
            </div>

            {/* <div className="form-group mt-3">
              <label>Status</label>
              <div className="radio-inline">
                <label className="radio">
                  <input
                    value="approved"
                    onClick={(e) => handleApprovelStatus(e)}
                    type="radio"
                    name="radios3"
                  />
                  <span />
                  Approved
                </label>
                <label className="radio">
                  <input
                    value="disapproved"
                    onClick={(e) => handleApprovelStatus(e)}
                    type="radio"
                    name="radios3"
                  />
                  <span />
                  Disapproved
                </label>
              </div>
            </div> */}

            <div className="form-group mt-3">
              <label>{intl.formatMessage({ id: "Priorities" })}</label>
              <div className="radio-inline">
                <label className="radio">
                  <input
                    value="not_sending"
                    onClick={(e) => handleAllSelect(e)}
                    type="radio"
                    name="radios2"
                  />
                  <span />
                  {intl.formatMessage({ id: "User_Not_Sending" })}
                </label>
                <label className="radio">
                  <input
                    value="all"
                    onClick={(e) => handleAllSelect(e)}
                    type="radio"
                    name="radios2"
                  />
                  <span />
                  {intl.formatMessage({ id: "User_All" })}
                </label>
                <label className="radio">
                  <input
                    value="important"
                    onClick={(e) => handleAllSelect(e)}
                    type="radio"
                    name="radios2"
                  />
                  <span />
                  {intl.formatMessage({ id: "User_Important" })}
                </label>
              </div>
            </div>

            {props?.selectedChannels?.map((su) => {
              return (
                <div className="d-flex align-items-center mb-2">
                  <span className="bullet bullet-bar bg-success align-self-stretch mr-2" />
                  <div className="d-flex flex-column flex-grow-1">
                    <a
                      href="#"
                      className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                    >
                      {su.label}
                    </a>
                    <span className="text-muted font-weight-bold">
                      {su.priority}
                    </span>
                  </div>
                  <div
                    className="dropdown dropdown-inline ml-2 w-25"
                    data-toggle="tooltip"
                    title
                    data-placement="left"
                    data-original-title="Quick actions"
                  >
                    <Select
                      // onChange={(e) => handleSingleSelect(e, su.value)}
                      value={{
                        value: su.approved,
                        label: su.approved == 1 ? "Approved" : "disapproved",
                      }}
                      onChange={(e) => handleApprovelStatus(e, su.value)}
                      options={[
                        { value: 1, label: "Approved" },
                        { value: 0, label: "Disapproved" },
                      ]}
                    />
                  </div>
                  <div
                    className="dropdown dropdown-inline ml-2 w-25"
                    data-toggle="tooltip"
                    title
                    data-placement="left"
                    data-original-title="Quick actions"
                  >
                    <Select
                      onChange={(e) => handleSingleSelect(e, su.value)}
                      value={{ value: su.priority, label: su.priority }}
                      options={[
                        { value: "not_sending", label: "not sending" },
                        { value: "all", label: "all" },
                        { value: "important", label: "important" },
                      ]}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold"
              data-dismiss="modal"
            >
              {intl.formatMessage({
                id: "CONTACT.GENERAL.CANCEL.BUTTON",
              })}
            </button>
            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              onClick={() => props.handleAssignUser()}
            >
              {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
