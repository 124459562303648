import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import "../../../_metronic/_assets/sass/pages/error/error404.scss";

export function error404() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg5.jpg")})`,
        }}
      >
        <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
          <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">
            <FormattedMessage id="PAGE.ERROR404.TITLE" />
          </h1>
          <p className="font-weight-boldest display-4">
            <FormattedMessage id="PAGE.ERROR404.SUB_TITLE" />
          </p>
          <p className="font-size-h3">
            <FormattedHTMLMessage id="PAGE.ERROR404.DESCRIPTION" />
          </p>
        </div>
      </div>
    </div>
  );
}
