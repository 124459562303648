import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { ARHIVA_REST_URL } from "../../../../../environments/environment";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUserRolesData } from "../../../../_redux/users/actions";

export const CreateUserModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllUsers();
  }, []);

  const [rolesData, setRolesData] = useState([]);

  const userRolesData = useSelector(
    (state) => state?.users?.UserRoles.data ?? []
  );

  useEffect(() => {
    setRolesData(userRolesData);
  }, [userRolesData]);

  const getAllUsers = () => {
    dispatch(getUserRolesData());
    // axios
    //   .post(`${ARHIVA_REST_URL}api/roles`)
    //   .then((response) => {
    //     console.log("response: ", response);
    //     setRolesData(response.data.data);
    //   })
    //   .catch((e) => {});
  };

  const handleSelectedRole = (e) => {
    props.setSelectedRole(e);
  };

  return (
    <div
      className="modal fade"
      id="exampleModalCreate"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}{" "}
              {intl.formatMessage({ id: "MENU.USER" })}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" className="ki ki-close" />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>{intl.formatMessage({ id: "Role" })}</label>

              <Select
                onChange={(e) => {
                  handleSelectedRole(e);
                }}
                isMulti
                options={rolesData.map((role) => {
                  return { value: role.id, label: role.name };
                })}
                value={props.selectedRole}
              />
            </div>
            <div className="form-group">
              <label>
                {intl.formatMessage({ id: "MENU.USER" })}{" "}
                {intl.formatMessage({ id: "Name" })}
              </label>
              <input
                type="text"
                onChange={(e) => {
                  props.setUserName(e.target.value);
                  props.setErrMessage("");
                }}
                className="form-control"
                placeholder="User Name"
                value={props?.userName}
              />
              <div className="text-danger">
                {props?.errMessage?.username ?? ""}
              </div>
            </div>
            <div className="form-group">
              <label>{intl.formatMessage({ id: "First_Name" })}</label>
              <input
                type="text"
                onChange={(e) => {
                  props.setFirstName(e.target.value);
                  props.setErrMessage("");
                }}
                className="form-control"
                placeholder={intl.formatMessage({ id: "First_Name" })}
                value={props?.firstName}
              />
              <div className="text-danger">
                {props?.errMessage?.first_name ?? ""}
              </div>
            </div>

            <div className="form-group">
              <label>{intl.formatMessage({ id: "Last_Name" })}</label>
              <input
                type="text"
                onChange={(e) => {
                  props.setLastName(e.target.value);
                  props.setErrMessage("");
                }}
                className="form-control"
                placeholder={intl.formatMessage({ id: "Last_Name" })}
                value={props?.lastName}
              />
              <div className="text-danger">
                {props?.errMessage?.last_name ?? ""}
              </div>
            </div>
            <div className="form-group">
              <label>{intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}</label>
              <input
                type="email"
                onChange={(e) => {
                  props.setEmail(e.target.value);
                  props.setErrMessage("");
                }}
                className="form-control"
                placeholder={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                value={props?.email}
              />
              <div className="text-danger">
                {props?.errMessage?.email ?? ""}
              </div>
            </div>
            <div className="form-group">
              <label>{intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  props.setPassword(e.target.value);
                  props.setErrMessage("");
                }}
                placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
                value={props?.password ?? ""}
              />
              <div className="text-danger">
                {props?.errMessage?.password ?? ""}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold"
              data-dismiss="modal"
            >
              {intl.formatMessage({ id: "CONTACT.GENERAL.CANCEL.BUTTON" })}
            </button>
            <button
              onClick={() => props.handleCreateUser()}
              type="button"
              disabled={props.callLoader}
              className="btn btn-primary font-weight-bold d-flex"
            >
              {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
              {props.callLoader == true ? (
                <>
                  <div className="spinner ml-2" style={{ width: "20px" }}></div>
                </>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
