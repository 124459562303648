import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as auth from "../../Auth/_redux/authRedux";
import { EMAIL_SETTINGS_URL } from "./../../../../_metronic/_helpers/Constants";
import { useIntl } from "react-intl";
import ProfileService from "../service";

function EmailSettings(props) {
  // Fields
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const {
    personal_information,
    account_information,
    email_settings,
  } = useSelector((state) => state.auth.user, shallowEqual);

  const intl = useIntl();

  // Methods
  const saveUser = async (values, setStatus, setSubmitting) => {
    setloading(true);
    try {
      const profileService = new ProfileService();
      await profileService.post(EMAIL_SETTINGS_URL, values);
      const user = {
        personal_information,
        account_information,
        email_settings: values,
      };
      dispatch(auth.actions.fulfillUser(user));
      dispatch(auth.actions.requestUser());

      setloading(false);
      setSubmitting(false);
    } catch (error) {
      setloading(false);
      setSubmitting(false);
      setStatus(error);
    }
  };
  // UI Helpers
  const initialValues = {
    email_notification: email_settings.email_notification,
    email_notification_time: email_settings.email_notification_time,
    youHaveNewNotifications: false,
    videoType: false,
    linkType: false,
    newsType: false,
  };
  const Schema = Yup.object().shape({
    email_notification: Yup.bool(),
    email_notification_time: Yup.string(),
    youHaveNewNotifications: Yup.bool(),
    videoType: Yup.bool(),
    linkType: Yup.bool(),
    newsType: Yup.bool(),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Email Settings
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Change your email settings
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
            {formik.isSubmitting}
          </button>
          <Link to="/user/profile" className="btn btn-secondary">
            {intl.formatMessage({ id: "CONTACT.GENERAL.CANCEL.BUTTON" })}
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Setup email klipping:</h5>
            </div>
          </div>
          <div className="form-group row align-items-center">
            <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">
              Email Notification
            </label>
            <div className="col-lg-9 col-xl-6">
              <span className="switch switch-sm">
                <label>
                  <input
                    type="checkbox"
                    name="email_notification"
                    checked={formik.values.email_notification}
                    onChange={formik.handleChange}
                  />
                  <span></span>
                </label>
              </span>
            </div>
          </div>
          <div className="form-group row align-items-center">
            <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">
              Time to receive email notification
            </label>
            <div className="col-lg-9 col-xl-6">
              <TextField
                id="email_notification_time"
                type="time"
                defaultValue={formik.values.email_notification_time}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </div>
          </div>
          <div className="form-group row align-items-center">
            <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">
              Arhimed news
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="checkbox-inline">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="videoType"
                    checked={formik.values.videoType}
                    onChange={formik.handleChange}
                  />
                  <span></span>Video
                </label>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="linkType"
                    checked={formik.values.linkType}
                    {...formik.getFieldProps("linkType")}
                    onChange={formik.handleChange}
                  />
                  <span></span>Link
                </label>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="newsType"
                    checked={formik.values.newsType}
                    {...formik.getFieldProps("newsType")}
                    onChange={formik.handleChange}
                  />
                  <span></span>News
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Form */}
    </form>
  );
}

export default connect(null, auth.actions)(EmailSettings);
