export const changeStatus = (payload, intl, toasts, getUserData) => {
  return {
    type: "CHANGE_USER_STATUS",
    payload: payload,
    intl: intl,
    toasts: toasts,
    getUserData: getUserData,
  };
};

export const getAllUsers = (payload) => {
  return {
    type: "GET_ALL_USERS",
    payload: payload,
  };
};

export const getUser = (id, setCreatedBy) => {
  return {
    type: "GET_USER",
    id: id,
    setCreatedBy: setCreatedBy,
  };
};

export const setUsers = (payload) => {
  return {
    type: "SET_USERS",
    payload: payload,
  };
};

export const createUser = (
  payload,
  setBool,
  bool,
  modalClose,
  intl,
  toasts,
  setErrMessage,
  setCallLoader
) => {
  return {
    type: "CREATE_USER",
    payload: payload,
    setBool: setBool,
    bool: bool,
    modalClose: modalClose,
    intl: intl,
    toasts: toasts,
    setErrMessage: setErrMessage,
    setCallLoader: setCallLoader,
  };
};

export const editUser = (
  payload,
  setBool,
  bool,
  modalClose,
  intl,
  toasts,
  setErrMessage,
  setCallLoader
) => {
  return {
    type: "EDIT_USER",
    payload: payload,
    setBool: setBool,
    bool: bool,
    modalClose: modalClose,
    intl: intl,
    toasts: toasts,
    setErrMessage: setErrMessage,
    setCallLoader: setCallLoader,
  };
};

export const assignChannelToUser = (
  payload,
  toasts,
  modalClose,
  intl,
  setErrMessage,
  activeUserId,
  channelsPayload,
  getUserData
) => {
  return {
    type: "ASSIGN_CHANNEL_TO_USER",
    payload: payload,
    toasts: toasts,
    modalClose: modalClose,
    intl: intl,
    setErrMessage: setErrMessage,
    activeUserId: activeUserId,
    channelsPayload: channelsPayload,
    getUserData: getUserData,
  };
};

export const deleteUser = (id, intl, toasts) => {
  return {
    type: "DELETE_USER",
    id: id,
    intl: intl,
    toasts: toasts,
  };
};

export const deleteUserFromRedux = (id) => {
  return {
    type: "DELETE_USER_FROM_REDUX",
    id: id,
  };
};

export const multiDeleteUserFromRedux = (ids) => {
  return {
    type: "MULTI_DELETE_USER_FROM_REDUX",
    ids: ids,
  };
};

export const getUserRolesData = () => {
  return {
    type: "GET_USER_ROLES",
  };
};

export const setUserRoles = (payload) => {
  return {
    type: "SET_USER_ROLES",
    payload: payload,
  };
};

export const getUserPersonalInfo = (id, setLoader) => {
  return {
    type: "GET_USER_PROFILE_INFO",
    id: id,
    setLoader: setLoader,
  };
};

export const setProfileInfo = (payload) => {
  return {
    type: "SET_USER_PROFILE_INFO",
    payload: payload,
  };
};

export const editProfilePersonalInfo = (
  id,
  payload,
  setCallLoader,
  intl,
  toasts
) => {
  return {
    type: "EDIT_PERSONAL_PROFILE_INFO",
    id: id,
    payload: payload,
    setCallLoader: setCallLoader,
    toasts: toasts,
    intl: intl,
  };
};

export const editProfileAccountInfo = (payload, intl, toasts) => {
  return {
    type: "EDIT_ACCOUNT_INFO",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const editEmailSettings = (payload, intl, toasts) => {
  return {
    type: "EDIT_EMAIL_SETTINGS",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const assignUserMedia = (id, payload, intl, toasts, setCallLoader) => {
  return {
    type: "ASSIGN_USER_MEDIA",
    id: id,
    payload: payload,
    intl: intl,
    toasts: toasts,
    setCallLoader: setCallLoader,
  };
};

export const assignUserUnallowedMedia = (
  id,
  payload,
  intl,
  toasts,
  setCallLoader
) => {
  return {
    type: "ASSIGN_USER_UNALLOWED_MEDIA",
    id: id,
    payload: payload,
    intl: intl,
    toasts: toasts,
    setCallLoader: setCallLoader,
  };
};

export const getAllMediaData = (payload) => {
  return {
    type: "GET_ALL_MEDIA_DATA",
    payload: payload,
  };
};

export const changeUserMediaStatus = (
  payload,

  toasts,
  intl,
  selectedPostType
) => {
  return {
    type: "CHANGE_MEDIA_STATUS",
    payload: payload,

    toasts: toasts,
    intl: intl,
    selectedPostType: selectedPostType,
  };
};

export const multiMediaStatusChange = (
  payload,

  toasts,
  intl
) => {
  return {
    type: "MULTI_MEDIA_STATUS_CHANGE",
    payload: payload,

    toasts: toasts,
    intl: intl,
  };
};

export const multiUnallowedMediaStatusChange = (
  payload,

  toasts,
  intl
) => {
  return {
    type: "MULTI_UNALLOWED_MEDIA_STATUS_CHANGE",
    payload: payload,

    toasts: toasts,
    intl: intl,
  };
};

export const getUnallowedMediaData = (payload) => {
  return {
    type: "GET_UNALLOWED_MEDIA_DATA",
    payload: payload,
  };
};

export const changeUnallowedMediaStatus = (
  payload,

  toasts,
  intl,
  selectedPostType,
  selectedMediaType
) => {
  return {
    type: "CHANGE_Unallowed_MEDIA_STATUS",
    payload: payload,

    toasts: toasts,
    intl: intl,
    selectedPostType: selectedPostType,
    selectedMediaType: selectedMediaType,
  };
};

export const changeUserPassword = (payload, intl, toasts, setCallLoader) => {
  return {
    type: "CHANGE_USER_PASSWORD",
    payload: payload,
    intl: intl,
    toasts: toasts,
    setCallLoader: setCallLoader,
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});

export const updatedEditUserProfile = (tabName, fieldName, payload) => {
  return {
    type: "UPDATE_EDIT_USER_PROFILE",
    tabName: tabName,
    fieldName: fieldName,
    payload: payload,
  };
};

export const upadetUserSettings = (
  payload,
  setCallLoader,
  id,
  toasts,
  intl
) => {
  return {
    type: "UPDATE_USER_SETTINGS",
    payload: payload,
    setCallLoader: setCallLoader,
    id: id,
    toasts: toasts,
    intl: intl,
  };
};

export const handleErrors = (payload) => {
  return {
    type: "EDIT_USER_ERROR",
    payload: payload,
  };
};

export const getUserAccountSettings = (user_id) => {
  return {
    type: "GET_USER_ACCOUNT_SETTINGS",
    user_id: user_id,
  };
};

export const setUserAccountSettings = (payload) => {
  return {
    type: "SET_USER_ACCOUNT_SETTINGS",
    payload: payload,
  };
};

export const updateAccountSettings = (fieldName, payload) => {
  return {
    type: "UPDATE_ACCOUNT_SETTINGS",
    fieldName: fieldName,
    payload: payload,
  };
};

export const saveEditAccountSettings = (
  payload,
  id,
  toasts,
  intl,
  setLoader
) => {
  return {
    type: "SAVE_EDIT_ACCOUNT_SETTINGS",
    payload: payload,
    id: id,
    toasts: toasts,
    intl: intl,
    setLoader,
  };
};

export const getUserSystemSettings = (user_id) => {
  return {
    type: "GET_USER_SYSTEM_SETTINGS",
    user_id: user_id,
  };
};

export const setUserSystemSettings = (payload) => {
  return {
    type: "SET_USER_SYSTEM_SETTINGS",
    payload: payload,
  };
};

export const updateSystemSettings = (fieldName, payload) => {
  return {
    type: "UPDATE_SYSTEM_SETTINGS",
    fieldName: fieldName,
    payload: payload,
  };
};

export const saveEditSystemSettings = (payload, toasts, intl) => {
  return {
    type: "SAVE_EDIT_SYSTEM_SETTINGS",
    payload: payload,
    toasts: toasts,
    intl: intl,
  };
};

export const setUserStatusOnSite = (payload) => {
  return {
    type: "SET_USER_STATUS_ON_SITE",
    payload: payload,
  };
};
