import { put, takeLatest, call } from "redux-saga/effects";
import {
  assignUserCall,
  channelDeleteCall,
  createChannelCall,
  disableChannelStatusCall,
  enableChannelStatusCall,
  getAllChannelsDataCall,
  selectedChannelDelete,
  updateChannelCall,
} from "./Service";
import {
  editChannelFromRedux,
  getAllChannelsData,
  setAllChannelsData,
  showLoader,
} from "./actions";

function* attemptDeleteChannel(action) {
  try {
    const response = yield call(channelDeleteCall, action.payload);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
    // yield put(getAllChannelsData(action.getAllChannelsPayload));
    // action.closeDeleteModal();
  } catch (error) {
    yield put(
      action.toasts.showToast(
        500,
        action.intl.formatMessage({ id: error.response.data.message })
      )
    );
    yield put(action.toasts.hideToast());
  }
}

function* attemptDeleteSelectedChannels(action) {
  try {
    const response = yield call(selectedChannelDelete, action.payload);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
    yield put(getAllChannelsData());
  } catch (error) {}
}

function* attemptUpdate(action) {
  const {
    setErrMessage,
    toasts,
    intl,
    closeModal,
    getAllChannelsPayload,
    payload,
    id,
  } = action;
  try {
    const response = yield call(updateChannelCall, action);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    editChannelFromRedux(payload, id);
    // yield put(getAllChannelsData(getAllChannelsPayload));
    closeModal("exampleModalEdit");
  } catch (error) {
    setErrMessage(error?.response?.data?.errors);
  }
}

function* attemptAssignUser(action) {
  const {
    setErrMessage,
    toasts,
    intl,
    closeModal,
    id,
    getAllChannelsPayload,
  } = action;
  try {
    const response = yield call(assignUserCall, action);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllChannelsData(getAllChannelsPayload));
    closeModal("exampleModalAssign");
  } catch (error) {
    setErrMessage(error?.response?.data?.errors);
  }
}

function* attemptCreateChannel(action) {
  const {
    payload,
    toasts,
    intl,
    closeModal,
    setErrMessage,
    getAllChannelsPayload,
  } = action;
  try {
    const response = yield call(createChannelCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllChannelsData(getAllChannelsPayload));
    closeModal("exampleModal");
  } catch (error) {
    setErrMessage(error?.response?.data?.errors);
  }
}

function* attemptGetChannelSaga(action) {
  const { payload } = action;
  try {
    yield put(showLoader(true));
    const response = yield call(getAllChannelsDataCall, payload);
    yield put(setAllChannelsData(response.data.data));
    yield put(showLoader(false));
  } catch (error) {}
}

function* attemptEnableChannelStatus(action) {
  const { payload, toasts, intl } = action;
  try {
    const response = yield call(enableChannelStatusCall, payload);
    yield put(getAllChannelsData());
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (error) {}
}

function* attemptDisableChannelStatus(action) {
  const { payload, toasts, intl, getAllChannelsPayload } = action;
  try {
    const response = yield call(disableChannelStatusCall, payload);
    yield put(getAllChannelsData(getAllChannelsPayload));
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (error) {}
}

export function* saga() {
  yield takeLatest("CREATE_CHANNEL", attemptCreateChannel);
  yield takeLatest("GET_ALL_CHANNELS_DATA", attemptGetChannelSaga);
  yield takeLatest("DELETE_CHANNEL", attemptDeleteChannel);
  yield takeLatest("DELETE_SELECTED_CHANNEL", attemptDeleteSelectedChannels);
  yield takeLatest("EDIT_CHANNEL", attemptUpdate);
  yield takeLatest("ASSIGN_USER", attemptAssignUser);
  yield takeLatest("ENABLE_CHANNEL_STATUS", attemptEnableChannelStatus);
  yield takeLatest("DISABLE_CHANNEL_STATUS", attemptDisableChannelStatus);
}
