import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { Modal, Button } from "react-bootstrap";

import {
  DELETE_MULTIPLE_POLICY_URL,
  DELETE_MULTIPLE_USER_URL,
  DELETE_MULTIPLE_CRAWLERS_URL,
  DELETE_MULTIPLE_POSTS_URL,
  DELETE_MULTIPLE_CATEGORIES_URL,
  DELETE_MULTIPLE_MEDIA_URL,
  DELETE_MULTIPLE_SECTIONS_URL,
  DELETE_MULTIPLE_POST_TYPES_URL,
  DELETE_MULTIPLE_SOURCES_URL,
} from "../../../../../_metronic/_helpers/Constants";
import ProfileService from "../../../Profile/service";
import { useDispatch } from "react-redux";
import * as toasts from "../../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";
import {
  multiDeleteMedia,
  multiDeleteMediaFromRedux,
} from "../../../../_redux/media/actions";
import {
  multiDeleteCategoriesFromRedux,
  multiDeleteCategory,
} from "../../../../_redux/Category/actions";
import { multiPostTypeDelete } from "../../../../_redux/PostType/actions";
import { multiPostDeleteFromRedux } from "../../../../_redux/Post/action";
import { multiDeleteUserFromRedux } from "../../../../_redux/users/actions";
import { mutiDeleteSectionsFromRedux } from "../../../../_redux/sections/actions";
import { multiDeleteSourceFromRedux } from "../../../../_redux/sources/actions";
import { multiPolicyDeleteFromRedux } from "../../../../_redux/policy/actions";

const MultipleDeleteModal = ({
  isVisible,
  setIsVisible,
  currentItems,
  setCurrentItems,
  setSelectedRows,
  itemType,
  setAllIds,
}) => {
  const service = new ProfileService();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const intl = useIntl();

  const saveItems = async () => {
    // eslint-disable-next-line default-case
    console.log("itemType", itemType);
    switch (itemType) {
      case "users":
        // console.log("answer:", abc([]));
        dispatch(multiDeleteUserFromRedux(currentItems));
        setAllIds([]);
        return await service.post(DELETE_MULTIPLE_USER_URL, {
          ids: currentItems,
        });
      case "policy":
        dispatch(multiPolicyDeleteFromRedux(currentItems));
        setAllIds([]);
        return await service.post(DELETE_MULTIPLE_POLICY_URL, {
          ids: currentItems,
        });
      case "crawlers":
        return await service.post(DELETE_MULTIPLE_CRAWLERS_URL, {
          ids: currentItems,
        });
      case "posts":
        let data = {};
        data.ids = currentItems;
        // data.setAllIds = setCurrentItems;
        dispatch(multiPostDeleteFromRedux(data));
        setAllIds([]);
        return await service.post(DELETE_MULTIPLE_POSTS_URL, {
          ids: currentItems,
        });
      case "categories":
        dispatch(multiDeleteCategoriesFromRedux(currentItems));
        return dispatch(multiDeleteCategory(currentItems, intl, toasts));
      case "media":
        dispatch(multiDeleteMediaFromRedux(currentItems));
        return dispatch(multiDeleteMedia(currentItems, intl, toasts));
      case "section":
        dispatch(mutiDeleteSectionsFromRedux(currentItems));
        return await service.post(DELETE_MULTIPLE_SECTIONS_URL, {
          ids: currentItems,
        });
      case "post-type":
        return dispatch(multiPostTypeDelete(currentItems, intl, toasts));
      // await service.post(DELETE_MULTIPLE_POST_TYPES_URL, {
      //   ids: currentItems,
      // });
      case "source":
        dispatch(multiDeleteSourceFromRedux(currentItems));
        return await service.post(DELETE_MULTIPLE_SOURCES_URL, {
          ids: currentItems,
        });
    }
  };

  const mutation = useMutation(saveItems, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(itemType);
      dispatch(
        toasts.showToast(
          data?.data?.status,
          intl.formatMessage({ id: data?.data?.title ?? "message" })
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(toasts.showToast(500, error.response.data.message));
      dispatch(toasts.hideToast());
    },
  });
  if (!isVisible) return null;

  const itemsSwitch = (param) => {
    // eslint-disable-next-line default-case
    switch (itemType) {
      case "users":
        return "users";
      case "policy":
        return "policies";
      case "crawlers":
        return "crawlers";
      case "posts":
        return "posts";
      case "categories":
        return "categories";
      case "media":
        return "media";
      case "sections":
        return "sections";
      case "post-types":
        return "post types";
    }
  };

  const itemOption = itemsSwitch(itemType);

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        setCurrentItems(null);
        setIsVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete selected {itemOption}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure to permanently delete these {itemOption}?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setCurrentItems(null);
            setIsVisible(false);
          }}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            try {
              mutation.mutate();
              setCurrentItems(null);
              setIsVisible(false);
              // clear array with selected rows(ids) after bulk delete
              if (setSelectedRows) {
                setSelectedRows([]);
              }
            } catch (error) {}
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MultipleDeleteModal;
