// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    isUser,
    viewUserPage,
    openEditModal,
    openAssignModal,
    openDeleteModal,
    isCrawler,
    openStartManuallyModal,
    isManagePage,
    openNotificationsModal,
    openTagModal,
    changeUserStatus,
  }
) {
  return (
    <>
      {isManagePage !== "undefined" && isManagePage && (
        <>
          <a
            title="Notifications"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => openNotificationsModal(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Notifications1.svg"
                )}
              />
            </span>
          </a>
        </>
      )}
      {isCrawler !== "undefined" && isCrawler && (
        <a
          title="Start Crawler Manually"
          className={
            "btn btn-icon btn-light btn-hover-primary btn-sm mx-3 " +
            (row.running ? "disabled" : "")
          }
          onClick={() => openStartManuallyModal(row)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Play.svg")} />
          </span>
        </a>
      )}
      <a
        title="Edit customer"
        data-toggle="modal"
        data-target="#exampleModalEdit"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => {
          openEditModal(row);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </a>
      {isUser ? (
        <>
          <a
            title="Edit customer"
            className="svg-icon svg-icon-primary svg-icon-2x btn btn-icon btn-light btn-hover-light btn-sm mx-3"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={() => openAssignModal(row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <path
                  d="M16.5428932,17.4571068 L11,11.9142136 L11,4 C11,3.44771525 11.4477153,3 12,3 C12.5522847,3 13,3.44771525 13,4 L13,11.0857864 L17.9571068,16.0428932 L20.1464466,13.8535534 C20.3417088,13.6582912 20.6582912,13.6582912 20.8535534,13.8535534 C20.9473216,13.9473216 21,14.0744985 21,14.2071068 L21,19.5 C21,19.7761424 20.7761424,20 20.5,20 L15.2071068,20 C14.9309644,20 14.7071068,19.7761424 14.7071068,19.5 C14.7071068,19.3673918 14.7597852,19.2402148 14.8535534,19.1464466 L16.5428932,17.4571068 Z"
                  fill="#000000"
                  fillRule="nonzero"
                />
                <path
                  d="M7.24478854,17.1447885 L9.2464466,19.1464466 C9.34021479,19.2402148 9.39289321,19.3673918 9.39289321,19.5 C9.39289321,19.7761424 9.16903559,20 8.89289321,20 L3.52893218,20 C3.25278981,20 3.02893218,19.7761424 3.02893218,19.5 L3.02893218,14.136039 C3.02893218,14.0034307 3.0816106,13.8762538 3.17537879,13.7824856 C3.37064094,13.5872234 3.68722343,13.5872234 3.88248557,13.7824856 L5.82567301,15.725673 L8.85405776,13.1631936 L10.1459422,14.6899662 L7.24478854,17.1447885 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  opacity="0.3"
                />
              </g>
            </svg>
          </a>
          {/* ---------------- view button ------------- */}
          <span
            onClick={() => viewUserPage(row)}
            className="svg-icon svg-icon-primary svg-icon-2x btn btn-icon btn-light btn-hover-light btn-sm mx-3"
          >
            {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/General/Visible.svg*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <path
                  d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z"
                  fill="#000000"
                  fillRule="nonzero"
                  opacity="0.3"
                />
                <path
                  d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z"
                  fill="#000000"
                  opacity="0.3"
                />
              </g>
            </svg>
            {/*end::Svg Icon*/}
          </span>
        </>
      ) : (
        ""
      )}

      <a
        title="Delete customer"
        className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
        onClick={() => {
          openDeleteModal(row);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
      {isUser ? (
        <span
          title={
            [null, "", 0].includes(row?.is_verified) ? "Verify" : "Unverify"
          }
          className="btn btn-icon btn-sm mx-3"
        >
          <span className="switch switch-sm switch-icon switch-primary">
            <label>
              <input
                type="checkbox"
                checked={
                  row?.is_verified == 1 ? true : false
                  // [null, "", 0].includes(row?.is_verified) ? false : true
                }
                name="select"
                onClick={() => {
                  changeUserStatus(row);
                }}
              />
              <span />
            </label>
          </span>
        </span>
      ) : null}
    </>
  );
}

export function TitleColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditModal, openAssignModal }
) {
  return (
    <>
      <a onClick={() => openEditModal(row)}>{cellContent}</a>
      {/* <a onClick={() => openAssignModal(row)}>{cellContent}</a> */}
    </>
  );
}
