import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getFoldersDataCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/folder/allowed/get`, data);
};
export const createNewFolderCall = (data) => {
  return axios.post(
    `${ARHIVA_REST_URL}api/folder/${data.folder_id ? "rename" : "create"}`,
    data
  );
};

export const folderDeleteCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/folder/delete`, data);
};

export const assignFolderCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/folder/assign`, data);
};
