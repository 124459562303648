import React, { useState, useEffect } from "react";

import Personal from "./Folders/Personal";
import Search from "./Folders/Search";
import Clipping from "./Folders/Clipping";

export default function Folders(props) {
  const [activeTab, setActiveTab] = useState("");
  useEffect(() => {
    window.$('a[data-toggle="tab"]').on("show.bs.tab", function(e) {
      setActiveTab(e.target.text);
    });
  }, [props?.renderCall == true]);

  //   useEffect(() => {
  //     console.log("here :", activeTab);
  //   }, [activeTab]);

  //   const getAllFolders = async () => {
  //     console.log("here in function");
  //     let data = {};
  //     data.folder_type = "licni";
  //     data.user_id = props?.params.id;
  //     try {
  //       const response = await axios.post(
  //         `${ARHIVA_REST_URL}api/folder/get`,
  //         data
  //       );
  //       console.log("folder response", response.data);
  //     } catch (e) {
  //       console.log("sda", e);
  //     }
  //   };

  return (
    <>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#all">
            All
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link " data-toggle="tab" href="#personal">
            Personal
          </a>
        </li>
        {/* <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#search">
            Search
          </a>
        </li> */}
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#clipping">
            Clipping
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div id="all" className="container tab-pane active">
          <br />
          <p>Tab is in progress</p>
        </div>
        <div id="personal" className="container tab-pane">
          <br />
          <Personal
            params={props?.params}
            renderFolderCall={activeTab == "Personal" ? true : false}
          />
        </div>
        {/* <div id="search" className="container tab-pane ">
          <br />
          <Search renderFolderCall={activeTab == "Search" ? true : false} />
        </div> */}
        <div id="clipping" className="container tab-pane ">
          <br />
          <Clipping
            params={props?.params}
            renderFolderCall={activeTab == "Clipping" ? true : false}
          />
        </div>
      </div>
    </>
  );
}
