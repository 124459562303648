import { put, takeLatest, call } from "redux-saga/effects";
import {
  getAllPoliciesData,
  setAllPoliciesData,
  setSectionsData,
  showLoader,
} from "./actions";
import {
  createPolicyCall,
  deletPolicyCall,
  editPolicyCall,
  getAllPoliciesCall,
  getSectionCall,
} from "./Service";
import { a_policy_url } from "../../../_metronic/_helpers/Constants";

function* attemptGetAllPolicies(actions) {
  try {
    yield put(showLoader(true));
    const response = yield call(getAllPoliciesCall, actions);
    yield put(setAllPoliciesData(response.data));
    yield put(showLoader(false));
  } catch (error) {}
}

function* attemptDeletePolicy(actions) {
  try {
    const response = yield call(deletPolicyCall, actions);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(getAllPoliciesData());
  } catch (errors) {
    yield put(
      actions.toasts.showToast(
        500,
        actions.intl.formatMessage({ id: errors.response.data.message })
      )
    );
    yield put(actions.toasts.hideToast());
  }
}

function* attemptCreatePolicy(actions) {
  try {
    const response = yield call(createPolicyCall, actions);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(actions.history.push(`${a_policy_url}`));
  } catch (error) {}
}

function* attemptEditPolicy(actions) {
  try {
    const response = yield call(editPolicyCall, actions);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(actions.history.push(`${a_policy_url}`));
  } catch (errors) {}
}

function* attemptGetSections(actions) {
  try {
    const response = yield call(getSectionCall, actions);
    yield put(setSectionsData(response.data));
  } catch (errors) {}
}

export function* saga() {
  yield takeLatest("GET_ALL_POLICY_DATA", attemptGetAllPolicies);
  yield takeLatest("DELETE_POLICY", attemptDeletePolicy);
  yield takeLatest("CREATE_POLICY", attemptCreatePolicy);
  yield takeLatest("EDIT_POLICY", attemptEditPolicy);
  yield takeLatest("GET_SECTIONS", attemptGetSections);
}
