import React, { useEffect, useState } from "react";

import { ARHIVA_REST_URL } from "../../../../../environments/environment.prod";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { editEmailSettings } from "../../../../_redux/users/actions";

export const EmailSettings = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [callLoader, setCallLoader] = useState(false);
  const [emailNotification, setEmailNotification] = useState("");
  const [emailNotificationTime, setEmailNotificationTime] = useState("");

  useEffect(() => {
    setEmailNotification(props?.email_settings?.email_notification);
    setEmailNotificationTime(props?.email_settings?.email_notification_time);
  }, [props.email_settings]);

  const handleEmailNotificationChange = (e) => {
    if (e.target.checked === true) {
      setEmailNotification(1);
    } else {
      setEmailNotification(0);
    }
  };

  const handleEditUser = () => {
    // setCallLoader(true);
    let data = {};
    data.user_id = props?.routId;
    data.email_notification = emailNotification;
    data.email_notification_time = emailNotificationTime;

    dispatch(editEmailSettings(data, intl, toasts));
  };

  return (
    <>
      <form className="card card-custom">
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              {intl.formatMessage({
                id: "MENU.PROFILE.EMAIL_SETTINGS",
              })}
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Change your email settings
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              disabled={callLoader}
              onClick={() => handleEditUser()}
              className="btn btn-success mr-2 d-flex"
            >
              {intl.formatMessage({
                id: "MENU.OPTIONS.SAVE",
              })}
              {callLoader == true ? (
                <>
                  <div className="spinner ml-2" style={{ width: "20px" }}></div>
                </>
              ) : (
                ""
              )}
            </button>
            <NavLink
              to={`/admin/user/view/${props.routId}`}
              className="navi-link py-4"
              activeClassName="active"
              exact
            >
              <button type="button" className="btn btn-secondary">
                {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
              </button>
            </NavLink>
          </div>
        </div>
        <div className="form">
          <div className="card-body">
            <div className="form-group row align-items-center">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">
                {intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}{" "}
                {intl.formatMessage({
                  id: "TOOLTIP.NOTIFICATIONS",
                })}
              </label>
              <div className="col-lg-9 col-xl-6">
                <span className="switch switch-sm">
                  <label>
                    <input
                      checked={emailNotification}
                      onChange={(e) => handleEmailNotificationChange(e)}
                      type="checkbox"
                      name="email_notification"
                    />
                    <span />
                  </label>
                </span>
              </div>
            </div>
            <div className="form-group row align-items-center">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">
                Time to receive email notification
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="MuiFormControl-root MuiTextField-root">
                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                    <input
                      onChange={(e) => setEmailNotificationTime(e.target.value)}
                      aria-invalid="false"
                      id="email_notification_time"
                      type="time"
                      step={300}
                      className="MuiInputBase-input MuiInput-input"
                      value={emailNotificationTime}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row align-items-center">
              <label className="col-xl-3 col-lg-3 col-form-label font-weight-bold text-left text-lg-right">
                Arhimed news
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="checkbox-inline">
                  <label className="checkbox">
                    <input type="checkbox" name="videoType" />
                    <span />
                    Video
                  </label>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="linkType"
                      defaultValue="false"
                    />
                    <span />
                    Link
                  </label>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="newsType"
                      defaultValue="false"
                    />
                    <span />
                    News
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
