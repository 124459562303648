import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";

import {
  Input,
  Select,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  EDIT_SOURCE_URL,
  SAVE_SOURCE_URL,
  GET_ALL_SOURCES_URL,
  a_source_url,
} from "../../../../_metronic/_helpers/Constants";
import ProfileService from "../../Profile/service";
import {
  editSourceAction,
  saveSourceAction,
  getSourceById,
} from "../../../_redux/sources/actions";

const EditSchema = Yup.object().shape({
  name: Yup.string().required(),
});

const SourceEditPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [currentSource, setCurrentSource] = useState(null);
  const history = useHistory();
  const service = new ProfileService();

  const params = useParams();

  useEffect(() => {
    const fetchSource = async () => {
      if (!params.id) return;
      let payload = {
        id: params.id,
        page: 0,
        size: 20,
      };
      try {
        dispatch(getSourceById(payload, setCurrentSource));
      } catch (error) {}
    };

    fetchSource();
  }, [params]);

  const saveSource = async (values) => {
    const id = +params.id || null;
    const payload = id ? { ...values, id } : { ...values };
    if (id) {
      dispatch(editSourceAction(payload, intl, toasts, history));
    } else {
      dispatch(saveSourceAction(payload, intl, toasts, history));
    }
  };

  const mutation = useMutation(saveSource, {
    onSuccess: (data) => {
      dispatch(
        toasts.showToast(
          data.data.status,
          intl.formatMessage({ id: data.data.title })
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(
        toasts.showToast(
          error.response.data.status,
          intl.formatMessage({
            id: error.response.data.details || error.response.data.errors[0],
          })
        )
      );
      dispatch(toasts.hideToast());
    },
  });

  const backToSourceList = () => {
    history.push(a_source_url);
  };
  const btnRef = useRef();

  const saveSourceClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  if (params.id && !currentSource) {
    return null;
  }

  const initialValues = {
    name: currentSource ? currentSource.name : "",
  };

  return (
    <Card>
      <CardHeader
        title={
          params.id
            ? intl.formatMessage({ id: "MENU.OPTIONS.EDIT" }) +
              " " +
              intl.formatMessage({ id: "MENU.SOURCE" })
            : intl.formatMessage({ id: "Add_new" }) +
              " " +
              intl.formatMessage({ id: "MENU.SOURCE" })
        }
      >
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToSourceList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveSourceClick}
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <Formik
            initialValues={initialValues}
            validationSchema={EditSchema}
            enableReinitialize
            onSubmit={async (values) => {
              try {
                await mutation.mutateAsync(values);
                history.push(a_source_url);
              } catch (error) {
                console.error(error);
              }
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, values, setFieldValue, errors }) => (
              <>
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        type="text"
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label="Name"
                        withFeedbackLabel={false}
                      />
                      {errors?.name && (
                        <span className="error">{errors?.name}</span>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    style={{ display: "none" }}
                    ref={btnRef}
                    onSubmit={() => handleSubmit()}
                  ></button>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </CardBody>
    </Card>
  );
};

export default SourceEditPage;
