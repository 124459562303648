import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const socialCategoryCall = (data) => {
  return axios.post(
    `${ARHIVA_REST_URL}api/users/get/unallowed-social-categories`,
    data
  );
};

export const socialMediaStatusCall = (data) => {
  return axios.post(
    `${ARHIVA_REST_URL}api/user/social-media-categories/status`,
    data
  );
};
