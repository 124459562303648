
import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { addPost } from '../../../_redux/folders/foldersActions';
import { licni, toAbsoluteUrl } from '../../../../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';

const ITEM_HEIGHT = 48;

export default function AddToFolderButton({ ids }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const { data, foldersLoaded } = useSelector(
        (state) => (state.folders)
    );

    return (
        <span className="svg-icon svg-icon-xl svg-icon mr-3">
            <span className="svg-icon svg-icon-xl svg-icon-primary cursor-pointer ml-1" onClick={handleClick}>
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")} />
            </span>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200,
                        marginTop: 40
                    },
                }}
            >
                {data[licni] && foldersLoaded[licni] && Object.entries(data[licni]).map((folder, i) => {
                    return (
                        <MenuItem
                            key={folder[0]}
                            onClick={() => {
                                dispatch(addPost(folder[0], ids))
                                handleClose();
                            }}
                        >
                            {folder[1].title}
                        </MenuItem>
                    )
                })}
            </Menu>
        </span>
    );
}