import React, { useState, useEffect } from "react";
import {
  toAbsoluteUrl,
  archive_url,
  searches_url,
} from "../../../_metronic/_helpers";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import ArhivaTable from "../../../app/modules/ECommerce/pages/arhiva/ArhivaTable";
import BaseComponent from "../../../app/modules/ReactiveBase/BaseComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  setArhivaQueryValue,
  setArhivaQuery,
} from "../../../app/modules/ECommerce/_redux/arhiva/ArhivaActions";
import "../../_assets/sass/pages/archive/index.scss";
import ArhivaSearch from "../../../app/modules/ECommerce/pages/arhiva/ArhivaSearch/ArhivaSearch";
import { setActiveFolder } from "../../../app/modules/ECommerce/_redux/folders/foldersActions";
import { ArhivaUIProvider } from "../../../app/modules/ECommerce/pages/arhiva/ArhivaUIContext";
import { SearchIndex } from "../../../app/modules/ECommerce/pages/arhiva/ArhivaSearch/SearchIndex";
export function Arhiva() {
  const location = useLocation();
  const { arhiva } = useSelector((state) => ({ arhiva: state.arhiva }));
  const intl = useIntl();
  const [showHeader, setShowHeader] = useState(true);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [bool, setBool] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (arhiva.queryValue && arhiva.queryValue !== "") {
      setShowHeader(false);
      setShowTable(true);
    } else if (location.search) {
      setShowHeader(false);
      setShowTable(true);
    } else {
      setShowHeader(true);
      setShowTable(false);
    }
  }, [arhiva.queryValue, location]);

  useEffect(() => {
    // if there's no existing folder, reset all search params and active folder
    if (location.pathname === `/${archive_url}` && !window.location.search) {
      dispatch(setArhivaQueryValue(null));
      dispatch(setArhivaQuery(""));
      dispatch(setActiveFolder(undefined));
    }
    // we use window location because location hook doesnt recognize URL params change
  }, [location, dispatch]);

  return (
    <>
      {bool == true ? (
        <SearchIndex
          data={data}
          searchData={searchData}
          setBool={(val) => {
            setBool(val);
          }}
        />
      ) : (
        <BaseComponent>
          <div style={{ display: !showHeader && "none" }}>
            <div
              className="d-flex flex-row-fluid bgi-size-cover bgi-position-center no-padding"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("media/bg/bg-9.jpg")})`,
              }}
            >
              <div className="container">
                <div className="d-flex justify-content-between align-items-center border-bottom border-white py-7">
                  <h3 className="h4 text-dark mb-0">
                    {intl.formatMessage({ id: "PAGE.SEARCH.TITLE" })}
                  </h3>
                  <div className="d-flex">
                    <Link
                      to="/helpcenter"
                      className="font-size-h6 font-weight-bold"
                    >
                      {intl.formatMessage({ id: "LINK.HELPCENTER" })}
                    </Link>
                  </div>
                </div>
                <div className="d-flex align-items-stretch text-center flex-column py-40">
                  <h1 className="text-dark font-weight-bolder mb-12">
                    {intl.formatMessage({ id: "PAGE.SEARCH.QUESTION" })}
                  </h1>
                  <ArhivaSearch
                    setData={(val) => {
                      setData(val);
                    }}
                    setSearchData={(val) => {
                      setSearchData(val);
                    }}
                    setBool={(val) => {
                      setBool(val);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {showTable && (
            <ArhivaUIProvider>
              <ArhivaTable />
            </ArhivaUIProvider>
          )}
        </BaseComponent>
      )}
    </>
  );
}
