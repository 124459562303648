/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";

export function QuickUserToggler() {
  const {
    user: { account_information, personal_information },
  } = useSelector((state) => state.auth);
  const intl = useIntl();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="quick-user-tooltip">
                {intl.formatMessage({ id: "TOOLTIP.USER_PANEL" })}
              </Tooltip>
            }
          >
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <>
                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                  {intl.formatMessage({ id: "USER.PROFILE.WELCOME" })}
                </span>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                  {personal_information?.first_name}
                </span>
                <span className="symbol symbol-35 symbol-light-success">
                  <span className="symbol-label font-size-h5 font-weight-bold">
                    {personal_information?.first_name?.charAt(0).toUpperCase()}
                  </span>
                </span>
              </>
            </div>
          </OverlayTrigger>
        </div>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
