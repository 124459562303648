import React, { useState, useEffect } from "react";
import { Table } from "../../../shared";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { CardHeaderToolbar } from "../../../../../../_metronic/_partials/controls";
import * as toasts from "../../../../ECommerce/_redux/toasts/toastsActions";
import { useDispatch } from "react-redux";
import {
  assignFolderUser,
  assignMultiFolders,
  getFoldersData,
  setAssignFolder,
  setMultiAssignFolder,
} from "../../../../../_redux/FolderTabs/actions";

export default function Clipping(props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);
  const [tableData, setTableData] = useState([]);
  const showLoader = useSelector((state) => state?.users?.showLoader ?? []);

  const foldersData = useSelector((state) => state?.foldersData?.Index ?? []);
  const alreadyAssignedFolders = useSelector(
    (state) => state?.foldersData?.Index?.already_assigned ?? []
  );
  const allFolders = useSelector(
    (state) => state?.foldersData?.Index?.all_folders?.data ?? []
  );

  useEffect(() => {
    let tempAlreadyAssigned = [...alreadyAssignedFolders];
    let tmpAllFolders = [...allFolders];
    if (alreadyAssignedFolders.length) {
      let tmpArray = tmpAllFolders?.map((d) => {
        let tmp = { ...d };
        if (tempAlreadyAssigned?.map((aaf) => aaf?.id)?.includes(d.id)) {
          tmp.status = true;
        }
        return tmp;
      });
      setTableData(tmpArray);
    } else {
      setTableData(allFolders);
    }
  }, [alreadyAssignedFolders.length]);

  useEffect(() => {
    setTableData(allFolders);
  }, [foldersData?.all_folders?.data]);

  const paginationOptions = {
    totalSize: foldersData?.all_folders?.totalCount,
    sizePerPage,
    page: page,
  };

  useEffect(() => {
    if (props?.renderFolderCall == true) {
      getAllFolders();
    }
  }, [props?.renderFolderCall, page, sizePerPage]);

  const getAllFolders = async () => {
    setAllIds([]);
    let data = {};
    data.folder_type = "kliping";
    data.user_id = props?.params.id;
    data.size = sizePerPage;
    data.page = page - 1;
    dispatch(getFoldersData(data));
  };

  const checkIfAssignFolderIsTrue = (row) => {
    if (foldersData?.already_assigned?.map((p) => p.id).includes(row.id)) {
      return true;
    } else {
      return false;
    }
  };

  const handleFolderChecked = (e, row) => {
    let data = {};
    data.user_id = props.params.id;
    data.folder_id = row.id;
    data.status = e.target.checked == true ? 1 : 0;
    dispatch(setAssignFolder(row));
    dispatch(assignFolderUser(data, toasts, intl, page, sizePerPage));
  };

  const ChangeFolderStatus = (mediaIds, status) => {
    let data = {};
    data.user_id = props.params.id;
    data.folder_ids = mediaIds;
    data.status = status == true ? 1 : 0;

    let fObj = mediaIds.map((id) => {
      return allFolders.find((x) => x.id == id);
    });

    dispatch(setMultiAssignFolder(fObj, status));
    dispatch(assignFolderUser(data, toasts, intl, page, sizePerPage));
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "title",
      text: "Title",
      headerStyle: { verticalAlign: "initial" },
    },

    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),

      formatter: (cell, row) => {
        return (
          <div>
            <span className="switch switch-sm switch-icon switch-primary">
              <label>
                <input
                  onChange={(e) => handleFolderChecked(e, row)}
                  type="checkbox"
                  checked={
                    checkIfAssignFolderIsTrue(row) == true ? true : false
                  }
                  name="select"
                />
                <span />
              </label>
            </span>
          </div>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  return (
    <>
      {allIds.length > 1 && (
        <div className="form-group">
          <p>Selected records count:{allIds.length}</p>
          &nbsp;
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder font-size-sm"
            onClick={() => ChangeFolderStatus(allIds, true)}
          >
            Enable
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder font-size-sm mr-5"
            onClick={() => ChangeFolderStatus(allIds, false)}
          >
            Disable
          </button>
        </div>
      )}
      <Table
        showLoader={showLoader}
        data={tableData}
        columns={columns}
        pagiOptions={paginationOptions}
        setPage={setPage}
        // isFetching={isFetching}
        setSizePerPage={setSizePerPage}
        selectRowOptions={{
          allIds,
          setAllIds,
        }}
        intl={intl}
      />
    </>
  );
}
