import React, { useEffect } from "react";
import FoldersTable from "./folders-table/FoldersTable";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/folders/foldersActions";
import { useHistory } from "react-router-dom";
import * as toasts from "../../_redux/toasts/toastsActions";
import { useIntl } from "react-intl";
import { FoldersUIProvider, useFoldersUIContext } from "./FoldersUIContext";
import BaseComponent from "../../../ReactiveBase/BaseComponent";
import { queryHelper } from "../../../../../_metronic/_helpers";

const SingleFolder = (props) => {
  const toast = useSelector((state) => ({
    status: state.toasts.status,
    type: state.toasts.type,
    title: state.toasts.title,
  }));
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { currentState } = useSelector((state) => ({
    currentState: state.folders,
  }));
  const {
    match: {
      params: { foldertype, id },
    },
  } = props;
  const { data, posts, activeFolder, activeId } = currentState;
  const defaultQuery = () => queryHelper(activeFolder, activeId);

  // check if folder exists and load data
  useEffect(() => {
    if (currentState.foldersLoaded[foldertype] && data && data[foldertype]) {
      if (data[foldertype][id]) {
        dispatch(actions.setActiveFolder(data[foldertype][id], id));
      } else {
        dispatch(
          toasts.showToast(400, intl.formatMessage({ id: "PAGE.NOT.EXIST" }))
        );
      }
    }
  }, [currentState, data, dispatch, foldertype, id, intl]);

  // if folder does not exist, redirect
  useEffect(() => {
    if (toast.status === 400) {
      dispatch(toasts.hideToast());
      history.push(`/folders/${foldertype}`);
    }
  }, [dispatch, foldertype, history, toast]);

  return (
    <FoldersUIProvider>
      <BaseComponent>
        <FoldersTable
          Context={useFoldersUIContext}
          initialSize={10}
          actions={actions}
          componentId={"list"}
          infiniteScroll={false}
          folderState={currentState}
          defaultQuery={defaultQuery}
          type={
            //   activeFolder?.type
            "1"
          }
        />
      </BaseComponent>
    </FoldersUIProvider>
  );
};

export default SingleFolder;
