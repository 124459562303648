import { createSlice } from "@reduxjs/toolkit";

export const initialArhivaState = {
  listLoading: false,
  actionsLoading: false,
  stats: undefined,
  posts: undefined,
  query: undefined,
  queryValue: "",
  open: false,
  url_pretrage: undefined,
  fuzziness: 2,
  queryFormat: "or",
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const arhivaSlice = createSlice({
  name: "arhiva",
  initialState: initialArhivaState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    postsFetched: (state, action) => {
      const { data, stats } = action.payload;
      state.posts = data;
      state.stats = stats;
      state.listLoading = false;
    },
    queryApplied: (state, action) => {
      state.query = action.payload;
    },
    URLapplied: (state, action) => {
      state.url_pretrage = action.payload;
      state.queryValue = "";
    },
    queryValueApplied: (state, action) => {
      state.queryValue = action.payload;
    },
    sidebarOpened: (state, action) => {
      state.open = true;
    },
    sidebarClosed: (state, action) => {
      state.open = false;
    },
    dataSearchParamsSet: (state, action) => {
      const { fuzziness, queryFormat } = action.payload;
      state.fuzziness = fuzziness;
      state.queryFormat = queryFormat;
    },
  },
});
