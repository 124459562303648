/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import PageContent from "../Other/pages/PageContent";
import ProfileService from "../Profile/service";
import {
  GET_ALL_POLICIES_URL,
  GET_ALL_CATEGORIES_URL,
} from "../../../_metronic/_helpers/Constants";
import { useQuery } from "react-query";
import axios from "axios";

export default function ReactiveExtrasPage({ type }) {
  const axiosInstance = axios.create({
    headers: {
      "Content-type": "application/json",
    },
  });

  const [page, setPage] = useState(1);

  const getTypeId = (type) => {
    switch (type) {
      case "Privacy":
        return 5;
      case "Legal":
        return 6;
      case "Help":
        return 7;
    }
  };

  const typeId = getTypeId(type);
  const [sizePerPage, setSizePerPage] = useState(20);

  const fetchData = async (page) => {
    const response = await axiosInstance.post(GET_ALL_POLICIES_URL, {
      page: page - 1,
      size: sizePerPage,
      type_id: typeId,
      status: 1,
    });
    return response.data;
  };

  const { isFetching, data } = useQuery(
    ["privacy", page, sizePerPage],
    () => fetchData(page),
    {
      keepPreviousData: true,
    }
  );

  let categories = [];
  if (!isFetching) {
    const catArr = data.data.map((row) => {
      return {
        id: row.category_id,
        name: row.category_name,
      };
    });
    categories = catArr.filter(
      (el, ind) =>
        ind ===
        catArr.findIndex((elem) => elem.id === el.id && elem.name === el.name)
    );
  }

  if (isFetching) return null;

  return (
    <PageContent
      data={{ navItemsData: categories, accordionData: data.data }}
      type={type}
    />
  );
}
