import { data } from "jquery";
import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, toasts, intl, id, closeModal, ids, setAllIds } = action;
  switch (action.type) {
    case "SET_ALL_CHANNELS_DATA":
      return payload;
    case "DELETE_CHANNEL_FROM_REDUX":
      const deleteData = state.data.filter((x) => (x.id === id ? false : x));
      closeModal("exampleModalDelete");
      return {
        ...state,
        data: deleteData,
      };
    case "DELETE_MULTI_CHANNELS_FROM_REDUX":
      const deleteMultiData = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );
      closeModal("exampleModalMultiDelete");
      setAllIds([]);
      return {
        ...state,
        data: deleteMultiData,
      };
    // -------------------------- Edit Redux ---------------------------------
    case "EDIT_CHANNEL_FROM_REDUX":
      const updatedState = state.data.map((obj) =>
        obj.id === id
          ? {
              ...obj,
              name: payload.name,
              order_by: payload.order_by,
              status: payload.status ? 1 : 0,
            }
          : obj
      );

      return {
        data: updatedState,
      };
    // return {
    //   ...state,
    //   data: editChannel,
    // };
    default:
      return state;
  }
};

const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};
export const reducer = combineReducers({
  Index,
  showLoader,
});
