import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { Modal, Button } from "react-bootstrap";

import {
  DELETE_POLICY_URL,
  DELETE_USER_URL,
  DELETE_CRAWLER_URL,
  DELETE_POST_URL,
  DELETE_CATEGORY_URL,
  DELETE_MEDIA_URL,
  DELETE_SECTION_URL,
  DELETE_POST_TYPE_URL,
  DELETE_SOURCE_URL,
} from "../../../../../_metronic/_helpers/Constants";
import ProfileService from "../../../Profile/service";
import { useDispatch } from "react-redux";
import * as toasts from "../../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";
// import { ARHIVA_REST_URL } from "../../environments/environment";
import { ARHIVA_REST_URL } from "../../../../../environments/environment";
import {
  deleteMedia,
  deleteMediaFromRedux,
} from "../../../../_redux/media/actions";
import {
  deletePolicy,
  deletePolicyFromRedux,
} from "../../../../_redux/policy/actions";
import {
  deleteSection,
  deleteSectionFromRedux,
} from "../../../../_redux/sections/actions";
import {
  deleteUser,
  deleteUserFromRedux,
} from "../../../../_redux/users/actions";
import { deleteCrawler } from "../../../../_redux/Crawler/actions";
import {
  deleteSource,
  deleteSourceFromRedux,
} from "../../../../_redux/sources/actions";
import {
  deleteCategoryAction,
  deleteCategoryFromRedux,
} from "../../../../_redux/Category/actions";
import { deletePostType } from "../../../../_redux/PostType/actions";
import { deletePostFromRedux } from "../../../../_redux/Post/action";

const DeleteModal = ({
  isVisible,
  setIsVisible,
  currentItem,
  setCurrentItem,
  itemType,
}) => {
  const service = new ProfileService();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const intl = useIntl();

  const saveItem = async () => {
    // eslint-disable-next-line default-case
    switch (itemType) {
      case "users":
        dispatch(deleteUserFromRedux(currentItem.id));
        return dispatch(deleteUser(currentItem.id, intl, toasts));
      case "policy":
        dispatch(deletePolicyFromRedux(currentItem.id));
        return dispatch(deletePolicy(currentItem.id, intl, toasts));
      case "crawlers":
        return dispatch(deleteCrawler(currentItem.id, intl, toasts));
      case "posts":
        dispatch(deletePostFromRedux(currentItem.id));
        return await service.delete(DELETE_POST_URL + currentItem.id);
      case "categories":
        dispatch(deleteCategoryFromRedux(currentItem.id));
        return dispatch(deleteCategoryAction(currentItem.id, intl, toasts));
      case "media":
        dispatch(deleteMediaFromRedux(currentItem.id));
        return dispatch(deleteMedia(currentItem.id, intl, toasts));
      case "section":
        dispatch(deleteSectionFromRedux(currentItem.id));
        return dispatch(deleteSection(currentItem.id, intl, toasts));
      case "post-type":
        console.log("currentItem", currentItem);
        return dispatch(deletePostType(currentItem.id, intl, toasts));
      case "source":
        dispatch(deleteSourceFromRedux(currentItem.id));
        return dispatch(deleteSource(currentItem.id, intl, toasts));
    }
  };

  const mutation = useMutation(saveItem, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(itemType);

      dispatch(
        toasts.showToast(
          data?.data?.status,
          intl.formatMessage({ id: data?.data?.title ?? "message" })
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(toasts.showToast(500, error?.response?.data?.message));
      dispatch(toasts.hideToast());
    },
  });
  if (!isVisible) return null;

  const itemsSwitch = (param) => {
    // eslint-disable-next-line default-case
    switch (itemType) {
      case "users":
        return "user";
      case "policy":
        return "policy";
      case "crawlers":
        return "crawler";
      case "posts":
        return "post";
      case "categories":
        return "category";
      case "media":
        return "media";
      case "section":
        return "section";
      case "post-types":
        return "post type";
      case "sources":
        return "source";
    }
  };

  const itemOption = itemsSwitch(itemType);

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        setCurrentItem(null);
        setIsVisible(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}{" "}
          {currentItem.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {intl.formatMessage({
          id: "Are_you_sure_to_permanently_delete_this_media?",
        })}{" "}
        {itemOption}?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setCurrentItem(null);
            setIsVisible(false);
          }}
        >
          {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            try {
              mutation.mutate();
              setCurrentItem(null);
              setIsVisible(false);
            } catch (error) {}
          }}
        >
          {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
