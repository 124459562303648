import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, toasts, intl, ids, id } = action;
  switch (action.type) {
    case "SET_SOURCES_DATA":
      return payload;
    case "DELETE_SOURCE_FROM_REDUX":
      const deleteData = state.data.filter((x) => (x.id === id ? false : x));
      return {
        ...state,
        data: deleteData,
      };
    case "MULTI_SOURCE_DELETE_FROM_REDUX":
      const deleteMultiData = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );

      return {
        ...state,
        data: deleteMultiData,
      };

    default:
      return state;
  }
};

const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};
const SourceReducer = combineReducers({
  Index,
  showLoader,
});

export default SourceReducer;
