import React from "react";
import { useIntl } from "react-intl";
import { useLocation, NavLink } from "react-router-dom";
import {
  checkIsActive,
  toAbsoluteUrl,
  licni,
  hasId,
  searches_url,
  clipping_media_url,
} from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import AsideMenuItem from "../../../../../../_metronic/layout/components/aside/aside-menu/AsideMenuItem";
import { CreateFolderButton } from "../folder-buttons/Buttons";

const AsideFolder = ({ folders, type }) => {
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? " menu-item-open " : "";
  };
  const intl = useIntl();
  const location = useLocation();

  return (
    <React.Fragment>
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(
          `/folders/${type}`
        )}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
        <NavLink className="menu-link menu-toggle" to={`/folders/${type}`}>
          {type === licni && (
            <>
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Files/User-folder.svg")}
                />
              </span>
              <span className="menu-text">
                {intl.formatMessage({ id: "MENU.USER_FOLDERS_PERSONAL" })}
              </span>
              <i className="menu-arrow" />
            </>
          )}
          {type === searches_url && (
            <>
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                />
              </span>
              <span className="menu-text">
                {intl.formatMessage({ id: "MENU.USER_SEARCH" })}
              </span>
              <i className="menu-arrow" />
            </>
          )}
          {type === clipping_media_url && (
            <>
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")}
                />
              </span>
              <span className="menu-text">
                {intl.formatMessage({ id: "MENU.USER_CLIPPING_MEDIA" })}
              </span>
              <i className="menu-arrow" />
            </>
          )}
        </NavLink>
        <div className="menu-submenu ">
          <i className="menu-arrow" />
          <ul className="menu-subnav">
            {/* check if folder type exists */}
            {/* folders[type] &&
              // iterate through each individual folder type
              Object.keys(folders[type])
                .slice(
                  0,
                  // show folders  in the aside
                  location.pathname.includes(`${type}`) && hasId(location)
                    ? Object.keys(folders[type]).length
                    : 10
              ) */

            folders[type]?.data?.map((singObj) => {
              return (
                <React.Fragment>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      `/folders/${type}/${"data"}`
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to={`/folders/${type}/${"data"}`}
                    >
                      <AsideMenuItem
                        folderId={"data"}
                        data={singObj}
                        type={type}
                      />
                    </NavLink>
                  </li>
                </React.Fragment>
              );
            })}
            {/* show the button only if >10 folders on given locations */}
            {folders[type] &&
              Object.keys(folders[type]).length > 10 &&
              !location.pathname.includes(`${type}`) && (
                <NavLink
                  className="btn btn-light font-weight-normal w-75 p-2 mx-auto mb-5 dropdown-toggle"
                  to={`/folders/${type}`}
                >
                  {intl.formatMessage({ id: "MENU.FOLDERS_BUTTON_LABEL" })}
                </NavLink>
              )}
            {folders[type] && type === licni && (
              <CreateFolderButton position={"aside"} type={licni} />
            )}
            {folders[type] && type === searches_url && (
              <CreateFolderButton position={"aside"} type={searches_url} />
            )}
          </ul>
        </div>
      </li>
    </React.Fragment>
  );
};

export default AsideFolder;
