import { put, takeLatest, call } from "redux-saga/effects";
import {
  editStreamFromRedux,
  getAllChannelsData,
  getAllStreamData,
  setAllServersData,
  setAllStreamData,
  showLoader,
} from "./actions";
import {
  createStreamCall,
  deleteAllPostsCall,
  deleteStreamCall,
  getAllServersDataCall,
  getAllStreamDataCall,
  postDeleteCall,
  startStopCall,
  startStreamCall,
  stopStreamCall,
  updatePostCall,
  updateStreamCall,
} from "./Service";

function* attemptGetStreamSaga(action) {
  try {
    yield put(showLoader(true));
    const response = yield call(getAllStreamDataCall,action);
    const transformedData = {
      status: response.status,
      data: response.data,
      totalCount: response.total_count,
    };
    yield put(setAllStreamData(transformedData));
    yield put(showLoader(false));
  } catch (error) {}
}

function* attemptGetServersData(action) {
  const {payload, size, page} = action
  try {
    yield put(showLoader(true));
    const response = yield call(getAllServersDataCall,action.payload);
    yield put(setAllServersData(response?.data));
    yield put(getAllStreamData(payload, size, page));


    yield put(showLoader(false));
  } catch (error) {}
}


function* attemptCreateStream(action) {
  const { payload, toasts, intl, closeModal, setErrMessage, page, size } = action;
  try {
    const response = yield call(createStreamCall, payload);
    const parsRes = JSON.parse(response)
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: parsRes.msg }))
    );
    var tokenValue = payload.get("token");
    yield put(action.toasts.hideToast());
    yield put(getAllStreamData(tokenValue, size, page));
    closeModal("exampleModal");
  } catch (error) {
    // setErrMessage(error?.response?.data?.errors);
  }
}

function* attemptUpdate(action) {
  const { setErrMessage, closeModal, payload, page ,size  } = action;
  try {
    const response = yield call(updateStreamCall, action);
  const parsRes = JSON.parse(response)

    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: parsRes.msg })
      )
    );
    yield put(action.toasts.hideToast());
    const token = payload.get('token');
    // yield put(editStreamFromRedux(payload));
    yield put(getAllStreamData(token, size, page));
    closeModal("exampleModalEdit");
  } catch (error) {
    setErrMessage(error?.response?.data?.errors);
  }
}



function* attemptStartStream(action) {
  yield put(showLoader(true));

  const { payload } = action;
  try {
    const response = yield call(startStreamCall, action);
  const parsRes = JSON.parse(response)

    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: parsRes.msg })
      )
    );
    yield put(action.toasts.hideToast());
    yield put(editStreamFromRedux(payload));
    // yield put(getAllStreamData());
    // closeModal("exampleModalEdit");
    yield put(showLoader(false));

  } catch (error) {
    // setErrMessage(error?.response?.data?.errors);
  }
}

function* attemptStopStream(action) {
  yield put(showLoader(true));
  const { payload } = action;
  try {
    const response = yield call(stopStreamCall, action);
  const parsRes = JSON.parse(response)

    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: parsRes.msg })
      )
    );
    yield put(action.toasts.hideToast());
    yield put(editStreamFromRedux(payload));
    // yield put(getAllStreamData());
    // closeModal("exampleModalEdit");
    yield put(showLoader(false));
  } catch (error) {
    // setErrMessage(error?.response?.data?.errors);
  }
}

function* attemptDeleteStream(action) {
  const { payload, closeModal } = action;
  try {
    const response = yield call(deleteStreamCall, action);
  const parsRes = JSON.parse(response)

    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: parsRes.msg })
      )
    );
    yield put(action.toasts.hideToast());
    const token = payload.get('token');
    // yield put(editStreamFromRedux(payload));
    // yield put(getAllStreamData(token));
    closeModal("exampleModalDelete");
  } catch (error) {
    // setErrMessage(error?.response?.data?.errors);
  }
}


// function* attemptDeleteChannel(action) {
//   try {
//     const response = yield call(postDeleteCall, action.payload);
//     yield put(
//       action.toasts.showToast(
//         200,
//         action.intl.formatMessage({ id: response.data.title })
//       )
//     );
//     yield put(action.toasts.hideToast());
//   } catch (error) {}
// }

// function* attemptDeleteAll(action) {
//   try {
//     const response = yield call(deleteAllPostsCall, action);
//     yield put(
//       action.toasts.showToast(
//         200,
//         action.intl.formatMessage({ id: response.data.title })
//       )
//     );
//     yield put(action.toasts.hideToast());
//   } catch (error) {}
// }

export function* saga() {
  yield takeLatest("CREATE_STREAM", attemptCreateStream);
  yield takeLatest("GET_ALL_STREAM_DATA", attemptGetStreamSaga);
  yield takeLatest("EDIT_STREAM", attemptUpdate);
  yield takeLatest("START_STREAM", attemptStartStream);
  yield takeLatest("STOP_STREAM", attemptStopStream);

  yield takeLatest("DELETE_STREAM", attemptDeleteStream);
  yield takeLatest("GET_ALL_SERVERS_DATA", attemptGetServersData)
  // yield takeLatest("COMMON.Delete", attemptDeleteAll);
}
