import { toastsSlice, callTypes } from "./toastsSlice";
const { actions } = toastsSlice;

export const showToast = (status, title) => (dispatch) => {
  dispatch(actions.toastCreated({ status, title }));
};

export const hideToast = () => (dispatch) => {
  dispatch(actions.toastHidden());
};
