/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";

import { Input } from "../../../../../_metronic/_partials/controls";
import { SAVE_CATEGORY_URL } from "../../../../../_metronic/_helpers/Constants";
import ProfileService from "../../../Profile/service";
import { useIntl } from "react-intl";

const AddCategorySchema = Yup.object().shape({
  name: Yup.string().required(),
});

const AddCategoryModal = ({ isVisible, setIsVisible, type_id }) => {
  const intl = useIntl();

  const service = new ProfileService();
  const queryClient = useQueryClient();

  const saveCategory = async (values) => {
    await service.post(SAVE_CATEGORY_URL, values);
  };

  const mutation = useMutation(saveCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("categories");
    },
  });

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        setIsVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: "Add_new" })}{" "}
          {intl.formatMessage({ id: "MENU.CATEGORY" })}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          name: "",
          slug: "",
          type: type_id,
          medij_id: 109,
        }}
        validationSchema={AddCategorySchema}
        onSubmit={async (values) => {
          try {
            mutation.mutate(values);
            setIsVisible(false);
          } catch (error) {}
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, errors }) => (
          <>
            <Form className="form form-label-right">
              <Modal.Body>
                <div className="form-group row">
                  <div className="col-12">
                    <Field
                      type="text"
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      withFeedbackLabel={false}
                    />
                    {errors?.name && (
                      <span className="error">{errors?.name}</span>
                    )}
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsVisible(false);
                  }}
                >
                  {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                </Button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default AddCategoryModal;
