import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";
import * as gconst from "../../../../../src/_metronic/_helpers/Constants";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    loginType: Yup.string(),
    email: Yup.string()
      .min(
        gconst.min_input,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.min_input,
          }}
        />
      )
      .max(
        gconst.max_input,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.max_input,
          }}
        />
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(
        gconst.min_input,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.min_input,
          }}
        />
      )
      .max(
        gconst.max_input,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.max_input,
          }}
        />
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      loginType: "username",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password, values.loginType)
          .then(({ data: { password } }) => {
            disableLoading();
            props.login(password);
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <>
      {/*begin::Content header*/}
      <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">
          <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT" />
        </span>
        <Link
          to="/auth/registration"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
        >
          <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
        </Link>
      </div>
      {/*end::Content header*/}
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* begin::Head */}
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">
            <FormattedMessage id="AUTH.LOGIN.SUBTITLE" />
          </p>
        </div>
        {/* end::Head */}

        {/*begin::Form*/}
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
            <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
              <div className="alert-text ">
                <FormattedHTMLMessage id="AUTH.LOGIN.NOTICE" />
              </div>
            </div>
          )}

          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder={intl.formatMessage({
                id: "AUTH.INPUT.USERNAME_OR_EMAIL",
              })}
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder={intl.formatMessage({
                id: "AUTH.INPUT.PASSWORD",
              })}
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <Link
              to="/auth/forgot-password"
              className="text-dark-50 text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span>
                <FormattedMessage id="AUTH.GENERAL.SIGNIN_BUTTON" />
              </span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
        {/*end::Form*/}
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
