export const getPostTypeData = (payload) => {
  return {
    type: "GET_POST_TYPE_DATA",
    payload: payload,
  };
};

export const setPostTypeData = (payload) => {
  return {
    type: "SET_POST_TYPE_DATA",
    payload: payload,
  };
};

export const deletePostType = (id, intl, toasts) => {
  return {
    type: "DELETE_POST_TYPE",
    id: id,
    intl: intl,
    toasts: toasts,
  };
};

export const multiPostTypeDelete = (payload, intl, toasts) => {
  return {
    type: "MULTI_POST_TYPE_DELETE",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const editPostType = (payload, intl, toasts) => {
  return {
    type: "EDIT_POST_TYPE",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const createPostType = (payload, intl, toasts) => {
  return {
    type: "CREATE_POST_TYPE",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const sendNewOrderData = (payload) => {
  return {
    type: "SEND_NEW_ORDER_DATA",
    payload: payload,
  };
};
