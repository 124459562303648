import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Table } from "../../shared";
import { useDispatch } from "react-redux";
import {
  changeUnallowedMediaStatus,
  getAllMediaData,
  getUnallowedMediaData,
  multiUnallowedMediaStatusChange,
} from "./../../../../_redux/users/actions";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";

import ProfileService from "../../../Profile/service";
import {
  GET_POST_TYPES_URL,
  a_post_type_edit_url,
} from "../../../../../_metronic/_helpers/Constants";
import { SearchTable } from "../../shared/Table/SearchTable";
import Lottie from "react-lottie";
import sadbox from "../../../Other/pages/lottieFile/sadbox.json";
import {
  setAssignUnallowedMedia,
  setMultiAssignUnallowedMedia,
} from "../../../../_redux/media/actions";

export default function MediaCategories(props) {
  const service = new ProfileService();

  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const showLoader = useSelector((state) => state?.users?.showLoader ?? []);
  const umd = useSelector(
    (state) => state?.media?.unallowedMedia?.allowed_media_category ?? []
  );
  const unAllowedMediaData = useSelector(
    (state) => state?.media?.unallowedMedia?.allowed_media_category?.data ?? []
  );

  const currentUnallowedMedia = useSelector(
    (state) =>
      state?.media?.unallowedMedia?.selected_unallowed_media_categories ?? []
  );

  const mediaData = useSelector(
    (state) => state?.media?.Index?.current_assign_media ?? []
  );

  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  const [searchPage, setSearchPage] = useState(1);

  const [allIds, setAllIds] = useState([]);
  const [postTypeData, setPostTypeData] = useState([]);

  const [selectedPostType, setSelectedPostType] = useState("");

  const [selectedMediaType, setSelectedMediaType] = useState("");

  const [getSearch, setSearch] = useState("");

  const [is_active, setIs_active] = useState(false);

  useEffect(() => {
    let tempAlreadyAssigned = [...currentUnallowedMedia];
    let tmpAllMedias = [...unAllowedMediaData];
    if (currentUnallowedMedia.length) {
      let tmpArray = tmpAllMedias?.map((d) => {
        let tmp = { ...d };
        if (tempAlreadyAssigned?.map((aaf) => aaf?.id)?.includes(d.id)) {
          tmp.status = true;
        }
        return tmp;
      });
      setTableData(tmpArray);
    } else {
      setTableData(unAllowedMediaData);
    }
  }, [currentUnallowedMedia.length]);

  useEffect(() => {
    setTableData(unAllowedMediaData);
  }, [umd?.data]);

  useEffect(() => {
    setIs_active(props?.renderCall);
  }, [props?.renderCall]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: sadbox,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const paginationOptions = {
    totalSize: umd?.totalCount,
    sizePerPage,
    page: getSearch ? searchPage : page,
  };

  useEffect(() => {
    if (is_active == true) {
      getDisallowedMedia();
    }
  }, [page, sizePerPage, getSearch, is_active, searchPage]);

  const getDisallowedMedia = () => {
    setAllIds([]);
    let data = {};
    data.user_id = props?.params.id;

    data.post_type_id = selectedPostType ?? "";

    data.media_id = selectedMediaType ?? "";

    if (getSearch != "") {
      data.title = getSearch;
      data.page = searchPage - 1;
      data.size = sizePerPage;
    } else {
      data.size = sizePerPage;
      data.page = page - 1;
    }
    dispatch(getUnallowedMediaData(data));
  };

  const handleUnallowedMediaChecked = (e, row) => {
    let data = {};
    data.user_id = props.params.id;
    data.media_category_id = row.id;
    data.status = e.target.checked == true ? 1 : 0;
    if (getSearch != "") {
      data.title = getSearch;
      data.page = 0;
      data.size = sizePerPage;
    } else {
      data.size = sizePerPage;
      data.page = page - 1;
    }

    dispatch(setAssignUnallowedMedia(row));

    dispatch(
      changeUnallowedMediaStatus(
        data,
        toasts,
        intl,
        selectedPostType,
        selectedMediaType
      )
    );
  };

  const checkIfAssignMediaIsTrue = (row) => {
    if (currentUnallowedMedia?.map((p) => p.id).includes(row.id)) {
      return true;
    } else {
      return false;
    }
  };

  const fetchPostTypes = async () => {
    const response = await service.post(GET_POST_TYPES_URL);
    setPostTypeData(response.data);
  };

  useEffect(() => {
    if (is_active == true) {
      fetchPostTypes();
    }
  }, [is_active]);

  const handlePostTypeChange = (e) => {
    setAllIds([]);
    setSelectedPostType(e.target.value);
    let data = {};
    data.user_id = props?.params.id;
    data.media_id = selectedMediaType ?? "";

    data.post_type_id = e.target.value == "all" ? "" : e.target.value;
    if (getSearch != "") {
      data.title = getSearch;
      data.page = 0;
      data.size = sizePerPage;
    } else {
      data.size = sizePerPage;
      data.page = page - 1;
    }

    dispatch(getUnallowedMediaData(data));
  };

  const handleMediaTypeChange = (e) => {
    setAllIds([]);
    setSelectedMediaType(e.target.value);
    let data = {};
    data.user_id = props?.params.id;
    data.media_id = e.target.value == "all" ? "" : e.target.value;
    data.post_type_id = selectedPostType ?? "";
    if (getSearch != "") {
      data.title = getSearch;
      data.page = 0;
      data.size = sizePerPage;
    } else {
      data.size = sizePerPage;
      data.page = page - 1;
    }

    dispatch(getUnallowedMediaData(data));
  };

  const ChangeMultiStatus = (mediaIds, status) => {
    let data = {};
    data.user_id = props.params.id;
    data.media_category_ids = mediaIds;
    data.status = status == true ? 1 : 0;
    if (getSearch != "") {
      data.title = getSearch;
      data.page = 0;
      data.size = sizePerPage;
    } else {
      data.size = sizePerPage;
      data.page = page - 1;
    }
    let fObj = mediaIds.map((id) => {
      return unAllowedMediaData.find((x) => x.id == id);
    });
    console.log("mded", fObj);

    dispatch(setMultiAssignUnallowedMedia(fObj, status));
    dispatch(multiUnallowedMediaStatusChange(data, toasts, intl));
  };

  useEffect(() => {
    if (is_active == true) {
      getMedias();
    }
  }, [props?.params, is_active]);

  const getMedias = () => {
    let data = {};
    data.user_id = props?.params.id;
    //  data.post_type_id = selectedPostType ?? null;

    dispatch(getAllMediaData(data));
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "post_type.name",
      text: "Post Type",
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "media.name",
      text: "Media",
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),

      formatter: (cell, row) => {
        return (
          <div>
            <span className="switch switch-sm switch-icon switch-primary">
              <label>
                <input
                  onChange={(e) => handleUnallowedMediaChecked(e, row)}
                  type="checkbox"
                  checked={checkIfAssignMediaIsTrue(row) == true ? true : false}
                  name="select"
                />
                <span />
              </label>
            </span>
          </div>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  return (
    <div className="">
      <div className="form-group row">
        <div className="col-lg-3">
          <select
            className="form-control"
            name="type"
            placeholder="Filter by Status"
            onChange={(e) => handlePostTypeChange(e)}
            // onChange={(e) => {
            //   setFilterBy({
            //     ...filterBy,
            //     status: e.target.value == "0" ? 0 : e.target.value,
            //   });
            //   setPage(1);
            // }}
            // value={filterBy.status}
          >
            <option value={""}>All</option>
            {postTypeData?.data?.map((pt) => {
              return <option value={pt?.id}>{pt?.name}</option>;
            })}
          </select>
          <small className="form-text text-muted">
            <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Post Type
          </small>
        </div>
        <div className="col-lg-3">
          <select
            className="form-control"
            name="type"
            placeholder="Filter by Status"
            onChange={(e) => handleMediaTypeChange(e)}
            // onChange={(e) => {
            //   setFilterBy({
            //     ...filterBy,
            //     status: e.target.value == "0" ? 0 : e.target.value,
            //   });
            //   setPage(1);
            // }}
            // value={filterBy.status}
          >
            <option value={""}>All</option>
            {mediaData?.map((md) => {
              return <option value={md?.id}>{md?.name}</option>;
            })}
          </select>
          <small className="form-text text-muted">
            <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Media
          </small>
        </div>
        <SearchTable setSearch={setSearch} />
      </div>
      {allIds.length > 1 && (
        <div className="form-group">
          <p>Selected records count:{allIds.length}</p>
          &nbsp;
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder font-size-sm"
            onClick={() => ChangeMultiStatus(allIds, true)}
          >
            Enable
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder font-size-sm mr-5"
            onClick={() => ChangeMultiStatus(allIds, false)}
          >
            Disable
          </button>
        </div>
      )}
      {umd?.data?.length ? (
        <Table
          // showLoader={showLoader}
          data={tableData}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={getSearch ? setSearchPage : setPage}
          // isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
      ) : (
        <div>
          <Lottie options={defaultOptions} height={200} width={400} />
          <p style={{ display: "flex", justifyContent: "center" }}>
            Please Assign Some Media
          </p>
        </div>
      )}
    </div>
  );
}
