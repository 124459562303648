import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const {
    payload,
    toasts,
    intl,
    id,
    status,
    ids,
    modalClose,
    setAllIds,
  } = action;
  switch (action.type) {
    case "SET_ALL_INSTITUTIONS_ACTIVITITES_DATA":
      return payload;
    case "SET_INSTITUTE_ACTIVITY_STATUS":
      const updatedData = state.data.map((x) =>
        x.id === id ? { ...x, status: status === 1 ? 1 : 0 } : x
      );

      return {
        ...state,
        data: updatedData,
      };
    case "SET_MULTI_ACTIVITIES":
      console.log("std", status);
      const updatedMultiData = state.data.map((x) =>
        ids.includes(x.id) ? { ...x, status: status == true ? 1 : 0 } : x
      );

      // let c = state.data.filter((sd) => {
      //   return ids.includes(sd.id);
      // });
      // let z = c.map((st) => {
      //   return (st.status = 1);
      // });
      // console.log("v", z);

      return {
        ...state,
        data: updatedMultiData,
      };
    case "DELETE_MULTI_ACTIVITY_FROM_REDUX":
      const deleteMulti = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );
      modalClose("#exampleModalDeleteMulti");
      setAllIds([]);
      return {
        ...state,
        data: deleteMulti,
      };

    case "DELETE_ACTIVITY_FROM_REDUX":
      const deleteData = state.data.filter((x) =>
        x.id === payload.id ? false : x
      );
      modalClose("#exampleModalDelete");

      return {
        ...state,
        data: deleteData,
      };

    default:
      return state;
  }
};

const Errors = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_ERRORS_INSTITUTE_ACTIVITY":
      return payload;

    default:
      return state;
  }
};

export const reducer = combineReducers({
  Index,
  Errors,
});
