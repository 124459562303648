import React, { useEffect, useState } from "react";
import { ARHIVA_REST_URL } from "../../../../environments/environment";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Redirect, Switch, Route, NavLink } from "react-router-dom";
import { PersonalInfo } from "./UserFiles/PersonalInfo";
import { AccountInfo } from "./UserFiles/AccountInfo";
import { ChangePassword } from "./UserFiles/ChangePassword";
import { EmailSettings } from "./UserFiles/EmailSettings";
import { AssignMedia } from "./UserFiles/AssignMedia";
import { ManageInstitute } from "./UserFiles/ManageInstitute";
import { useDispatch } from "react-redux";
import { getUserPersonalInfo } from "../../../_redux/users/actions";
import { useSelector } from "react-redux";

export const View = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const userData = useSelector(
    (state) => state?.users?.UserInfo.personal_information ?? []
  );

  const userPersonalInfo = useSelector(
    (state) => state?.users?.UserInfo.personal_information ?? []
  );
  const userAccountInfo = useSelector(
    (state) => state?.users?.UserInfo.account_information ?? []
  );
  const userEmailSettings = useSelector(
    (state) => state?.users?.UserInfo.email_settings
  );

  useEffect(() => {
    setpersonal_information(userPersonalInfo);
  }, []);

  useEffect(() => {
    setaccount_information(userAccountInfo);
  }, []);
  useEffect(() => {
    setemail_settings(userEmailSettings);
  }, []);

  const [routId, setRoutId] = useState("");

  const [personal_information, setpersonal_information] = useState(null);
  const [account_information, setaccount_information] = useState(null);
  const [email_settings, setemail_settings] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setRoutId(props?.match?.params?.id);
  }, [props]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setLoader(true);
    let id = props?.match?.params?.id;
    dispatch(getUserPersonalInfo(id, setLoader));
  };

  const getPersonalInfo = () => {
    return personal_information;
  };

  return (
    <>
      <div className="d-flex flex-row" id="kt_profile_aside">
        <div className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px">
          <div className="card card-custom card-stretch">
            <div className="card-body pt-4">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  <div className="symbol-label"></div>
                  <i className="symbol-badge bg-success"></i>
                </div>
                <div>
                  <div className="font-weight-bolder font-size-h5 text-dark-75">
                    {personal_information?.first_name}{" "}
                    {personal_information?.last_name}
                  </div>
                  <div className="text-muted">
                    {!personal_information?.company
                      ? intl.formatMessage({ id: "USER.PROFILE.TYPE_PERSONAL" })
                      : personal_information?.company}
                  </div>
                </div>
              </div>
              <div className="py-9">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">
                    {intl.formatMessage({ id: "AUTH.INPUT.USERNAME" })}
                  </span>
                  <span className="text-muted">
                    {account_information?.username}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">
                    {intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                  </span>
                  <span className="text-muted">
                    {account_information?.email}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">
                    {intl.formatMessage({ id: "INPUT.MOBILE" })}
                  </span>
                  <span className="text-muted">
                    {personal_information?.mobile}
                  </span>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/admin/user/view/${routId}`}
                    className="navi-link py-4"
                    activeClassName="active"
                    exact
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Layers.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.PROFILE_OVERVIEW",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/admin/user/view/${routId}/info`}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/User.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.PERSONAL_INFORMATIONS",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/admin/user/view/${routId}/account-info`}
                    activeClassName="active"
                    className="navi-link py-4"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Compiling.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.ACCOUNT_INFORMATIONS",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/admin/user/view/${routId}/change-password`}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Shield-user.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.CHANGE_PASSWORD",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/admin/user/view/${routId}/email-settings`}
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-opened.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.EMAIL_SETTINGS",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/admin/user/view/${routId}/assign-media`}
                    activeClassName="active"
                    className="navi-link py-4"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Settings-1.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {/* {intl.formatMessage({
                        id: "MENU.PROFILE.ACCOUNT_INFORMATIONS",
                      })} */}
                      {intl.formatMessage({ id: "Manage" })}{" "}
                      {intl.formatMessage({ id: "MENU.MEDIA" })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to={`/admin/user/view/${routId}/manage-institute`}
                    activeClassName="active"
                    className="navi-link py-4"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Settings-1.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {/* {intl.formatMessage({
                        id: "MENU.PROFILE.ACCOUNT_INFORMATIONS",
                      })} */}
                      {intl.formatMessage({ id: "Manage" })}{" "}
                      {intl.formatMessage({ id: "Institutions" })}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row-fluid ml-lg-8">
          <Switch>
            <Route
              path={`/admin/user/view/${routId}/info`}
              render={() => (
                <PersonalInfo
                  loader={loader}
                  personal_information={personal_information}
                  routId={routId}
                />
              )}
              // component={PersonalInfo}
            />
            <Route
              path={`/admin/user/view/${routId}/account-info`}
              render={() => (
                <AccountInfo
                  loader={loader}
                  routId={routId}
                  account_information={account_information}
                />
              )}
            />
            <Route
              path={`/admin/user/view/${routId}/change-password`}
              render={() => <ChangePassword routId={routId} />}
            />
            <Route
              path={`/admin/user/view/${routId}/email-settings`}
              render={() => (
                <EmailSettings
                  email_settings={email_settings}
                  routId={routId}
                />
              )}
            />
            <Route
              path={`/admin/user/view/${routId}/assign-media`}
              render={() => <AssignMedia loader={loader} routId={routId} />}
            />
            <Route
              path={`/admin/user/view/${routId}/manage-institute`}
              render={() => (
                <ManageInstitute
                  loader={loader}
                  routId={routId}
                  getUserData={() => getUserData()}
                  personal_information={personal_information}
                  getPersonalInfo={() => {
                    getPersonalInfo();
                  }}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};
