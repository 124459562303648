import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "./advanceSearch.css";
import { useDispatch, useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";

import Select from "react-select";
import { useIntl } from "react-intl";

import axios from "axios";
import { ARHIVA_REST_URL } from "../../../../../../environments/environment";
import swal from "sweetalert";
import { search, searchedWordsAction } from "./_redux/SearchRedux";
export const AdvanceSearchModal = (props) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [error, setError] = useState("");
  const [wordsIn, setWordsIn] = useState("");
  const [wordsNotIn, setWordsNotIn] = useState("");
  const [anyWordsIn, setAnyWordsIn] = useState("");
  const [phrase, setPhrase] = useState("");
  const [media, setMedia] = useState([]);
  const [postType, setPostType] = useState([]);
  const [location, setLocation] = useState([]);
  const [author, setAuthor] = useState([]);
  const [sort, setSort] = useState([]);

  const filterLocations = (inputValue) => {
    let location =
      props?.location?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      }) ?? [];

    return location.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  useEffect(() => {
    let inputElm = document.getElementById("allWordsTagify");
    new window.Tagify(inputElm, {
      maxTags: 10,
    });
    let theseInputElm = document.getElementById("theseWordsTagify");
    new window.Tagify(theseInputElm, {
      maxTags: 10,
    });
    let anyInputElm = document.getElementById("anyWordsTagify");
    new window.Tagify(anyInputElm, {
      maxTags: 10,
    });
    let phraseInputElm = document.getElementById("phraseWordsTagify");
    new window.Tagify(phraseInputElm, {
      maxTags: 10,
    });
  }, []);

  const promiseLocationOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterLocations(inputValue));
      }, 1000);
    });

  const filterPost = (inputValue) => {
    let postType =
      props?.postType?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      }) ?? [];

    return postType.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promisePostOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterPost(inputValue));
      }, 1000);
    });

  const filterMedia = (inputValue) => {
    let media =
      props?.media?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      }) ?? [];

    return media.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseMediaOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterMedia(inputValue));
      }, 1000);
    });

  const filterAuthor = (inputValue) => {
    let author =
      props?.author?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      }) ?? [];

    return author.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseAuthorOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterAuthor(inputValue));
      }, 1000);
    });
  const isJson = (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  };
  const _handleSubmit = () => {
    let anyWordArray = isJson(anyWordsIn) ? JSON.parse(anyWordsIn) : [];
    anyWordArray = anyWordArray.map((q) => q.value);
    let phraseWordArray = isJson(phrase) ? JSON.parse(phrase) : [];
    phraseWordArray = phraseWordArray.map((q) => q.value);

    let wordsInArray = isJson(wordsIn) ? JSON.parse(wordsIn) : [];
    wordsInArray = wordsInArray.map((q) => q.value);

    let wordsNotInArray = isJson(wordsNotIn) ? JSON.parse(wordsNotIn) : [];
    wordsNotInArray = wordsNotInArray.map((q) => q.value);

    if (anyWordsIn || wordsIn || phrase) {
      let mediaData =
        media?.map((item) => {
          return item.value;
        }) ?? [];
      let authorData =
        author?.map((item) => {
          return item.value;
        }) ?? [];
      let postTypeData =
        postType?.map((item) => {
          return item.value;
        }) ?? [];
      let locationData =
        location?.map((item) => {
          return item.value;
        }) ?? [];

      let data = {};
      data.words_not_in = wordsNotInArray ? wordsNotInArray : null;
      data.any_word_in = anyWordArray.length ? anyWordArray : [];
      data.word_in = wordsInArray.length ? wordsInArray : [];
      data.phrase = phraseWordArray.length ? phraseWordArray : [];
      data.date_from = startDate;
      data.date_to = endDate;
      data.post_type = postTypeData;
      data.media = mediaData;
      data.author = authorData;
      data.location = locationData;
      data.order_direction = sort.value;
      data.size = 100;
      data.page = 0;
      dispatch(search(data));
      dispatch(
        searchedWordsAction([...anyWordArray, ...wordsInArray, phraseWordArray])
      );
      props.setBool(true);
    } else {
      setError("Please Search Something First.");
    }
  };

  let sorting = [
    { label: "Sort By Ascending", value: "asc" },
    { label: "Sort By Descending", value: "desc" },
  ];

  return (
    <>
      <div>
        {/* <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_1"
        >
          Launch demo modal
        </button> */}
        <div
          className={
            props.showModal === true ? "modal fade show  d-block" : "modal fade"
          }
          tabIndex={-1}
          id="kt_modal_1"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {intl.formatMessage({ id: "advanced_search" })}
                </h5>
                <div
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="svg-icon svg-icon-2x" />
                </div>
              </div>
              <div className="modal-body text-left">
                <div className="row">
                  <div className="col-lg-6">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.ALL_WORDS_TEXT" })}
                    </label>
                    <input
                      value={wordsIn}
                      id="allWordsTagify"
                      className={
                        error ? "form-control is-invalid" : "form-control"
                      }
                      onChange={(e) => {
                        setWordsIn(e.target.value);
                      }}
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.DO_NOT_CONTAIN" })}
                    </label>
                    <input
                      value={wordsNotIn}
                      id="theseWordsTagify"
                      onChange={(e) => {
                        setWordsNotIn(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.ANY_WORD_TEXT" })}
                    </label>
                    <input
                      value={anyWordsIn}
                      id="anyWordsTagify"
                      className={
                        error ? "is-invalid form-control" : "form-control"
                      }
                      onChange={(e) => {
                        setAnyWordsIn(e.target.value);
                      }}
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.EXACT_PHASE" })}
                    </label>
                    <input
                      id="phraseWordsTagify"
                      className={
                        error ? "is-invalid form-control" : "form-control"
                      }
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="form-label">
                      {intl.formatMessage({ id: "MENU.MEDIA" })}
                    </label>
                    <AsyncSelect
                      isMulti
                      onChange={(e) => {
                        setMedia(e);
                      }}
                      cacheOptions
                      defaultOptions
                      loadOptions={promiseMediaOptions}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.PERIOD_FROM" })}
                    </label>

                    <DatePicker
                      className="form-control col-lg-12"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.PERIOD_TO" })}
                    </label>

                    <DatePicker
                      className="form-control col-lg-12"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.SORTING" })}
                    </label>

                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="color"
                      onChange={(e) => {
                        setSort(e);
                      }}
                      options={sorting}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.POST_TYPE" })}
                    </label>

                    <AsyncSelect
                      isMulti
                      cacheOptions
                      onChange={(e) => {
                        setPostType(e);
                      }}
                      defaultOptions
                      loadOptions={promisePostOptions}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.LOCATION" })}
                    </label>

                    <AsyncSelect
                      isMulti
                      onChange={(e) => {
                        setLocation(e);
                      }}
                      cacheOptions
                      defaultOptions
                      loadOptions={promiseLocationOptions}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">
                      {intl.formatMessage({ id: "ADVANCE.AUTHOR" })}
                    </label>

                    <AsyncSelect
                      isMulti
                      onChange={(e) => {
                        setAuthor(e);
                      }}
                      cacheOptions
                      defaultOptions
                      loadOptions={promiseAuthorOptions}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={(e) => props.setShowModal(false)}
                >
                  {intl.formatMessage({ id: "CONTACT.GENERAL.CANCEL.BUTTON" })}
                </button>
                <button
                  onClick={() => {
                    _handleSubmit();
                  }}
                  type="button"
                  className="btn btn-primary"
                >
                  {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        show={props.showModal}
        onHide={() => {
          props.setShowModal(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Advanced Search
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error ? <p className="mb-1 text-danger">{error}</p> : ""}
            <Card.Title className="mb-2">Search</Card.Title>
            <Row className="mb-3">
              <Col column="lg" lg={6} controlId="formGridEmail">
                <Form.Label muted>
                  Search for posts that contain all of these words ...
                </Form.Label>

                <Form.Control
                  value={wordsIn}
                  className={error ? "is-invalid" : ""}
                  onChange={(e) => {
                    setWordsIn(e.target.value);
                  }}
                  type="text"
                  placeholder="some text"
                />
              </Col>
              <Col column="lg" lg={6} controlId="formGridEmail">
                <Form.Label muted>
                  ... and which DO NOT CONTAIN these words
                </Form.Label>

                <Form.Control
                  value={wordsNotIn}
                  onChange={(e) => {
                    setWordsNotIn(e.target.value);
                  }}
                  type="text"
                  placeholder="some text"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col column="lg" lg={6} controlId="formGridEmail">
                <Form.Label muted>
                  ..and containing ANY of these words ...
                </Form.Label>

                <Form.Control
                  value={anyWordsIn}
                  className={error ? "is-invalid" : ""}
                  onChange={(e) => {
                    setAnyWordsIn(e.target.value);
                  }}
                  type="text"
                  placeholder="some text"
                />
              </Col>
              <Col column="lg" lg={6} controlId="formGridEmail">
                <Form.Label muted>Exact phrase</Form.Label>

                <Form.Control
                  type="text"
                  value={phrase}
                  className={error ? "is-invalid" : ""}
                  onChange={(e) => {
                    setPhrase(e.target.value);
                  }}
                  placeholder="some text"
                />
              </Col>
            </Row>
            <Row className="mb-3"></Row>
            <Row className="mb-3"></Row>
            <Card.Title className="mb-2">Filters</Card.Title>
            <Row className="mb-3">
              <Col column="lg" lg={6} controlId="formGridEmail">
                <Form.Label muted>Media</Form.Label>

                <AsyncSelect
                  isMulti
                  onChange={(e) => {
                    setMedia(e);
                  }}
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseMediaOptions}
                />
              </Col>
              <Col column="lg" lg={3} controlId="formGridEmail">
                <Form.Label muted>Period From</Form.Label>
                <br />
                <DatePicker
                  className="form-control col-lg-12"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Col>
              <Col column="lg" lg={3} controlId="formGridEmail">
                <Form.Label muted>Period To</Form.Label>
                <br />
                <DatePicker
                  className="form-control col-lg-12"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </Col>
            </Row>
            <Row className="mb-3"></Row>
            <Row className="mb-3">
              <Col column="lg" lg={6} controlId="formGridEmail">
                <Form.Label muted>Sorting</Form.Label>

                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  name="color"
                  onChange={(e) => {
                    setSort(e);
                  }}
                  options={sorting}
                />
              </Col>
              <Col column="lg" lg={6} controlId="formGridEmail">
                <Form.Label muted>Post Type</Form.Label>

                <AsyncSelect
                  isMulti
                  cacheOptions
                  onChange={(e) => {
                    setPostType(e);
                  }}
                  defaultOptions
                  loadOptions={promisePostOptions}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col column="lg" lg={6} controlId="formGridEmail">
                <Form.Label muted>Location</Form.Label>

                <AsyncSelect
                  isMulti
                  onChange={(e) => {
                    setLocation(e);
                  }}
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseLocationOptions}
                />
              </Col>
              <Col column="lg" lg={6} controlId="formGridEmail">
                <Form.Label muted>Author</Form.Label>

                <AsyncSelect
                  isMulti
                  onChange={(e) => {
                    setAuthor(e);
                  }}
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseAuthorOptions}
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-lg-center">
          <Button
            onClick={() => {
              _handleSubmit();
            }}
            variant="success"
          >
            SEARCH
          </Button>
          <Button variant="secondary">DELETE EVERYTHING</Button>
          <Button
            onClick={() => {
              props.setShowModal(false);
            }}
          >
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
