import moment from "moment";
import 'moment/locale/me';
import 'moment/locale/en-gb';

export function removeCSSClass(ele, cls) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const hasId = (location) => {
    return /\d/.test(location.pathname)
}

export const convertTime = (time, lang) => {
    // if there's no timestamp, exit the function
    if (time == undefined) return null;
    let formatedTime = moment(time).format("DD-MMMM-YYYY HH:mm:ss");
    // change language depending on selected
    if (lang == "me") {
        moment.locale('me');
    }
    // get the difference in hours
    const diff = (moment().unix() - moment().unix(time)) / 3600;
    if (diff > 24) {
        return formatedTime
    }
    let timeSince = moment(time).fromNow();

    return timeSince;
}

export const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};