import React, { useState, forwardRef, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";
import $ from "jquery";
import jQuery from "jquery";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import ProfileService from "../../Profile/service";
import {
  GET_ALL_POSTS_URL,
  GET_POST_TYPES_URL,
  GET_MEDIA_LIST_URL,
  GET_SECTION_LIST_URL,
  a_manage_edit_url,
} from "../../../../_metronic/_helpers/Constants";
import { Table } from "../shared";
import { sortCaret } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import { useHistory } from "react-router-dom";
import DeleteModal from "../shared/DeleteModal";
import MultipleDeleteModal from "../shared/MultipleDeleteModal";
import NotificationsModal from "../shared/NotificationsModal";
import TagModal from "../shared/NotificationsModal";
import { useIntl } from "react-intl";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { render } from "react-dom";
import CustomDatePicker from "../shared/DatePicker/CustomDatePicker";
import { getPostsData } from "../../../_redux/Post/action";
import { useSelector } from "react-redux";

const ManagePage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const postsData = useSelector((state) => state?.posts?.Index ?? []);

  const service = new ProfileService();
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);
  const [categoryDisabled, setCategoryDisabled] = useState(false);

  const [filterBy, setFilterBy] = useState({
    post_type: "All",
    media: "All",
    section: "All",
    created_at: "All",
  });

  // useEffect(() => {
  //   if (filterBy["media"] === "All") {
  //     setCategoryDisabled(true);
  //   } else {
  //     setCategoryDisabled(false);
  //   }
  // }, [filterBy]);

  // let id = window.$("#kt_datepicker_2");
  // useEffect(() => {
  //   id.datepicker();
  // }, [id]);

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });

  const [sizePerPage, setSizePerPage] = useState(20);

  const history = useHistory();

  const [currentPost, setCurrentPost] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isMultipleDeleteModalVisible,
    setMultipleIsDeleteModalVisible,
  ] = useState(false);
  const [
    IsNotificationsModalVisible,
    setIsNotificationsModalVisible,
  ] = useState(false);
  const [IsTagModalVisible, setIsTagModalVisible] = useState(false);
  const [currentPosts, setCurrentPosts] = useState(null);

  const [startDate, setStartDate] = useState(new Date());

  const [dateValue, setDateValue] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const fetchPosts = (page) => {
    // setShowLoader(true);
    // const response = await service.post(GET_ALL_POSTS_URL, {
    //   page: page - 1,
    //   size: sizePerPage,
    //   post_type: filterBy.post_type === "All" ? null : filterBy.post_type,
    //   media: filterBy.media === "All" ? null : filterBy.media,
    //   section: filterBy.section === "All" ? null : filterBy.section,
    //   created_at:
    //     filterBy.created_at === "All" || filterBy.created_at === "Invalid date"
    //       ? null
    //       : filterBy.created_at,
    //   order_by: orderBy.order_by === "" ? null : orderBy.order_by,
    //   order_direction:
    //     orderBy.order_direction === "" ? null : orderBy.order_direction,
    // });
    let payload = {
      page: page - 1,
      size: sizePerPage,
      post_type: filterBy.post_type === "All" ? null : filterBy.post_type,
      media: filterBy.media === "All" ? null : filterBy.media,
      section: filterBy.section === "All" ? null : filterBy.section,
      created_at:
        filterBy.created_at === "All" || filterBy.created_at === "Invalid date"
          ? null
          : filterBy.created_at,
      order_by: orderBy.order_by === "" ? null : orderBy.order_by,
      order_direction:
        orderBy.order_direction === "" ? null : orderBy.order_direction,
    };

    dispatch(getPostsData(payload));

    // setShowLoader(false);
    // return response?.data;
  };

  // useEffect(() => {
  //   searchByCreatedDate(dateValue);
  // }, [dateValue]);

  // const { isLoading, isFetching, data } = useQuery(
  //   ["posts", page, filterBy, orderBy, sizePerPage],
  //   () => fetchPosts(page),
  //   {
  //     keepPreviousData: true,
  //   }
  // );

  useEffect(() => {
    fetchPosts(page);
  }, ["posts", page, filterBy, orderBy, sizePerPage]);

  const fetchPostTypes = async () => {
    const response = await service.post(GET_POST_TYPES_URL);
    return response?.data;
  };

  const postTypes = useQuery(["postTypes"], () => fetchPostTypes(), {
    keepPreviousData: true,
  });

  const fetchMediaTypes = async () => {
    const response = await service.post(GET_MEDIA_LIST_URL, {
      post_type: filterBy.post_type === "All" ? null : filterBy.post_type,
    });
    return response.data;
  };

  const mediaTypes = useQuery(
    ["mediaTypes", filterBy.post_type],
    () => fetchMediaTypes(),
    {
      keepPreviousData: true,
    }
  );

  const fetchCategories = async () => {
    const response = await service.post(GET_SECTION_LIST_URL, {
      media: filterBy.media === "All" ? null : filterBy.media,
      type: filterBy.post_type === "All" ? null : filterBy.post_type,
    });
    return response.data;
  };

  const categories = useQuery(
    ["categories", filterBy],
    () => fetchCategories(),
    {
      keepPreviousData: true,
    }
  );

  const onSort = (field, order) => {
    const sortField = (field) => {
      switch (field) {
        case "media.name":
          return "medij_id";
        case "section.name":
          return "rubrika_id";
        case "type.name":
          return "type.id";
        default:
          return field;
      }
    };
    setOrderBy({
      order_by: sortField,
      order_direction: order,
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "title",
      text: intl.formatMessage({ id: "TH_TITLE" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_manage_edit_url}/${row.id}`);
        },
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "media.name",
      text: intl.formatMessage({ id: "TH_MEDIA" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "section.name",
      text: intl.formatMessage({ id: "TH_SECTION" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "type.name",
      text: intl.formatMessage({ id: "TH_TYPE_NAME" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_manage_edit_url}/${row.id}`);
        },
        openDeleteModal: (row) => {
          setCurrentPost(row);
          setIsDeleteModalVisible(true);
        },
        isManagePage: true,
        openNotificationsModal: (row) => {
          setCurrentPost(row);
          setIsNotificationsModalVisible(true);
        },
        // openTagModal: (row) => {
        //   setCurrentPost(row);
        //   setIsTagModalVisible(true);
        // },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  // if (isLoading) return null;
  const paginationOptions = {
    totalSize: postsData?.totalCount ?? 0,
    sizePerPage,
    page,
  };

  const deleteSelectedItems = (selectedIds) => {
    setMultipleIsDeleteModalVisible(true);
    setCurrentPosts(selectedIds);
  };

  const searchByCreatedDate = (date) => {
    // setStartDate(date);
    setFilterBy({
      ...filterBy,
      created_at: moment(date).format("YYYY-MM-DD"),
    });
  };

  return (
    <Card>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        currentItem={currentPost}
        setCurrentItem={setCurrentPost}
        itemType="posts"
      />
      <MultipleDeleteModal
        isVisible={isMultipleDeleteModalVisible}
        setIsVisible={setMultipleIsDeleteModalVisible}
        currentItems={currentPosts}
        setCurrentItems={setCurrentPosts}
        itemType="posts"
        setAllIds={setAllIds}
      />
      <NotificationsModal
        isVisible={IsNotificationsModalVisible}
        setIsVisible={setIsNotificationsModalVisible}
        currentItem={currentPost}
        setCurrentItem={setCurrentPost}
      />
      <TagModal
        isVisible={IsTagModalVisible}
        setIsVisible={setIsTagModalVisible}
        currentItem={currentPost}
        setCurrentItem={setCurrentPost}
      />
      <CardHeader title={intl.formatMessage({ id: "Post" })} sticky>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary ml-5"
            onClick={() => {
              history.push(`${a_manage_edit_url}`);
            }}
          >
            {intl.formatMessage({ id: "MENU.NEW" })}{" "}
            {intl.formatMessage({ id: "Post" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-lg-2">
            {!postTypes.isLoading && (
              <select
                className="form-control"
                name="type"
                placeholder="Filter by Types"
                onChange={(e) => {
                  setFilterBy({
                    ...filterBy,
                    post_type: e.target.value,
                  });
                  setPage(1);
                }}
                value={filterBy.post_type}
              >
                <option value={"All"}>All</option>
                <option value={"Elektronski"}>Elektronski</option>
                <option value={"Štampani"}>Štampani</option>
                <option value={"Internet"}>Internet</option>
                <option value={"Social"}>Social</option>
              </select>
            )}
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              {intl.formatMessage({ id: "TH_TYPE" })}
            </small>
          </div>
          <div className="col-lg-2">
            {!mediaTypes.isLoading && (
              <>
                <select
                  className="form-control"
                  name="media"
                  placeholder="Filter by Media"
                  onChange={(e) => {
                    setFilterBy({
                      ...filterBy,
                      media: e.target.value,
                    });
                    setPage(1);
                  }}
                  value={filterBy.media}
                >
                  <option value={"All"}>All</option>
                  {mediaTypes.data.data.map((media) => (
                    <option key={media.id} value={media.id}>
                      {media.name}
                    </option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
                  {intl.formatMessage({ id: "MENU.MEDIA" })}
                </small>
              </>
            )}
          </div>
          <div className="col-lg-2">
            {!categories.isLoading && (
              <>
                <select
                  className="form-control"
                  name="category"
                  placeholder="Filter by Category"
                  onChange={(e) => {
                    setFilterBy({
                      ...filterBy,
                      section_id: e.target.value,
                    });
                    setPage(1);
                  }}
                  value={filterBy.section_id}
                  disabled={categoryDisabled}
                >
                  <option value={"All"}>All</option>
                  {categories?.data?.data?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
                  {intl.formatMessage({ id: "MENU.CATEGORY" })}
                </small>
              </>
            )}
          </div>
          <div className="col-lg-3">
            <CustomDatePicker
              id={"postDate"}
              dateValue={dateValue}
              setDateValue={setDateValue}
            />
          </div>
        </div>
        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
          </div>
        )}

        <Table
          showLoader={showLoader}
          data={postsData?.data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={setPage}
          // isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
      </CardBody>
    </Card>
  );
};

export default ManagePage;
