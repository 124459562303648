import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, toasts, intl, id, ids } = action;
  switch (action.type) {
    case "SET_ALL_POLICIES_DATA":
      return payload;
    case "DELETE_POLICY_FROM_REDUX":
      const deleteData = state.data.filter((x) => (x.id === id ? false : x));
      return {
        ...state,
        data: deleteData,
      };
    case "DELETE_MULTI_POLICY_FROM_REDUX":
      const deleteMultiData = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );

      //   setAllIds([]);
      return {
        ...state,
        data: deleteMultiData,
      };
    default:
      return state;
  }
};

const sectionsIndex = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_SECTIONS_DATA":
      return payload;
    default:
      return state;
  }
};

const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  Index,
  sectionsIndex,
  showLoader,
});
