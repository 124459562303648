import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";
import { useFormik } from "formik";

import {
  Input,
  Select,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  EDIT_POST_TYPE_URL,
  SAVE_POST_TYPE_URL,
  GET_POST_TYPES_URL,
  a_post_type_url,
} from "../../../../_metronic/_helpers/Constants";
import ProfileService from "../../Profile/service";
import { createPostType, editPostType } from "../../../_redux/PostType/actions";

const EditSchema = Yup.object().shape({
  name: Yup.string().required(),
});

const PostTypeEditPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [currentPostType, setCurrentPostType] = useState(null);
  const history = useHistory();
  const service = new ProfileService();
  const [error, setError] = useState("");

  // useEffect(() => {
  //   window.$("[data-switch=true]").bootstrapSwitch();
  //   // KTBootstrapSwitch.init();
  // }, []);

  const params = useParams();
  useEffect(() => {
    const fetchPostType = async () => {
      if (!params.id) return;
      try {
        const response = await service.post(GET_POST_TYPES_URL, {
          id: params.id,
          page: 0,
          size: 20,
        });
        setCurrentPostType(response.data.data[0]);
      } catch (error) {}
    };

    fetchPostType();
  }, [params]);
  const formik = useFormik({
    initialValues: {
      name: currentPostType ? currentPostType.name : "",
      is_public: currentPostType
        ? currentPostType.is_public
          ? true
          : false
        : false,
      order_by: currentPostType ? currentPostType.order_by : "",
    },
    // validationSchema: { EditSchema },
    enableReinitialize: true,

    onSubmit: async (values) => {
      if (![undefined, null, ""].includes(values.name)) {
        try {
          await mutation.mutateAsync(values);
          history.push(a_post_type_url);
        } catch (error) {
          console.error(error);
        }
      } else {
        setError("name field is required");
      }
    },
  });

  useEffect(() => {
    if (formik) {
      window.$("#is_public").bootstrapSwitch();
      window
        .$("#is_public")
        .on("switchChange.bootstrapSwitch", function(event, state) {
          formik.setFieldValue("is_public", state);
        });
    }
  }, [formik]);

  // const savePostType = async (values) => {
  //   const id = +params.id || null;
  //   const payload = id ? { ...values, id } : { ...values };
  //   return await service.post(
  //     id ? EDIT_POST_TYPE_URL : SAVE_POST_TYPE_URL,
  //     payload
  //   );
  // };

  const savePostType = (values) => {
    const id = +params.id || null;
    const payload = id ? { ...values, id } : { ...values };
    id
      ? dispatch(editPostType(payload, intl, toasts))
      : dispatch(createPostType(payload, intl, toasts));
  };

  const mutation = useMutation(savePostType, {
    onSuccess: (data) => {
      dispatch(
        toasts.showToast(
          data.data.status,
          intl.formatMessage({ id: data.data.title })
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(toasts.showToast(500, error?.response?.data?.message));
      dispatch(toasts.hideToast());
    },
  });

  const backToPostTypeList = () => {
    history.push(a_post_type_url);
  };
  const btnRef = useRef();

  const savePostTypeClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  if (params.id && !currentPostType) {
    return null;
  }

  // const initialValues = {
  //   name: currentPostType ? currentPostType.name : "",
  // };

  return (
    <Card>
      <CardHeader
        title={
          params.id
            ? intl.formatMessage({ id: "MENU.OPTIONS.EDIT" }) +
              " " +
              intl.formatMessage({ id: "ADVANCE.POST_TYPE" })
            : intl.formatMessage({ id: "Add_new" }) +
              " " +
              intl.formatMessage({ id: "ADVANCE.POST_TYPE" })
        }
      >
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToPostTypeList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={savePostTypeClick}
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {/* <Formik
            initialValues={initialValues}
            validationSchema={EditSchema}
            enableReinitialize
            onSubmit={async (values) => {
              try {
                await mutation.mutateAsync(values);
                history.push(a_post_type_url);
              } catch (error) {
                console.error(error);
              }
            }}
          >
            {({ handleSubmit, values, setFieldValue, errors }) => ( */}
          <>
            <form
              onSubmit={formik.handleSubmit}
              className="form form-label-right"
            >
              <div className="form-group ">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      component={Input}
                      label="Name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      withFeedbackLabel={false}
                    />
                    <div class="text-danger">{error}</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Order</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Order number"
                      name="order_by"
                      component={Input}
                      label="order_by"
                      onChange={formik.handleChange}
                      value={formik.values.order_by}
                      withFeedbackLabel={false}
                    />
                    <div class="text-danger">{error}</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <label className="col-form-label ">
                      {intl.formatMessage({ id: "Is_Public" })}
                    </label>
                    <div className="">
                      <input
                        checked={currentPostType?.is_public}
                        data-switch="true"
                        type="checkbox"
                        data-on-text="Yes"
                        data-handle-width="50"
                        data-off-text="No"
                        data-on-color="primary"
                        id="is_public"
                        name="is_public"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                // onSubmit={() => handleSubmit()}
              ></button>
            </form>
          </>
          {/* )}
          </Formik> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default PostTypeEditPage;
