export const getAllCategories = (payload) => {
  return {
    type: "GET_ALL_CATEGORIES",
    payload: payload,
  };
};

export const setAllCategoriesData = (payload) => {
  return {
    type: "SET_ALL_CATEGORIES_DATA",
    payload: payload,
  };
};

export const editCategoryAction = (payload, intl, toasts, history) => {
  return {
    type: "EDIT_CATEGORY",
    payload: payload,
    intl: intl,
    toasts: toasts,
    history: history,
  };
};

export const saveCategoryAction = (payload, intl, toasts, history) => {
  return {
    type: "SAVE_CATEGORY",
    payload: payload,
    intl: intl,
    toasts: toasts,
    history: history,
  };
};

export const deleteCategoryAction = (id, intl, toasts) => {
  return {
    type: "DELETE_CATEGORY",
    id: id,
    intl: intl,
    toasts: toasts,
  };
};

export const deleteCategoryFromRedux = (id) => {
  return {
    type: "DELETE_CATEGORY_FROM_REDUX",
    id: id,
  };
};

export const multiDeleteCategory = (payload, intl, toasts) => {
  return {
    type: "DELETE_MULTI_CATEGORY",
    payload: payload,
    intl: intl,
    toasts: toasts,
  };
};

export const multiDeleteCategoriesFromRedux = (ids) => {
  return {
    type: "MULTI_DELETE_CATEGORIES_FROM_REDUX",
    ids: ids,
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});
