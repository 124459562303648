import React, { useState, useEffect } from "react";
import { Nav, Col, Tab, Accordion, Row } from "react-bootstrap";
import CustomTab from "./CustomTab";
import CustomTabContent from "./CustomTabContent";

const CustomTabContainer = ({ data }) => {
  const [activeKey, setActiveKey] = useState(0);
  const [activeTab, setActiveTab] = useState(data?.navItemsData[0]?.id);

  return (
    <Tab.Container
      id="left-tabs-example"
      transition={false}
      defaultActiveKey={activeTab}
      onSelect={setActiveTab}
    >
      <Row>
        <Col lg={3}>
          <Nav className="navi navi-link-rounded navi-accent navi-hover navi-active nav flex-column mb-8 mb-lg-0">
            <Nav.Item
              className="navi-item mb-2"
              onClick={() => setActiveKey(0)}
            >
              {data?.navItemsData?.map((item, i) => {
                return (
                  <CustomTab eventKey={item.id} item={item.name} key={i} />
                );
              })}
            </Nav.Item>
          </Nav>
        </Col>
        <Col lg={9}>
          <Tab.Content>
            {data.accordionData.map((item, i) => {
              return (
                <Tab.Pane eventKey={item.category_id} key={i}>
                  <Accordion
                    className="accordion-light accordion-light-borderless accordion-svg-toggle"
                    activeKey={activeKey}
                    onSelect={setActiveKey}
                  >
                    <CustomTabContent
                      eventKey={item.id}
                      item={item}
                      activeKey={activeKey}
                      key={i}
                    />
                  </Accordion>
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default CustomTabContainer;
