import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { error404 } from "./error404";

export default function ErrorsPage() {
  return (
    <Switch>
      <Redirect from="/error" exact={true} to="/error/404" />
      <Route path="/error/404" component={error404} />
    </Switch>
  );
}
