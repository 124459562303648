import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CustomDatePicker from "../../shared/DatePicker/CustomDatePicker";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  getUserSystemSettings,
  saveEditSystemSettings,
  updatedEditUserProfile,
  updateSystemSettings,
} from "../../../../_redux/users/actions";
import CustomDateTimePicker from "../../shared/DatePicker/CustomDateTimePicker";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import moment from "moment";

export default function System(props) {
  const intl = useIntl();
  const history = useHistory();

  const dispatch = useDispatch();

  const systemSettingsInfo = useSelector(
    (state) => state?.users?.userSystemSettings ?? {}
  );

  const [language, setLanguage] = useState("");
  const [belonging, setBeloning] = useState("");
  const [time_zone, setTime_zone] = useState(systemSettingsInfo?.timezone);
  const [active_from, setActive_From] = useState(
    systemSettingsInfo?.active_from
  );
  const [active_to, setActive_to] = useState(systemSettingsInfo?.active_to);
  const [front_page, setFront_page] = useState("");
  const [info_access_from, setInfo_access_from] = useState(
    systemSettingsInfo?.information_access_from
  );
  const [info_access_to, setInfo_access_to] = useState(
    systemSettingsInfo?.information_access_to
  );

  const [is_active, setIs_active] = useState(false);

  useEffect(() => {
    setIs_active(props?.renderCall);
  }, [props?.renderCall]);

  useEffect(() => {
    if (is_active) {
      let user_id = props?.params;
      dispatch(getUserSystemSettings(user_id));
    }
  }, [props?.params, is_active]);

  useEffect(() => {
    if (!props.loader) {
      document.getElementById("timezoneId").value =
        systemSettingsInfo?.timezone;
    }
  }, [systemSettingsInfo?.timezone]);

  const handleTimeZone = () => {
    if (!props.loader) {
      let tz = document.getElementById("timezoneId").value;
      setTime_zone(tz);
    }
  };

  useEffect(() => {
    dispatch(updateSystemSettings("timezone", time_zone));
  }, [time_zone]);

  const handleFrontPageChange = () => {
    if (!props.loader) {
      let fp = document.getElementById("front_page").value;
      setFront_page(fp);
    }
  };

  useEffect(() => {
    dispatch(updateSystemSettings("active_from", active_from));
  }, [active_from]);

  useEffect(() => {
    dispatch(updateSystemSettings("active_to", active_to));
  }, [active_to]);

  useEffect(() => {
    dispatch(updateSystemSettings("front_page", front_page));
  }, [front_page]);

  useEffect(() => {
    dispatch(updateSystemSettings("information_access_from", info_access_from));
  }, [info_access_from]);

  useEffect(() => {
    dispatch(updateSystemSettings("information_access_to", info_access_to));
  }, [info_access_to]);

  const handleLanguageChange = () => {
    if (!props.loader) {
      let id = document.getElementById("lang").value;
      dispatch(updateSystemSettings("language", id));
    }
  };

  const handleBelonging = () => {
    if (!props.loader) {
      let id = document.getElementById("belonging").value;
      dispatch(updateSystemSettings("belonging", id));
    }
  };

  const handleSystemSubmit = () => {
    let data = {};

    data = systemSettingsInfo;
    data.user_id = props?.params?.id;
    let activeDateFrom = moment
      .utc(data.active_from)
      .format("YYYY-MM-DD  HH:mm:ss ");
    data.active_from = activeDateFrom;

    let activeDateTo = moment
      .utc(data.active_to)
      .format("YYYY-MM-DD  HH:mm:ss ");
    data.active_to = activeDateTo;

    let infoAccessFromDate = moment
      .utc(data.information_access_from)
      .format("YYYY-MM-DD ");
    data.information_access_from = infoAccessFromDate;

    let infoAccessToDate = moment
      .utc(data.information_access_to)
      .format("YYYY-MM-DD");
    data.information_access_to = infoAccessToDate;
    Object.keys(data).forEach((key) => {
      if (data[key] === "Invalid date") {
        delete data[key];
      }
    });
    dispatch(saveEditSystemSettings(data, toasts, intl));
  };

  return (
    <>
      {props.loader == false ? (
        <form action="#" className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "Language" })}</label>
              <select
                onChange={() => handleLanguageChange()}
                className="form-control form-control "
                id="lang"
                name="manufacture"
              >
                <option
                  value="me"
                  selected={systemSettingsInfo?.language == "me" ? true : false}
                >
                  Mentonegro
                </option>
                <option
                  value="en"
                  selected={systemSettingsInfo?.language == "en" ? true : false}
                >
                  English
                </option>
              </select>
            </div>
            <div className="col-lg-4">
              <label>{intl.formatMessage({ id: "Time_Zone" })}</label>
              <select
                id="timezoneId"
                onChange={() => handleTimeZone()}
                placeholder="Select Timezone"
                className="form-control form-control-lg form-control-solid"
              >
                <option
                  data-offset={-39600}
                  value="International Date Line West"
                >
                  (GMT-11:00) International Date Line West
                </option>
                <option value="International Date Line West">
                  (GMT-11:00) International Date Line West
                </option>
                <option value="Midway Island">(GMT-11:00) Midway Island</option>

                <option value="Central America">
                  (GMT-06:00) Central America
                </option>
                <option value="Central Time (US &amp; Canada)">
                  (GMT-05:00) Central Time (US &amp; Canada)
                </option>
                <option value="Eastern Time (US &amp; Canada)">
                  (GMT-04:00) Eastern Time (US &amp; Canada)
                </option>
                <option value="Indiana (East)">
                  (GMT-04:00) Indiana (East)
                </option>

                <option value="Georgetown">(GMT-04:00) Georgetown</option>

                <option value="Buenos Aires">(GMT-03:00) Buenos Aires</option>
                <option value="Newfoundland">(GMT-02:30) Newfoundland</option>
                <option value="Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
                <option value="Cape Verde Is.">
                  (GMT-01:00) Cape Verde Is.
                </option>
                <option value="UTC">(GMT) UTC</option>
                <option value="Dublin">(GMT+01:00) Dublin</option>
                <option value="Edinburgh">(GMT+01:00) Edinburgh</option>
                <option value="London">(GMT+01:00) London</option>
                <option value="Prague">(GMT+02:00) Prague</option>
                <option value="Helsinki">(GMT+03:00) Helsinki</option>
                <option value="Abu Dhabi">(GMT+04:00) Abu Dhabi</option>
                <option value="Tehran">(GMT+04:30) Tehran</option>
                <option value="Islamabad">(GMT+05:00) Islamabad</option>
                <option value="Karachi">(GMT+05:00) Karachi</option>
                <option value="Tashkent">(GMT+05:00) Tashkent</option>
                <option value="Kathmandu">(GMT+05:45) Kathmandu</option>
                <option value="Astana">(GMT+06:00) Astana</option>
              </select>
            </div>
            {/* Arhimed PC Both Represent AiR */}
            <div className="col-lg-4">
              <label>Belonging</label>
              <select
                onChange={() => handleBelonging()}
                className="form-control form-control "
                id="belonging"
                name="manufacture"
              >
                <option
                  value="PC"
                  selected={
                    systemSettingsInfo?.belonging == "PC" ? true : false
                  }
                >
                  PC
                </option>
                <option
                  value="Arhimed"
                  selected={
                    systemSettingsInfo?.belonging == "Arhimed" ? true : false
                  }
                >
                  Arhimed
                </option>
                <option
                  value="Both"
                  selected={
                    systemSettingsInfo?.belonging == "Both" ? true : false
                  }
                >
                  Both
                </option>
                <option
                  value="AiR"
                  selected={
                    systemSettingsInfo?.belonging == "Air" ? true : false
                  }
                >
                  AiR
                </option>
              </select>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-4">
              <label>Active From</label>
              {/* <CustomDatePicker
              dateValue={active_from}
              id={"acttive_from_system"}
              setDateValue={setActive_From}
            /> */}
              <CustomDateTimePicker
                id={"acttive_from_system"}
                setDateValue={setActive_From}
                dateValue={systemSettingsInfo?.active_from}
              />
            </div>
            <div className="col-lg-4">
              <label>Active To</label>
              {/* <CustomDatePicker
              dateValue={active_to}
              id={"active_to_system"}
              setDateValue={setActive_to}
            /> */}
              <CustomDateTimePicker
                id={"active_to_system"}
                setDateValue={setActive_to}
                dateValue={systemSettingsInfo?.active_to}
              />
            </div>
            <div className="col-lg-4">
              <label>Front Page</label>
              <select
                onChange={() => handleFrontPageChange()}
                id="front_page"
                className="form-control form-control "
              >
                <option
                  selected={
                    systemSettingsInfo?.front_page == "dashboard" ? true : false
                  }
                  value="dashboard"
                >
                  Dashboard
                </option>
                <option
                  selected={
                    systemSettingsInfo?.front_page == "home" ? true : false
                  }
                  value="home"
                >
                  Home
                </option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4">
              <label>Information Access From</label>
              {/* <CustomDatePicker
              dateValue={info_access_from}
              id={"info_access_from"}
              setDateValue={setInfo_access_from}
            /> */}
              <CustomDateTimePicker
                id={"info_access_from"}
                setDateValue={setInfo_access_from}
                dateValue={systemSettingsInfo?.information_access_from}
              />
              {/* <select className="form-control form-control " name="manufacture">
              <option value="Pontiac">12/18/20</option>
            </select> */}
            </div>
            <div className="col-lg-4">
              <label>Information Access To</label>
              {/* <CustomDatePicker
              dateValue={info_access_to}
              id={"info_access_to"}
              setDateValue={setInfo_access_to}
            /> */}
              <CustomDateTimePicker
                id={"info_access_to"}
                setDateValue={setInfo_access_to}
                dateValue={systemSettingsInfo?.information_access_to}
              />
            </div>
          </div>
          <div className="form-group row">
            <label class="col-4 col-form-label">Communictions</label>
            <div className="col-4 col-form-label">
              <div className="checkbox-inline">
                <label className="checkbox checkbox-primary">
                  <input
                    checked={systemSettingsInfo?.sms_communication}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "sms_communication",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                    type="checkbox"
                    name="Checkboxes12"
                  />
                  <span />
                  SMS Communication
                </label>
                <label className="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.email_communication}
                    onChange={
                      (e) =>
                        dispatch(
                          updateSystemSettings(
                            "email_communication",
                            e.target.checked ? 1 : 0
                          )
                        )
                    }
                  />
                  <span />
                  Email Commmunication
                </label>
                <label className="checkbox checkbox-primary ">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.viber_communication}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "viber_communication",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Viber Commmunication
                </label>
              </div>
            </div>
            <div className="col-4 col-form-label">
              <div className="checkbox-inline">
                <label className="checkbox checkbox-primary ">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.phone_communication}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "phone_communication",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Phone Commmunication
                </label>
                <label className="checkbox checkbox-primary ">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.email_notification}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "email_notification",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Email Notification
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label class="col-4 col-form-label">Access</label>

            <div className="col-2 col-form-label">
              <div className="checkbox-inline">
                <label className="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.access_admin}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "access_admin",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Access Admin
                </label>
                <label className="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.access_manage}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "access_manage",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Access Manage
                </label>
                <label className="checkbox checkbox-primary ">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.access_arhiva}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "access_arhiva",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Access Arhiva
                </label>
              </div>
            </div>
            <div className="col-2 col-form-label">
              <label className="checkbox checkbox-primary ">
                <input
                  type="checkbox"
                  name="Checkboxes12"
                  checked={systemSettingsInfo?.access_reklame}
                  onChange={(e) =>
                    dispatch(
                      updateSystemSettings(
                        "access_reklame",
                        e.target.checked ? 1 : 0
                      )
                    )
                  }
                />
                <span />
                Access Reklame
              </label>
              <label className="checkbox checkbox-primary">
                <input
                  type="checkbox"
                  name="Checkboxes12"
                  checked={systemSettingsInfo?.access_clipping}
                  onChange={(e) =>
                    dispatch(
                      updateSystemSettings(
                        "access_clipping",
                        e.target.checked ? 1 : 0
                      )
                    )
                  }
                />
                <span />
                Access Clipping
              </label>
              <label className="checkbox checkbox-primary">
                <input
                  type="checkbox"
                  name="Checkboxes12"
                  checked={systemSettingsInfo?.access_social}
                  onChange={(e) =>
                    dispatch(
                      updateSystemSettings(
                        "access_social",
                        e.target.checked ? 1 : 0
                      )
                    )
                  }
                />
                <span />
                Access Social
              </label>
              <label className="checkbox checkbox-primary">
                <input
                  type="checkbox"
                  name="Checkboxes12"
                  checked={systemSettingsInfo?.access_monitoring}
                  onChange={(e) =>
                    dispatch(
                      updateSystemSettings(
                        "access_monitoring",
                        e.target.checked ? 1 : 0
                      )
                    )
                  }
                />
                <span />
                Access Monitoring
              </label>
            </div>
            <div className="col-2 col-form-label">
              <div className="checkbox-inline">
                <label className="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.access_translation}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "access_translation",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Access Translation
                </label>
                <label className="checkbox checkbox-primary ">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.access_video}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "access_video",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Access Video
                </label>
              </div>
            </div>
            <div className="col-2 col-form-label">
              <label className="checkbox checkbox-primary ">
                <input
                  type="checkbox"
                  name="Checkboxes12"
                  checked={systemSettingsInfo?.access_ms}
                  onChange={(e) =>
                    dispatch(
                      updateSystemSettings(
                        "access_ms",
                        e.target.checked ? 1 : 0
                      )
                    )
                  }
                />
                <span />
                Access Ms
              </label>
              <label className="checkbox checkbox-primary ">
                <input
                  type="checkbox"
                  n
                  ame="Checkboxes12"
                  checked={systemSettingsInfo?.access_generation}
                  onChange={(e) =>
                    dispatch(
                      updateSystemSettings(
                        "access_generation",
                        e.target.checked ? 1 : 0
                      )
                    )
                  }
                />
                <span />
                Access Generation
              </label>
            </div>
          </div>
          <div className="form-group row">
            <label class="col-4 col-form-label">Other</label>

            <div className="col-8 col-form-label">
              <div className="checkbox-inline">
                <label className="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.crop_view}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "crop_view",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Crop View
                </label>
                <label className="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.translation}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "translation",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Translation
                </label>
                <label className="checkbox checkbox-primary">
                  <input
                    type="checkbox"
                    name="Checkboxes12"
                    checked={systemSettingsInfo?.clipping}
                    onChange={(e) =>
                      dispatch(
                        updateSystemSettings(
                          "clipping",
                          e.target.checked ? 1 : 0
                        )
                      )
                    }
                  />
                  <span />
                  Clipping
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row p-2 d-flex flex-row-reverse">
            <button
              onClick={() => handleSystemSubmit()}
              className="btn btn-primary ml-2 "
              type="button"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => history.push(`${"/admin/users"}`)}
            >
              <i className="fa fa-arrow-left" />
              Back
            </button>{" "}
          </div>

         
        </form>
      ) : (
        <>
          <div
            className="wao"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <div className="spinner spinner-lg"></div>
          </div>
        </>
      )}
    </>
  );
}
