import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, ids } = action;
  switch (action.type) {
    case "SET_POST_DATA":
      return payload;
    case "DELETE_POST_FROM_REDUX":
      const deleteData = state.data.filter((x) =>
        x.id === payload ? false : x
      );
      return {
        ...state,
        data: deleteData,
      };
    case "MULTI_POST_DELETE_FROM_REDUX":
      const deleteMultiData = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );

      //   setAllIds([]);
      return {
        ...state,
        data: deleteMultiData,
      };
    default:
      return state;
  }
};

export const reducer = combineReducers({
  Index,
});
