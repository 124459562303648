import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";
import { GET_ALL_USERS } from "../../../_metronic/_helpers/Constants";

export const changeUserStatusCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/verification`, data);
};

export const getAllUsersCall = (actions) => {
  let data = actions.payload;
  return axios.post(`${ARHIVA_REST_URL}api/users`, data);
};

export const createUserCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/create`, data);
};

export const updateUserCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/edit/${data?.id}`, data);
};

export const assignChannelToUserCall = (data, activeUserId) => {
  return axios.post(
    `${ARHIVA_REST_URL}api/users/${activeUserId}/assign-channels`,
    data
  );
};

export const deleteuserCall = (id) => {
  let data = {};
  data.id = id;
  return axios.post(`${ARHIVA_REST_URL}api/user/delete`, data);
};

export const getUserCall = (id) => {
  let data = {};
  data.id = id;
  return axios.post(`${GET_ALL_USERS}`, data);
};

export const getUsersRoleCall = () => {
  return axios.post(`${ARHIVA_REST_URL}api/roles`);
};

export const getProfileInfoCall = (id) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/profile/${id}`);
};

export const editPersonalInfoCall = (id, data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/edit/${id}`, data);
};

export const editAccountInfoCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/account-information`, data);
};

export const getUserRoleById = (id) =>
  axios.post(`${ARHIVA_REST_URL}api/user/${id}/roles`);

export const editEmailSettingsCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/email-settings`, data);
};

export const assignUserMediaCall = (id, data) => {
  return axios.post(`${ARHIVA_REST_URL}api/users/${id}/assign-media`, data);
};

export const assignUserUnallowedMediaCall = (id, data) => {
  data.user_id = id;
  return axios.post(
    `${ARHIVA_REST_URL}api/users/unallowed-media-categories`,
    data
  );
};

export const getAllMediaDataCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/get-all-media`, data);
};

export const changeMediaStatusCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/media/status`, data);
};

export const changeUnallowedMediaStatusCall = (data) => {
  return axios.post(
    `${ARHIVA_REST_URL}api/users/unallowed-media-categories/status`,
    data
  );
};

export const changeMultiMediaStatusCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/multi-media/status`, data);
};

export const changeMultiUnallowedMediaStatusCall = (data) => {
  return axios.post(
    `${ARHIVA_REST_URL}api/users/unallowed-media-categories/status`,
    data
  );
};

export const getUnallowedMediaDataCall = (data) => {
  return axios.post(
    `${ARHIVA_REST_URL}api/users/get/unallowed-media-categories`,
    data
  );
};

export const changeUserPasswordCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/change-password`, data);
};

export const updateUserSettingsCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/update/settings`, data);
};

export const getUserAccountSettingsCall = (user_id) => {
  let data = {};
  data.user_id = user_id;
  return axios.post(`${ARHIVA_REST_URL}api/user/account-information/get`, data);
};

export const saveEditAccountSettingsCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/account-information`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getUserSystemSettingsCall = (user_id) => {
  let data = {};
  data.user_id = user_id;
  return axios.post(`${ARHIVA_REST_URL}api/user/system-settings/get`, data);
};

export const attemptSaveEditSystemSettingsCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/user/system-settings`, data);
};
