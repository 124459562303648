import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { Modal, Button } from "react-bootstrap";

import { PUBLISH_MULTIPLE_POLICY_URL, PUBLISH_MULTIPLE_USER_URL, PUBLISH_MULTIPLE_CRAWLER_URL } from "../../../../../_metronic/_helpers/Constants";
import ProfileService from "../../../Profile/service";
import { useDispatch } from "react-redux";
import * as toasts from "../../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from 'react-intl';

const PublishModal = ({
  isVisible,
  setIsVisible,
  currentItems,
  setCurrentItems,
  publishType,
  itemType
}) => {
  const service = new ProfileService();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const intl = useIntl();

  const saveItems = async () => {
    // eslint-disable-next-line default-case
    switch (itemType) {
      case "users":
        return await service.post(PUBLISH_MULTIPLE_USER_URL, { status: publishType, ids: currentItems});
      case "policy":
        return await service.post(PUBLISH_MULTIPLE_POLICY_URL, { status: publishType, ids: currentItems});
      case "crawlers":
        return await service.post(PUBLISH_MULTIPLE_CRAWLER_URL, { status: publishType, ids: currentItems});
    } 
  };

  const mutation = useMutation(saveItems, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(itemType);
      dispatch(
        toasts.showToast(
          data.data.status,
          intl.formatMessage(
            { id: data.data.title },
          )
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(
        toasts.showToast(
          error.response.data.status,
          intl.formatMessage(
            {
              id: error.response.data.details
                ? error.response.data.details
                : error.response.data.errors[0],
            }
          )
        )
      );
      dispatch(toasts.hideToast());
    },
  });
  if (!isVisible) return null;

  const publishOption = publishType ? "publish" : "unpublish";

  const itemsSwitch = (param) => {
    // eslint-disable-next-line default-case
    switch (itemType) {
      case "users":
        return "users";
      case "policy":
        return "policies";
      case "crawlers":
        return "crawlers";
    }
  }

  const itemOption = itemsSwitch(itemType);  

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        setCurrentItems(null);
        setIsVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title><span style={{textTransform: 'capitalize'}}>{ publishOption }</span> selected {itemOption}</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure to permanently {publishOption} these {itemOption}?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setCurrentItems(null);
            setIsVisible(false);
          }}
        >
          No
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            try {
              mutation.mutate();
              setCurrentItems(null);
              setIsVisible(false);
            } catch (error) {}
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishModal;
