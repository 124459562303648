/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import "../../../../_metronic/_assets/sass/pages/other/all-other.scss";
import CustomTabContainer from "../../../../_metronic/layout/components/extras/tabs/CustomTabContainer";
import Lottie from "react-lottie";
import sadbox from "./lottieFile/sadbox.json";

const PageContent = ({ data, type }) => {
  const intl = useIntl();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: sadbox,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div
        className="d-flex flex-row-fluid bgi-size-cover bgi-position-top no-padding"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-9.jpg")})`,
        }}
      >
        <div className="container">
          <div className="d-flex justify-content-between align-items-center pt-25 pb-35">
            <h3 className="font-weight-bolder text-dark mb-0">
              {intl.formatMessage({ id: `PAGE.${type.toUpperCase()}.TITLE` })}
            </h3>
            <div className="d-flex">
              <Link to="/helpcenter" className="font-size-h4 font-weight-bold">
                {intl.formatMessage({ id: "LINK.HELPCENTER" })}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-n15">
        <div className="card mb-8">
          <div className="card-body p-10">
            {data?.navItemsData?.length ? (
              <></>
            ) : (
              <Lottie options={defaultOptions} height={400} width={400} />
            )}
            {/* Pass data to container */}
            <CustomTabContainer data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageContent;
