
import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useIntl } from 'react-intl';

const ITEM_HEIGHT = 48;

export default function LongMenu({ onClickHandler }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const intl = useIntl();

    const options = [
        intl.formatMessage({ id: 'MENU.LONG.OPTION-1' }),
        intl.formatMessage({ id: 'MENU.LONG.OPTION-2' }),
        intl.formatMessage({ id: 'MENU.LONG.OPTION-3' }),
        intl.formatMessage({ id: 'MENU.LONG.OPTION-4' }),
        intl.formatMessage({ id: 'MENU.LONG.OPTION-5' }),
    ];


    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div>
            <MoreVertIcon onClick={handleClick} />
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: styles.PaperProps
                }}
            >
                {options.map(option => (
                    <MenuItem key={option} onClick={() => onClickHandler(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

const styles = {
    PaperProps: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 200,
    }
}