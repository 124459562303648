/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";

import {
  Input,
  Select,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  SAVE_MEDIA_URL,
  EDIT_MEDIA_URL,
  GET_POST_TYPES_URL,
  GET_MEDIA_LIST_URL,
  a_media_url,
} from "../../../../_metronic/_helpers/Constants";
import ProfileService from "../../Profile/service";
import { createMedia, editMedia } from "../../../_redux/media/actions";

const EditSchema = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.string().required(),
  order_by: Yup.string()
    .required("order by is a required field")
    .nullable(),
});

const MediaEditPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [currentMedia, setCurrentMedia] = useState(null);
  const history = useHistory();
  const service = new ProfileService();

  const params = useParams();
  useEffect(() => {
    const fetchMedia = async () => {
      if (!params.id) return;
      try {
        const response = await service.post(GET_MEDIA_LIST_URL, {
          id: params.id,
          page: 0,
          size: 20,
        });
        const media = response.data.data[0];
        setCurrentMedia(media);
      } catch (error) {}
    };

    fetchMedia();
  }, [params]);

  const saveMedia = async (values) => {
    const id = +params.id || null;
    const payload = id ? { ...values, id } : { ...values };
    id
      ? dispatch(editMedia(payload, intl, toasts, history))
      : dispatch(createMedia(payload, intl, toasts, history));

    // return await service.post(id ? EDIT_MEDIA_URL : SAVE_MEDIA_URL, payload);
  };

  const mutation = useMutation(saveMedia, {
    onSuccess: (data) => {
      dispatch(
        toasts.showToast(
          data.data.status,
          intl.formatMessage({ id: data.data.title })
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(
        toasts.showToast(
          error.response.data.status,
          intl.formatMessage({
            id: error.response.data.details || error.response.data.errors[0],
          })
        )
      );
      dispatch(toasts.hideToast());
    },
  });

  const backToMediaList = () => {
    history.push(a_media_url);
  };
  const btnRef = useRef();

  const saveMediaClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const postTypes = useQuery(
    ["postTypes"],
    async () => (await service.post(GET_POST_TYPES_URL)).data,
    { keepPreviousData: true }
  );

  if (params.id && !currentMedia) {
    return null;
  }

  const initialValues = {
    name: currentMedia ? currentMedia.name : "",
    post_type: currentMedia ? currentMedia.post_type?.id : "",
    order_type: currentMedia ? currentMedia.order_by?.id : "",
    order_by: currentMedia ? currentMedia.order_by : "",
    type: currentMedia ? currentMedia.post_type?.id : "",
  };

  const formTitle = params.id
    ? intl.formatMessage({ id: "MENU.OPTIONS.EDIT" }) +
      " " +
      intl.formatMessage({ id: "MENU.MEDIA" })
    : intl.formatMessage({ id: "Add_new" }) +
      " " +
      intl.formatMessage({ id: "MENU.MEDIA" });

  return (
    <Card>
      <CardHeader title={formTitle}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToMediaList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveMediaClick}
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <Formik
            initialValues={initialValues}
            validationSchema={EditSchema}
            enableReinitialize
            onSubmit={
              async (values) => {
                try {
                  await mutation.mutateAsync(values);
                  history.push(a_media_url);
                } catch (error) {
                  console.error(error);
                }
              }
              // handleCallSubmit
            }
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, values, setFieldValue, errors }) => (
              <>
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        type="text"
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label="Name"
                        withFeedbackLabel={false}
                      />
                      {errors?.name && (
                        <span className="error">{errors?.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        type="text"
                        name="order_by"
                        component={Input}
                        placeholder="Order By"
                        label="order by"
                        withFeedbackLabel={false}
                      />
                      {errors?.order_by && (
                        <span className="error">{errors?.order_by}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      {!postTypes.isLoading && (
                        <Select
                          name="type"
                          id="type"
                          label="Type"
                          withFeedbackLabel={false}
                          onChange={(e) => {
                            setFieldValue("type", +e.target.value || "");
                          }}
                        >
                          <option value="''">Select</option>
                          {postTypes.data.data.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          ))}
                        </Select>
                      )}
                      {errors?.type && (
                        <span className="error">{errors?.type}</span>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    style={{ display: "none" }}
                    ref={btnRef}
                    onSubmit={() => handleSubmit()}
                  ></button>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </CardBody>
    </Card>
  );
};

export default MediaEditPage;
