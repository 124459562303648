import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TOAST_PLACEMENT, TOAST_TIMEOUT } from '../../_helpers/Constants';

export const toastPopup = (title, type) =>
    toast[type](title, {
        position: TOAST_PLACEMENT,
        autoClose: TOAST_TIMEOUT,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });