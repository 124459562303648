import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, toasts, intl, id, modalClose, ids, setAllIds } = action;
  switch (action.type) {
    case "SET_ALL_INSTITUTIONS_DATA":
      return payload;
    case "DELETE_INSTITUTE_FROM_REDUX":
      console.log("id", id);
      console.log("state", state);
      const deleteinstitute = state.data.filter((x) =>
        x.id === id ? false : x
      );
      modalClose("#exampleModalDelete");

      return {
        ...state,
        data: deleteinstitute,
      };
    case "DELETE_MULTI_INSTITUTE_FROM_REDUX":
      const deleteMultiInstitute = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );
      setAllIds([]);
      modalClose("#exampleModalDeleteMulti");
      return {
        ...state,
        data: deleteMultiInstitute,
      };
    default:
      return state;
  }
};

const userIndex = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_ALL_USERS_DATA":
      return payload;
    default:
      return state;
  }
};
const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};
export const reducer = combineReducers({
  Index,
  userIndex,
  showLoader,
});
