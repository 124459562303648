import React from 'react'
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers';

const HeaderButton = (props) => {
    const { title, url, onClickHandler } = props;

    return (
        <OverlayTrigger
            overlay={<Tooltip>{title}</Tooltip>}
        >
            <span
                className={`svg-icon svg-icon-xl svg-icon-primary cursor-pointer mr-3`}
                onClick={onClickHandler}
            >
                <SVG src={toAbsoluteUrl(url)} />
            </span>
        </OverlayTrigger>
    )
}

export default HeaderButton
