import { put, takeLatest, call } from "redux-saga/effects";
import { a_category_url } from "../../../_metronic/_helpers";
import { setAllCategoriesData, getAllCategories, showLoader } from "./actions";
import {
  deleteCategoryDataCall,
  editCategoryDataCall,
  getAllCategoriesDataCall,
  multiDeleteCategoryCall,
  saveCategoryDataCall,
} from "./Service";

function* attemptGetCategorySaga(action) {
  try {
    yield put(showLoader(true));
    const response = yield call(getAllCategoriesDataCall, action);
    yield put(setAllCategoriesData(response.data));
    yield put(showLoader(false));
  } catch (errors) {}
}

function* attemptCreateCategorySaga(actions) {
  try {
    const response = yield call(saveCategoryDataCall, actions.payload);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(actions.history.push(a_category_url));
    return response;
  } catch (errors) {}
}

function* attemptEditCategorySaga(actions) {
  try {
    const response = yield call(editCategoryDataCall, actions);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(actions.history.push(a_category_url));
    return response;
  } catch (errors) {
    yield put(
      actions.toasts.showToast(
        500,
        actions.intl.formatMessage({ id: errors.response.data.message })
      )
    );
    yield put(actions.toasts.hideToast());
  }
}

function* attemptDeleteCategory(action) {
  const { id } = action;
  try {
    const response = yield call(deleteCategoryDataCall, id);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
  } catch (error) {
    yield put(
      action.toasts.showToast(
        500,
        action.intl.formatMessage({ id: error.response.data.message })
      )
    );
    yield put(action.toasts.hideToast());
  }
}

function* attemptMultiDeleteCategory(action) {
  try {
    const response = yield call(multiDeleteCategoryCall, action);
    yield put(
      action.toasts.showToast(
        200,
        action.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(action.toasts.hideToast());
  } catch (error) {
    yield put(
      action.toasts.showToast(
        500,
        action.intl.formatMessage({ id: error.response.data.message })
      )
    );
    yield put(action.toasts.hideToast());
  }
}

export function* saga() {
  yield takeLatest("GET_ALL_CATEGORIES", attemptGetCategorySaga);
  yield takeLatest("SAVE_CATEGORY", attemptCreateCategorySaga);
  yield takeLatest("EDIT_CATEGORY", attemptEditCategorySaga);
  yield takeLatest("DELETE_MULTI_CATEGORY", attemptMultiDeleteCategory);
  yield takeLatest("DELETE_CATEGORY", attemptDeleteCategory);
}
