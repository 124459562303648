import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import Lottie from "react-lottie";
import sadbox from "../../../Other/pages/lottieFile/sadbox.json";
import Select from "react-select";
import {
  assignUserMedia,
  changeUserMediaStatus,
  getAllMediaData,
  getUnallowedMediaData,
  multiMediaStatusChange,
} from "../../../../_redux/users/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { assignUserUnallowedMedia } from "./../../../../_redux/users/actions";
import { Table } from "../../shared";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

import ProfileService from "../../../Profile/service";
import {
  GET_POST_TYPES_URL,
  a_post_type_edit_url,
} from "../../../../../_metronic/_helpers/Constants";
import { SearchTable } from "../../shared/Table/SearchTable";
import {
  setMediaCheckedStatus,
  setMultiMediaCheckStatus,
} from "../../../../_redux/media/actions";

export default function Media(props) {
  const service = new ProfileService();

  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const showLoader = useSelector(
    (state) => state?.institutions?.showLoader ?? []
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: sadbox,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const currentAssingMedia = useSelector(
    (state) => state?.media?.Index?.current_assign_media ?? []
  );
  const mediaData = useSelector(
    (state) => state?.media?.Index?.data?.data ?? []
  );
  const mediaD = useSelector((state) => state?.media?.Index?.data ?? []);

  const unallowedMediaData = useSelector(
    (state) => state?.media?.unallowedMedia?.allowed_media_category ?? []
  );

  const currentAssignAllowedMedia = useSelector(
    (state) =>
      state?.media?.unallowedMedia?.selected_unallowed_media_categories ?? []
  );

  const [allIds, setAllIds] = useState([]);
  const [testing, setTesting] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [tableData, setTableData] = useState([]);

  const [postTypeData, setPostTypeData] = useState([]);

  const [selectedPostType, setSelectedPostType] = useState("");

  const [getSearch, setSearch] = useState("");

  const [is_active, setIs_active] = useState(false);

  useEffect(() => {
    let tempAlreadyAssigned = [...currentAssingMedia];
    let tmpAllMedias = [...mediaData];
    if (currentAssingMedia.length) {
      let tmpArray = tmpAllMedias?.map((d) => {
        let tmp = { ...d };
        if (tempAlreadyAssigned?.map((aaf) => aaf?.id)?.includes(d.id)) {
          tmp.status = true;
        }
        return tmp;
      });
      setTableData(tmpArray);
    } else {
      setTableData(mediaData);
    }
  }, [currentAssingMedia.length]);

  useEffect(() => {
    setTableData(mediaData);
  }, [mediaD?.data]);

  useEffect(() => {
    setIs_active(props?.renderCall);
  }, [props?.renderCall]);

  const paginationOptions = {
    totalSize: mediaData?.totalCount,
  };

  useEffect(() => {
    if (mediaData.length) {
      setTesting(true);
    }
  }, [mediaData.data]);

  useEffect(() => {
    if (is_active == true) {
      getMedias();
    }
  }, [props?.params, getSearch, is_active]);

  const getMedias = () => {
    let data = {};
    data.user_id = props?.params.id;
    data.post_type_id = selectedPostType ?? null;
    data.title = getSearch;

    dispatch(getAllMediaData(data));
  };

  const fetchPostTypes = async () => {
    const response = await service.post(GET_POST_TYPES_URL);
    setPostTypeData(response.data);
  };

  useEffect(() => {
    if (is_active == true) {
      fetchPostTypes();
    }
  }, [is_active]);

  const checkIfAssignMediaIsTrue = (row) => {
    if (currentAssingMedia?.map((p) => p.id).includes(row.id)) {
      return true;
    } else {
      return false;
    }
  };

  const handleMediaChecked = (e, row) => {
    let data = {};
    data.user_id = props.params.id;
    data.medij_id = row.id;
    data.status = e.target.checked == true ? 1 : 0;
    data.post_type_id = selectedPostType ?? "";
    data.title = getSearch;

    dispatch(setMediaCheckedStatus(row));
    dispatch(changeUserMediaStatus(data, toasts, intl, selectedPostType));
  };

  const ChangeMultiStatus = (mediaIds, status) => {
    let data = {};
    data.user_id = props.params.id;
    data.medij_id = mediaIds;
    data.status = status == true ? 1 : 0;
    data.title = getSearch;

    let fObj = mediaIds.map((id) => {
      return mediaData.find((x) => x.id == id);
    });
    console.log("mded", fObj);

    dispatch(setMultiMediaCheckStatus(fObj, status));

    dispatch(multiMediaStatusChange(data, toasts, intl));
  };

  const handlePostTypeChange = (e) => {
    setAllIds([]);

    setSelectedPostType(e.target.value);
    let data = {};
    data.user_id = props?.params.id;
    data.post_type_id = e.target.value == "all" ? null : e.target.value;
    data.title = getSearch;

    dispatch(getAllMediaData(data));
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "post_type.name",
      text: "Post Type",
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),

      formatter: (cell, row) => {
        return (
          <div>
            <span className="switch switch-sm switch-icon switch-primary">
              <label>
                <input
                  onChange={(e) => handleMediaChecked(e, row)}
                  type="checkbox"
                  checked={checkIfAssignMediaIsTrue(row) == true ? true : false}
                  name="select"
                />
                <span />
              </label>
            </span>
          </div>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-lg-3">
            <select
              className="form-control"
              name="type"
              placeholder="Filter by Status"
              onChange={(e) => handlePostTypeChange(e)}
            >
              <option value={"all"}>All</option>
              {postTypeData?.data?.map((pt) => {
                return <option value={pt?.id}>{pt?.name}</option>;
              })}
            </select>
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Post Type
            </small>
          </div>
          <SearchTable setSearch={setSearch} />
        </div>
        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            &nbsp;
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm"
              onClick={() => ChangeMultiStatus(allIds, true)}
            >
              Enable
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm mr-5"
              onClick={() => ChangeMultiStatus(allIds, false)}
            >
              Disable
            </button>
          </div>
        )}
        {/* mediaD.data.length */}
        {mediaD?.data?.length ? (
          <Table
            showLoader={showLoader}
            data={tableData ?? []}
            columns={columns}
            selectRowOptions={{
              allIds,
              setAllIds,
            }}
            show={true}
            intl={intl}
            // pagiOptions={paginationOptions}
          />
        ) : (
          <div>
            <Lottie options={defaultOptions} height={200} width={400} />
            <p style={{ display: "flex", justifyContent: "center" }}>
              Please Assign Some Media
            </p>
          </div>
        )}
      </div>
    </>
  );
}
