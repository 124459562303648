export const getSectionsData = (payload) => {
  return {
    type: "GET_SECTIONS_DATA",
    payload: payload,
  };
};

export const setSectionsData = (payload) => {
  return {
    type: "SET_SECTIONS_DATA",
    payload: payload,
  };
};

export const editSections = (payload, intl, toasts, history) => {
  return {
    type: "EDIT_SECTION",
    payload: payload,
    intl: intl,
    toasts: toasts,
    history: history,
  };
};

export const createSection = (payload, intl, toasts, history) => {
  return {
    type: "CREATE_SECTION",
    payload: payload,
    intl: intl,
    toasts: toasts,
    history: history,
  };
};

export const deleteSection = (id, intl, toasts) => {
  return {
    type: "DELETE_SECTION",
    id: id,
    intl: intl,
    toasts: toasts,
  };
};

export const deleteSectionFromRedux = (id) => {
  return {
    type: "DELETE_SECTION_FROM_REDUX",
    id: id,
  };
};

export const mutiDeleteSectionsFromRedux = (ids) => {
  return {
    type: "DELTE_MULTI_SECTION_FROM_REDUX",
    ids: ids,
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});
