import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, toasts, intl } = action;
  switch (action.type) {
    case "SET_POST_TYPE_DATA":
      return payload;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  Index,
});
