import * as requestsFromServer from "./foldersCrud";
import { foldersSlice, callTypes } from "./foldersSlice";
import * as toasts from "../toasts/toastsActions";
import * as constants from "../../../../../_metronic/_helpers";
const { actions } = foldersSlice;

export const fetchPersonalFolders = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestsFromServer
    .getFolderData(constants.licni)
    .then((response) => {
      const data = response;
      dispatch(actions.personalFoldersFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "can't find folders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const setFolderPosts = (data, stats) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.postsFetched({ data, stats }));
};

export const fetchSearches = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestsFromServer
    .getFolderData(constants.searches_url)
    .then((response) => {
      const data = response;
      dispatch(actions.searchesFetched({ data }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchClippingFolders = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestsFromServer
    .getFolderData(constants.clipping_media_url)
    .then((response) => {
      const data = response;
      dispatch(actions.clippingFoldersFetched({ data }));
    })
    .catch((error) => {
      error.clientMessage = "can't find folders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
// create folder function
export const createFolder = (
  folder_name,
  folder_type,
  folder_description,
  podesavanja_pretrage,
  url_pretrage
) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // axios call
  return requestsFromServer
    .createFolder(
      folder_name,
      folder_type,
      folder_description,
      podesavanja_pretrage,
      url_pretrage
    )
    .then((response) => {
      // depending on response, show message or throw error
      if (response.data.status !== 200) {
        throw new Error(response.data.title);
      }
      // add dispatch after creating subheader buttons
      dispatch(actions.folderCreated({ folder_name, folder_type }));
      dispatch(toasts.showToast(response.data.status, response.data.title));
    })
    .catch((error) => {
      error.clientMessage = "Cannot create folder";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      // display error message as response title
      dispatch(
        toasts.showToast(error.response.data.status, error.response.data.title)
      );
    })
    .finally(() => {
      dispatch(toasts.hideToast());
      folder_type === constants.licni && dispatch(fetchPersonalFolders());
      folder_type === constants.clipping_media_url &&
        dispatch(fetchClippingFolders());
      folder_type === constants.searches_url && dispatch(fetchSearches());
    });
};

export const deleteFolder = (id, type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestsFromServer
    .deleteFolder(id)
    .then((response) => {
      dispatch(actions.folderDeleted({ id, type }));
      dispatch(toasts.showToast(response.data.status, response.data.title));
      dispatch(toasts.hideToast());
      dispatch(fetchPersonalFolders());
    })
    .catch((error) => {
      error.clientMessage = "Can't delete folder";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        toasts.showToast(error.response.data.status, error.response.data.title)
      );
    })
    .finally(() => {
      dispatch(toasts.hideToast());
      type === constants.licni && dispatch(fetchPersonalFolders());
      type === constants.clipping_media_url && dispatch(fetchClippingFolders());
      type === constants.searches_url && dispatch(fetchSearches());
    });
};

export const renameFolder = (
  id,
  new_name,
  type,
  description,
  podesavanja_pretrage,
  url_pretrage
) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestsFromServer
    .renameFolder(id, new_name, description, podesavanja_pretrage, url_pretrage)
    .then((response) => {
      dispatch(actions.folderRenamed({ id, new_name, type }));
      dispatch(toasts.showToast(response.data.status, response.data.title));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      if (typeof error.response === "undefined") {
        dispatch(
          toasts.showToast(400, "Došlo je do greške, molimo pokušajte kasnije.")
        );
      } else {
        dispatch(
          toasts.showToast(
            error.response.data.status,
            error.response.data.title
          )
        );
      }
    })
    .finally(() => {
      dispatch(toasts.hideToast());
      type === constants.licni && dispatch(fetchPersonalFolders());
      type === constants.clipping_media_url && dispatch(fetchClippingFolders());
      type === constants.searches_url && dispatch(fetchSearches());
    });
};

export const purgeFolder = (id, type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestsFromServer
    .purgeFolder(id)
    .then((response) => {
      if (response.data.status !== 200) {
        throw new Error();
      }
      dispatch(actions.folderPurged({ id, type }));
      dispatch(toasts.showToast(response.data.status, response.data.title));
    })
    .catch((error) => {
      error.clientMessage = "Can't purge folder";
      if (error.response === undefined) {
        dispatch(toasts.showToast(400, "No response"));
        return;
      }
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        toasts.showToast(error.response.data.status, error.response.data.title)
      );
    })
    .finally(() => {
      dispatch(toasts.hideToast());
      type === constants.licni && dispatch(fetchPersonalFolders());
      type === constants.clipping_media_url && dispatch(fetchClippingFolders());
      type === constants.searches_url && dispatch(fetchSearches());
    });
};

export const addPost = (folder_id, post_ids, type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestsFromServer
    .addPost(folder_id, post_ids)
    .then((response) => {
      // dispatch(actions.postAdded());
      dispatch(toasts.showToast(response.data.status, response.data.title));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        toasts.showToast(error.response.data.status, error.response.data.title)
      );
    })
    .finally(() => {
      dispatch(toasts.hideToast());
      type === constants.licni && dispatch(fetchPersonalFolders());
      type === constants.clipping_media_url && dispatch(fetchClippingFolders());
      type === constants.searches_url && dispatch(fetchSearches());
    });
};

export const removePost = (folder_id, post_ids, type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestsFromServer
    .removePost(folder_id, post_ids)
    .then((response) => {
      dispatch(toasts.showToast(response.data.status, response.data.title));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(
        toasts.showToast(error.response.data.status, error.response.data.title)
      );
    })
    .finally(() => {
      dispatch(toasts.hideToast());
      type === constants.licni && dispatch(fetchPersonalFolders());
      type === constants.clipping_media_url && dispatch(fetchClippingFolders());
      type === constants.searches_url && dispatch(fetchSearches());
    });
};

export const openFolderDialog = (dialogType, type) => (dispatch) => {
  console.log("hoo");
  dispatch(actions.folderDialogOpened({ dialogType, type }));
};

export const closeFolderDialog = () => (dispatch) => {
  dispatch(actions.folderDialogClosed());
};

// store active folder data to initialize query even on refresh
export const setActiveFolder = (folder, id) => (dispatch) => {
  dispatch(actions.activeFolderSet({ folder, id }));
};

export const setActivePost = (post) => (dispatch) => {
  dispatch(actions.activePostSet(post));
};
