import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import "../../../../../../_metronic/_assets/css/arhivaSearch.css";

import {
  FormGroup,
  FormControl,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { useDispatch, useSelector } from "react-redux";

import SearchSwitches from "./SearchSwitches";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AdvanceSearchModal } from "./AdvanceSearchModal";
import axios from "axios";
import { SearchIndex } from "./SearchIndex";
import { ARHIVA_REST_URL } from "../../../../../../environments/environment";
import { search, searchedWordsAction } from "./_redux/SearchRedux";
const ArhivaSearch = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [authors, setAuthors] = useState([]);
  const [locations, setLocations] = useState([]);
  const [media, setMedia] = useState([]);
  const [postType, setPostType] = useState([]);

  const [query, setQuery] = useState("");
  const [queryType, setQueryType] = useState(
    intl.formatMessage({ id: "PAGE.SEARCH.CHECKBOX.EXACT" })
  );

  useEffect(() => {
    getAdvanceSearchMetaData();
  }, []);

  useEffect(() => {
    let inputElm = document.getElementById("tagify");
    new window.Tagify(inputElm, {
      maxTags: 10,
    });
  }, []);

  const getAdvanceSearchMetaData = () => {
    axios
      .get(`${ARHIVA_REST_URL}api/search/meta`)
      .then((response) => {
        setAuthors(response.data.authors);
        setLocations(response.data.locations);
        setMedia(response.data.media);
        setPostType(response.data.post_types);
      })
      .catch((e) => {});
  };

  const isJson = (item) => {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  };

  const searchQuery = () => {
    // props.setBool(true);
    let queryArray = isJson(query) ? JSON.parse(query) : [];
    // let queryArray = JSON.parse(query);
    queryArray = queryArray.map((q) => q.value);
    let data = {};
    if (queryType == intl.formatMessage({ id: "PAGE.SEARCH.CHECKBOX.EXACT" })) {
      data.phrase = queryArray;
    } else if (
      queryType == intl.formatMessage({ id: "PAGE.SEARCH.CHECKBOX.ANY" })
    ) {
      data.any_word_in = queryArray;
    } else if (
      queryType == intl.formatMessage({ id: "PAGE.SEARCH.CHECKBOX.ALL" })
    ) {
      data.word_in = queryArray;
    }
    data.size = 100;
    data.page = 0;

    dispatch(search(data));
    dispatch(searchedWordsAction(queryArray));
    props.setBool(true);
    // axios
    //   .post(`${ARHIVA_REST_URL}api/search/results`, data)
    //   .then((response) => {
    //     if (response.data.data.length > 0) {
    //       props.setData(response.data.data);
    //     }
    //     props.setSearchData(queryArray);
    //     props.setBool(true);
    //   })
    //   .catch((e) => {
    //   });
  };
  const [modalShow, setModalShow] = useState(false);

  const dataField = [
    "title",
    "content",
    "rijeci_str",
    "institucija_str",
    "subjekt_str",
  ];
  const fieldWeights = [5, 5, 1, 1, 1];

  const checkboxes = [
    intl.formatMessage({ id: "PAGE.SEARCH.CHECKBOX.EXACT" }),
    intl.formatMessage({ id: "PAGE.SEARCH.CHECKBOX.ANY" }),
    intl.formatMessage({ id: "PAGE.SEARCH.CHECKBOX.ALL" }),
  ];

  const { fuzziness, queryFormat } = useSelector((state) => state.arhiva);

  const location = useLocation();

  const searchSwtichTheme = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiFormGroup: {
        // Name of the rule
        root: {
          // Some CSS
          justifyContent: "center",
        },
      },
    },
  });
  return (
    <>
      <form className="position-relative w-75 px-lg-40 m-auto">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white border-0 py-7 px-8">
              <span className="svg-icon svg-icon-xl">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                />
              </span>
            </span>
          </div>
          <input
            id="tagify"
            className={"archive-input form-control h-auto border-0"}
            // placeholder={intl.formatMessage({ id: "PAGE.SEARCH.INPUT" })}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          <Button
            className="h-auto"
            onClick={() => {
              searchQuery();
            }}
            variant="success"
          >
            {intl.formatMessage({ id: "COMMON.SEARCH" })}
            <span className="svg-icon svg-icon-xl">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </Button>
        </div>
        <ThemeProvider theme={searchSwtichTheme}>
          <FormControl component="fieldset" className="align-items-center">
            <FormGroup
              className="p-4"
              aria-label="position"
              name="position"
              onChange={(e) => {
                setQueryType(e.target.value);
              }}
              row
            >
              <SearchSwitches selected={queryType} checkboxes={checkboxes} />

              <AdvanceSearchModal
                showModal={modalShow}
                setShowModal={(val) => {
                  setModalShow(val);
                }}
                setBool={(val) => {
                  props.setBool(val);
                }}
                author={authors ?? []}
                location={locations ?? []}
                media={media ?? []}
                postType={postType ?? []}
              />
            </FormGroup>
            <Button
              className="h-auto w-50"
              variant="primary"
              onClick={() => setModalShow(true)}
            >
              {intl.formatMessage({ id: "advanced_search" })}
              <span className="svg-icon svg-icon-xl">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                />
              </span>
            </Button>
          </FormControl>
        </ThemeProvider>
      </form>
      {/* <SearchIndex data={data} /> */}
    </>
  );
};

export default ArhivaSearch;
