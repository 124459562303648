import { combineReducers } from "redux";

const Index = (state = [], action) => {
  const { payload, modalClose, setAllIds } = action;
  switch (action.type) {
    case "SET_SUBJECTS_DATA":
      return payload;
    case "DELETE_SUBJECT_FROM_REDUX":
      const deleteData = state.data.filter((x) =>
        x.id === payload.id ? false : x
      );
      modalClose("#exampleModalDelete");
      return {
        ...state,
        data: deleteData,
      };
    case "DELETE_MULTI_SUBJECT_FROM_REDUX":
      const deleteMultiData = state.data.filter((x) =>
        payload.ids.includes(x.id) ? false : x
      );

      modalClose("#exampleModalDeleteMulti");
      setAllIds([]);
      return {
        ...state,
        data: deleteMultiData,
      };

    default:
      return state;
  }
};

const Errors = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_ERRORS_SUBJECTS":
      return payload;

    default:
      return state;
  }
};

export const reducer = combineReducers({
  Index,
  Errors,
});
