import { combineReducers } from "redux";

export const setAllMediaData = (payload) => {
  return {
    type: "SET_ALL_MEDIA_DATA",
    payload: payload,
  };
};

const Index = (state = [], action) => {
  const { payload, toasts, intl, id, ids } = action;
  switch (action.type) {
    case "SET_ALL_MEDIA_DATA":
      return payload;
    case "SET_MEDIA_CHECKED_STATUS":
      let check = state.current_assign_media.find(
        (saa) => saa.id == payload.id
      );
      return {
        ...state,
        current_assign_media: check
          ? state.current_assign_media.filter((s) => s.id != payload.id)
          : [...state.current_assign_media, payload],
      };

    case "SET_MULTI_MEDIA_CHECK_STATUS":
      let tmp = [];
      if (action.status) {
        tmp = [...state.current_assign_media, ...payload];
        tmp = [...new Set(tmp)];
      } else {
        tmp = state.current_assign_media.filter((aa) => {
          if (payload.map((p) => p.id).includes(aa.id)) {
            return false;
          }
          return true;
        });
      }

      return {
        ...state,
        current_assign_media: tmp,
      };
    case "DELETE_MEDIA_FROM_REDUX":
      const deleteData = state?.filter((x) => (x.id === id ? false : x));

      return deleteData;
    case "DELETE_MULTI_MEDIA_FROM_REDUX":
      const deleteMultiData = state.filter((x) =>
        ids.includes(x.id) ? false : x
      );

      //   setAllIds([]);
      return deleteMultiData;

    default:
      return state;
  }
};

const unallowedMedia = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_UNALLOWED_MEDIA_DATA":
      return payload;
    case "SET_ASSIGN_UNALLOWED_MEDIA":
      let check = state.selected_unallowed_media_categories.find(
        (saa) => saa.id == payload.id
      );
      return {
        ...state,
        selected_unallowed_media_categories: check
          ? state.selected_unallowed_media_categories.filter(
              (s) => s.id != payload.id
            )
          : [...state.selected_unallowed_media_categories, payload],
      };
    case "SET_MULTI_ASSIGN_UNALLOWED_MEDIA":
    case "SET_MULTI_ASSIGN_FOLDER":
      let tmp = [];
      if (action.status) {
        tmp = [...state.selected_unallowed_media_categories, ...payload];
        tmp = [...new Set(tmp)];
      } else {
        tmp = state.selected_unallowed_media_categories.filter((aa) => {
          if (payload.map((p) => p.id).includes(aa.id)) {
            return false;
          }
          return true;
        });
      }

      return {
        ...state,
        selected_unallowed_media_categories: tmp,
      };
    default:
      return state;
  }
};

const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};
export const reducer = combineReducers({
  Index,
  showLoader,
  unallowedMedia,
});
