import { put, takeLatest, call } from "redux-saga/effects";
import { getSocialCategoryData, setSocialCategoryData } from "./actions";
import { socialCategoryCall, socialMediaStatusCall } from "./Service";
import { showLoader } from "./../users/actions";

function* attemptGetSocialCategory(action) {
  const { payload } = action;

  try {
    // yield put(showLoader(true));
    const response = yield call(socialCategoryCall, payload);

    yield put(setSocialCategoryData(response.data));
  } catch (error) {
  } finally {
    // yield put(showLoader(false));
  }
}

function* attemptChangeSocialMediaStatus(action) {
  const { payload, toasts, intl } = action;

  let data = {};
  data.user_id = payload.user_id;
  data.page = payload.page;
  data.size = payload.size;
  data.title = payload.title;
  try {
    const response = yield call(socialMediaStatusCall, payload);
    // yield put(getSocialCategoryData(data));
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (error) {}
}

export function* saga() {
  yield takeLatest("GET_SOCIAL_CATEGORY_DATA", attemptGetSocialCategory);
  yield takeLatest(
    "CHANGE_SOCIAL_MEDIA_STATUS",
    attemptChangeSocialMediaStatus
  );
}
