export const getAllInstitutionsData = (payload, toasts, intl) => {
  return {
    type: "GET_ALL_INSTITUTIONS_DATA",
    payload: payload,
    toasts: toasts,
    intl: intl,
  };
};

export const setAllInstitutionsData = (payload) => {
  return {
    type: "SET_ALL_INSTITUTIONS_DATA",
    payload: payload,
  };
};

export const setAllUsersData = (payload) => {
  return {
    type: "SET_ALL_USERS_DATA",
    payload: payload,
  };
};

export const createInstitute = (
  payload,
  toasts,
  intl,
  modalClose,
  setErrorMessage,
  getCallPayload
) => {
  return {
    type: "CREATE_INSTITUTE",
    payload: payload,
    toasts: toasts,
    intl: intl,
    modalClose: modalClose,
    setErrorMessage: setErrorMessage,
    getCallPayload: getCallPayload,
  };
};

// -------------------------------------- DELETE --------------------------------------------------

export const deleteInstitute = (payload) => {
  return {
    type: "DELETE_INSTITUTE",
    payload: payload,
  };
};

export const deleteInstituteFromRedux = (id, modalClose) => {
  return {
    type: "DELETE_INSTITUTE_FROM_REDUX",
    id: id,
    modalClose: modalClose,
  };
};

export const deleteMultiInstituteFromRedux = (ids, setAllIds, modalClose) => {
  return {
    type: "DELETE_MULTI_INSTITUTE_FROM_REDUX",
    ids: ids,
    setAllIds: setAllIds,
    modalClose: modalClose,
  };
};

// ------------------------------------- Edit ---------------------------------

export const editInstitute = (payload) => {
  return {
    type: "EDIT_INSTITUTE",
    payload: payload,
  };
};

// -------------------------------------- Get Users ------------------------------

export const getAllUsersData = () => {
  return {
    type: "GET_USERS_DATA",
  };
};

// ----------------------------------------- Assign Users --------------------------

export const assignUsers = (payload, toasts, intl, b) => {
  return {
    type: "ASSIGN_USERS_TO_INSTITUTE",
    payload: payload,
    toasts: toasts,
    intl: intl,
    b: b,
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});
