import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getAllInstitutionsDataCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/institutes/list`, data);
};

export const createInstitutionCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/institutes`, data);
};

export const deleteInstitutionCall = (id) => {
  return axios.post(`${ARHIVA_REST_URL}api/institutes/delete/${id}`);
};

export const updateInstitutionCall = (action, id) => {
  let data = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/institutes/${id}/update`, data);
};

export const getAllUsersCall = () => {
  return axios.post(`${ARHIVA_REST_URL}api/users`);
};

export const assignUsersCall = (action) => {
  let id = action.payload.id;
  let data = action.payload;

  return axios.post(
    `${ARHIVA_REST_URL}api/institutes/${id}/assign-user-institutes`,
    data
  );
};
