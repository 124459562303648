import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Table } from "../shared";
import { sortCaret } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import axios from "axios";
import { ARHIVA_REST_URL } from "../../../../environments/environment";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useDispatch } from "react-redux";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import * as moment from "moment";

import { useQuery } from "react-query";
import { SearchTable } from "../shared/Table/SearchTable";
import {
  assignUsers,
  createInstitute,
  deleteInstitute,
  deleteInstituteFromRedux,
  deleteMultiInstituteFromRedux,
  editInstitute,
  getAllInstitutionsData,
  getAllUsersData,
} from "../../../_redux/Institutions/actions";
import { useSelector } from "react-redux";

export const InstitutePage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [getSearch, setSearch] = useState("");

  const [data, setData] = useState([]);
  const [allIds, setAllIds] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);

  const [name, setName] = useState("");
  const [sid, setSid] = useState("");
  const [clickedRow, setClickedRow] = useState("");

  const [subject, setSubject] = useState([]);
  const [loadSubject, setLoadSubject] = useState([]);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const showLoader = useSelector(
    (state) => state?.institutions?.showLoader ?? []
  );

  const [errorMessage, setErrorMessage] = useState("");

  const InstitutionsData = useSelector(
    (state) => state?.institutions?.Index ?? []
  );
  const usersData = useSelector(
    (state) => state?.institutions?.userIndex ?? []
  );

  useEffect(() => {
    let myArray = InstitutionsData?.data?.filter(function(obj) {
      return obj.is_deleted != 1;
    });
    InstitutionsData.data = myArray;
    setData(InstitutionsData?.data);
  }, [InstitutionsData]);
  const handleChange = (e) => {
    setLoadSubject(
      e?.map((sub) => {
        return { value: sub.value, label: sub.label };
      })
    );
  };

  useEffect(() => {
    getAllInstitute();
  }, []);

  useEffect(() => {
    getAllSubjects();
  }, []);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    setUsers(usersData);
  }, [usersData]);

  // ---------------------------------------------------------------------------------------------
  const [inputValue, setInputValue] = useState([]);
  const [valData, setValData] = useState([]);

  const loadOptions = async (inputValue, callback) => {
    let d = {};
    d.page = page - 1;
    d.size = sizePerPage;
    d.title = inputValue;
    const response = await axios.post(`${ARHIVA_REST_URL}api/users`, d); //use that input value in the endpoint
    let data = await response.data.data;
    callback(
      data?.map((d) => {
        return { value: d.id, label: d.username };
      })
    );
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue({ inputValue });
    return inputValue;
  };

  const handleUChange = (e) => {
    setValData(e);
  };

  useEffect(() => {
    setValData(selectedUser);
  }, [selectedUser]);

  // -------------------------------------------------------------------------------------------
  const [inputSubjectValue, setInputSubjectValue] = useState([]);
  const [valSubjectData, setValSubjectData] = useState([]);
  const loadSubjectOptions = async (inputValue, callback) => {
    let d = {};
    d.page = page - 1;
    d.size = sizePerPage;
    d.title = inputValue;
    const response = await axios.post(`${ARHIVA_REST_URL}api/subjects`, d); //use that input value in the endpoint
    let data = await response.data.data;
    callback(
      data?.map((d) => {
        return { value: d.name, label: d.name };
      })
    );
  };

  const handleInputSubjectChange = (newValue) => {
    const inputSubjectValue = newValue.replace(/\W/g, "");
    setInputSubjectValue({ inputSubjectValue });
    return inputSubjectValue;
  };

  const handleSubjectChange = (e) => {
    setValSubjectData(e);
  };

  useEffect(() => {
    setValSubjectData(loadSubject);
  }, [loadSubject]);

  // -----------------------------------------------------------------------------------------------

  const getAllSubjects = () => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;
    data.title = getSearch;
    axios
      .post(`${ARHIVA_REST_URL}api/subjects`, data)
      .then((response) => {
        setSubject(response?.data?.data);
      })
      .catch((e) => {});
  };

  const getAllInstitute = () => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;
    data.title = getSearch;
    dispatch(getAllInstitutionsData(data, toasts, intl));
  };

  const getAllUsers = () => {
    dispatch(getAllUsersData());
  };

  const handleEditInstitute = (id) => {
    let data = {};
    data.name = name;
    data.sId = sid;
    data.id = id;
    data.intl = intl;
    data.toasts = toasts;
    data.modalClose = modalClose;
    data.setErrorMessage = setErrorMessage;
    dispatch(editInstitute(data));
  };

  const handleAssignSubjectCall = (id) => {
    let data = {};
    let subArray = valSubjectData.map((sub) => sub.value);
    data.subjects = subArray;
    axios
      .post(`${ARHIVA_REST_URL}api/institutes/${id}/subjects`, data)
      .then((response) => {
        dispatch(
          toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
        );
        dispatch(toasts.hideToast());
        getAllInstitute();
        getAllSubjects();
        window.$(`#exampleModalAssignSubjects`).modal("hide");
      })
      .catch((e) => {});
  };

  const handleAssignUser = (id) => {
    let data = {};
    let usersArray = valData?.map((user) => user.value);
    data.user_ids = usersArray;

    // data.page = page - 1;
    // data.size = sizePerPage;
    // data.title = getSearch;
    data.id = id;

    data.modalClose = modalClose;
    data.setErrorMessage = setErrorMessage;
    let b = {};
    b.page = page - 1;
    b.size = sizePerPage;
    b.title = getSearch;
    dispatch(assignUsers(data, toasts, intl, b));
  };

  const modalClose = (id) => {
    window.$(id).modal("hide");
  };

  const handleCreateInstitute = () => {
    let data = {};
    data.name = name;
    data.sId = sid;
    let getCallPayload = {
      page: page - 1,
      size: sizePerPage,
      title: "",
    };
    dispatch(
      createInstitute(
        data,
        toasts,
        intl,
        modalClose,
        setErrorMessage,
        getCallPayload
      )
    );
  };

  const hanedleDelete = (id) => {
    data.page = page - 1;
    data.size = sizePerPage;
    data.title = getSearch;
    data.id = id;
    data.intl = intl;
    data.modalClose = modalClose;
    data.toasts = toasts;
    dispatch(deleteInstituteFromRedux(id, modalClose));
    dispatch(deleteInstitute(data));
  };

  const deleteSelectedItems = (idsToDelete) => {
    let data = {};
    data.ids = idsToDelete;

    dispatch(deleteMultiInstituteFromRedux(idsToDelete, setAllIds, modalClose));
    axios
      .post(`${ARHIVA_REST_URL}api/institutes/delete`, data)
      .then((response) => {
        dispatch(
          toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
        );
        dispatch(toasts.hideToast());
      })
      .catch((e) => {});
  };

  const handleUserChange = (e) => {
    setSelectedUser(e);
  };

  const paginationOptions = {
    totalSize: InstitutionsData?.totalCount,
    sizePerPage,
    page,
  };

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });
  const onSort = (field, order) => {
    setOrderBy({
      order_by: field,
      order_direction: order,
    });
  };

  const { isLoading, isFetching } = useQuery(
    ["media", page, sizePerPage, getSearch],
    () => getAllInstitute(page),
    { keepPreviousData: true }
  );

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      // sort: true,
      // sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.YYYY");
      },
      // formatter: columnFormatters.ColorColumnFormatter,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      // sort: true,
      // sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.YYYY");
      },
      // formatter: columnFormatters.ColorColumnFormatter,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      // formatter: ActionsColumnFormatter,
      // formatExtraData: {
      //   is: <button>ok</button>,
      //   isUser: (row) => {
      //     let isUservar = true;
      //   },
      // },
      formatter: (cell, row) => {
        return (
          <>
            <a
              href
              title="Assign User"
              data-toggle="modal"
              data-target="#exampleModalUserAssign"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                setErrorMessage("");
                setClickedRow(row);
                setSelectedUser(
                  row?.users?.map((user) => {
                    return { value: user.id, label: user.username };
                  })
                );
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Add-user.svg"
                  )}
                />
              </span>
            </a>

            <a
              href
              title="Edit customer"
              data-toggle="modal"
              data-target="#exampleModalEdit"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                setErrorMessage("");
                setClickedRow(row);
                setName(row.name);
                setSid(row.sId);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>

            {/* ---------------------------- Delete Button --------------------------- */}

            <span
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target={`#exampleModalDelete`}
              onClick={() => {
                // setClickedRow(row.id);
                setClickedRow(row);
              }}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              title={intl.formatMessage({
                id: "MENU.OPTIONS.DELETE",
              })}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
              {/*end::Svg Icon*/}
            </span>
            <a
              href
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                <span
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target={`#exampleModalAssignSubjects`}
              onClick={() => {
                setLoadSubject(
                  row?.subjects?.map((sub) => {
                    return { value: sub.name, label: sub.name };
                  })
                );
                setClickedRow(row);
              }}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              title={intl.formatMessage({
                id: "MENU.OPTIONS.DELETE",
              })}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                />
              </span>
            </span>
              </a>
           
          </>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];
  const emptyFormValues = () => {
    setErrorMessage("");
    setName("");
    setSid("");
  };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "Institutions" })} sticky>
          <CardHeaderToolbar>
            <button
              className="btn btn-primary font-weight-bold"
              data-toggle="modal"
              data-target="#exampleModal"
              onClick={() => {
                emptyFormValues();
              }}
            >
              {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}{" "}
              {intl.formatMessage({ id: "Institution" })}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
            <SearchTable setSearch={setSearch} />
          </div>
          {allIds.length > 1 && (
            <div className="form-group">
              <p>Selected records count:{allIds.length}</p>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                data-toggle="modal"
                data-target={`#exampleModalDeleteMulti`}
              >
                <i className="fa fa-trash"></i>
                {intl.formatMessage({ id: "COMMON.Delete" })}
              </button>
            </div>
          )}

          <Table
            showLoader={showLoader}
            data={data ?? []}
            columns={columns}
            pagiOptions={paginationOptions}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            selectRowOptions={{
              allIds,
              setAllIds,
            }}
            intl={intl}
          />
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {/* {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}{" "}
                    {intl.formatMessage({
                      id: "MENU.CHANNEL",
                    })} */}
                    {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}{" "}
                    {intl.formatMessage({ id: "Institution" })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {" "}
                  <div className="form-group">
                    <label>{intl.formatMessage({ id: "Name" })}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={intl.formatMessage({ id: "Name" })}
                      onChange={(e) => {
                        setName(e.target.value);
                        setErrorMessage("");
                      }}
                      value={name}
                    />
                    <div className="text-danger">
                      {errorMessage?.name ?? ""}
                    </div>
                    <label>SID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="sid"
                      onChange={(e) => {
                        setSid(e.target.value);
                        setErrorMessage("");
                      }}
                      value={sid}
                    />
                    <div className="text-danger">{errorMessage?.sId ?? ""}</div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold"
                      data-dismiss="modal"
                    >
                      {intl.formatMessage({
                        id: "CONTACT.GENERAL.CANCEL.BUTTON",
                      })}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bold"
                      onClick={handleCreateInstitute}
                    >
                      {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id={`exampleModalDelete`}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}{" "}
                    {intl.formatMessage({ id: "Institution" })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {/* {intl.formatMessage({
                    id: "CHANNELS.DELETE_CHANNEL_SIMPLE.DESCRIPTION",
                  })} */}
                  {intl.formatMessage({ id: "DELETE_INSTITUTE_DESCRIPTION" })}?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    onClick={() => {
                      hanedleDelete(clickedRow.id);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id={`exampleModalDeleteMulti`}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}{" "}
                    {intl.formatMessage({ id: "Institution" })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {/* {intl.formatMessage({
                    id: "CHANNELS.DELETE_CHANNEL_SIMPLE.DESCRIPTION",
                  })} */}
                  Are You Sure You Want To Delete These Institutes?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    onClick={() => deleteSelectedItems(allIds)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModalEdit"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {/* {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}{" "}
                    {intl.formatMessage({
                      id: "MENU.CHANNEL",
                    })} */}
                    {intl.formatMessage({ id: "MENU.OPTIONS.EDIT" })}{" "}
                    {intl.formatMessage({ id: "Institution" })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {" "}
                  <div className="form-group">
                    <label>{intl.formatMessage({ id: "Name" })}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={intl.formatMessage({ id: "Name" })}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setErrorMessage("");
                      }}
                    />
                    <div className="text-danger">
                      {errorMessage?.name ?? ""}
                    </div>
                    <label>SID</label>
                    <input
                      value={sid}
                      type="text"
                      className="form-control"
                      placeholder="sid"
                      onChange={(e) => {
                        setSid(e.target.value);
                        setErrorMessage("");
                      }}
                    />
                    <div className="text-danger">{errorMessage?.sId ?? ""}</div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold"
                      data-dismiss="modal"
                    >
                      {intl.formatMessage({
                        id: "CONTACT.GENERAL.CANCEL.BUTTON",
                      })}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bold"
                      // onClick={handleCreateChannel}
                      onClick={() => handleEditInstitute(clickedRow.id)}
                    >
                      {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModalAssignSubjects"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {/* {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}{" "}
                    {intl.formatMessage({
                      id: "MENU.CHANNEL",
                    })} */}
                    Assign Subjects
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {" "}
                  <AsyncCreatableSelect
                    // value={loadSubject}
                    isMulti
                    loadOptions={loadSubjectOptions}
                    defaultOptions
                    onInputChange={(e) => handleInputSubjectChange(e)}
                    onChange={(e) => handleSubjectChange(e)}
                    value={valSubjectData}
                    // onChange={(e) => handleChange(e)}
                    // options={subject?.map((sub) => {
                    //   return {
                    //     value: sub?.name,
                    //     label: sub?.name,
                    //   };
                    // })}
                  />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold"
                      data-dismiss="modal"
                    >
                      {intl.formatMessage({
                        id: "CONTACT.GENERAL.CANCEL.BUTTON",
                      })}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bold"
                      // onClick={handleCreateChannel}
                      onClick={() => handleAssignSubjectCall(clickedRow.id)}
                    >
                      {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModalUserAssign"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {/* {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}{" "}
                    {intl.formatMessage({
                      id: "MENU.CHANNEL",
                    })} */}
                    {intl.formatMessage({ id: "Assign_User" })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {" "}
                  {/* <Select
                    value={selectedUser}
                    isMulti
                    options={users?.map((user) => {
                      return {
                        value: user.id,
                        label: user.username,
                      };
                    })}
                    onChange={(e) => {
                      handleUserChange(e);
                      setErrorMessage("");
                    }}
                  /> */}
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    loadOptions={loadOptions}
                    defaultOptions
                    onInputChange={(e) => handleInputChange(e)}
                    onChange={(e) => handleUChange(e)}
                    value={valData}
                  />
                  <div className="text-danger">
                    {errorMessage?.user_ids ?? ""}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold"
                      data-dismiss="modal"
                    >
                      {intl.formatMessage({
                        id: "CONTACT.GENERAL.CANCEL.BUTTON",
                      })}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bold"
                      // onClick={handleCreateChannel}
                      onClick={() => handleAssignUser(clickedRow.id)}
                    >
                      {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
