import React, { useEffect, useState } from "react";
import { ARHIVA_REST_URL } from "../../../../../environments/environment.prod";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { editProfileAccountInfo } from "../../../../_redux/users/actions";

export const AccountInfo = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [callLoader, setCallLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState("en");
  const [timezone, setTimezone] = useState("");
  const [email_communication, setEmail_communication] = useState("");
  const [sms_communication, setSms_communication] = useState("");
  const [phone_communication, setPhone_communication] = useState("");
  const [viber_communication, setViber_communication] = useState("");

  useEffect(() => {
    setEmail(props?.account_information?.email);
    setEmail_communication(props?.account_information?.email_communication);
    setSms_communication(props?.account_information?.sms_communication);
    setPhone_communication(props?.account_information?.phone_communication);
    setViber_communication(props?.account_information?.viber_communication);
  }, [props.account_information]);

  const handleEditUser = () => {
    // setCallLoader(true);
    let data = {};
    //  data.username = userName;
    data.user_id = props?.routId;
    data.email = email;
    data.language = language;
    data.timezone = timezone;
    data.email_communication = email_communication;
    data.sms_communication = sms_communication;
    data.phone_communication = phone_communication;
    data.viber_communication = viber_communication;

    //  data.password = password;

    dispatch(editProfileAccountInfo(data, intl, toasts));
  };

  const handleLanguageChange = () => {
    let id = document.getElementById("lan").value;
    setLanguage(id);
  };
  const handleTimeZone = () => {
    let tz = document.getElementById("timezoneId").value;
    setTimezone(tz);
  };
  const handleEmailChecked = (e) => {
    setEmail_communication(e.target.checked);
  };
  const handleSmsChecked = (e) => {
    setSms_communication(e.target.checked);
  };
  const handlePhoneChecked = (e) => {
    setPhone_communication(e.target.checked);
  };
  const handleViberChecked = (e) => {
    setViber_communication(e.target.checked);
  };

  return (
    <>
      <div className="card card-custom">
        {/*begin::Header*/}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              {intl.formatMessage({
                id: "MENU.PROFILE.ACCOUNT_INFORMATIONS",
              })}
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              {intl.formatMessage({ id: "Change_your_account_settings" })}
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              disabled={callLoader}
              onClick={() => {
                handleEditUser();
              }}
              className="btn btn-success mr-2 d-flex"
            >
              {intl.formatMessage({
                id: "MENU.OPTIONS.SAVE",
              })}
              {callLoader == true ? (
                <>
                  <div className="spinner ml-2" style={{ width: "20px" }}></div>
                </>
              ) : (
                ""
              )}
            </button>
            <NavLink
              to={`/admin/user/view/${props.routId}`}
              className="navi-link py-4"
              activeClassName="active"
              exact
            >
              <button type="button" className="btn btn-secondary">
                {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
              </button>
            </NavLink>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Form*/}
        <form className="form">
          <div className="card-body">
            {/*begin::Heading*/}
            {props.loader == true ? (
              <>
                <div className="d-flex justify-content-center">
                  <div className="spinner"></div>
                </div>
              </>
            ) : (
              <>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({
                      id: "AUTH.INPUT.EMAIL",
                    })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="input-group input-group-lg input-group-solid">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="la la-at" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </div>
                    <span className="form-text text-muted">
                      Email will not be publicly displayed.
                      <a href="#" className="font-weight-bold">
                        Learn more
                      </a>
                      .
                    </span>
                  </div>
                </div>
                {/*begin::Form Group*/}
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({ id: "Language" })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <select
                      id="lan"
                      onChange={() => handleLanguageChange()}
                      className="form-control form-control-lg form-control-solid"
                    >
                      <option>Select Language...</option>

                      <option value="id">Bahasa Indonesia - Indonesian</option>
                      <option value="msa">Bahasa Melayu - Malay</option>
                      <option value="ca">Català - Catalan</option>
                      <option value="cs">Čeština - Czech</option>
                      <option value="da">Dansk - Danish</option>
                      <option value="de">Deutsch - German</option>
                      <option value="en" selected>
                        English
                      </option>
                      <option value="en-gb">
                        English UK - British English
                      </option>
                      <option value="es">Español - Spanish</option>
                      <option value="eu">Euskara - Basque (beta)</option>
                      <option value="fil">Filipino</option>
                      <option value="fr">Français - French</option>
                      <option value="pt">Português - Portuguese</option>
                      <option value="ro">Română - Romanian</option>
                      <option value="ru">Русский - Russian</option>
                      <option value="sr">Српски - Serbian</option>
                      <option value="uk">Українська мова - Ukrainian</option>
                    </select>
                  </div>
                </div>
                {/*begin::Form Group*/}
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({ id: "Time_Zone" })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <select
                      id="timezoneId"
                      onChange={() => handleTimeZone()}
                      className="form-control form-control-lg form-control-solid"
                    >
                      <option
                        data-offset={-39600}
                        value="International Date Line West"
                      >
                        (GMT-11:00) International Date Line West
                      </option>
                      <option value="International Date Line West">
                        (GMT-11:00) International Date Line West
                      </option>
                      <option value="Midway Island">
                        (GMT-11:00) Midway Island
                      </option>

                      <option value="Central America">
                        (GMT-06:00) Central America
                      </option>
                      <option value="Central Time (US &amp; Canada)">
                        (GMT-05:00) Central Time (US &amp; Canada)
                      </option>
                      <option value="Eastern Time (US &amp; Canada)">
                        (GMT-04:00) Eastern Time (US &amp; Canada)
                      </option>
                      <option value="Indiana (East)">
                        (GMT-04:00) Indiana (East)
                      </option>

                      <option value="Georgetown">(GMT-04:00) Georgetown</option>

                      <option value="Buenos Aires">
                        (GMT-03:00) Buenos Aires
                      </option>
                      <option value="Newfoundland">
                        (GMT-02:30) Newfoundland
                      </option>
                      <option value="Mid-Atlantic">
                        (GMT-02:00) Mid-Atlantic
                      </option>
                      <option value="Cape Verde Is.">
                        (GMT-01:00) Cape Verde Is.
                      </option>
                      <option value="UTC">(GMT) UTC</option>
                      <option value="Dublin">(GMT+01:00) Dublin</option>
                      <option value="Edinburgh">(GMT+01:00) Edinburgh</option>
                      <option value="London">(GMT+01:00) London</option>
                      <option value="Prague">(GMT+02:00) Prague</option>
                      <option value="Helsinki">(GMT+03:00) Helsinki</option>
                      <option value="Abu Dhabi">(GMT+04:00) Abu Dhabi</option>
                      <option value="Tehran">(GMT+04:30) Tehran</option>
                      <option value="Islamabad">(GMT+05:00) Islamabad</option>
                      <option value="Karachi">(GMT+05:00) Karachi</option>
                      <option value="Tashkent">(GMT+05:00) Tashkent</option>
                      <option value="Kathmandu">(GMT+05:45) Kathmandu</option>
                      <option value="Astana">(GMT+06:00) Astana</option>
                    </select>
                  </div>
                </div>
                {/*begin::Form Group*/}
                <div className="form-group row align-items-center">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({ id: "Communication" })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="checkbox-inline">
                      <label className="checkbox">
                        <input
                          onChange={(e) => handleEmailChecked(e)}
                          checked={email_communication}
                          type="checkbox"
                        />
                        <span />
                        {intl.formatMessage({
                          id: "AUTH.INPUT.EMAIL",
                        })}
                      </label>
                      <label className="checkbox">
                        <input
                          checked={sms_communication}
                          onChange={(e) => handleSmsChecked(e)}
                          type="checkbox"
                        />
                        <span />
                        SMS
                      </label>
                      <label className="checkbox">
                        <input
                          onChange={(e) => handlePhoneChecked(e)}
                          checked={phone_communication}
                          type="checkbox"
                          defaultChecked="checked"
                        />
                        <span />
                        {intl.formatMessage({
                          id: "INPUT.MOBILE",
                        })}
                      </label>
                      <label className="checkbox">
                        <input
                          onChange={(e) => handleViberChecked(e)}
                          checked={viber_communication}
                          type="checkbox"
                          defaultChecked="checked"
                        />
                        <span />
                        Viber
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
        {/*end::Form*/}
      </div>
    </>
  );
};
