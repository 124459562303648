import React, { useMemo, useEffect } from "react";
import { AsideMenuList } from "./AsideMenuList";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as actions from "../../../../../app/modules/ECommerce/_redux/folders/foldersActions";

export function AsideMenu({ disableScroll }) {
  // Folders Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchPersonalFolders());
    dispatch(actions.fetchSearches());
    dispatch(actions.fetchClippingFolders());
  }, [dispatch]);
  const { data } = useSelector(
    (state) => ({ data: state.folders }),
    shallowEqual
  );
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);
  const menuProps = useMemo(()=> {
    return {
      menuConfig: data
    }
  })


  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList layoutProps={layoutProps} menuProps={menuProps}/>
      </div>
      {/* end::Menu Container */}
    </>
  );
}
