import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { Table } from "../modules/Admin/shared";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as toasts from "../../app/modules/ECommerce/_redux/toasts/toastsActions";
import AsyncSelect from "react-select/async";
import * as moment from "moment";



import { createStream, deleteStream, editStream, getAllServersData, getAllStreamData, startStream, stopStream } from "../_redux/stream/actions";

export const StreamManagment = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // ---------------- state vars -----------------------
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [clickedRow, setClickedRow] = useState("");
  const [token, setToken] =useState('');

  const [name, setName] = useState("");
  const [ordered_by, setOrdered_by] = useState("");
  const [valData, setValData] = useState([]);


  const [streamName, setStreamName] = useState('');
  const [hlsSource, setHlsSource] = useState('');
  const [streamSource, setStreamSource] = useState('');
  const [mapping, setMapping] = useState('');
  const [customCmd, setCustomCmd] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [server, setServer] = useState('');
  const [errMessage, setErrMessage] = useState(null);
  const [serevrId, setServerId] = useState(null)

  const [allIds, setAllIds] = useState([]);
  const [streamData, setStreamData] = useState([]);

  const data = useSelector((state) => state?.stream?.Index ?? []);
  const availableServer = useSelector((state) => state?.stream?.Server ?? []);


  const showLoader = useSelector((state) => state?.stream?.showLoader ?? []);
  // -------------- state vars end ---------------------

  const paginationOptions = {
    totalSize: data?.totalCount,
    sizePerPage,
    page,
  };


  const renderStopButton = (row)=>{
    let i = data.data.findIndex(d=>d.id == row.id)
    if(data.data[i].state == '1'){
   return (
    <span
    style={{ cursor: "pointer" }}
    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
    onClick={()=>{handleStopStream(row)}}
  >
    
    <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
<g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
  <rect x={0} y={0} width={24} height={24} />
  <path d="M8,6 L10,6 C10.5522847,6 11,6.44771525 11,7 L11,17 C11,17.5522847 10.5522847,18 10,18 L8,18 C7.44771525,18 7,17.5522847 7,17 L7,7 C7,6.44771525 7.44771525,6 8,6 Z M14,6 L16,6 C16.5522847,6 17,6.44771525 17,7 L17,17 C17,17.5522847 16.5522847,18 16,18 L14,18 C13.4477153,18 13,17.5522847 13,17 L13,7 C13,6.44771525 13.4477153,6 14,6 Z" fill="#000000" />
</g>
</svg>{/*end::Svg ||*/}</span>

    </span>
   )
    }
    else{
      return (<></>)
    }
  }
  const renderStartButton = (row)=>{
   let i = data.data.findIndex(d=>d.id == row.id)
   if(data.data[i].state == '2'){
    return (
      <span
        style={{ cursor: "pointer" }}
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={()=>{handleStartStream(row)}}
       
      >
        
        <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <path d="M9.82866499,18.2771971 L16.5693679,12.3976203 C16.7774696,12.2161036 16.7990211,11.9002555 16.6175044,11.6921539 C16.6029128,11.6754252 16.5872233,11.6596867 16.5705402,11.6450431 L9.82983723,5.72838979 C9.62230202,5.54622572 9.30638833,5.56679309 9.12422426,5.7743283 C9.04415337,5.86555116 9,5.98278612 9,6.10416552 L9,17.9003957 C9,18.1765381 9.22385763,18.4003957 9.5,18.4003957 C9.62084305,18.4003957 9.73759731,18.3566309 9.82866499,18.2771971 Z" fill="#000000" />
    </g>
  </svg>{/*end::Svg |>*/}</span>
        </span>
    )
   }else{
    return (<></>)
   }

  }

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });
  const onSort = (field, order) => {
    setOrderBy({
      order_by: field,
      order_direction: order,
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "server_id",
      text: "Server",
      headerStyle: { verticalAlign: "initial" },
      formatter: (server_id) => {
       return availableServer?.find((d) => d.id == server_id)?.name
      },
    },

    {
      dataField: "stream_name",
      text: "Stream Name",
      sort: true,

     
      headerStyle: { verticalAlign: "initial" },
    },
    {
        dataField: "hls_source",
        text: "Hls",
        sort: true,
        headerStyle: { verticalAlign: "initial" },
      },
    {
      dataField: "stream_source",
      text: "Stream Source",
      sort: true,
      headerStyle: { verticalAlign: "initial" },
    },
    {
        dataField: "output_format",
        text: "Output format",
        sort: true,
        headerStyle: { verticalAlign: "initial" },
      },

     
      {
        dataField: "created_at",
        text: "Created at",
        sort: true,
      
        headerStyle: { verticalAlign: "initial" },
        formatter: (val) => {
          return moment(val).format("DD.MM.yyyy");
        },
      },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: (cell, row) => {
        return (
          <>
            <a
              href
              title="Edit customer"
              data-toggle="modal"
              data-target="#exampleModalEdit"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                setErrMessage("");
                setClickedRow(row);
                setStreamName(row.stream_name);
                setHlsSource(row.hls_source);
                setStreamSource(row.stream_source);
                setMapping(row.mapping);
                setCustomCmd(row.custom_cmd);
                setOutputFormat(row.output_format);
                setServerId(row.server_id);
                setOrdered_by(row.order);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <title>
                    {intl.formatMessage({ id: "MENU.OPTIONS.EDIT" })}
                  </title>
                  <desc>Created with Sketch.</desc>
                  <defs />
                  <g
                    id="Stockholm-icons-/-Communication-/-Write"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x={0} y={0} width={24} height={24} />
                    <path
                      d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                      id="Path-11"
                      fill="#000000"
                      fillRule="nonzero"
                      transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                    />
                    <path
                      d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                      id="Path-57"
                      fill="#000000"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                  </g>
                </svg>
              </span>
            </a>

            {/* ---------------------------- Delete Button --------------------------- */}

            <span
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target={`#exampleModalDelete`}
              onClick={() => {
                setClickedRow(row);
              }}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              title={intl.formatMessage({
                id: "MENU.OPTIONS.DELETE",
              })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <path
                    d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                    fill="red"
                    fillRule="nonzero"
                  />
                  <path
                    d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                    fill="red"
                    opacity="0.3"
                  />
                </g>
              </svg>
              {/*end::Svg Icon*/}
            </span>
            {/* ----------------- start ----------------- */}
            
            <div>
         
      {row.state == "0" ? <span>N/A</span>:null}
      {renderStopButton(row)}
      
      {renderStartButton(row)}
      
    </div>
          </>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];




  useEffect(() => {
    if(token){
    dispatch(getAllServersData(token, sizePerPage, page));
    }
  }, [sizePerPage, page, token]);


  const handleChange =(e)=>{
    setServerId(e.value)
  }


  useEffect(() => {
    loginRequest();
  }, []);

  const loginRequest = () => {
var formdata = new FormData();
formdata.append("login", "transcoder_api_user");
formdata.append("password", "LC20v02KjhVVPvYcG");

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://streamapi.arhiva.me/api/login", requestOptions)
  .then(response => response.text())
  .then(result => {
   let data = JSON.parse(result)
   setToken(data.token)
  })
  .catch(error => console.log('error', error));
  };

  const handleCreateStream = (tkn) => {

    const validateFields = () => {
      let isValid = true;
      const errorMessages = {};
  
      if (!serevrId) {
        errorMessages.serevrId = "Server is required.";
        isValid = false;
      }
  
      if (!streamName) {
        errorMessages.streamName = "Stream Name is required.";
        isValid = false;
      }
  
      if (!hlsSource) {
        errorMessages.hlsSource = "HLS Source is required.";
        isValid = false;
      }
  
      if (!streamSource) {
        errorMessages.streamSource = "Stream Source is required.";
        isValid = false;
      }
  
      setErrMessage(errorMessages);
      return isValid;
    };
  
    // Perform frontend validations here
    if (!validateFields()) {
      return;
    }
  
 
    var data = new FormData();
    data.append("token", tkn);
    data.append("server_id", serevrId);
    data.append("stream_name", streamName);
    data.append("hls_source", hlsSource);
    data.append("stream_source", streamSource);
    data.append("mapping", mapping);
    data.append("custom_cmd", customCmd);
    data.append("output_format", outputFormat);
    
  

    dispatch(createStream(data, toasts, intl, closeModal, setErrMessage, page, sizePerPage));
    setTimeout(() => {
      setErrMessage({});
    }, 2000);
  };

  const emptyFormValues = () => {
    setErrMessage("");
    setName("");
  };

  const closeModal = (id) => {
    window.$(`#${id}`).modal("hide");
  };



  const handleUpdateStream = (row) => {
    const validateFields = () => {
      let isValid = true;
      const errorMessages = {};
  
      if (!serevrId) {
        errorMessages.serevrId = "Server is required.";
        isValid = false;
      }
  
      if (!streamName) {
        errorMessages.streamName = "Stream Name is required.";
        isValid = false;
      }
  
      if (!hlsSource) {
        errorMessages.hlsSource = "HLS Source is required.";
        isValid = false;
      }
  
      if (!streamSource) {
        errorMessages.streamSource = "Stream Source is required.";
        isValid = false;
      }
  
      setErrMessage(errorMessages);
      return isValid;
    };
  
    // Perform frontend validations here
    if (!validateFields()) {
      return;
    }
    var data = new FormData();
    
    data.append("token", token);
    data.append("server_id", serevrId);
    data.append("stream_name", streamName);
    data.append("hls_source", hlsSource);
    data.append("stream_source", streamSource);
    data.append("mapping", mapping);
    data.append("custom_cmd", customCmd);
    data.append("output_format", outputFormat);

 
    dispatch(editStream(data, intl, toasts, closeModal, setErrMessage, page, sizePerPage, row.id));
    setTimeout(() => {
      setErrMessage({});
    }, 2000);
  };



  const handleStartStream =(row)=>{
    let data = {}
    data.token =  token;
    data.id = row.id;
    data.state = row.state == "1" ? "2": "1"




    dispatch(startStream(data, intl, toasts))

  }
  const handleStopStream =(row)=>{
    let data = {}
    data.token =  token;
    data.id = row.id;




    dispatch(stopStream(data, intl, toasts))

  }

  const handleDeleteStream =(row)=>{
    let data = {}
    data.token =  token;
    data.id = row.id;


    dispatch(deleteStream(data, intl, toasts,closeModal))
  }


  // useEffect(() => {
  //   if (availableServer)
  //     setValData(
  //       availableServer?.map((v) => {
  //         return { value: v.id, label: v.name };
  //       })
  //     );
  // }, [availableServer]);


  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "stream_management" })}>
        <CardHeaderToolbar>
          <button
            className="btn btn-primary font-weight-bold"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={() => {
              emptyFormValues();
            }}
          >
            {/* {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })} */}
            Create Stream
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {0 > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              data-toggle="modal"
              data-target={`#exampleModalMultiDelete`}
              // onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>{" "}
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
          </div>
        )}
        <Table
          showLoader={showLoader}
          data={data?.data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setSizePerPage={setSizePerPage}
          setPage={setPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
       

<div
  className="modal fade"
  id="exampleModal"
  tabIndex={-1}
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {/* {intl.formatMessage({ id: "MENU.CREATE_POST_SIZE" })}{" "}
           */}
           Create Stream
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" className="ki ki-close" />
        </button>
      </div>
      <div className="modal-body">
        <div className="form-group">
          <label>Stream Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Stream Name"
            value={streamName}
            onChange={(e) => {
              setStreamName(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.streamName ?? ""}</div>
        </div>
        <div className="form-group">
          <label>HLS Source</label>
          <input
            type="text"
            className="form-control"
            placeholder="HLS Source"
            value={hlsSource}
            onChange={(e) => {
              setHlsSource(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.hlsSource ?? ""}</div>
        </div>
        <div className="form-group">
          <label>Stream Source</label>
          <input
            type="text"
            className="form-control"
            placeholder="Stream Source"
            value={streamSource}
            onChange={(e) => {
              setStreamSource(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.streamSource ?? ""}</div>
        </div>
        <div className="form-group">
          <label>Mapping</label>
          <input
            type="text"
            className="form-control"
            placeholder="Mapping"
            value={mapping}
            onChange={(e) => {
              setMapping(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.mapping ?? ""}</div>
        </div>
        <div className="form-group">
          <label>Custom CMD</label>
          <input
            type="text"
            className="form-control"
            placeholder="Custom CMD"
            value={customCmd}
            onChange={(e) => {
              setCustomCmd(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.customCmd ?? ""}</div>
        </div>
        <div className="form-group">
          <label>Output Format</label>
          <input
            type="text"
            className="form-control"
            placeholder="Output Format"
            value={outputFormat}
            onChange={(e) => {
              setOutputFormat(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.outputFormat ?? ""}</div>
        </div>
       
        <div className="form-group">
          <label>Servers</label>
          <AsyncSelect
                    cacheOptions
                    // isMulti
                    // loadOptions={loadOptions}
                    defaultOptions={availableServer?.map((d)=>({value: d?.id, label: d?.name}))}
                    // onInputChange={(e) => handleInputChange(e)}
                    onChange={(e) => handleChange(e)}
                    // value={valData}
                  />
          <div className="text-danger">{errMessage?.server ?? ""}</div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
        >
          {intl.formatMessage({
            id: "CONTACT.GENERAL.CANCEL.BUTTON",
          })}
        </button>
        <button
          type="button"
          className="btn btn-primary font-weight-bold"
          onClick={token?()=>handleCreateStream(token):null}
        >
          {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
        </button>
      </div>
    </div>
  </div>
</div>

        {/* Modal create stream end*/}

        {/* ---------------------- Delete stream Modal -------------------- */}
        <div
          className="modal fade"
          id={`exampleModalDelete`}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Stream
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
              </div>
              <div className="modal-body">
                {intl.formatMessage({
                  id: "STREAM.DELETE_POST_SIMPLE.DESCRIPTION",
                })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  {intl.formatMessage({ id: "CONTACT.GENERAL.CANCEL.BUTTON" })}
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold"
                  onClick={() => {
                    handleDeleteStream(clickedRow);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------- Update post Modal -------------------- */}
        <div
          className="modal fade"
          id={`exampleModalEdit`}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {/* {intl.formatMessage({
                    id: "MENU.EDIT_POST_SIZE",
                  })} */}
                  Edit Stream
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
              </div>
              <div className="modal-body">
        <div className="form-group">
          <label>Stream Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Stream Name"
            value={streamName}
            onChange={(e) => {
              setStreamName(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.streamName ?? ""}</div>
        </div>
        <div className="form-group">
          <label>HLS Source</label>
          <input
            type="text"
            className="form-control"
            placeholder="HLS Source"
            value={hlsSource}
            onChange={(e) => {
              setHlsSource(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.hlsSource ?? ""}</div>
        </div>
        <div className="form-group">
          <label>Stream Source</label>
          <input
            type="text"
            className="form-control"
            placeholder="Stream Source"
            value={streamSource}
            onChange={(e) => {
              setStreamSource(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.streamSource ?? ""}</div>
        </div>
        <div className="form-group">
          <label>Mapping</label>
          <input
            type="text"
            className="form-control"
            placeholder="Mapping"
            value={mapping}
            onChange={(e) => {
              setMapping(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.mapping ?? ""}</div>
        </div>
        <div className="form-group">
          <label>Custom CMD</label>
          <input
            type="text"
            className="form-control"
            placeholder="Custom CMD"
            value={customCmd}
            onChange={(e) => {
              setCustomCmd(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.customCmd ?? ""}</div>
        </div>
        <div className="form-group">
          <label>Output Format</label>
          <input
            type="text"
            className="form-control"
            placeholder="Output Format"
            value={outputFormat}
            onChange={(e) => {
              setOutputFormat(e.target.value);
              setErrMessage("");
            }}
          />
          <div className="text-danger">{errMessage?.outputFormat ?? ""}</div>
        </div>
      
        <div className="form-group">
         
        <label>Server</label>
                <AsyncSelect
                    cacheOptions
                    // isMulti
                    // loadOptions={loadOptions}
                    defaultOptions={availableServer?.map((d)=>({value: d?.id, label: d?.name}))}
                    // onInputChange={(e) => handleInputChange(e)}
                    onChange={(e) => handleChange(e)}
                    value={availableServer?.find((d) => d.id == serevrId) ? { value: availableServer.find((d) => d.id == serevrId)?.id, label: availableServer.find((d) => d.id == serevrId)?.name } : null}
                    
                  />
                  <div className="text-danger">{errMessage?.server ?? ""}</div>
          </div>
      </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  {intl.formatMessage({
                    id: "CONTACT.GENERAL.CANCEL.BUTTON",
                  })}
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold"
                  onClick={() => {
                    handleUpdateStream(clickedRow);
                  }}
                >
                  {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------------------- Multi Delete Modal ------------------------------------ */}
        <div
          className="modal fade"
          id={`exampleModalMultiDelete`}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {/* {intl.formatMessage({
                    id: "CHANNELS.DELETE_CHANNEL_SIMPLE.TITLE",
                  })} */}
                  Delete Stream
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
              </div>
              <div className="modal-body">
                {/* {intl.formatMessage({
                    id: "CHANNELS.DELETE_CHANNEL_SIMPLE.DESCRIPTION",
                  })} */}
                Are you sure to permanently delete these stream?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold"
                  // onClick={() => deleteSelectedItems(allIds)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
