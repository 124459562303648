import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from "../../../../_metronic/_partials/controls";
import {useIntl} from "react-intl";
import Select from "react-select";
import CustomDatePicker from "../shared/DatePicker/CustomDatePicker";
import axios from 'axios';
import {GET_ALL_USERS, GET_ALL_USERS_KLIPPING_FOLDERS, PURGE_FOLDER_BY_DATE_URL} from "../../../../_metronic/_helpers";
import './custom-datepicker-style.css';
import { ToastContainer, toast } from 'react-toastify';

const FolderPurging = () => {

    const intl = useIntl();
    const [selectedDate, setSelectedDate] = useState(null);
    const [options, setOptions] = useState([]);
    const [folderOptions, setFolderOptions] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [folderId, setFolderId] = useState(null);
    const [isRunning, setIsRunning] = useState(false);


    useEffect(() => {

        setButtonDisabled(true);
        const fetchData = async () => {
            try {
                const response = await axios.post(GET_ALL_USERS);
                console.log(response.data.data);
                const usersOptions = response.data.data.map(item => ({
                    value: item.id,
                    label: item.username,
                }));

                console.log(usersOptions);
                setOptions(usersOptions);

            } catch (error) {
                console.error('Greška prilikom dohvaćanja podataka:', error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        console.log(selectedDate);
        checkButtonDisabled();
    }, [folderId, selectedDate,isRunning]);
    const handleChangeUser = async (selectedOption) => {
        console.log('Selected Option:', selectedOption);
        const response = await axios.post(GET_ALL_USERS_KLIPPING_FOLDERS, {user_id: selectedOption.value});
        console.log(response);

        const folderOptions =  response.data.map(item => ({value: item.id, label: item.title}));
        setFolderOptions(folderOptions);
    };

    const handleChangeFolder = async (selectedOption) => {
        console.log('Selected Option:', selectedOption);
        setFolderId(selectedOption.value);
    };

    const checkButtonDisabled = () => {
        setButtonDisabled(folderId!=null && selectedDate!=null && !isRunning   ? false : true);
    };

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    const handleButtonClick = async () => {
        setIsRunning(true);
        await sleep(1000);
        console.log('Button clicked');
        const response = await axios.post(PURGE_FOLDER_BY_DATE_URL, {folder_id: folderId, date_to: selectedDate});
        console.log(response);
        setIsRunning(false);
        showToast();
    };

    const showToast = () => {
        toast.success('Folder Data Purged successfully!', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    return (
        <Card>
            <CardHeader  title={intl.formatMessage({ id: "FOLDER_PURGING_TILE" })}  sticky={true}/>
            <CardBody>
                <div className="row">
                    <div className="col-md-3">
                        <Select
                            options={options}
                            onChange={handleChangeUser}
                            placeholder="Select a user..."
                        />
                    </div>

                    <div className="col-md-3">
                        <Select
                            options={folderOptions}
                            onChange={handleChangeFolder}
                            placeholder="Select a user folder..."
                        />
                    </div>
                    <div className="col-md-3">
                        <CustomDatePicker id="FolderPurging" dateValue={selectedDate} setDateValue={setSelectedDate}/>
                    </div>
                    <div className="col-md-3">
                        {/* Button */}
                        <button className="btn btn-primary" onClick={handleButtonClick}    disabled={buttonDisabled}>
                            Izvrši
                        </button>
                    </div>
                    <ToastContainer />
                </div>
            </CardBody>
        </Card>
    );
};
export default FolderPurging;
