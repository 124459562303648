import React, { useEffect, useState } from "react";
import axios from "axios";
import { ARHIVA_REST_URL } from "../../../../../environments/environment.prod";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useIntl } from "react-intl";
import { Table } from "../../shared";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { useSelector } from "react-redux";
import { getAllInstitutionsData } from "../../../../_redux/Institutions/actions";

export const ManageInstitute = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const assignedInst = useSelector(
    (state) =>
      state?.users?.UserInfo?.personal_information?.assigned_institute ?? null
  );

  const [institutesData, setInstitutesData] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedInstituteId, setSelectedInstituteId] = useState("");
  const [assignedInstitutes, setAssignedInstitutes] = useState([]);
  const [allIds, setAllIds] = useState([]);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPage] = useState(1);

  const [id, setId] = useState(null);

  const [instId, setInstId] = useState("");

  useEffect(() => {
    if (assignedInst) {
      console.log("assignedInst", assignedInst);
      window
        .$(".select2-selection__choice")
        .append(`<span>${assignedInst?.name}</span>`);
    }
  }, [assignedInst]);

  useEffect(() => {
    setId(window.$(`#kt_select2_1`));
  }, []);
  useEffect(() => {
    if (id !== null) {
      id.select2({
        width: "resolve",
        placeholder: "Select Institute Name",
        allowClear: true,
        // tags: true,
        maximumSelectionLength: 1,
        templateResult: (row) => {
          return row.name;
        },
        templateSelection: (row) => {
          return row.name;
        },
        minimumInputLength: 1,
        ajax: {
          delay: 250,
          url: `${ARHIVA_REST_URL}api/insitutions/search`,
          method: "post",
          data: function(params) {
            var query = {
              title: params.term,
            };

            // Query parameters will be ?search=[term]&type=public
            return query;
          },
          processResults: function(data, params) {
            params.page = params.page || 1;

            return {
              results: data.data.data,
            };
          },
        },
      });
      window.$(".select2-search__field").addClass("form-control");
    }
  }, [id]);

  useEffect(() => {
    if (id != null) {
      id.on("select2:unselecting", function(e) {
        console.log(e);
        setInstId("");
      });
    }
  }, [id]);

  useEffect(() => {
    if (id != null) {
      id.on("select2:select", function(e) {
        console.log("e", e.params.data);
        setInstId(e.params.data.id);
        // setInstitutionOptions(e.params.data);
        // setInstActId(e.params.data.id);
      });
    }
  }, [id]);

  useEffect(() => {
    console.log("errorMessage", errorMessage);
  }, [errorMessage]);

  const InstitutionsData = useSelector(
    (state) => state?.institutions?.Index ?? []
  );

  useEffect(() => {
    setInstitutesData(InstitutionsData);
  }, [InstitutionsData]);
  useEffect(() => {
    // window.$("#kt_datatable_example_1").DataTable();
  }, []);
  useEffect(() => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;
    dispatch(getAllInstitutionsData(data));
  }, []);
  useEffect(() => {
    setInstitutesData(props?.personal_information?.institutes);
  }, [props.personal_information]);
  useEffect(() => {
    setSelectedInstitute(
      props?.personal_information?.institutes?.map((institute) => {
        return { value: institute.id, label: institute.name };
      })
    );
  }, [props?.personal_information]);
  const assignInstituteToUser = (id) => {
    let data = {};
    console.log("Save Click");
    data.instituteId = instId;

    axios
      .post(`${ARHIVA_REST_URL}api/users/${id}/assign-user-institutes`, data)
      .then((response) => {
        dispatch(
          toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
        );
        dispatch(toasts.hideToast());
      })
      .catch((e) => {
        setErrorMessage(e.response.data.errors);
      });
  };
  useEffect(() => {
    setAssignedInstitutes(
      props.personal_information?.assigned_institutes ?? []
    );
  }, [props.personal_information]);
  const paginationOptions = {
    totalSize: 4,
    sizePerPage,
    page,
  };
  const openSubjectModal = (row) => {
    // var bb = [];
    // setAssignedInstitutes((state) => {
    //   bb = state
    //     ?.find((i) => i.institucija_id == row.id)
    //     ?.institute_subjects?.map((is) => {
    //       return { value: is.subjekt.name, label: is.subjekt.name };
    //     });
    //   setSelectedSubject(bb);
    //   return state;
    // });
    // setSelectedInstituteId(row.id);
    // setSubjects(
    //   row?.subjects?.map((sub) => {
    //     return { value: sub.name, label: sub.name };
    //   })
    // );
  };
  const handleSubjectChange = (e) => {
    setSelectedSubject(e);
  };
  const handleUserSubjectCall = (id) => {
    let data = {};
    data.institucija_id = selectedInstituteId;
    data.subjects = selectedSubject?.map((sub) => sub.value) ?? [];

    axios
      .post(
        `${ARHIVA_REST_URL}api/users/${id}/assign-user-institute-subjects`,
        data
      )
      .then((response) => {
        dispatch(
          toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
        );
        dispatch(toasts.hideToast());
        window.$(`#exampleModalAssignSubject`).modal("hide");
        props.getUserData();
      })
      .catch((e) => {})
      .finally();
  };

  return (
    <>
      <div className="flex-row-fluid ml-lg-8">
        <div className="card card-custom">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                {intl.formatMessage({ id: "Manage" })}{" "}
                {intl.formatMessage({ id: "Institutions" })}
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                {intl.formatMessage({ id: "Assign_Institute_To_User" })}
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                onClick={() => assignInstituteToUser(props.routId)}
                className="btn btn-success mr-2 d-flex"
              >
                {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
              </button>
              <a className="navi-link py-4" href="/admin/user/view/34">
                <button type="button" className="btn btn-secondary">
                  {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
                </button>
              </a>
            </div>
          </div>
          <form className="form">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-lg-12 col-md-9 col-sm-12">
                  <label>Select Institute</label>
                  <select
                    className="form-control select2"
                    id="kt_select2_1"
                    multiple
                    name="param"
                  >
                    <option label="Label"></option>
                    {assignedInst?.name ? (
                      <option label="Label" selected value></option>
                    ) : (
                      ""
                    )}
                  </select>
                  {errorMessage?.instituteId?.map((inE) => {
                    return <div className="text-danger mt-2">{inE}</div>;
                  })}
                  {/* {errorMessage?.instituteId?.map (
                    <div className="text-danger">
                      {ErrorMessages.errors.institute_id[0]}
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>

              {/* <div className="row">
                <div className="col-lg-9 col-xl-6 ">
                  <h5 className="font-weight-bold mb-6">
                    {intl.formatMessage({ id: "Institutions" })}
                  </h5>
                </div>
              </div> */}
              {/* <Table
                data={institutesData ?? []}
                columns={columns}
                pagiOptions={paginationOptions}
                setPage={setPage}
                setSizePerPage={setSizePerPage}
                selectRowOptions={{
                  allIds,
                  setAllIds,
                }}
                intl={intl}
              /> */}

              <div
                className="modal fade"
                id="exampleModalAssignSubject"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        {intl.formatMessage({ id: "Subjects" })}
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i aria-hidden="true" className="ki ki-close" />
                      </button>
                    </div>
                    <div className="modal-body">
                      <Select
                        isMulti
                        value={selectedSubject}
                        onChange={(e) => handleSubjectChange(e)}
                        options={subjects.map((sub) => {
                          return { value: sub.value, label: sub.label };
                        })}
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light-primary font-weight-bold"
                        data-dismiss="modal"
                      >
                        {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
                      </button>
                      <button
                        onClick={() => handleUserSubjectCall(props.routId)}
                        type="button"
                        className="btn btn-primary font-weight-bold"
                      >
                        {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
