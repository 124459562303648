import { arhivaSlice, callTypes } from "./ArhivaSlice";
const { actions } = arhivaSlice;

export const setFolderPosts = (data, stats) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    dispatch(actions.postsFetched({ data, stats }));
}

export const setArhivaQuery = (query) => dispatch => {
    dispatch(actions.queryApplied(query))
}

export const setArhivaURLparams = (url_params) => dispatch => {
    dispatch(actions.URLapplied(url_params))
}

export const setArhivaQueryValue = (value) => dispatch => {
    dispatch(actions.queryValueApplied(value))
}

export const openAsideModal = () => dispatch => {
    dispatch(actions.sidebarOpened())
}

export const closeAsideModal = () => dispatch => {
    dispatch(actions.sidebarClosed())
}

export const setDataSearchParams = (params) => dispatch => {
    dispatch(actions.dataSearchParamsSet(params))
}