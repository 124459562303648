import axios from "axios";
import {
  FOLDERS_URL,
  DELETE_FOLDER_URL,
  CREATE_FOLDER_URL,
  RENAME_FOLDER_URL,
  PURGE_FOLDER_URL,
  ADD_POST_URL,
  REMOVE_POST_URL,
} from "../../../../../_metronic/_helpers";

//export const KLIPING_URL = "https://wpdev-admin.arhiva.me/wp-json/arhimed/kliping/data";
export const KLIPING_URL =
  "http://54.36.238.4:8792/wpsolr_elasticsearch_arhiva/_search";

export async function getFolderData(folder_type) {
  let res = await axios.post(FOLDERS_URL, { folder_type });
  let data = await res.data;
  return data;
}

export async function deleteFolder(folder_id) {
  return axios.post(DELETE_FOLDER_URL, { folder_id });
}

export async function createFolder(
  folder_name,
  folder_type,
  description,
  podesavanja_pretrage,
  url_pretrage
) {
  return axios.post(CREATE_FOLDER_URL, {
    folder_name,
    folder_type,
    description,
    podesavanja_pretrage,
    url_pretrage,
  });
}

export async function renameFolder(
  folder_id,
  new_name,
  description,
  podesavanja_pretrage,
  url_pretrage
) {
  return axios.post(RENAME_FOLDER_URL, {
    folder_id,
    new_name,
    description,
    podesavanja_pretrage,
    url_pretrage,
  });
}

export async function purgeFolder(folder_id) {
  return axios.post(PURGE_FOLDER_URL, { folder_id });
}

export async function addPost(folder_id, post_ids) {
  return axios.post(ADD_POST_URL, { folder_id, post_ids });
}

export async function removePost(folder_id, post_ids) {
  return axios.post(REMOVE_POST_URL, { folder_id, post_ids });
}
