import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getAllPostSizeDataCall = () => {
  return axios.post(`${ARHIVA_REST_URL}api/sizes`);
};

export const createPostSizeCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/sizes/add`, data);
};

export const postDeleteCall = (id) => {
  return axios.delete(`${ARHIVA_REST_URL}api/sizes/delete/${id}`);
};

export const updatePostCall = (action) => {
  let data = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/sizes/edit`, data);
};

export const deleteAllPostsCall = (action) => {
  let data = action.payload;
  return axios.post(`${ARHIVA_REST_URL}api/sizes/bulk-delete`, data);
};
