import React from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogContentText, useMediaQuery, useTheme, DialogActions, Button } from '@material-ui/core';
import { closeFolderDialog } from '../../../_redux/folders/foldersActions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const PostInformationDialog = () => {
    const folders = useSelector(
        (state) => (state.folders),
        shallowEqual
    );
    const intl = useIntl();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { activePost: { content, title } } = folders;

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={true}
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="form-dialog-title">
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ maxHeight: 500 }}>
                        <span dangerouslySetInnerHTML={{ __html: content }} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            dispatch(closeFolderDialog());
                        }}
                        color="primary">
                        {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PostInformationDialog 
