import axios from "axios";
import {
  GET_ALL_SOURCES_URL,
  SAVE_SOURCE_URL,
  EDIT_SOURCE_URL,
  DELETE_SOURCE_URL,
} from "../../../_metronic/_helpers";

export const getSourcesDataCall = (data) => {
  return axios.post(GET_ALL_SOURCES_URL, data);
};

export const getSourceByIdCall = (data) => {
  return axios.post(GET_ALL_SOURCES_URL, data);
};

export const saveSourcesDataCall = (data) => {
  return axios.post(SAVE_SOURCE_URL, data);
};

export const editSourceCall = (actions) => {
  let data = actions.payload;
  return axios.post(`${EDIT_SOURCE_URL}`, data);
};

export const deleteSourceCall = (action) => {
  let id = action.id;
  return axios.delete(`${DELETE_SOURCE_URL}${id}`);
};
