import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import {
  a_institute_activity_edit_url,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import { Table } from "../shared";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { SearchTable } from "../shared/Table/SearchTable";
import SVG from "react-inlinesvg";
import {
  changeMultiActivity,
  deleteActivityFromRedux,
  deleteInstituteActivity,
  deleteMultiActivityFromRedux,
  editInstituteActivity,
  getAllInstituteActivities,
  removeMultiActivity,
  setInstituteActivityStatus,
  setMultiStatus,
} from "../../../_redux/InstituteActivities/actions";

export const Index = () => {
  const history = useHistory();
  const location = useLocation();

  const intl = useIntl();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);
  const [clickedRow, setClickedRow] = useState([]);
  const [getSearch, setSearch] = useState("");
  const InstitutionsActivitiesData = useSelector(
    (state) => state?.instituteActivity?.Index ?? []
  );

  useEffect(() => {
    console.log("params in Inst", location);
    // if (location.state) {
    //   setPage(location?.state?.page);
    // }
  }, [location]);

  const getInstituteActivities = () => {
    setAllIds([]);
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;
    data.title = getSearch;
    if (getSearch) {
      data.page = 0;
    }
    dispatch(getAllInstituteActivities(data));
  };

  useEffect(() => {
    getInstituteActivities();
  }, [page, sizePerPage, getSearch]);

  const changeInstituteActivityStatus = (e, row) => {
    let data = {};
    data.id = row.id;
    data.name = row.name;
    data.institute_id = row?.institute?.id;
    data.status = e.target.checked ? 1 : 0;

    dispatch(editInstituteActivity(data, toasts, intl));

    let id = row.id;
    let status = e.target.checked ? 1 : 0;
    dispatch(setInstituteActivityStatus(id, status));
  };

  const modalClose = (id) => {
    window.$(id).modal("hide");
  };

  const removeActivity = (row) => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;

    dispatch(deleteActivityFromRedux(row, modalClose));
    dispatch(deleteInstituteActivity(row, toasts, intl, data));
  };

  const handleEnableActivityStatus = (ids, status) => {
    dispatch(setMultiStatus(ids, status));
    dispatch(changeMultiActivity(ids, status, toasts, intl));
  };

  const deleteMultiItems = (ids) => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;
    dispatch(deleteMultiActivityFromRedux(ids, modalClose, setAllIds));
    dispatch(removeMultiActivity(ids, toasts, intl, data));
  };

  const paginationOptions = {
    totalSize: InstitutionsActivitiesData?.totalCount,
    sizePerPage,
    page,
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      headerStyle: { verticalAlign: "initial" },
      formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push({
            pathname: `${a_institute_activity_edit_url}/${row.id}`,
            search: "sdas",
            state: { page: "page" },
          });
        },
      },
    },
    {
      dataField: "institute.name",
      text: "Institute Name",
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),

      formatter: (val) => {
        return moment(val).format("DD.MM.YYYY");
      },

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),

      formatter: (val) => {
        return moment(val).format("DD.MM.YYYY");
      },

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),

      formatter: (cell, row) => {
        return (
          <>
            <a
              href
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                history.push({
                  pathname: `${a_institute_activity_edit_url}/${row.id}`,
                  // search: "sdas",
                  state: { page: page },
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>

            {/* ---------------------------- Delete Button --------------------------- */}
            <span
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target={`#exampleModalDelete`}
              onClick={() => {
                setClickedRow(row);
              }}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              title={intl.formatMessage({
                id: "MENU.OPTIONS.DELETE",
              })}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
              {/*end::Svg Icon*/}
            </span>
            <span
              title={row.status == 1 ? "Verified" : "Unverify"}
              className="btn btn-icon btn-sm mx-3"
            >
              <span className="switch switch-sm switch-icon switch-primary">
                <label>
                  <input
                    type="checkbox"
                    checked={row.status == 1 ? true : false}
                    name="select"
                    onClick={(e) => changeInstituteActivityStatus(e, row)}
                  />
                  <span />
                </label>
              </span>
            </span>
          </>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({ id: "Institutions" }) + " " + "Activity"}
          sticky
        >
          <CardHeaderToolbar>
            <button
              className="btn btn-primary font-weight-bold"
              onClick={() => {
                history.push(a_institute_activity_edit_url);
              }}
            >
              Create Institution Activity
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
            <SearchTable setSearch={setSearch} />
          </div>
          {allIds.length > 1 && (
            <div className="form-group">
              <p>Selected records count:{allIds.length}</p>
              <button
                type="button"
                data-toggle="modal"
                data-target={`#exampleModalDeleteMulti`}
                className="btn btn-danger font-weight-bolder font-size-sm"
              >
                <i className="fa fa-trash"></i>
                {intl.formatMessage({ id: "COMMON.Delete" })}
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={() => handleEnableActivityStatus(allIds, true)}
              >
                {intl.formatMessage({ id: "Enable" })}
              </button>{" "}
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={() => handleEnableActivityStatus(allIds, false)}
              >
                {intl.formatMessage({ id: "Disable" })}
              </button>
            </div>
          )}

          <Table
            data={InstitutionsActivitiesData?.data ?? []}
            columns={columns}
            pagiOptions={paginationOptions}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            selectRowOptions={{
              allIds,
              setAllIds,
            }}
            intl={intl}
          />
        </CardBody>
      </Card>

      <div
        className="modal fade"
        id={`exampleModalDelete`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}{" "}
                {intl.formatMessage({ id: "Institution" })} Activities
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              {intl.formatMessage({ id: "DELETE_INSTITUTE_DESCRIPTION" })}{" "}
              Activities?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary font-weight-bold"
                onClick={() => {
                  removeActivity(clickedRow);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id={`exampleModalDeleteMulti`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })}{" "}
                {intl.formatMessage({ id: "Institution" })} Activities
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              {/* {intl.formatMessage({ id: "DELETE_INSTITUTE_DESCRIPTION" })}?
               */}
              Are you sure you want to delete these institution activities?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary font-weight-bold"
                onClick={() => deleteMultiItems(allIds)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
