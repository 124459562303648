import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { useQuery } from "react-query";

import { ARHIVA_REST_URL } from "../../environments/environment";
import * as moment from "moment";
import Select from "react-select";
import { useDispatch } from "react-redux";
import * as toasts from "../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Table } from "../modules/Admin/shared";
import { sortCaret } from "../../_metronic/_helpers";
import {
  getAllChannelsData,
  createChannelData,
  deleteChannels,
  editChannel,
  assignUser,
  deleteAllChannels,
  enableChannelStatus,
  disableChannelStatus,
  deleteChannelFromRedux,
  multiDeleteChannelsFromRedux,
  editChannelFromRedux,
} from "../_redux/channels/actions";
import { createChannel } from "../_redux/channels/actions";
import { SearchTable } from "../modules/Admin/shared/Table/SearchTable";
import { getAllUsers } from "../_redux/users/actions";
export const Channels = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [getSearch, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);
  const [clickedRow, setClickedRow] = useState("");

  const data = useSelector((state) => state?.channels?.Index ?? []);
  const users = useSelector((state) => state?.users?.Index.data ?? []);
  const showLoader = useSelector((state) => state?.channels?.showLoader ?? []);
  const [name, setName] = useState("");
  const [order, setOrder] = useState(null);
  const [channelStatus, setChannelStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [checkedUserId, setCheckedUserId] = useState([]);

  const [allIds, setAllIds] = useState([]);

  const [approvelStatus, setApprovelStatus] = useState("");
  const [filterBy, setFilterBy] = useState({
    status: "All",
    rule_type: "All",
    network_type: "All",
  });
  // -------------- state vars end ---------------------

  const paginationOptions = {
    totalSize: data?.totalCount ?? 0,
    sizePerPage,
    page,
  };

  // const [orderBy, setOrderBy] = useState({
  //   order_by: "",
  //   order_direction: "",
  // });
  const [ord, setOrderOrder] = useState("");
  const onSort = (field, order) => {
    setOrderOrder({
      order_by: field,
      order_direction: order,
    });
  };

  let getAllChannelsPayload = {
    page: page - 1,
    size: sizePerPage,
    status: filterBy.status,
    title: getSearch,
  };
  const getAllData = () => {
    dispatch(getAllChannelsData(getAllChannelsPayload));
  };
  const { isLoading, isFetching } = useQuery(
    ["media", page, sizePerPage, filterBy, getSearch],
    () => getAllData(page),
    { keepPreviousData: true }
  );

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "TH_STATUS" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (status) => {
        return status === 0 || status === "" ? (
          <span className="label label-lg label-light-danger label-inline">
            Disabled
          </span>
        ) : (
          <span className="label label-lg label-light-success label-inline">
            Enabled
          </span>
        );
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },

    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy");
      },
      // formatter: columnFormatters.ColorColumnFormatter,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy");
      },
      // formatter: columnFormatters.ColorColumnFormatter,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: (cell, row) => {
        return (
          <>
            <a
              href
              data-toggle="modal"
              data-target="#exampleModalEdit"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                setClickedRow(row.id);
                setName(row.name);
                setOrder(row.order_by);
                setChannelStatus(row.status);
                setErrMessage("");
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <title>
                    {intl.formatMessage({ id: "MENU.OPTIONS.EDIT" })}
                  </title>
                  <desc>Created with Sketch.</desc>
                  <defs />
                  <g
                    id="Stockholm-icons-/-Communication-/-Write"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect id="bound" x={0} y={0} width={24} height={24} />
                    <path
                      d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                      id="Path-11"
                      fill="#000000"
                      fillRule="nonzero"
                      transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                    />
                    <path
                      d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                      id="Path-57"
                      fill="#000000"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                  </g>
                </svg>
              </span>
            </a>
            {/* // ----------------------- assign button ------------------------- */}
            <span
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target={`#exampleModalAssign`}
              onClick={() => {
                setErrMessage("");
                setCheckedUserId(row.id);
                setSelectedUser(
                  row?.users?.map((user) => {
                    return {
                      value: user.id,
                      label: user.username,
                      priority: user.pivot.priority,
                      approved: user.pivot.approved,
                    };
                  })
                );
              }}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              title={intl.formatMessage({ id: "Assign_User" })}
            >
              {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Code/Github.svg*/}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <path
                    d="M16.5428932,17.4571068 L11,11.9142136 L11,4 C11,3.44771525 11.4477153,3 12,3 C12.5522847,3 13,3.44771525 13,4 L13,11.0857864 L17.9571068,16.0428932 L20.1464466,13.8535534 C20.3417088,13.6582912 20.6582912,13.6582912 20.8535534,13.8535534 C20.9473216,13.9473216 21,14.0744985 21,14.2071068 L21,19.5 C21,19.7761424 20.7761424,20 20.5,20 L15.2071068,20 C14.9309644,20 14.7071068,19.7761424 14.7071068,19.5 C14.7071068,19.3673918 14.7597852,19.2402148 14.8535534,19.1464466 L16.5428932,17.4571068 Z"
                    fill="red"
                    fillRule="nonzero"
                  />
                  <path
                    d="M7.24478854,17.1447885 L9.2464466,19.1464466 C9.34021479,19.2402148 9.39289321,19.3673918 9.39289321,19.5 C9.39289321,19.7761424 9.16903559,20 8.89289321,20 L3.52893218,20 C3.25278981,20 3.02893218,19.7761424 3.02893218,19.5 L3.02893218,14.136039 C3.02893218,14.0034307 3.0816106,13.8762538 3.17537879,13.7824856 C3.37064094,13.5872234 3.68722343,13.5872234 3.88248557,13.7824856 L5.82567301,15.725673 L8.85405776,13.1631936 L10.1459422,14.6899662 L7.24478854,17.1447885 Z"
                    fill="red"
                    fillRule="nonzero"
                    opacity="0.3"
                  />
                </g>
              </svg>
              {/*end::Svg Icon*/}
            </span>
            {/* ---------------------------- Delete Button --------------------------- */}

            <span
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target={`#exampleModalDelete`}
              onClick={() => {
                setClickedRow(row.id);
              }}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              title={intl.formatMessage({
                id: "MENU.OPTIONS.DELETE",
              })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <path
                    d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                    fill="red"
                    fillRule="nonzero"
                  />
                  <path
                    d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                    fill="red"
                    opacity="0.3"
                  />
                </g>
              </svg>
              {/*end::Svg Icon*/}
            </span>
          </>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  // ----------------- component did mount end -------------------

  // ------------- Modal Func ---------------------------

  const closeModal = (id) => {
    window.$(`#${id}`).modal("hide");
  };

  // ---------------- Modal func end ----------------------

  // --------------------- Functions start ---------------------------

  const handleCreateChannel = () => {
    let data = {};
    data.name = name;
    data.order_by = order;
    dispatch(
      createChannel(
        data,
        toasts,
        intl,
        closeModal,
        setErrMessage,
        getAllChannelsPayload
      )
    );
  };

  const handleEnableChannelsStatus = (idsToChecked) => {
    let data = {};
    data.ids = idsToChecked;
    data.status = true;

    dispatch(
      enableChannelStatus(data, intl, toasts.hideToast, getAllChannelsPayload)
    );
  };
  const handleDisableChannelsStatus = (idsToChecked) => {
    let data = {};
    data.ids = idsToChecked;
    data.status = false;
    dispatch(disableChannelStatus(data, intl, toasts, getAllChannelsPayload));
  };

  const deleteSelectedItems = (idsToDelete) => {
    let data = {};
    data.ids = idsToDelete;
    dispatch(multiDeleteChannelsFromRedux(idsToDelete, setAllIds, closeModal));
    dispatch(deleteAllChannels(data, toasts, intl));
  };

  const hanedleDelete = (id) => {
    dispatch(deleteChannelFromRedux(id, closeModal));
    dispatch(
      deleteChannels(id, intl, toasts, closeModal, getAllChannelsPayload)
    );
  };

  const handleEditCheck = (e) => {
    setChannelStatus(e.target.checked);
  };

  const handleAssignUser = (id) => {
    let data = {};
    data.users = selectedUser?.map((selectedUser) => {
      return {
        id: selectedUser.value,
        priority: selectedUser.priority,
        approved: selectedUser.approved == 1 ? true : false ?? "",
      };
    });
    dispatch(
      assignUser(
        data,
        id,
        intl,
        toasts,
        closeModal,
        setErrMessage,
        getAllChannelsPayload
      )
    );
  };
  const handleSelect = (e) => {
    setSelectedUser(e);
  };

  const handleUpdateChannel = (id) => {
    let data = {};
    data.status = channelStatus;
    data.name = name;
    data.order_by = order;
    // dispatch(editChannelFromRedux(data, id));
    dispatch(
      editChannel(
        data,
        id,
        intl,
        toasts,
        closeModal,
        setErrMessage,
        getAllChannelsPayload
      )
    );
  };

  const handleApprovelStatus = (e, userId) => {
    let changeUserApproved = [...selectedUser];
    changeUserApproved.find((obj) => obj.value == userId).approved = e.value;
    setSelectedUser(changeUserApproved);
  };

  const handleSingleSelect = (e, userId) => {
    let changeUserPriority = [...selectedUser];
    changeUserPriority.find((obj) => obj.value == userId).priority = e.value;
    setSelectedUser(changeUserPriority);
  };

  const handleAllSelect = (e) => {
    let changeAllUserPriority = [...selectedUser];
    changeAllUserPriority.map((up) => {
      up.priority = e.target.value;
      return up;
    });
    setSelectedUser(changeAllUserPriority);
  };

  // -------------------------- Functions End ------------------------------
  const emptyFormValues = () => {
    setErrMessage("");
    setName("");
  };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "MENU.CHANNEL" })} sticky>
          <CardHeaderToolbar>
            {/* ------- Create New channel modal button start -------- */}
            <button
              className="btn btn-primary font-weight-bold"
              data-toggle="modal"
              data-target="#exampleModal"
              onClick={() => {
                emptyFormValues();
              }}
            >
              {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}
            </button>
            {/* ------- Create New channel modal button End -------- */}
          </CardHeaderToolbar>
        </CardHeader>

        <CardBody>
          <div className="form-group row justify-content-between">
            <div className="col-lg-3">
              <select
                className="form-control"
                name="type"
                placeholder="Filter by Status"
                onChange={(e) => {
                  setFilterBy({
                    ...filterBy,
                    status: e.target.value,
                  });
                  setPage(1);
                }}
                value={filterBy.status}
              >
                <option value={"All"}>All</option>
                <option value={1}>Enabled</option>
                <option value={0}>Disabled</option>
              </select>
              <small className="form-text text-muted">
                <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Status
              </small>
            </div>

            <SearchTable setSearch={setSearch} />
          </div>

          {allIds.length > 1 && (
            <div className="form-group">
              <p>Selected records count:{allIds.length}</p>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                data-toggle="modal"
                data-target={`#exampleModalMultiDelete`}
                // onClick={() => deleteSelectedItems(allIds)}
              >
                <i className="fa fa-trash"></i>{" "}
                {intl.formatMessage({ id: "COMMON.Delete" })}
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={() => handleEnableChannelsStatus(allIds)}
              >
                {intl.formatMessage({ id: "Enable" })}
              </button>{" "}
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={() => handleDisableChannelsStatus(allIds)}
              >
                {intl.formatMessage({ id: "Disable" })}
              </button>
            </div>
          )}

          {/* ------------------ Bootstrap Tbale start ------------------ */}
          <Table
            showLoader={showLoader}
            data={data.data ?? []}
            columns={columns}
            pagiOptions={paginationOptions}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            selectRowOptions={{
              allIds,
              setAllIds,
            }}
            intl={intl}
          />
          {/* ------------------ Bootstrap Tbale End ------------------ */}

          {/* -------------------------------------- Modals ------------------------------------------------- */}
          {/* ---------------------- Delete User Modal -------------------- */}
          <div
            className="modal fade"
            id={`exampleModalDelete`}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {intl.formatMessage({
                      id: "CHANNELS.DELETE_CHANNEL_SIMPLE.TITLE",
                    })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {intl.formatMessage({
                    id: "CHANNELS.DELETE_CHANNEL_SIMPLE.DESCRIPTION",
                  })}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    onClick={() => {
                      hanedleDelete(clickedRow);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ----------------------------------- Multi Delete Modal ------------------------------------ */}
          <div
            className="modal fade"
            id={`exampleModalMultiDelete`}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {intl.formatMessage({
                      id: "CHANNELS.DELETE_CHANNEL_SIMPLE.TITLE",
                    })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {/* {intl.formatMessage({
                    id: "CHANNELS.DELETE_CHANNEL_SIMPLE.DESCRIPTION",
                  })} */}
                  Are you sure to permanently delete these channels?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    onClick={() => deleteSelectedItems(allIds)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ------------------ Assign Modal ---------------- */}
          <div
            className="modal fade"
            id={`exampleModalAssign`}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {/* {d?.name} */} {intl.formatMessage({ id: "Assign" })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="modalOne"
                  >
                    <i
                      aria-hidden="true"
                      id="modalOne"
                      className="ki ki-close"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="exampleSelect1">
                      {intl.formatMessage({ id: "Assign_User" })}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      onChange={(e) => handleSelect(e)}
                      isMulti
                      value={selectedUser}
                      options={users?.map((user) => {
                        return { value: user.id, label: user.username };
                      })}
                    />
                    <div className="text-danger">{errMessage?.users ?? ""}</div>

                    <div className="form-group mt-3">
                      <label>{intl.formatMessage({ id: "Priorities" })}</label>
                      <div className="radio-inline">
                        <label className="radio">
                          <input
                            value="dont_receive"
                            onClick={(e) => handleAllSelect(e)}
                            type="radio"
                            name="radios2"
                          />
                          <span />
                          {/* {intl.formatMessage({ id: "User_Not_Sending" })} */}
                          not receive
                        </label>
                        <label className="radio">
                          <input
                            value="all_news"
                            onClick={(e) => handleAllSelect(e)}
                            type="radio"
                            name="radios2"
                          />
                          <span />
                          {/* {intl.formatMessage({ id: "User_All" })} */}
                          all news
                        </label>
                        <label className="radio">
                          <input
                            value="important_news"
                            onClick={(e) => handleAllSelect(e)}
                            type="radio"
                            name="radios2"
                          />
                          <span />
                          {intl.formatMessage({ id: "User_Important" })} news
                        </label>
                        <label className="radio">
                          <input
                            value="most_important_news"
                            onClick={(e) => handleAllSelect(e)}
                            type="radio"
                            name="radios2"
                          />
                          <span />
                          {/* {intl.formatMessage({ id: "User_Important" })} */}
                          most important
                        </label>
                      </div>
                    </div>
                    {selectedUser?.map((su) => {
                      return (
                        <div className="d-flex align-items-center mb-2">
                          {/*begin::Bullet*/}
                          <span className="bullet bullet-bar bg-success align-self-stretch mr-2" />

                          <div className="d-flex flex-column flex-grow-1">
                            <a
                              href
                              className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                            >
                              {su.label}
                            </a>
                            <span className="text-muted font-weight-bold">
                              {su.priority}
                            </span>
                          </div>
                          <div
                            className="dropdown dropdown-inline ml-2 w-25"
                            data-toggle="tooltip"
                            title
                            data-placement="left"
                            data-original-title="Quick actions"
                          >
                            <Select
                              // onChange={(e) => handleSingleSelect(e, su.value)}
                              value={{
                                value: su.approved,
                                label:
                                  su.approved == 1 ? "Approved" : "disapproved",
                              }}
                              onChange={(e) =>
                                handleApprovelStatus(e, su.value)
                              }
                              options={[
                                { value: 1, label: "Approved" },
                                { value: 0, label: "disapproved" },
                              ]}
                            />
                          </div>
                          <div
                            className="dropdown dropdown-inline ml-2 w-25"
                            data-toggle="tooltip"
                            title
                            data-placement="left"
                            data-original-title="Quick actions"
                          >
                            <Select
                              onChange={(e) => handleSingleSelect(e, su.value)}
                              value={{ value: su.priority, label: su.priority }}
                              options={[
                                { value: "not_sending", label: "not sending" },
                                { value: "all", label: "all" },
                                { value: "important", label: "important" },
                              ]}
                            />
                          </div>
                          {/*end::Dropdown*/}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold"
                    data-dismiss="modal"
                    id="modalOne"
                  >
                    {intl.formatMessage({
                      id: "CONTACT.GENERAL.CANCEL.BUTTON",
                    })}
                  </button>
                  <button
                    onClick={() => handleAssignUser(checkedUserId)}
                    type="button"
                    className="btn btn-primary font-weight-bold"
                  >
                    {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Modal Edit*/}
          <div
            className="modal fade"
            id={`exampleModalEdit`}
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {intl.formatMessage({
                      id: "MENU.OPTIONS.EDIT",
                    })}{" "}
                    {intl.formatMessage({
                      id: "MENU.CHANNEL",
                    })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {" "}
                  <div className="form-group">
                    <label>{intl.formatMessage({ id: "Name" })}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={intl.formatMessage({ id: "Name" })}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setErrMessage("");
                      }}
                    />
                    <div className="text-danger">{errMessage?.name ?? ""}</div>
                  </div>
                  <div className="form-group">
                    <label>Order By</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Order By"
                      value={order}
                      onChange={(e) => {
                        setOrder(e.target.value);
                        // setErrMessage("");
                      }}
                    />
                    <div className="text-danger">
                      {errMessage?.order_by ?? ""}
                    </div>
                  </div>
                  <span className="switch switch-icon">
                    <label>
                      <input
                        checked={channelStatus}
                        type="checkbox"
                        name="select"
                        id="select"
                        onChange={(e) => handleEditCheck(e)}
                      />
                      <span />
                    </label>
                  </span>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold"
                    data-dismiss="modal"
                  >
                    {intl.formatMessage({
                      id: "CONTACT.GENERAL.CANCEL.BUTTON",
                    })}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    onClick={() => {
                      handleUpdateChannel(clickedRow);
                    }}
                  >
                    {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal create channel start*/}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {intl.formatMessage({ id: "MENU.OPTIONS.CREATE" })}{" "}
                    {intl.formatMessage({
                      id: "MENU.CHANNEL",
                    })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close" />
                  </button>
                </div>
                <div className="modal-body">
                  {" "}
                  <div className="form-group">
                    <label>{intl.formatMessage({ id: "Name" })}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={intl.formatMessage({ id: "Name" })}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setErrMessage("");
                      }}
                    />
                    <div className="text-danger">{errMessage?.name ?? ""}</div>
                  </div>
                  <div className="form-group">
                    <label>Order By</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Order By"
                      value={order}
                      onChange={(e) => {
                        setOrder(e.target.value);
                        // setErrMessage("");
                      }}
                    />
                    <div className="text-danger">
                      {errMessage?.order_by ?? ""}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold"
                    data-dismiss="modal"
                  >
                    {intl.formatMessage({
                      id: "CONTACT.GENERAL.CANCEL.BUTTON",
                    })}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold"
                    onClick={handleCreateChannel}
                  >
                    {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Modal create channel end*/}
        </CardBody>
      </Card>
    </>
  );
};
