import { pridruzeno_folderima_t, pridruzeno_pretragama_t } from './Constants';
import moment from 'moment';

export const queryHelper = (folder, id) => {
    let queryArray = [];
    let queryType = undefined;
    if (folder?.podesavanja_pretrage) {
        return JSON.parse(folder.podesavanja_pretrage);
    }

    if (folder?.pridruzene_pretrage) {
        // create an array of queries by combining their ids with fid
        folder.pridruzene_pretrage.map(item => {
            queryArray.push(`*fid_${item}_fid*`);
            queryType = pridruzeno_pretragama_t
        });
    }
    else {
        queryArray.push(`*fid_${id}_fid*`);
        queryType = pridruzeno_folderima_t
    }
    let obj = {

        "query": {
            "bool": {
                "must":
                {
                    "query_string": {
                        "query": queryArray.join(" "),
                        "fields": [queryType]
                    }
                }
            }
        }

    }
    return obj;
}

export const defaultSearchQuery = (folder) => {
    // query from currently modified active folder
    if (folder?.podesavanja_pretrage) {
        return JSON.parse(folder.podesavanja_pretrage);
    }

    // default search query
    return {
        "query": {
            "bool": {
                "must": [],
                "filter": [
                    {
                        "match_all": {}
                    },
                    {
                        "bool": {
                            "should": [
                                {
                                    "match_phrase": {
                                        "type": "stampani"
                                    }
                                },
                                {
                                    "match_phrase": {
                                        "type": "internet"
                                    }
                                },
                                {
                                    "match_phrase": {
                                        "type": "elektronski"
                                    }
                                }
                            ],
                            "minimum_should_match": 1
                        }
                    },
                    {
                        "match_phrase": {
                            "post_status_s": "publish"
                        }
                    }
                ],
                "should": [],
                "must_not": [],
            }
        }


    }
}
export const URLexpression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export const transformData = async (response, lang) => {
    const res = await response.data;
    const data = [];
    for (const [index, value] of res.entries()) {
        const val = {};
        val.id = value._id;
        moment.locale(lang)
        val.displaydate = moment(value.displaydate).format("DD.MMMM.YYYY");
        val.title = value.title;
        val.type = value.type;
        val.extras = value.dodatni_podaci_t;
        val.content = value.content;
        if (value.rijeci_str !== undefined) {
            val.rijeci_str = value.rijeci_str.join(", ");
        }
        data.push(val);
    }
    return data;
}

export const defaultExtrasQuery = (terms_tip_str) => {
    return {
        "query": {
            "bool": {
                "must": [],
                "filter": [
                    {
                        "match_all": {}
                    },
                    {
                        "match_phrase": {
                            "type": "terms"
                        }
                    },
                    {
                        "match_phrase": {
                            "terms_tip_str": terms_tip_str
                        },
                    }
                ],
                "should": [],
                "must_not": []
            }
        },
    }
}