import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import * as constants from "../../_helpers/Constants";
import { toastPopup } from "./ToastFunctions";
import { ToastContainer } from "react-toastify";
import "./Toast.css";
const Toast = () => {
  const toast = useSelector((state) => ({
    status: state.toasts.status,
    type: state.toasts.type,
    title: state.toasts.title,
  }));
  const intl = useIntl();

  useEffect(() => {
    if (toast.status !== undefined && toast.title) {
      if (toast.status == 200) {
        toastPopup(
          intl.formatMessage({ id: toast.title }),
          constants.SUCCESS_STRING
        );
      } else {
        toastPopup(
          intl.formatMessage({ id: toast.title }),
          constants.ERROR_STRING
        );
      }
    }
  }, [toast]);

  return <ToastContainer toastClassName="Toastify__toast" />;
};

export default Toast;
