import React from "react";
import { ReactiveBase } from "@appbaseio/reactivesearch";
import { ARHIVA_REST_URL } from "../../../environments/environment";

const BaseComponent = (props) => {
  return (
    <ReactiveBase
      app="wpsolr_elasticsearch_arhiva"
      // url="http://127.0.0.1:8000/api/"
      url={`${ARHIVA_REST_URL}api/`}
      headers={{
        authorization:
          "Basic " +
          Buffer.from("arhivasearch:SZt3V3N6s7ZvG9ML").toString("base64"),
      }}
    >
      {props.children}
    </ReactiveBase>
  );
};

export default BaseComponent;
