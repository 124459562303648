import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { clipping_media_url, searches_url } from "../../_helpers";
import FolderCard from "../../../app/modules/ECommerce/pages/folders/folder-card/FolderCard";

export function Folders() {
    const { id } = useParams();
    // convert to state prop
    const type = (id) => {
        switch (id) {
            case 'licni': return 'licni';
            case `${searches_url}`: return 'pretraga';
            case `${clipping_media_url}`: return 'kliping'
        }
    }
    const { data } = useSelector(
        (state) => ({ data: state.folders.data }),
        shallowEqual
    );

    return (
        <>
            {!data[type(id)] ? null : (
                Object.keys(data[type(id)]).map(key => {
                    return (
                        <React.Fragment key={key}>
                            <FolderCard folder={data[type(id)][key]} id={key} />
                        </React.Fragment>
                    )
                }))}
        </>);
}
