import axios from "axios";

class ProfileService {
  constructor() {
    this.axiosInstance = axios.create({
      headers: {
        "Content-type": "application/json",
      },
    });
    const storage = JSON.parse(localStorage.getItem("persist:arhiva-auth"));
    this.token = JSON.parse(storage.authToken);

    this.axiosInstance.interceptors.request.use((req) => {
      req.headers.Authorization = `Bearer ${this.token}`;
      return req;
    });
  }

  post(url, payload) {
    return this.axiosInstance.post(url, payload);
  }

  get(url) {
    return this.axiosInstance.get(url);
  }

  delete(url) {
    return this.axiosInstance.delete(url);
  }
}

export default ProfileService;
