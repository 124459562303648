/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  checkIsActive,
  announcements_url,
  archive_url,
  clipping_media_url,
  monitoring_url,
  admin_url,
} from "../../../../_helpers";
import { useIntl } from "react-intl";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };
  const {
    user: { account_information },
  } = useSelector((state) => state.auth);
  const intl = useIntl();

  const isAdmin = account_information?.roles?.includes("administrator");
  const isManager = account_information?.roles?.includes("manager");
  const showAdminPage = isAdmin || isManager;

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {showAdminPage && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              `${admin_url}`
            )}`}
          >
            <NavLink className="menu-link" to={`${admin_url}`}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Spy.svg")} />
              </span>
              <span className="menu-text">
                {intl.formatMessage({ id: "MENU.ADMIN" })}
              </span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            `/${archive_url}`
          )}`}
        >
          <NavLink className="menu-link" to={`/${archive_url}`}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "MENU.ARHIVA_MEDIJA" })}
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            `/${clipping_media_url}`
          )}`}
        >
          <NavLink className="menu-link" to={`/${clipping_media_url}`}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Scissors.svg")}
              />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "MENU.KLIPING_MEDIJA" })}
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            `/${announcements_url}`
          )}`}
        >
          <NavLink className="menu-link" to={`/${announcements_url}`}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Notifications1.svg"
                )}
              />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "MENU.ANNOUNCEMENTS" })}
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            `/${monitoring_url}`
          )}`}
        >
          <NavLink className="menu-link" to={`/${monitoring_url}`}>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "MENU.MONITORING" })}
            </span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
