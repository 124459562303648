/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Switch from "@material-ui/core/Switch";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useQuery, useMutation } from "react-query";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";

import {
  Input,
  Select,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter,
} from "../../../../_metronic/_partials/controls";
import {
  GET_CRAWLER_BY_ID,
  GET_ALL_CRAWLER_RULE_TYPES,
  GET_ALL_CRAWLER_NETWORK_TYPES,
  SAVE_CRAWLER_URL,
  EDIT_CRAWLER_URL,
  GET_CRAWLER_SETTINGS_URL,
  UPDATE_CRAWLER_SETTINGS_URL,
  CREATE_CRAWLER_SETTINGS_URL,
  a_crawler_url,
} from "../../../../_metronic/_helpers/Constants";
import ProfileService from "../../Profile/service";
import * as moment from "moment";
import { getUser } from "../../../_redux/users/actions";
import {
  getCrawler,
  getCrawlerRuleType,
} from "../../../_redux/Crawler/actions";

const EditSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  cron_timeout: Yup.number()
    .required()
    .min(60),
  profile: Yup.string().required(),
  count: Yup.number()
    .required()
    .min(1)
    .max(20),
});

const CrawlerEdit = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [currentCrawler, setCurrentCrawler] = useState(null);
  const [crawlerSettingsMap, setCrawlerSettingsMap] = useState(
    getDefaultSettingsMap()
  );
  const [createdBy, setCreatedBy] = useState(null);
  const history = useHistory();
  const service = new ProfileService();

  const params = useParams();

  useEffect(() => {
    const fetchUser = (id) => {
      dispatch(getUser(id, setCreatedBy));
    };

    const fetchCrawler = async () => {
      if (!params.id) return;
      dispatch(getCrawler(params.id));
      try {
        const crawler = (await service.get(GET_CRAWLER_BY_ID + params.id))
          .data[0];
        setCurrentCrawler(crawler);
        fetchUser(crawler.created_by);
      } catch (error) {}
    };

    fetchCrawler();
  }, [params]);

  const fetchCrawlerRuleTypes = async () => {
    // dispatch(getCrawlerRuleType());
    const response = await service.post(GET_ALL_CRAWLER_RULE_TYPES);
    return response.data;
  };

  const fetchCrawlerNetworkTypes = async () => {
    const response = await service.post(GET_ALL_CRAWLER_NETWORK_TYPES);
    return response.data;
  };

  const fetchCrawlerSettings = async () => {
    const response = await service.post(GET_CRAWLER_SETTINGS_URL, {
      crawler_id: params.id,
    });
    if (!params.id || !response?.data?.data?.length) {
      return [];
    }
    // convert settings array to map
    setCrawlerSettingsMap(
      response?.data?.data?.reduce(
        (acc, item) => ({ ...acc, [item.setting_key]: item }),
        {}
      )
    );
    return response;
  };

  const crawlerSettings = useQuery(
    ["crawlerSettings"],
    () => fetchCrawlerSettings(),
    {
      keepPreviousData: true,
    }
  );

  const ruleTypes = useQuery(["ruleTypes"], () => fetchCrawlerRuleTypes(), {
    keepPreviousData: true,
  });

  const networkTypes = useQuery(
    ["networkTypes"],
    () => fetchCrawlerNetworkTypes(),
    {
      keepPreviousData: true,
    }
  );

  const saveCrawler = async (values) => {
    const id = +params.id || null;
    const data = id ? { ...values, id } : { ...values };
    const settings = Object.values(crawlerSettingsMap).map((item) => ({
      ...item,
      setting_value: data[item.setting_key],
    }));
    // try {
    // create/update crawler
    const crawler = await service.post(
      id ? EDIT_CRAWLER_URL : SAVE_CRAWLER_URL,
      data
    );
    // create/update crawler settings
    for (const setting of settings) {
      await service.post(
        setting.id ? UPDATE_CRAWLER_SETTINGS_URL : CREATE_CRAWLER_SETTINGS_URL,
        { ...setting, crawler_id: crawler?.data?.data?.id || id }
      );
    }
    return crawler;
  };

  const mutation = useMutation(saveCrawler, {
    onSuccess: (data) => {
      dispatch(
        toasts.showToast(
          data?.data?.status,
          intl.formatMessage({ id: data?.data?.title })
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(
        toasts.showToast(
          error.response.data.status,
          intl.formatMessage({
            id: error.response.data.details
              ? error.response.data.details
              : error.response.data.errors[0],
          })
        )
      );
      dispatch(toasts.hideToast());
    },
  });

  const backToCrawlerList = () => {
    history.push(`${a_crawler_url}`);
  };
  const btnRef = useRef();

  const saveCrawlerClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  if (
    ruleTypes.isLoading ||
    networkTypes.isLoading ||
    crawlerSettings.isLoading
  )
    return null;

  const currentCrawlerSettings =
    params.id && crawlerSettings?.data?.data?.length > 0
      ? {
          [crawlerSettings.data?.data[0]?.setting_key]: crawlerSettings.data
            ?.data[0]?.setting_value,
          [crawlerSettings.data?.data[1]?.setting_key]: crawlerSettings.data
            ?.data[1]?.setting_value,
        }
      : {
          profile: "",
          count: "",
        };

  const initialValues = {
    title: currentCrawler ? currentCrawler?.title : "",
    description: currentCrawler ? currentCrawler?.description : "",
    rule_type: currentCrawler
      ? currentCrawler?.rule_type
      : ruleTypes?.data?.data[0]?.id,
    network_type: currentCrawler
      ? currentCrawler?.network_type
      : networkTypes?.data?.data[0]?.id,
    status: currentCrawler ? !!currentCrawler?.status : true,
    cron_timeout: currentCrawler ? currentCrawler?.cron_timeout : 120,
    profile: crawlerSettingsMap?.profile?.setting_value || "",
    count: crawlerSettingsMap?.count?.setting_value || 10,
  };

  function getDefaultSettingsMap() {
    const settingKeys = ["profile", "count"];
    return settingKeys.reduce((acc, setting_key, i) => {
      return {
        ...acc,
        [setting_key]: { setting_key, setting_value: "", order_no: i + 1 },
      };
    }, {});
  }

  function getFormatedDate(date, format = "DD.MM.yyyy HH:mm") {
    return moment(date).format(format);
  }

  const formTitle = params.id
    ? intl.formatMessage({ id: "MENU.OPTIONS.EDIT" }) +
      " " +
      intl.formatMessage({ id: "MENU.CRAWLER" })
    : intl.formatMessage({ id: "Add_new" }) +
      " " +
      intl.formatMessage({ id: "MENU.CRAWLER" });
  const renderError = (message) => (
    <p className="text-danger mb-0 pt-2 pl-2">{message}</p>
  );

  return (
    <Card>
      <CardHeader title={formTitle}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToCrawlerList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveCrawlerClick}
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <Formik
            initialValues={initialValues}
            validationSchema={EditSchema}
            enableReinitialize
            onSubmit={async (values) => {
              try {
                await mutation.mutateAsync(values);
                history.push(`${a_crawler_url}`);
              } catch (error) {
                console.error(error);
              }
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, values, setFieldValue, errors }) => (
              <>
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-6">
                      {!ruleTypes.isLoading && (
                        <Select
                          name="rule_type_id"
                          label={intl.formatMessage({
                            id: "CRAWLER.RULE_TYPE.DESCRIPTION",
                          })}
                          withFeedbackLabel={false}
                          disabled={params.id ? true : false}
                        >
                          {ruleTypes?.data?.data?.map((rule) => (
                            <option key={rule.id} value={rule.id}>
                              {rule.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    </div>
                    <div className="col-lg-6">
                      {!networkTypes.isLoading && (
                        <Select
                          name="network_type"
                          label={intl.formatMessage({
                            id: "CRAWLER.NETWORK_TYPE.DESCRIPTION",
                          })}
                          withFeedbackLabel={false}
                          disabled={params.id ? true : false}
                        >
                          {networkTypes?.data?.data?.map((network) => (
                            <option key={network.id} value={network.id}>
                              {network.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        type="text"
                        name="title"
                        component={Input}
                        placeholder={intl.formatMessage({
                          id: "CRAWLER.TITLE",
                        })}
                        label={intl.formatMessage({ id: "CRAWLER.TITLE" })}
                        withFeedbackLabel={false}
                      />
                      {errors?.title && (
                        <span className="error">{errors?.title}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      {intl.formatMessage({ id: "COMMON.ENTER" })}{" "}
                      {intl.formatMessage({ id: "CRAWLER.DESCRIPTION" })}
                    </label>
                    <Field
                      name="description"
                      as="textarea"
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "CRAWLER.DESCRIPTION.DESCRIPTION",
                      })}
                    />
                    {errors?.description && (
                      <span className="error">{errors?.description}</span>
                    )}
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <Field
                        type="number"
                        name="cron_timeout"
                        component={Input}
                        placeholder={intl.formatMessage({
                          id: "CRAWLER.CRON.DESCRIPTION",
                        })}
                        label={intl.formatMessage({
                          id: "CRAWLER.CRON.DESCRIPTION",
                        })}
                        withFeedbackLabel={false}
                        min="60"
                        onKeyPress={(event) =>
                          !/[0-9]/.test(event.key) && event.preventDefault()
                        }
                      />
                      {errors?.cron_timeout && (
                        <span className="error">{errors?.cron_timeout}</span>
                      )}
                    </div>
                    <div className="col-lg-4">
                      <Field
                        type="text"
                        name="profile"
                        component={Input}
                        placeholder={intl.formatMessage({
                          id: "CRAWLER.PROFILE_OR_HASHTAG",
                        })}
                        label={intl.formatMessage({
                          id: "CRAWLER.PROFILE_OR_HASHTAG.DESCRIPTION",
                        })}
                        disabled={params.id ? true : false}
                        withFeedbackLabel={false}
                      />
                      {errors?.profile && (
                        <span className="error">{errors?.profile}</span>
                      )}
                    </div>
                    <div className="col-lg-4">
                      <Field
                        type="number"
                        name="count"
                        min="1"
                        max="20"
                        component={Input}
                        placeholder={intl.formatMessage({
                          id: "CRAWLER.COUNT.DESCRIPTION",
                        })}
                        label={intl.formatMessage({
                          id: "CRAWLER.COUNT.DESCRIPTION",
                        })}
                        withFeedbackLabel={false}
                        onKeyPress={(event) =>
                          !/[0-9]/.test(event.key) && event.preventDefault()
                        }
                      />
                      {errors?.count && (
                        <span className="error">{errors?.count}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <div style={{ display: "flex", flexFlow: "column wrap" }}>
                        <label>
                          {intl.formatMessage({ id: "COMMON.SELECT" })}{" "}
                          {intl.formatMessage({ id: "CRAWLER.STATUS" })}
                        </label>
                        <div>
                          <Field
                            name="status"
                            component={Switch}
                            label="Status"
                            checked={values.status}
                            color="primary"
                            onChange={() => {
                              setFieldValue("status", !values.status);
                            }}
                          />
                          {errors?.status && (
                            <span className="error">{errors?.status}</span>
                          )}
                          <label>
                            {intl.formatMessage({
                              id: "CRAWLER.STATUS.DESCRIPTION",
                            })}{" "}
                            {values.status
                              ? intl.formatMessage({
                                  id: "CRAWLER.STATUS.PUBLISHED",
                                })
                              : intl.formatMessage({
                                  id: "CRAWLER.STATUS.UNPUBLISHED",
                                })}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    style={{ display: "none" }}
                    ref={btnRef}
                    onSubmit={() => handleSubmit()}
                  ></button>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </CardBody>
      <CardFooter>
        {currentCrawler && (
          <div className="crawler-edit-form__details">
            <div>
              {intl.formatMessage({ id: "TH_CREATED" })}:{" "}
              {getFormatedDate(currentCrawler.created_at)}
            </div>
            <div>
              {intl.formatMessage({ id: "TH_UPDATED" })}:{" "}
              {getFormatedDate(currentCrawler.updated_at)}
            </div>
            <div>
              {intl.formatMessage({ id: "TH_EXECUTED" })}:{" "}
              {getFormatedDate(currentCrawler.executed_at)}
            </div>
            <div style={{ display: createdBy ? "block" : "none" }}>
              {intl.formatMessage({ id: "COMMON.CREATED_BY" })}:{" "}
              {createdBy && createdBy.username}
            </div>
          </div>
        )}
      </CardFooter>
    </Card>
  );
};

export default CrawlerEdit;
