import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getAllInstituteActivitiesCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/institute-activities/list`, data);
};

export const createInstituteActivityCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/institute-activities`, data);
};

export const editInstituteActivityCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/institute-activities/update`, data);
};

export const deleteActivityCall = (payload) => {
  let data = {};
  data.id = payload.id;
  return axios.post(`${ARHIVA_REST_URL}api/institute-activities/delete`, data);
};

export const removeMultiActivityCall = (ids) => {
  let data = {};
  data.ids = ids;
  console.log("l", data);
  return axios.post(`${ARHIVA_REST_URL}api/institute-activities/delete`, data);
};

export const cahngeMultiStatusCall = (payload) => {
  let data = {};
  data.ids = payload.ids;
  data.status = payload.status;
  console.log("===>", data);
  return axios.post(`${ARHIVA_REST_URL}api/institute-activities/status`, data);
};
