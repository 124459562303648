import { put, takeLatest, call } from "redux-saga/effects";
import {
  assignUsersCall,
  createInstitutionCall,
  deleteInstitutionCall,
  getAllInstitutionsDataCall,
  getAllUsersCall,
  updateInstitutionCall,
} from "./Service";
import {
  getAllInstitutionsData,
  setAllInstitutionsData,
  setAllUsersData,
  showLoader,
} from "./actions";

function* attemptCreateInstitute(action) {
  const {
    payload,
    toasts,
    intl,
    modalClose,
    setErrorMessage,
    getCallPayload,
  } = action;
  try {
    const response = yield call(createInstitutionCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(action.toasts.hideToast());
    yield put(getAllInstitutionsData(getCallPayload));
    yield put(modalClose("#exampleModal"));
  } catch (error) {
    setErrorMessage(error?.response?.data?.errors);
  }
}

function* attemptGetAllInstitutions(action) {
  const { payload, toasts, intl } = action;
  try {
    yield put(showLoader(true));
    const response = yield call(getAllInstitutionsDataCall, payload);
    // yield put(
    //   toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    // );
    // yield put(toasts.hideToast());
    yield put(setAllInstitutionsData(response.data));
    yield put(showLoader(false));
  } catch (error) {}
}

function* attemptDeleteInstitute(action) {
  const {
    payload: { id, intl, modalClose, toasts, page, size, title },
  } = action;

  try {
    const response = yield call(deleteInstitutionCall, id);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (error) {}
}

function* attemptUpdate(action) {
  const {
    payload: { id, toasts, intl, modalClose, setErrorMessage },
  } = action;

  try {
    const response = yield call(updateInstitutionCall, action, id);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllInstitutionsData());
    yield put(modalClose("#exampleModalEdit"));
  } catch (error) {
    setErrorMessage(error?.response?.data?.errors);
  }
}

function* attemptGetUsersData() {
  try {
    const response = yield call(getAllUsersCall);
    yield put(setAllUsersData(response.data.data));
  } catch (error) {}
}

function* attemptAssignUsers(action) {
  const {
    payload: { modalClose, setErrorMessage },
    payload,
    toasts,
    intl,
    b,
  } = action;
  try {
    const response = yield call(assignUsersCall, action);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllInstitutionsData(b));
    // yield put(attemptGetUsersData());
    modalClose("#exampleModalUserAssign");
  } catch (error) {
    setErrorMessage(error?.response?.data?.errors);
  }
}

export function* saga() {
  yield takeLatest("GET_ALL_INSTITUTIONS_DATA", attemptGetAllInstitutions);
  yield takeLatest("CREATE_INSTITUTE", attemptCreateInstitute);
  yield takeLatest("DELETE_INSTITUTE", attemptDeleteInstitute);
  yield takeLatest("EDIT_INSTITUTE", attemptUpdate);
  yield takeLatest("GET_USERS_DATA", attemptGetUsersData);
  yield takeLatest("ASSIGN_USERS_TO_INSTITUTE", attemptAssignUsers);
}
