export const getSourcesData = (payload) => {
  return {
    type: "GET_SOURCES_DATA",
    payload: payload,
  };
};

export const setSourcesData = (payload) => {
  return {
    type: "SET_SOURCES_DATA",
    payload: payload,
  };
};

export const saveSourceAction = (payload, intl, toasts, history) => {
  return {
    type: "SAVE_SOURCE",
    payload: payload,
    intl: intl,
    toasts: toasts,
    history: history,
  };
};

export const editSourceAction = (payload, intl, toasts, history) => {
  return {
    type: "EDIT_SOURCE",
    payload: payload,
    intl: intl,
    toasts: toasts,
    history: history,
  };
};

export const getSourceById = (payload, setCurrentSource) => {
  return {
    type: "GET_SOURCE_BY_ID",
    payload: payload,
    setCurrentSource: setCurrentSource,
  };
};

export const deleteSource = (id, intl, toasts) => {
  return {
    type: "DELETE_SOURCE",
    id: id,
    intl: intl,
    toasts: toasts,
  };
};

export const deleteSourceFromRedux = (id) => {
  return {
    type: "DELETE_SOURCE_FROM_REDUX",
    id: id,
  };
};

export const multiDeleteSourceFromRedux = (ids) => {
  return {
    type: "MULTI_SOURCE_DELETE_FROM_REDUX",
    ids: ids,
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});
