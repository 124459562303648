import React from "react";
import { useIntl } from "react-intl";

export default function DeleteFolderModal(props) {
  const intl = useIntl();

  return (
    <div
      className="modal fade"
      id="folderModalDelete"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Delete Folder
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" className="ki ki-close" />
            </button>
          </div>
          <div className="modal-body">
            <p> Do You Really Want To Delete This Folder?</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold"
              data-dismiss="modal"
            >
              {intl.formatMessage({ id: "CONTACT.GENERAL.CANCEL.BUTTON" })}
            </button>
            <button
              onClick={() => props.handleDeleteFolder()}
              type="button"
              // disabled={props.callLoader}
              className="btn btn-primary font-weight-bold d-flex"
            >
              Yes
              {/* 
              <>
                <div className="spinner ml-2" style={{ width: "20px" }}></div>
              </> */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
