import { put, takeLatest, call, delay } from "redux-saga/effects";
import { showLoader } from "../media/actions";
import {
  getFoldersData,
  setAssignFolder,
  setFoldersData,
  setFoldersError,
} from "./actions";
import {
  assignFolderCall,
  createNewFolderCall,
  folderDeleteCall,
  getFoldersDataCall,
} from "./Service";

function* attemptGetFoldersData(actions) {
  const { payload } = actions;

  try {
    // yield put(showLoader(true));
    const response = yield call(getFoldersDataCall, payload);
    yield put(setFoldersData(response.data));
    // yield put(showLoader(false));
  } catch (e) {}
}

function* attemptCreateNewFolder(actions) {
  const { payload, toasts, intl, modalClose } = actions;
  let data = {};
  data.user_id = payload.user_id;
  data.folder_type = payload.folder_type;

  try {
    const response = yield call(createNewFolderCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getFoldersData(data));
    modalClose("#folderModalCreate");
  } catch (e) {
    yield put(setFoldersError(e.response.data));
    yield delay(4000);
    yield put(setFoldersError({}));
  }
}

function* attemptDeleteFolder(actions) {
  const { payload, toasts, intl, id, modalClose } = actions;
  let data = {};
  data.user_id = id;
  data.folder_type = "licni";

  try {
    const response = yield call(folderDeleteCall, payload);

    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getFoldersData(data));
    modalClose("#folderModalDelete");
  } catch (e) {}
}

function* attemptAssignFolderUser(actions) {
  const { payload, toasts, intl, page, sizePerPage, row } = actions;
  let data = {};
  data.page = page - 1;
  data.size = sizePerPage;
  data.folder_type = "kliping";
  data.user_id = payload.user_id;
  try {
    const response = yield call(assignFolderCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (e) {}
}

export function* saga() {
  yield takeLatest("GET_FOLDERS_DATA", attemptGetFoldersData);
  yield takeLatest("CREATE_NEW_FOLDER", attemptCreateNewFolder);
  yield takeLatest("DELETE_PERSONAL_FOLDER", attemptDeleteFolder);
  yield takeLatest("ASSIGN_FOLDER_USER", attemptAssignFolderUser);
}
