import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { ARHIVA_REST_URL } from "../../../../environments/environment";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { SearchTable } from "../shared/Table/SearchTable";
import {
  a_institute_activity_edit_url,
  a_subjects_edit_url,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import { Table } from "../shared";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as moment from "moment";
import { useHistory } from "react-router-dom";

import SVG from "react-inlinesvg";
import {
  deleteMultiSubjectFromRedux,
  deleteSubject,
  deleteSubjectFromRedux,
  getSubjectsData,
  updateSubjects,
} from "../../../_redux/Subjects/actions";

export default function Subjects() {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);
  const [clickedRow, setClickedRow] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [valData, setValData] = useState([]);
  const [getSearch, setSearch] = useState("");
  const subjectData = useSelector((state) => state?.subjects?.Index ?? []);

  useEffect(() => {
    getAllSubjects();
  }, [page, sizePerPage, getSearch]);

  const getAllSubjects = () => {
    setAllIds([]);
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;
    data.title = getSearch;
    if (getSearch) {
      data.page = 0;
    }
    dispatch(getSubjectsData(data));
  };

  const paginationOptions = {
    totalSize: subjectData.totalCount,
    sizePerPage,
    page,
  };

  const removeSubject = (row) => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;

    let b = {};
    b.id = row.id;
    dispatch(deleteSubjectFromRedux(b, modalClose));
    dispatch(deleteSubject(b, toasts, intl));
  };

  const deleteMultiItems = (ids) => {
    let data = {};
    data.page = page - 1;
    data.size = sizePerPage;

    let b = {};
    b.ids = ids;
    dispatch(deleteMultiSubjectFromRedux(b, modalClose, setAllIds));
    dispatch(deleteSubject(b, toasts, intl));
  };

  const loadOptions = async (inputValue, callback) => {
    let d = {};
    d.page = page - 1;
    d.size = sizePerPage;
    d.title = inputValue;
    const response = await axios.post(
      `${ARHIVA_REST_URL}api/insitutions/search`,
      d
    ); //use that input value in the endpoint
    let data = await response.data.data.data;
    callback(
      data?.map((d) => {
        return { value: d.id, label: d.name };
      })
    );
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputValue({ inputValue });
    return inputValue;
  };

  const handleChange = (e) => {
    setValData(e);
  };

  useEffect(() => {
    if (clickedRow)
      setValData(
        clickedRow?.institutes?.map((v) => {
          return { value: v.id, label: v.name };
        })
      );
  }, [clickedRow]);

  const assignInstiituteToSubject = (row) => {
    let getData = {};
    getData.page = page - 1;
    getData.size = sizePerPage;
    let data = {};
    data.id = row.id;
    data.institute_ids = valData?.map((k) => k.value) ?? [];
    data.name = row.name;
    dispatch(updateSubjects(data, toasts, intl, modalClose, getData));
  };

  const modalClose = (id) => {
    window.$(id).modal("hide");
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      headerStyle: { verticalAlign: "initial" },
      formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_subjects_edit_url}/${row.id}`);
        },
      },
    },

    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),

      formatter: (val) => {
        return moment(val).format("DD.MM.YYYY");
      },

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      formatter: (val) => {
        return moment(val).format("DD.MM.YYYY");
      },

      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),

      formatter: (cell, row) => {
        return (
          <>
            <a
              href
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                history.push(`${a_subjects_edit_url}/${row.id}`);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>

            {/* ------------------------------------------------------------- */}
            <a
              title="Edit customer"
              className="svg-icon svg-icon-primary svg-icon-2x btn btn-icon btn-light btn-hover-light btn-sm mx-3"
              data-toggle="modal"
              data-target="#exampleModalAssign"
              onClick={() => setClickedRow(row)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <path
                    d="M16.5428932,17.4571068 L11,11.9142136 L11,4 C11,3.44771525 11.4477153,3 12,3 C12.5522847,3 13,3.44771525 13,4 L13,11.0857864 L17.9571068,16.0428932 L20.1464466,13.8535534 C20.3417088,13.6582912 20.6582912,13.6582912 20.8535534,13.8535534 C20.9473216,13.9473216 21,14.0744985 21,14.2071068 L21,19.5 C21,19.7761424 20.7761424,20 20.5,20 L15.2071068,20 C14.9309644,20 14.7071068,19.7761424 14.7071068,19.5 C14.7071068,19.3673918 14.7597852,19.2402148 14.8535534,19.1464466 L16.5428932,17.4571068 Z"
                    fill="#000000"
                    fillRule="nonzero"
                  />
                  <path
                    d="M7.24478854,17.1447885 L9.2464466,19.1464466 C9.34021479,19.2402148 9.39289321,19.3673918 9.39289321,19.5 C9.39289321,19.7761424 9.16903559,20 8.89289321,20 L3.52893218,20 C3.25278981,20 3.02893218,19.7761424 3.02893218,19.5 L3.02893218,14.136039 C3.02893218,14.0034307 3.0816106,13.8762538 3.17537879,13.7824856 C3.37064094,13.5872234 3.68722343,13.5872234 3.88248557,13.7824856 L5.82567301,15.725673 L8.85405776,13.1631936 L10.1459422,14.6899662 L7.24478854,17.1447885 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    opacity="0.3"
                  />
                </g>
              </svg>
            </a>
            {/* ----------------------------------------------------- */}

            {/* ---------------------------- Delete Button --------------------------- */}
            <span
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target={`#exampleModalDelete`}
              onClick={() => {
                // setClickedRow(row.id);
                setClickedRow(row);
              }}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              title={intl.formatMessage({
                id: "MENU.OPTIONS.DELETE",
              })}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
              {/*end::Svg Icon*/}
            </span>
          </>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "Subjects" })} sticky>
          <CardHeaderToolbar>
            <button
              className="btn btn-primary font-weight-bold"
              onClick={() => {
                history.push(a_subjects_edit_url);
              }}
            >
              Create Subject
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
            <div className="col-lg-3"></div>
            <SearchTable setSearch={setSearch} />
          </div>
          {allIds.length > 1 && (
            <div className="form-group">
              <p>Selected records count:{allIds.length}</p>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                data-toggle="modal"
                data-target={`#exampleModalDeleteMulti`}
              >
                <i className="fa fa-trash"></i>
                {intl.formatMessage({ id: "COMMON.Delete" })}
              </button>
            </div>
          )}

          <Table
            data={subjectData.data ?? []}
            columns={columns}
            pagiOptions={paginationOptions}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            selectRowOptions={{
              allIds,
              setAllIds,
            }}
            intl={intl}
          />
        </CardBody>
      </Card>
      <div
        className="modal fade"
        id={`exampleModalDelete`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })} Subject
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              Are You Sure You Want To Delete This Subject?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary font-weight-bold"
                onClick={() => {
                  removeSubject(clickedRow);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------------------------------- */}
      <div
        className="modal fade"
        id={`exampleModalAssign`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Assign {intl.formatMessage({ id: "Institution" })}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              <label>Select Institute</label>
              <AsyncSelect
                cacheOptions
                isMulti
                loadOptions={loadOptions}
                defaultOptions
                onInputChange={(e) => handleInputChange(e)}
                onChange={(e) => handleChange(e)}
                value={valData}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary font-weight-bold"
                onClick={() => {
                  assignInstiituteToSubject(clickedRow);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------------------------------------------- */}
      <div
        className="modal fade"
        id={`exampleModalDeleteMulti`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {intl.formatMessage({ id: "MENU.OPTIONS.DELETE" })} Subject
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i aria-hidden="true" className="ki ki-close" />
              </button>
            </div>
            <div className="modal-body">
              Are You Sure You Want To Delete These Subjects?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary font-weight-bold"
                onClick={() => deleteMultiItems(allIds)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
