import { put, takeLatest, delay, call } from "redux-saga/effects";
import {
  getAllInstituteActivities,
  setAllInstituteActivities,
  setErrors,
} from "./actions";
import {
  cahngeMultiStatusCall,
  createInstituteActivityCall,
  deleteActivityCall,
  editInstituteActivityCall,
  getAllInstituteActivitiesCall,
  removeMultiActivityCall,
} from "./Service";

function* attemptGetAllInstituteActivities(action) {
  const { payload } = action;
  try {
    const response = yield call(getAllInstituteActivitiesCall, payload);
    yield put(setAllInstituteActivities(response.data));
  } catch (e) {}
}

function* attemptCreateInstituteActivity(action) {
  const { payload, toasts, intl, setLoader } = action;
  try {
    const response = yield call(createInstituteActivityCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (e) {
    console.log("Err:", e.response.data);
    yield put(setErrors(e.response.data));
    yield delay(4000);
    yield put(setErrors({}));
  } finally {
    setLoader(false);
  }
}

function* attemptEditInstituteActivity(action) {
  const { payload, toasts, intl, setLoader } = action;
  try {
    const response = yield call(editInstituteActivityCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (e) {
    yield put(setErrors(e.response.data));
    yield delay(4000);
    yield put(setErrors({}));
  } finally {
    setLoader(false);
  }
}

function* attemptDeleteInstituteActivity(action) {
  const { payload, toasts, intl, data } = action;

  try {
    const response = yield call(deleteActivityCall, payload);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllInstituteActivities(data));
  } catch (e) {}
}

function* attemptRemoveMultiActivity(action) {
  const { ids, toasts, intl, data } = action;
  try {
    const response = yield call(removeMultiActivityCall, ids);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
    yield put(getAllInstituteActivities(data));
  } catch (e) {}
}

function* attemptMultiStatusActivity(action) {
  const { ids, status, toasts, intl } = action;
  try {
    const response = yield call(cahngeMultiStatusCall, action);
    yield put(
      toasts.showToast(200, intl.formatMessage({ id: response.data.title }))
    );
    yield put(toasts.hideToast());
  } catch (e) {}
}

export function* saga() {
  yield takeLatest(
    "GET_ALL_INSTITUTE_ACTIVITIES",
    attemptGetAllInstituteActivities
  );
  yield takeLatest("CREATE_INSTITUTE_ACTIVITY", attemptCreateInstituteActivity);
  yield takeLatest("EDIT_INSTITUTE_ACTIVITY", attemptEditInstituteActivity);
  yield takeLatest("DELETE_INSTITUTE_ACTIVITY", attemptDeleteInstituteActivity);
  yield takeLatest("REMOVE_MULTI_ACTIVETY", attemptRemoveMultiActivity);
  yield takeLatest("CHANGE_MULTI_ACTIVITIES", attemptMultiStatusActivity);
}
