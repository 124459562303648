import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getAllPoliciesCall = (actions) => {
  let data = actions.payload;
  return axios.post(`${ARHIVA_REST_URL}api/policies`, data);
};

export const deletPolicyCall = (actions) => {
  let id = {};
  id.id = actions.id;
  return axios.post(`${ARHIVA_REST_URL}api/policies/delete`, id);
};

export const createPolicyCall = (actions) => {
  let values = actions.payload;
  return axios.post(`${ARHIVA_REST_URL}api/policies/add`, values);
};

export const editPolicyCall = (actions) => {
  let data = {};
  data = actions.payload;
  data.id = actions.id;
  return axios.post(`${ARHIVA_REST_URL}api/policies/edit`, data);
};

export const getSectionCall = (actions) => {
  let data = actions.payload;
  return axios.post(`${ARHIVA_REST_URL}api/section/list`, data);
};
