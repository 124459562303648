/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { PRIVACY_STRING } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/sass/pages/other/all-other.scss";
import ReactiveExtrasPage from "../../ReactiveBase/ReactiveExtrasPage";

export default function PrivacyPage() {
	return <ReactiveExtrasPage type={PRIVACY_STRING} />

}

