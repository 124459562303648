import { put, takeLatest, call } from "redux-saga/effects";
import { setAllRoles } from "./actions";
import { getAllRolesCall } from "./Service";

function* attemptgetAllRoles() {
  try {
    const response = yield call(getAllRolesCall);
    yield put(setAllRoles(response.data.data));
  } catch (errors) {}
}

export function* saga() {
  yield takeLatest("GET_ALL_ROLES", attemptgetAllRoles);
}
