import React from "react";
import { Switch, FormControlLabel } from "@material-ui/core";

const SearchSwitches = ({ selected, checkboxes }) => {
  return (
    <div>
      {checkboxes.map((item) => {
        return (
          <FormControlLabel
            value={item}
            control={<Switch color="primary" />}
            checked={item === selected ? true : false}
            label={item}
            labelPlacement="end"
            key={item + 1}
          />
        );
      })}
    </div>
  );
};

export default SearchSwitches;
