import React from 'react'
import { licni, HeaderButtonString } from '../../../../../../../_metronic/_helpers';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../_redux/folders/foldersActions';
import HeaderButton from './HeaderButton';
import { useIntl } from 'react-intl';
import AddToFolderButton from '../AddToFolderButton';

const HeaderButtonsContainer = ({ type, props }) => {
    const dispatch = useDispatch();
    const { ids } = props;
    const { activeId } = useSelector(
        (state) => (state.folders)
    )
    const intl = useIntl()
    const onRemovePostHandler = (folder_id, post_ids, type) => {
        dispatch(actions.removePost(folder_id, post_ids, type))
    }

    const buttonProps = [
        {
            title: intl.formatMessage({ id: `${HeaderButtonString}VIEW` }),
            url: "/media/svg/icons/Files/File.svg",
            buttonType: 'fav'
        },
        {
            title: intl.formatMessage({ id: `${HeaderButtonString}DOWNLOAD` }),
            url: "/media/svg/icons/Files/Cloud-download.svg",
            buttonType: 'view'
        },
        {
            title: intl.formatMessage({ id: `${HeaderButtonString}REMOVE` }),
            url: "/media/svg/icons/General/Trash.svg",
            onClickHandler: () => onRemovePostHandler(activeId, ids, type),
            buttonType: 'remove'
        },
        {
            title: intl.formatMessage({ id: `${HeaderButtonString}UNREAD` }),
            url: "/media/svg/icons/Communication/Mail.svg",
            buttonType: 'read'
        },
        {
            title: intl.formatMessage({ id: `${HeaderButtonString}READ` }),
            url: "/media/svg/icons/Communication/Mail-opened.svg",
            buttonType: 'unread'
        },
    ]


    return (
        <div>
            <AddToFolderButton ids={ids} />
            {buttonProps.map(props => (
                type !== licni && props.buttonType === 'remove' ?
                    null :
                    <HeaderButton
                        key={props.url}
                        {...props}
                    />
            ))}
        </div>
    )
}

export default HeaderButtonsContainer
