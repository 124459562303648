import { put, takeLatest, call } from "redux-saga/effects";
import { a_section_url } from "../../../_metronic/_helpers";
import { getSectionsData, setSectionsData, showLoader } from "./actions";
import {
  createSectionCall,
  deleteSectionCall,
  editSectionCall,
  getSectionsDataCall,
} from "./Service";

function* attemptGetSectionsData(actions) {
  try {
    yield put(showLoader(true));
    const response = yield call(getSectionsDataCall, actions);
    yield put(setSectionsData(response.data.data));
    yield put(showLoader(false));
  } catch (errors) {}
}

function* attemptEditSection(actions) {
  try {
    const response = yield call(editSectionCall, actions);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(actions.history.push(a_section_url));
  } catch (errors) {}
}

function* attemptCreateSection(actions) {
  try {
    const response = yield call(createSectionCall, actions);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    yield put(actions.history.push(a_section_url));
    return response;
  } catch (errors) {}
}

function* attemptDeleteSection(actions) {
  const { id } = actions;
  try {
    const response = yield call(deleteSectionCall, id);
    yield put(
      actions.toasts.showToast(
        200,
        actions.intl.formatMessage({ id: response.data.title })
      )
    );
    yield put(actions.toasts.hideToast());
    // yield put(getSectionsData());
  } catch (errors) {
    yield put(
      actions.toasts.showToast(
        500,
        actions.intl.formatMessage({ id: errors.response.data.message })
      )
    );
    yield put(actions.toasts.hideToast());
  }
}

export function* saga() {
  yield takeLatest("GET_SECTIONS_DATA", attemptGetSectionsData);
  yield takeLatest("EDIT_SECTION", attemptEditSection);
  yield takeLatest("CREATE_SECTION", attemptCreateSection);
  yield takeLatest("DELETE_SECTION", attemptDeleteSection);
}
