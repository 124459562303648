import React, { useState } from "react";
import { useFormik } from "formik";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";
import * as gconst from "../../../../../src/_metronic/_helpers/Constants";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { Redirect } from "react-router-dom";
const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const dispatch = useDispatch();
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .min(
        gconst.min_input,
        <FormattedMessage
          id="AUTH.INPUT.MIN_INPUT"
          values={{
            min_input: gconst.min_input,
          }}
        />
      )
      .max(
        gconst.max_input,
        <FormattedMessage
          id="AUTH.INPUT.MAX_INPUT"
          values={{
            max_input: gconst.max_input,
          }}
        />
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      requestPassword(values.email)
        .then((response) => {
          setIsRequested(true);
          setSubmitting(true);
          disableLoading();
          dispatch(
            toasts.showToast(
              response.data.status,
              intl.formatMessage(
                { id: response.data.title },
                { name: values.email }
              )
            )
          );
          dispatch(toasts.hideToast());
          setStatus(intl.formatMessage({ id: response.data.title }));
        })
        .catch((error) => {
          dispatch(
            toasts.showToast(
              error.response.data.status,
              intl.formatMessage(
                {
                  id: error.response.data.title
                    ? error.response.data.title
                    : error.response.data.errors[0],
                },
                { name: values.email }
              )
            )
          );
          dispatch(toasts.hideToast());
          disableLoading();
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: error.response.data.details
                ? error.response.data.details
                : error.response.data.errors[0],
            })
          );
        });
    },
  });

  return (
    <>
      {/*begin::Content header*/}
      <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">
          <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT" />
        </span>
        <Link
          to="/auth/registration"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
        >
          <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
        </Link>
      </div>
      {/*end::Content header*/}

      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
          <div className="text-muted font-weight-bold">
            <FormattedMessage id="AUTH.FORGOT.SUB_TITLE" />
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status ? (
            isRequested ? (
              <Redirect to="/auth" />
            ) : (
              <div
                role="alert"
                className="mb-10 alert alert-custom alert-light-info alert-dismissible"
              >
                <div className="alert-text">
                  <FormattedHTMLMessage id="AUTH.RESET.NOTICE" />
                </div>
              </div>
            )
          ) : (
            <div
              role="alert"
              className="mb-10 alert alert-custom alert-light-info alert-dismissible"
            >
              <div className="alert-text">
                <FormattedHTMLMessage id="AUTH.RESET.NOTICE" />
              </div>
            </div>
          )}
          {!isRequested && (
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={intl.formatMessage({
                  id: "AUTH.INPUT.USERNAME_OR_EMAIL",
                })}
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
          )}
          <div className="form-group d-flex flex-wrap flex-center">
            {!isRequested && (
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            )}
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON" />
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
