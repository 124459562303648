import React, { useEffect, useState } from "react";

import { ARHIVA_REST_URL } from "../../../../../environments/environment";
import * as toasts from "../../../../../app/modules/ECommerce/_redux/toasts/toastsActions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { editProfilePersonalInfo } from "../../../../_redux/users/actions";

export const PersonalInfo = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [avatar, setAvatar] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [callLoader, setCallLoader] = useState(false);

  useEffect(() => {
    setAvatar(props?.personal_information?.avatar);
    setFirstName(props?.personal_information?.first_name);
    setLastName(props?.personal_information?.last_name);
    setCompany(props?.personal_information?.company);
    setContact(props?.personal_information?.mobile);
    setEmail(props?.personal_information?.email);
    setWebsite(props?.personal_information?.website);
  }, [props.personal_information]);

  const handleEditUser = () => {
    setCallLoader(true);
    let id = props?.routId;
    let data = {};
    data.name = firstName + " " + lastName;
    data.first_name = firstName;
    data.last_name = lastName;
    data.email = email;
    data.company = company;
    data.mobile = contact;
    data.website = website;

    dispatch(editProfilePersonalInfo(id, data, setCallLoader, intl, toasts));

  
  };

  return (
    <>
      <div className="card card-custom card-stretch">
        {/*begin::Header*/}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              {intl.formatMessage({
                id: "MENU.PROFILE.PERSONAL_INFORMATIONS",
              })}
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              {intl.formatMessage({
                id: "MENU.PROFILE.PERSONAL_INFORMATIONS_DESC",
              })}
            </span>
          </div>
          <div className="card-toolbar">
            <button
              type="reset"
              disabled={callLoader}
              onClick={() => handleEditUser()}
              className="btn btn-success mr-2 d-flex"
            >
              {intl.formatMessage({
                id: "MENU.OPTIONS.SAVE",
              })}
              {callLoader == true ? (
                <>
                  <div className="spinner ml-2" style={{ width: "20px" }}></div>
                </>
              ) : (
                ""
              )}
            </button>
            <NavLink
              to={`/admin/user/view/${props.routId}`}
              className="navi-link py-4"
              activeClassName="active"
              exact
            >
              <button type="button" className="btn btn-secondary">
                {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
              </button>
            </NavLink>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Form*/}
        <form className="form h-100">
          {/*begin::Body*/}
          <div className="card-body h-100">
            {props.loader == true ? (
              <>
                <div className="d-flex h-100 justify-content-center">
                  <div className="spinner"></div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <label className="col-xl-3" />
                  <div className="col-lg-9 col-xl-6">
                    <h5 className="font-weight-bold mb-6">
                      {intl.formatMessage({ id: "Customer_Info" })}
                    </h5>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Avatar
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div
                      className="image-input image-input-outline"
                      id="kt_profile_avatar"
                      style={{
                        backgroundImage: `url(${avatar})`,
                      }}
                    >
                      <div
                        className="image-input-wrapper"
                        style={{
                          backgroundImage:
                            "url(/metronic/theme/html/demo1/dist/assets/media/users/300_21.jpg)",
                        }}
                      />
                      <label
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title
                        data-original-title="Change avatar"
                      >
                        <i className="fa fa-pen icon-sm text-muted" />
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title
                        data-original-title="Cancel avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title
                        data-original-title="Remove avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>
                    </div>
                    <span className="form-text text-muted">
                      {intl.formatMessage({ id: "Allowed_file_types" })}: png,
                      jpg, jpeg.
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({ id: "First_Name" })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      className="form-control form-control-lg form-control-solid"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({
                      id: "AUTH.INPUT.LAST_NAME",
                    })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      className="form-control form-control-lg form-control-solid"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({ id: "Company_Name" })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      className="form-control form-control-lg form-control-solid"
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <span className="form-text text-muted">
                      If you want your invoices addressed to a company. Leave
                      blank to use your full name.
                    </span>
                  </div>
                </div>
                <div className="row">
                  <label className="col-xl-3" />
                  <div className="col-lg-9 col-xl-6">
                    <h5 className="font-weight-bold mt-10 mb-6">
                      {intl.formatMessage({ id: "Contact_Info" })}
                    </h5>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({
                      id: "INPUT.MOBILE",
                    })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="input-group input-group-lg input-group-solid">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="la la-phone" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                        placeholder="Phone"
                      />
                    </div>
                    <span className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({
                      id: "AUTH.INPUT.EMAIL",
                    })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="input-group input-group-lg input-group-solid">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="la la-at" />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {intl.formatMessage({ id: "Company_Site" })}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="www.example.com"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                      {/* <div className="input-group-append">
                    <span className="input-group-text">.com</span>
                  </div> */}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/*end::Body*/}
        </form>
        {/*end::Form*/}
      </div>
    </>
  );
};
