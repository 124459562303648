import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogContentText, useMediaQuery, useTheme, TextField, DialogActions, Button } from '@material-ui/core';
import { MIN_FOLDER_NAME_LENGTH, MAX_FOLDER_NAME_LENGTH, searches_url } from '../../../../../../_metronic/_helpers/Constants';
import { createFolder, closeFolderDialog } from '../../../_redux/folders/foldersActions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const CreateFolderDialog = () => {
    const folders = useSelector(
        (state) => (state.folders),
        shallowEqual
      );
      const arhiva = useSelector(
        (state) => (state.arhiva)
      )
    const intl = useIntl();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [folder, setFolder] = useState({
        new: '',
        description: '',
        url: ''
    })
    const [disabled, setDisabled] = useState(true);
    const closeFormDialog = () => {
        dispatch(closeFolderDialog());
        setDisabled(true);
    }

    const submitCreateFolder = () => {
        // dispatch(createFolder(folder.new, activeFolder.type, folder.description));
        if (folders.folderType === searches_url && arhiva.query) {
            dispatch(createFolder(folder.new, folders.folderType, folder.description, JSON.stringify(arhiva.query), folder.url))
        }
        else {
            dispatch(createFolder(folder.new, folders.folderType, folder.description))
        }
        closeFormDialog();
    }

    // form validation
    useEffect(() => {
        if (
            folder.new &&
            folder.new.length > MIN_FOLDER_NAME_LENGTH &&
            folder.new.length < MAX_FOLDER_NAME_LENGTH
        ) {
            setDisabled(false);
        }
        else {
            setDisabled(true);
        }
    }, [folder.new]);

    useEffect(() => {
        // once the user opens the dialog, take the url and store it in state
        setFolder({...folder, url: window.location.search})
    }, [window.location.search])

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={true}
                aria-labelledby="form-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="form-dialog-title">
                    {intl.formatMessage({ id: "DIALOG.CREATE.TITLE" })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {intl.formatMessage({ id: "DIALOG.WARNING.CREATE.MESSAGE" },
                            {
                                MIN_FOLDER_NAME_LENGTH: MIN_FOLDER_NAME_LENGTH,
                                MAX_FOLDER_NAME_LENGTH: MAX_FOLDER_NAME_LENGTH,
                            }
                        )}
                    </DialogContentText>
                    <TextField
                        onChange={(event) => { setFolder({ ...folder, new: event.target.value }) }}
                        margin="dense"
                        id="newfoldername"
                        label={intl.formatMessage({ id: "DIALOG.CREATE.NEW_TITLE" })}
                        type="text"
                        defaultValue=""
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField
                        onChange={(event) => { setFolder({ ...folder, description: event.target.value }) }}
                        multiline
                        rows="3"
                        margin="dense"
                        id="description"
                        label={intl.formatMessage({ id: "DIALOG.RENAME.DESCRIPTION" })}
                        type="text"
                        fullWidth
                        autoComplete="off"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            closeFormDialog();
                        }}
                        color="primary">
                        {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
                    </Button>
                    <Button
                        onClick={() => {
                            submitCreateFolder()
                        }}
                        color="primary"
                        disabled={disabled}
                    >
                        {intl.formatMessage({ id: "DIALOG.WARNING.CONFIRM" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CreateFolderDialog 
