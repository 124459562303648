import { combineReducers } from "redux";

// export const setAllChannelsData = (payload) => {
//   return {
//     type: "SET_ALL_CHANNELS_DATA",
//     payload: payload,
//   };
// };

const Index = (state = [], action) => {
  const {
    payload,
    toasts,
    intl,
    id,
    closeModal,
    ids,
    setAllIds,
    data,
  } = action;
  switch (action.type) {
   
    case "SET_ALL_STREAM_DATA":
      return payload;
     
    case "DELETE_STREAM_FROM_REDUX":
      const deleteData = state.data.filter((x) => (x.id === id ? false : x));
      closeModal("exampleModalDelete");
      return {
        ...state,
        data: deleteData,
      };
    case "DELETE_MULTI_STREAM_FROM_REDUX":
      const deleteMultiData = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );
      closeModal("exampleModalMultiDelete");
      setAllIds([]);
      return {
        ...state,
        data: deleteMultiData,
      };
    // ----------------------- Edit Redux ------------------------
    case "EDIT_STREAM_FROM_REDUX":
      const editStreamSize = state.data.filter((x) =>
        x.id === payload.id
          ? ((x.state = payload.state))
          : x
      );
      // closeModal("exampleModalEdit");
      return {
        ...state,
        data: editStreamSize,
      };
    default:
      return state;
  }
};

const Server = (state = [], action)=>{

  const {
    payload,
   
  } = action;

  switch (action.type) {
    case "SET_ALL_SERVERS_DATA":
      return payload;
    default:
      return state;
  }

}
const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  Index,
  Server,
  showLoader,
});
