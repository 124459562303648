/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Switch } from "@material-ui/core";

import { Formik, Form, Field } from "formik";
import { useQuery, useMutation } from "react-query";
import * as Yup from "yup";
import { AddCategoryModal } from "../shared";
import { useDispatch } from "react-redux";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";

import {
  Input,
  Select,
  ModalProgressBar,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  GET_ALL_POLICIES_URL,
  GET_ALL_CATEGORIES_URL,
  SAVE_POLICY_URL,
  EDIT_POLICY_URL,
  a_policy_url,
  GET_SECTION_LIST_URL,
} from "../../../../_metronic/_helpers/Constants";
import ProfileService from "../../Profile/service";
import {
  createPolicy,
  editPolicy,
  getSections,
} from "../../../_redux/policy/actions";
import { useSelector } from "react-redux";

const EditSchema = Yup.object().shape({
  title: Yup.string().required(),
  content: Yup.string().required(),
  category_id: Yup.number().required("category is a required field"),
});

const PolicyEdit = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPolicy, setCurrentPolicy] = useState(null);
  const [currentTypeId, setCurrentTypeId] = useState(5);
  const [data, setData] = useState([]);
  const history = useHistory();
  const service = new ProfileService();

  const params = useParams();

  useEffect(() => {
    const fetchPolicy = async () => {
      if (!params.id) return;
      try {
        const response = await service.post(GET_ALL_POLICIES_URL, {
          id: params.id,
        });
        setCurrentPolicy(response.data?.data[0]);
        setCurrentTypeId(response.data?.data[0]?.type_id);
      } catch (error) {}
    };

    fetchPolicy();
  }, [params]);

  const sectionsData = useSelector(
    (state) => state?.policies?.sectionsIndex ?? []
  );

  useEffect(() => {
    setData(sectionsData);
  }, [sectionsData]);

  const fetchSectionsList = async () => {
    let data = {};
    data.type_id = currentTypeId;
    dispatch(getSections(data));
    const response = await service.post(GET_SECTION_LIST_URL, {
      type_id: currentTypeId,
    });
    return response.data;
  };

  const savePolicy = async (values) => {
    // delete values.type_id;
    if (params.id) {
      let id = parseInt(params.id, 10);
      dispatch(editPolicy(values, id, history, intl, toasts));
      // return await service.post(EDIT_POLICY_URL, {
      //   id: parseInt(params.id, 10),
      //   ...values,
      // });
    } else {
      dispatch(createPolicy(values, history, intl, toasts));
    }
    // return await service.post(SAVE_POLICY_URL, values);
  };

  const mutation = useMutation(savePolicy, {
    onSuccess: (data) => {
      dispatch(
        toasts.showToast(
          data?.data?.status,
          intl.formatMessage({ id: data?.data?.title })
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(
        toasts.showToast(
          error.response.data.status,
          intl.formatMessage({
            id: error.response.data.details
              ? error.response.data.details
              : error.response.data.errors[0],
          })
        )
      );
      dispatch(toasts.hideToast());
    },
  });

  const backToPolicyList = () => {
    history.push(`${a_policy_url}`);
  };
  const btnRef = useRef();

  const savePolicyClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const { isLoading } = useQuery(
    ["sections", currentTypeId],
    () => fetchSectionsList(),
    {
      keepPreviousData: true,
    }
  );
  if (isLoading) return null;

  const category_id = data?.length !== 1 ? data[0]?.id : null;
  const initialValues = {
    title: currentPolicy ? currentPolicy.title : "",
    content: currentPolicy ? currentPolicy.content : "",
    category_id: currentPolicy ? currentPolicy.category_id : category_id,
    status: currentPolicy
      ? currentPolicy.status === ""
        ? false
        : currentPolicy.status
      : true,
    type_id: currentPolicy ? currentPolicy.type_id : 4,
  };

  const formTitle = params.id
    ? intl.formatMessage({ id: "MENU.OPTIONS.EDIT" }) +
      " " +
      intl.formatMessage({ id: "Policy" })
    : intl.formatMessage({ id: "Add_new" }) +
      " " +
      intl.formatMessage({ id: "Policy" });

  return (
    <Card>
      {isLoading && <ModalProgressBar />}
      <AddCategoryModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        type_id={currentTypeId}
      />
      <CardHeader title={formTitle}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToPolicyList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={savePolicyClick}
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <Formik
            initialValues={initialValues}
            validationSchema={EditSchema}
            enableReinitialize
            onSubmit={async (values) => {
              try {
                await mutation.mutateAsync(values);
                history.push(`${a_policy_url}`);
              } catch (error) {
                console.error(error);
              }
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, values, setFieldValue, errors }) => (
              <>
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        type="text"
                        name="title"
                        component={Input}
                        placeholder="Title"
                        label="Title"
                        withFeedbackLabel={false}
                      />
                      {errors?.title && (
                        <span className="error">{errors?.title}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <Select
                        name="type_id"
                        label="Type"
                        withFeedbackLabel={false}
                        onChange={(e) => {
                          setCurrentTypeId(parseInt(e.target.value, 10));
                          setFieldValue(
                            "type_id",
                            parseInt(e.target.value, 10)
                          );
                        }}
                      >
                        <option value={5}>Privacy</option>
                        <option value={6}>Legal</option>
                        <option value={7}>FAQ</option>
                      </Select>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-11">
                          {!isLoading && (
                            <Select
                              name="category_id"
                              label="Section"
                              withFeedbackLabel={false}
                              id="category_id"
                              onChange={(e) => {
                                setFieldValue(
                                  "category_id",
                                  parseInt(e.target.value, 10)
                                );
                              }}
                            >
                              {data?.data?.map((section) => (
                                <option key={section.id} value={section.id}>
                                  {section.name}
                                </option>
                              ))}
                            </Select>
                          )}
                          {errors?.category_id && (
                            <span className="error">{errors?.category_id}</span>
                          )}
                        </div>
                        <div
                          className="col-1"
                          style={{ marginTop: 24, paddingLeft: 0 }}
                        >
                          <a
                            className="btn btn-primary"
                            onClick={() => setIsModalVisible(true)}
                          >
                            +
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Enter Content</label>
                    <Field
                      name="content"
                      as="textarea"
                      className="form-control"
                    />
                    {errors?.content && (
                      <span className="error">{errors?.content}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <div style={{ display: "flex", flexFlow: "column wrap" }}>
                      <label>Enter Status</label>
                      <div>
                        <Field
                          name="status"
                          component={Switch}
                          label="Status"
                          checked={values.status}
                          onChange={() => {
                            setFieldValue("status", !values.status);
                          }}
                        />
                        <label>
                          Policy will be{" "}
                          {values.status ? "published" : "unublished"}
                        </label>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    style={{ display: "none" }}
                    ref={btnRef}
                    onSubmit={() => handleSubmit()}
                  ></button>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </CardBody>
    </Card>
  );
};

export default PolicyEdit;
