import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

export const FolderCreateModal = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <div
      className="modal fade"
      id="folderModalCreate"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {props.folderId ? "Edit Folder" : "Create Folder"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i aria-hidden="true" className="ki ki-close" />
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Folder Name</label>
              <input
                onChange={(e) => props?.setFolderName(e.target.value)}
                type="text"
                className="form-control"
                value={props?.folderName}
              />
              {props?.folderErrors ? (
                <div className="text-danger">
                  {" "}
                  {props?.folderErrors?.details}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                onChange={(e) => props?.setFolderDes(e.target.value)}
                type="text"
                className="form-control"
                value={props?.folderDes}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold"
              data-dismiss="modal"
            >
              {intl.formatMessage({ id: "CONTACT.GENERAL.CANCEL.BUTTON" })}
            </button>
            <button
              onClick={() => props.handleCreateFolder()}
              type="button"
              // disabled={props.callLoader}
              className="btn btn-primary font-weight-bold d-flex"
            >
              {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
              {/* 
              <>
                <div className="spinner ml-2" style={{ width: "20px" }}></div>
              </> */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
