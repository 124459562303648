export const getAllPoliciesData = (payload) => {
  return {
    type: "GET_ALL_POLICY_DATA",
    payload: payload,
  };
};

export const setAllPoliciesData = (payload) => {
  return {
    type: "SET_ALL_POLICIES_DATA",
    payload: payload,
  };
};

export const deletePolicy = (id, intl, toasts) => {
  return {
    type: "DELETE_POLICY",
    id: id,
    intl: intl,
    toasts: toasts,
  };
};

export const deletePolicyFromRedux = (id) => {
  return {
    type: "DELETE_POLICY_FROM_REDUX",
    id: id,
  };
};

export const multiPolicyDeleteFromRedux = (ids) => {
  return {
    type: "DELETE_MULTI_POLICY_FROM_REDUX",
    ids: ids,
  };
};

export const createPolicy = (payload, history, intl, toasts) => {
  return {
    type: "CREATE_POLICY",
    payload: payload,
    history: history,
    intl: intl,
    toasts: toasts,
  };
};

export const editPolicy = (payload, id, history, intl, toasts) => {
  return {
    type: "EDIT_POLICY",
    payload: payload,
    id: id,
    history: history,
    intl: intl,
    toasts: toasts,
  };
};

export const getSections = (payload) => {
  return {
    type: "GET_SECTIONS",
    payload: payload,
  };
};

export const setSectionsData = (payload) => {
  return {
    type: "SET_SECTIONS_DATA",
    payload: payload,
  };
};

export const showLoader = (payload) => ({
  type: "SHOW_LOADER",
  showLoader: payload,
});
