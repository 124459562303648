import axios from "axios";
import {
  LOGIN_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
  SET_NEW_PASSWORD_URL,
  ME_URL,
  VALIDATE_URL,
} from "../../../../../src/_metronic/_helpers/Constants";

axios.interceptors.request.use((request) => {
  return request;
});

axios.interceptors.response.use((response) => {
  return response;
});

export function login(username, password, loginType) {
  return axios.post(LOGIN_URL, {
    username,
    password,
    loginType,
    platform_type: "arhimed",
  });
}

export function register(
  email,
  first_name,
  last_name,
  username,
  phone_number,
  institution_name,
  password
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name,
    last_name,
    username,
    phone_number,
    institution_name,
    password,
  });
}

export function validateEmail(email) {
  return axios.post(VALIDATE_URL, { email });
}

export function validatePhoneNumber(phone_number) {
  return axios.post(VALIDATE_URL, { phone_number });
}

export function validateUsername(username) {
  return axios.post(VALIDATE_URL, { username });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });

  let re = /(.+)@(.+){2,}\.(.+){2,}/;
  if (re.test(email)) {
    return axios.post(REQUEST_PASSWORD_URL, { email });
  } else {
    return axios.post(REQUEST_PASSWORD_URL, { username: email });
  }
}

export function setNewPassword(new_pass, rKey, login) {
  return axios.post(SET_NEW_PASSWORD_URL, {
    password: new_pass,
    token: rKey,
    email: login,
  });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
