import React, { useState, useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  useMediaQuery,
  useTheme,
  Button,
  DialogActions,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import {
  MAX_FOLDER_NAME_LENGTH,
  MIN_FOLDER_NAME_LENGTH,
  RENAME_STRING,
  searches_url,
} from "../../../../../../_metronic/_helpers";
import * as actions from "../../../_redux/folders/foldersActions";

const RenameFolderDialog = () => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const currentState = useSelector((state) => state.folders);
  const arhivaState = useSelector((state) => state.folders);
  const [folder, setFolder] = useState({
    old: "",
    new: "",
    description: "",
  });
  const { folderDialog, activeFolder, dialogType, activeId } = currentState;
  const { title, description, type, content } = activeFolder;
  const [disabled, setDisabled] = useState(true);

  const closeFormDialog = () => {
    dispatch(actions.closeFolderDialog());
    setDisabled(true);
  };
  const submitRenameFolder = () => {
    if (type === searches_url) {
      dispatch(
        actions.renameFolder(
          activeId,
          folder.new,
          type,
          folder.description,
          JSON.stringify(arhivaState.query),
          activeFolder.url_pretrage || arhivaState.url_pretrage
        )
      );
    } else {
      dispatch(
        actions.renameFolder(
          activeId,
          folder.new,
          activeFolder.type,
          folder.description
        )
      );
    }

    closeFormDialog();
  };
  useEffect(() => {
    if (
      folder.new &&
      folder.new.length > MIN_FOLDER_NAME_LENGTH &&
      folder.new.length < MAX_FOLDER_NAME_LENGTH
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [folder.new]);

  useEffect(() => {
    if (folderDialog && dialogType === RENAME_STRING) {
      setFolder({
        old: title,
        new: title,
        description: content,
      });
    }
  }, [folderDialog, dialogType]);

  // const [count, setCount] = useState(0);
  // const renders = useRef(0);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={true}
        aria-labelledby="form-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="form-dialog-title">
          {intl.formatMessage({ id: "DIALOG.RENAME.TITLE" })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage(
              { id: "DIALOG.WARNING.RENAME.MESSAGE" },
              {
                activeFoldertitle: title,
                MIN_FOLDER_NAME_LENGTH: MIN_FOLDER_NAME_LENGTH,
                MAX_FOLDER_NAME_LENGTH: MAX_FOLDER_NAME_LENGTH,
              }
            )}
          </DialogContentText>
          {activeFolder ? null : (
            <TextField
              onChange={(e) => setFolder({ ...folder, old: e.target.value })}
              autoFocus
              margin="dense"
              id="oldname"
              label="Dense"
              type="text"
              fullWidth
            />
          )}
          <TextField
            onChange={(e) => setFolder({ ...folder, new: e.target.value })}
            margin="dense"
            id="newname"
            label={intl.formatMessage({ id: "DIALOG.RENAME.NEW_TITLE" })}
            defaultValue={title}
            type="text"
            fullWidth
            autoComplete="off"
          />
          <TextField
            onChange={(e) => {
              setFolder({ ...folder, description: e.target.value });
            }}
            multiline
            rows="3"
            margin="dense"
            id="description"
            label={intl.formatMessage({ id: "DIALOG.RENAME.DESCRIPTION" })}
            defaultValue={content}
            type="text"
            fullWidth
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeFormDialog()} color="primary">
            {intl.formatMessage({ id: "DIALOG.WARNING.CANCEL" })}
          </Button>
          <Button
            onClick={() => {
              submitRenameFolder();
            }}
            color="primary"
            disabled={disabled}
          >
            {intl.formatMessage({ id: "DIALOG.WARNING.CONFIRM" })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RenameFolderDialog;
