/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { HELP_STRING } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/sass/pages/other/all-other.scss";
import ReactiveExtrasPage from "../../ReactiveBase/ReactiveExtrasPage";

export default function HelpCenterPage() {
	return <ReactiveExtrasPage type={HELP_STRING}/>
}

