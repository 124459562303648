import React from 'react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, PDF_STRING } from '../../../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { openFolderDialog } from '../../../../_redux/folders/foldersActions';
const PDFActions = () => {
    const dispatch = useDispatch();
    return (
        <>
            <OverlayTrigger
                overlay={<Tooltip>Pregled objave</Tooltip>}
            >
                <span 
                className="svg-icon svg-icon-lg svg-icon-primary ml-1" 
                onClick={()=>dispatch(openFolderDialog(PDF_STRING))} 
                style={{cursor: 'pointer'}}>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
                </span>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={<Tooltip>Preuzmi objavu</Tooltip>}
            >
                <span className="svg-icon svg-icon-lg svg-icon-primary ml-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Cloud-upload.svg")} />
                </span>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={<Tooltip>Pripremi za stampu</Tooltip>}
            >
                <span className="svg-icon svg-icon-lg svg-icon-primary ml-1">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Printer.svg")} />
                </span>
            </OverlayTrigger>


        </>
    )
}

export default PDFActions
