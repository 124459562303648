export const getFoldersData = (payload) => {
  return {
    type: "GET_FOLDERS_DATA",
    payload: payload,
  };
};

export const setFoldersData = (payload) => {
  return {
    type: "SET_FOLDERS_DATA",
    payload: payload,
  };
};

export const setFoldersError = (payload) => {
  return {
    type: "SET_FOLDERS_ERROR",
    payload: payload,
  };
};

export const createNewFolder = (payload, toasts, intl, modalClose) => {
  return {
    type: "CREATE_NEW_FOLDER",
    payload: payload,
    toasts: toasts,
    intl: intl,
    modalClose: modalClose,
  };
};

export const deleteFolder = (payload, toasts, intl, id, modalClose) => {
  return {
    type: "DELETE_PERSONAL_FOLDER",
    payload: payload,
    toasts: toasts,
    intl: intl,
    id,
    modalClose,
  };
};

export const assignFolderUser = (
  payload,
  toasts,
  intl,
  page,
  sizePerPage,
  row
) => {
  return {
    type: "ASSIGN_FOLDER_USER",
    payload,
    toasts,
    intl,
    page,
    sizePerPage,
    row,
  };
};

export const setAssignFolder = (payload) => {
  return {
    type: "SET_ASSIGN_FOLDER",
    payload: payload,
  };
};

export const setMultiAssignFolder = (payload, status) => {
  return {
    type: "SET_MULTI_ASSIGN_FOLDER",
    payload: payload,
    status: status,
  };
};
