export const getSubjectsData = (payload) => {
  return {
    type: "GET_SUBJECTS_DATA",
    payload: payload,
  };
};

export const setSubjectsData = (payload) => {
  return {
    type: "SET_SUBJECTS_DATA",
    payload: payload,
  };
};

export const createSubject = (payload, toasts, intl, setLoader) => {
  return {
    type: "CREATE_SUBJECT",
    payload: payload,
    toasts: toasts,
    intl: intl,
    setLoader,
  };
};

export const updateSubjects = (payload, toasts, intl, modalClose, getData) => {
  return {
    type: "EDIT_SUBJECT",
    payload: payload,
    toasts: toasts,
    intl: intl,
    modalClose: modalClose,
    getData,
  };
};

export const deleteSubject = (payload, toasts, intl) => {
  return {
    type: "DELETE_SUBJECT",
    payload: payload,
    toasts: toasts,
    intl: intl,
  };
};

export const deleteSubjectFromRedux = (payload, modalClose) => {
  return {
    type: "DELETE_SUBJECT_FROM_REDUX",
    payload: payload,
    modalClose: modalClose,
  };
};

export const deleteMultiSubjectFromRedux = (payload, modalClose, setAllIds) => {
  return {
    type: "DELETE_MULTI_SUBJECT_FROM_REDUX",
    payload: payload,
    modalClose: modalClose,
    setAllIds: setAllIds,
  };
};

export const setSubjectsErrors = (payload) => {
  return {
    type: "SET_ERRORS_SUBJECTS",
    payload: payload,
  };
};
