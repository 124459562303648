import { combineReducers } from "redux";
import { put, takeLatest, call } from "redux-saga/effects";
import { searchData } from "../Service/index";

export const startSearchLoading = (payload) => ({
  type: "SEARCH_LOADING_START",
});
export const stopSearchLoading = (payload) => ({
  type: "SEARCH_LOADING_STOP",
});
export const storeSearchData = (payload) => ({
  type: "SEARCH_DATA",
  payload: payload,
});
export const search = (payload) => ({
  type: "START_SEARCH",
  payload: payload,
});
export const searchedWordsAction = (payload) => ({
  type: "STORE_SEARCH_WORDS",
  payload: payload,
});

const isLoading = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case "SEARCH_LOADING_START":
      return true;
    case "SEARCH_LOADING_STOP":
      return false;
    default:
      return state;
  }
};

const data = (state = null, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SEARCH_DATA":
      return payload;
    default:
      return state;
  }
};

const searchedWords = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case "STORE_SEARCH_WORDS":
      return payload;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  data,
  isLoading,
  searchedWords,
});
export function* saga() {
  yield takeLatest("START_SEARCH", function* searchSaga(action) {
    try {
      yield put(startSearchLoading());
      const response = yield call(searchData, action.payload);
      yield put(storeSearchData(response?.data?.data ?? []));
    } catch (exeption) {
    } finally {
      yield put(stopSearchLoading());
    }
  });
}
