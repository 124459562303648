import React from "react";
import { Redirect, Switch, Route, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import PersonaInformation from "./PersonalInfo";
import AccountInformation from "./AccountInfo";
import ChangePassword from "./ChangePassword";
import EmailSettings from "./EmailSettings";
import MainOverview from "./MainOverview";
import { ManageChannels } from "./ManageChannels";

export function ProfileOverview() {
  const {
    user: { account_information, personal_information },
  } = useSelector((state) => state.auth);
  const intl = useIntl();
  return (
    <>
      <div className="d-flex flex-row" id="kt_profile_aside">
        <div className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px">
          <div className="card card-custom card-stretch">
            <div className="card-body pt-4">
              {/*
              <div className="d-flex justify-content-end">
                <div className="dropdown dropdown-inline">
                  <a
                    href="/"
                    className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="ki ki-bold-more-hor"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <ul className="navi navi-hover py-5">
                      <li className="navi-item">
                        <a href="/" className="navi-link">
                          <span className="navi-icon">
                            <i className="flaticon2-drop"></i>
                          </span>
                          <span className="navi-text">New Group</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              */}
              <div className="d-flex align-items-center">
                <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  <div className="symbol-label"></div>
                  <i className="symbol-badge bg-success"></i>
                </div>
                <div>
                  <div className="font-weight-bolder font-size-h5 text-dark-75">
                    {personal_information?.first_name}{" "}
                    {personal_information.last_name}
                  </div>
                  <div className="text-muted">
                    {!personal_information.company
                      ? intl.formatMessage({ id: "USER.PROFILE.TYPE_PERSONAL" })
                      : personal_information.company}
                  </div>
                  {/*
                  <div className="mt-2">
                    <a
                      href="/"
                      className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                    >
                      Chat
                    </a>
                    <a
                      href="/"
                      className="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
                    >
                      Follow
                    </a>
                  </div>
                  */}
                </div>
              </div>
              <div className="py-9">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">
                    {intl.formatMessage({ id: "AUTH.INPUT.USERNAME" })}
                  </span>
                  <span className="text-muted">
                    {account_information.username}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">
                    {intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
                  </span>
                  <span className="text-muted">
                    {account_information.email}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">
                    {intl.formatMessage({ id: "INPUT.MOBILE" })}
                  </span>
                  <span className="text-muted">
                    {personal_information.mobile}
                  </span>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink
                    to="/user/profile"
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Layers.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.PROFILE_OVERVIEW",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to="/user/profile-info"
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/User.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.PERSONAL_INFORMATIONS",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to="/user/account-info"
                    activeClassName="active"
                    className="navi-link py-4"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Compiling.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.ACCOUNT_INFORMATIONS",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to="/user/change-password"
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Shield-user.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.CHANGE_PASSWORD",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to="/user/email-settings"
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-opened.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({
                        id: "MENU.PROFILE.EMAIL_SETTINGS",
                      })}
                    </span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink
                    to="/user/manage-channels"
                    className="navi-link py-4"
                    activeClassName="active"
                  >
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Settings-1.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">
                      {intl.formatMessage({ id: "MENU.CHANNEL.DESCRIPTION" })}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row-fluid ml-lg-8">
          <Switch>
            <Redirect from="/user" exact={true} to="/user/profile" />
            <Route path="/user/profile" component={MainOverview} />
            <Route path="/user/profile-info" component={PersonaInformation} />
            <Route path="/user/account-info" component={AccountInformation} />
            <Route path="/user/change-password" component={ChangePassword} />
            <Route path="/user/email-settings" component={EmailSettings} />
            <Route path="/user/manage-channels" component={ManageChannels} />
          </Switch>
        </div>
      </div>
    </>
  );
}
