import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useIntl } from "react-intl";
import {
  toAbsoluteUrl,
  CREATE_STRING,
  RENAME_STRING,
  REMOVE_STRING,
  PURGE_STRING,
  POST_INFORMATION_STRING,
  PDF_STRING,
} from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import CreateFolderDialog from "../../../../app/modules/ECommerce/pages/folders/folder-dialogs/CreateFolderDialog";
import RenameFolderDialog from "../../../../app/modules/ECommerce/pages/folders/folder-dialogs/RenameFolderDialog";
import { useSelector, shallowEqual } from "react-redux";
import RemoveFolderDialog from "../../../../app/modules/ECommerce/pages/folders/folder-dialogs/RemoveFolderDialog";
import PurgeFolderDialog from "../../../../app/modules/ECommerce/pages/folders/folder-dialogs/PurgeFolderDialog";
import PostInformationDialog from "../../../../app/modules/ECommerce/pages/folders/folder-dialogs/PostInformationDialog";
import PdfDialog from "../../../../app/modules/ECommerce/pages/folders/folder-dialogs/PdfDialog";
export function Topbar() {
  const {
    user: { personal_information },
  } = useSelector((state) => state.auth);

  const uiService = useHtmlClassService();
  const intl = useIntl();

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
      viewChatDisplay: objectPath.get(uiService.config, "extras.chat.display"),
    };
  }, [uiService]);

  const folders = useSelector((state) => state.folders, shallowEqual);

  useEffect(() => {
    const s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/5cb8551cc1fe2560f3ff6dfd/1ee7tdmkg";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  }, []);

  const handleChatModal = () => {
    if (window.Tawk_API) {
      window.Tawk_API.setAttributes({
        name: `${personal_information?.first_name} ${personal_information.last_name}`,
        email: personal_information.email,
      });
      setTimeout(() => {
        window.Tawk_API.toggle();
      }, 200);
    }
  };

  return (
    <div className="topbar">
      {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

      {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

      {layoutProps.viewCartDisplay && <MyCartDropdown />}

      {layoutProps.viewQuickPanelDisplay && (
        <div
          className="topbar-item"
          data-toggle="tooltip"
          title="Quick panel"
          data-placement="right"
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="quick-panel-tooltip">
                {intl.formatMessage({ id: "TOOLTIP.QUICK_PANEL" })}
              </Tooltip>
            }
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
            </div>
          </OverlayTrigger>
        </div>
      )}

      {layoutProps.viewChatDisplay && (
        <div
          className="topbar-item"
          data-toggle="tooltip"
          title="Chat"
          data-placement="right"
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="chat-support-tooltip">
                {intl.formatMessage({ id: "TOOLTIP.CHAT_SUPPORT" })}
              </Tooltip>
            }
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_chat_modal"
              onClick={handleChatModal}
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group-chat.svg"
                  )}
                />
              </span>
            </div>
          </OverlayTrigger>
        </div>
      )}

      {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
      {/* modals are always set to open but parent component dictates it's display */}
      {folders.dialogType === CREATE_STRING && <CreateFolderDialog />}
      {folders.dialogType === RENAME_STRING && <RenameFolderDialog />}
      {folders.dialogType === REMOVE_STRING && <RemoveFolderDialog />}
      {folders.dialogType === PURGE_STRING && <PurgeFolderDialog />}
      {folders.dialogType === POST_INFORMATION_STRING && (
        <PostInformationDialog />
      )}
      {folders.dialogType === PDF_STRING && <PdfDialog />}
    </div>
  );
}
