import React, { useEffect, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { Modal, Button } from "react-bootstrap";

import ProfileService from "../../../Profile/service";
import { useDispatch } from "react-redux";
import * as toasts from "../../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";
import axios from "axios";
import { ARHIVA_REST_URL } from "../../../../../environments/environment";
import Select from "react-select";

const NotificationsModal = ({
  isVisible,
  setIsVisible,
  currentItem,
  setCurrentItem,
}) => {
  const service = new ProfileService();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  useEffect(() => {
    getAllChannels();
  }, []);

  const getAllChannels = () => {
    axios
      .post(`${ARHIVA_REST_URL}api/channels`)
      .then((response) => {
        setChannels(response.data.data);
      })
      .catch((e) => {});
  };

  const handleChange = (e) => {
    setSelectedChannels(e);
  };

  const handleSendNotification = () => {
    let data = {};
    data.post_id = currentItem.id;
    data.channel_id = selectedChannels.value;
    axios
      .post(`${ARHIVA_REST_URL}api/send-notification`, data)
      .then((response) => {
        dispatch(
          toasts.showToast(
            200,
            intl.formatMessage({ id: response.data.message })
          )
        );
        setIsVisible(false);
      })
      .catch((e) => {});
  };

  if (!isVisible) return null;

  const itemOption = "post";

  return (
    <Modal
      show={isVisible}
      onHide={() => {
        setCurrentItem(null);
        setIsVisible(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title> {currentItem.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>{intl.formatMessage({ id: "MENU.CHANNEL" })}</label>
        <Select
          onChange={(e) => handleChange(e)}
          value={selectedChannels}
          options={channels?.data?.map((channel) => {
            return {
              value: channel.id,
              label: channel.name,
              priority: "all",
            };
          })}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setCurrentItem(null);
            setIsVisible(false);
          }}
        >
          No
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleSendNotification();
          }}
          // onClick={async () => {
          //   try {
          //     //   mutation.mutate();
          //     setCurrentItem(null);
          //     setIsVisible(false);
          //   } catch (error) {}
          // }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationsModal;
