import { combineReducers } from "redux";

export const setAllChannelsData = (payload) => {
  return {
    type: "SET_ALL_CHANNELS_DATA",
    payload: payload,
  };
};

const Index = (state = [], action) => {
  const {
    payload,
    toasts,
    intl,
    id,
    closeModal,
    ids,
    setAllIds,
    data,
  } = action;
  switch (action.type) {
    case "SET_ALL_POST_SIZE_DATA":
      return payload;
    case "DELETE_POST_SIZE_FROM_REDUX":
      const deleteData = state.data.filter((x) => (x.id === id ? false : x));
      closeModal("exampleModalDelete");
      return {
        ...state,
        data: deleteData,
      };
    case "DELETE_MULTI_POST_SIZE_FROM_REDUX":
      const deleteMultiData = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );
      closeModal("exampleModalMultiDelete");
      setAllIds([]);
      return {
        ...state,
        data: deleteMultiData,
      };
    // ----------------------- Edit Redux ------------------------
    case "EDIT_POST_SIZE_FROM_REDUX":
      const editPostSize = state.data.filter((x) =>
        x.id === payload.id
          ? ((x.name = payload.name), (x.order = payload.order))
          : x
      );
      console.log("this post size", editPostSize);
      // closeModal("exampleModalEdit");
      return {
        ...state,
        data: editPostSize,
      };
    default:
      return state;
  }
};
const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  Index,
  showLoader,
});
