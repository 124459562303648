
  
  const MediaFactory = (data, observedRecord) => {
   const videoExtensions = [
      "3g2",
      "3gp",
      "aaf",
      "asf",
      "avchd",
      "avi",
      "drc",
      "flv",
      "m2v",
      "m3u8",
      "m4p",
      "m4v",
      "mkv",
      "mng",
      "mov",
      "mp2",
      "mp4",
      "mpe",
      "mpeg",
      "mpg",
      "mpv",
      "mxf",
      "nsv",
      "ogg",
      "ogv",
      "qt",
      "rm",
      "rmvb",
      "roq",
      "svi",
      "vob",
      "webm",
      "wmv",
      "yuv",
    ];
    
 const imageExtensions = [
      "ase",
      "art",
      "bmp",
      "blp",
      "cd5",
      "cit",
      "cpt",
      "cr2",
      "cut",
      "dds",
      "dib",
      "djvu",
      "egt",
      "exif",
      "gif",
      "gpl",
      "grf",
      "icns",
      "ico",
      "iff",
      "jng",
      "jpeg",
      "jpg",
      "jfif",
      "jp2",
      "jps",
      "lbm",
      "max",
      "miff",
      "mng",
      "msp",
      "nef",
      "nitf",
      "ota",
      "pbm",
      "pc1",
      "pc2",
      "pc3",
      "pcf",
      "pcx",
      "pdn",
      "pgm",
      "PI1",
      "PI2",
      "PI3",
      "pict",
      "pct",
      "pnm",
      "pns",
      "ppm",
      "psb",
      "psd",
      "pdd",
      "psp",
      "px",
      "pxm",
      "pxr",
      "qfx",
      "raw",
      "rle",
      "sct",
      "sgi",
      "rgb",
      "int",
      "bw",
      "tga",
      "tiff",
      "tif",
      "vtf",
      "xbm",
      "xcf",
      "xpm",
      "3dv",
      "amf",
      "ai",
      "awg",
      "cgm",
      "cdr",
      "cmx",
      "dxf",
      "e2d",
      "egt",
      "eps",
      "fs",
      "gbr",
      "odg",
      "svg",
      "stl",
      "vrml",
      "x3d",
      "sxd",
      "v2d",
      "vnd",
      "wmf",
      "emf",
      "art",
      "xar",
      "png",
      "webp",
      "jxr",
      "hdp",
      "wdp",
      "cur",
      "ecw",
      "iff",
      "lbm",
      "liff",
      "nrrd",
      "pam",
      "pcx",
      "pgf",
      "sgi",
      "rgb",
      "rgba",
      "bw",
      "int",
      "inta",
      "sid",
      "ras",
      "sun",
      "tga",
      "heic",
      "heif",
    ];
    
    const getExtension = (d) => {
      let extension = "";
      if (observedRecord?.data_segment_file?.Naziv) {
        extension = observedRecord?.data_segment_file?.Naziv;
      } else {
        if (observedRecord?.data_segment_file_links.length > 0 &&
          observedRecord?.data_segment_file_links[0]?.dma_data_segment_file?.Naziv) {
          extension =
            observedRecord?.data_segment_file_links?.[0]?.dma_data_segment_file
              ?.Naziv;
        } else {
          extension = observedRecord.dma_data_segment_file_crop_links?.[0]?.Naziv;
        }
      }
      extension = extension?.split(".")?.pop() ?? "";
  
      if (extension == "pdf") {
        return 2;
      } else if (videoExtensions.includes(extension)) {
        return 1;
      } else if (imageExtensions.includes(extension)) {
        return 3;
      }
      return 4;
    };
    const mediaType = { type: null, data: null };
    switch (getExtension(data)) {
      case 2:
        console.log("here run", observedRecord)
        mediaType.type = 2;
        mediaType.data = observedRecord?.data_segment_file_links.length > 0 &&
        observedRecord?.data_segment_file_links[0]?.dma_data_segment_file?.Naziv
          ? observedRecord?.data_segment_file_links.map(
              (dsfl) => dsfl?.dma_data_segment_file?.Naziv
            )
          : observedRecord?.dma_data_segment_file_crop_links.map(
              (dsfl) => dsfl?.Naziv
            );
        break;
      case 3:
        mediaType.type = 3;
        mediaType.data = observedRecord?.data_segment_file?.Naziv;
        mediaType.link = observedRecord?.data_segment_file?.Naziv;
        break;
      case 1:
        mediaType.type = 1;
        if (data.notification_data) {
          mediaType.data = data.notification_data?.video_link
            ? {
                videoDetails: data.notification_data?.video_link,
                id: observedRecord.ID,
                title: data.notification_data?.title,
              }
            : null;
        } else {
          mediaType.data = observedRecord?.data_segment_file?.Naziv
            ? {
                videoDetails: observedRecord?.data_segment_file?.Naziv,
                id: observedRecord?.ID,
                title: observedRecord?.Naslov,
              }
            : null;
        }
      default:
        break;
    }
  
    return mediaType;
  };
  
  export default MediaFactory;