import React from "react";
import {
  toAbsoluteUrl,
  a_crawler_url,
  a_manage_url,
  a_policy_url,
  a_users_url,
  a_channels_url,
} from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import "../../../../_metronic/_assets/sass/pages/other/all-other.scss";

const AdminDashboardPage = () => {
  const intl = useIntl();
  const adminoptions = [
    {
      title: `${intl.formatMessage({ id: "MENU.MANAGE" })}`,
      desc: `${intl.formatMessage({ id: "MENU.MANAGE.DESCRIPTION" })}`,
      icon: "/media/svg/icons/General/Edit.svg",
      link: a_manage_url,
    },
    {
      title: `${intl.formatMessage({ id: "MENU.USERS" })}`,
      desc: `${intl.formatMessage({ id: "MENU.USERS.DESCRIPTION" })}`,
      icon: "/media/svg/icons/General/User.svg",
      link: a_users_url,
    },
    {
      title: `${intl.formatMessage({ id: "MENU.CRAWLER" })}`,
      desc: `${intl.formatMessage({ id: "MENU.CRAWLER.DESCRIPTION" })}`,
      icon: "/media/svg/icons/Files/Download.svg",
      link: a_crawler_url,
    },
    {
      title: `${intl.formatMessage({ id: "MENU.CHANNEL" })}`,
      desc: `${intl.formatMessage({ id: "MENU.CHANNEL.DESCRIPTION" })}`,
      icon: "/media/svg/icons/Files/Download.svg",
      link: a_channels_url,
    },
    {
      title: `${intl.formatMessage({ id: "MENU.POLICY" })}`,
      desc: `${intl.formatMessage({ id: "MENU.POLICY.DESCRIPTION" })}`,
      icon: "/media/svg/icons/General/Clipboard.svg",
      link: a_policy_url,
    },
  ];

  return (
    <>
      <div
        className="d-flex flex-row-fluid bgi-size-cover bgi-position-top no-padding"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-9.jpg")})`,
        }}
      >
        <div className="container">
          <div className="d-flex justify-content-between align-items-center pt-25 pb-35">
            <h3 className="font-weight-bolder text-dark mb-0">
              {intl.formatMessage({ id: "PAGE.ADMIN.TITLE" })}
            </h3>
            <div className="d-flex">
              <NavLink
                to="/helpcenter"
                className="font-size-h4 font-weight-bold"
              >
                {intl.formatMessage({ id: "LINK.HELPCENTER" })}
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-n15">
        <div className="row">
          {adminoptions.map((option) => (
            <div className="col-lg-4">
              <NavLink
                to={option.link}
                className="font-size-h6 font-weight-bold"
              >
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-40 symbol-light-primary mr-5">
                        <span className="symbol-label">
                          <span className="svg-icon svg-icon-lg svg-icon-primary">
                            <SVG
                              className="h-75 align-self-end"
                              src={toAbsoluteUrl(`${option.icon}`)}
                            ></SVG>
                          </span>
                        </span>
                      </div>

                      <div className="d-flex flex-column font-weight-bold">
                        <h4 class="mb-0">{option.title}</h4>
                        <span className="text-muted">{option.desc}</span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdminDashboardPage;
