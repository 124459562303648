export const getPostsData = (payload) => {
  return {
    type: "GET_POST_DATA",
    payload: payload,
  };
};

export const setPostsData = (payload) => {
  return {
    type: "SET_POST_DATA",
    payload: payload,
  };
};

export const deletePostFromRedux = (payload) => {
  return {
    type: "DELETE_POST_FROM_REDUX",
    payload: payload,
  };
};

export const multiPostDeleteFromRedux = (payload) => {
  console.log("payyy", payload);
  return {
    type: "MULTI_POST_DELETE_FROM_REDUX",
    ids: payload.ids,
    // setAllIds: payload.setAllIds,
  };
};
