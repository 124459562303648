import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";

export const getSubjectsCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/subjects/list`, data);
};

export const createSubjectCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/subjects/create`, data);
};
// editSubjectCall

export const editSubjectCall = (data) => {
  return axios.post(`${ARHIVA_REST_URL}api/subjects/update`, data);
};

export const deleteSubjectCall = (payloda) => {
  return axios.post(`${ARHIVA_REST_URL}api/subjects/delete`, payloda);
};
