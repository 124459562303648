export const getAllRoles = (payload) => {
  return {
    type: "GET_ALL_ROLES",
    payload: payload,
  };
};

export const setAllRoles = (payload) => {
  return {
    type: "SET_ALL_ROLES",
    payload: payload,
  };
};
